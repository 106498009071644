import React, { FormEvent, SyntheticEvent, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Grid, Button, Alert, Form } from "tabler-react";

import { ContentTextProps, blackListProps } from "../../types";
import ServerSidePagination from "../../components/pagination/serverSidePagination";
import TableCard from "../../components/tableCard";
import TableModal from "./tableModal";

import "./style.css";

const PublicWorkFlows = ({
  data,
  loading,
  setSearchWorkflow,
  searchWorkflow,
  updatePublicWorkflow,
  onPageNumberChange,
  deletePublicWorkflow,
  ...props
}: any): JSX.Element => {
  const [workflowsList, setWorkflowsList] = useState<Array<blackListProps>>([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [editCategory] = useState(false);
  const [mostUsed, setMostUsed] = useState(false);
  const [emailText, setEmailText] = useState("");
  const [total, setTotal] = useState(0);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [editField, setEditField] = useState(null);

  const handleModal = () => {
    if (showModal) {
      setEditField(null);
      setErrorMessage("");
    }
    setShowModal(!showModal);
  };

  const handleSave = async ({ ...rest }) => {
    const { id, name, description, metaTitle, metaDescription, image, categoryIds } = rest;
    if (editCategory) {
    } else {
      await updatePublicWorkflow({
        variables: {
          settings: {
            id,
            name: name,
            description: description || "",
            meta_title: metaTitle || "",
            meta_description: metaDescription || "",
            image: image,
            categoryIds: categoryIds,
            configs: {
              send_mail: false,
              selectedOutputKeys: [],
            },
          },
        },
      });
    }
    return true;
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    props.setToDate(toDate);
    props.setFromDate(fromDate);
    props.setMostUsed(mostUsed);
    setSearchWorkflow(emailText);
  };

  const handleFromDate = (date: Date) => {
    if (date) {
      return setFromDate(moment(date).format("YYYY-MM-DDT00:00:00.000"));
    }
    setFromDate(null);
  };

  const handleToDate = (date: Date) => {
    if (date) {
      return setToDate(moment(date).format("YYYY-MM-DDT23:59:59.999"));
    }
    setToDate(null);
  };

  const handlePublicWorkflowDelete = async (id: number): Promise<any> => {
    const confirmed = confirm(`Are you sure you want to delete public workflow with an ID of ${id}`);

    if (confirmed) {
      const { data } = await deletePublicWorkflow({
        variables: {
          id,
        },
      });
      const { message, error } =
        data && data.HexomaticPublicWorkflowOps && data.HexomaticPublicWorkflowOps.adminDeletePublicWorkflow;

      if (error) {
        setErrorMessage(message);
        alert(message);
      }
    }
  };

  const textCheckbox = ({ item, name }: { item: any; name: string }) => {
    return (
      <div className="check-wrapper">
        <span>{name.replace(/_/g, " ")}</span>
        <Form.Checkbox
          className="crs-point"
          label=" "
          checked={item[name]}
          onChange={async (e: SyntheticEvent) => {
            e.stopPropagation();

            const res = await updatePublicWorkflow({
              variables: {
                settings: {
                  id: item.id,
                  most_used: name === "most_used" ? !item.most_used : null,
                },
              },
            });

            if (!res.data.HexomaticPublicWorkflowOps.adminUpdatePublicWorkflow.error) {
              props.refetch();
            }
          }}
        />
      </div>
    );
  };

  const tableData = {
    tData:
      workflowsList &&
      workflowsList.map((item: ContentTextProps) => ({
        id: item.id,
        "Created at": moment(item.createdAt).utc().format("DD-MM-YYYY hh:mm:ss a"),
        user_email: item.user_email,
        name: item.name,
        // "Meta title": item.meta_title,
        // "Meta description": item.meta_description,
        "Short description": item.description,
        "Public description": item.meta_description,
        Illustration: item.image,
        Category: item.categories && item.categories.map((item: any) => item.name).join(", "),
        steps:
          item.steps &&
          item.steps.map((step) => <img src={step.automation.icon_url} style={{ width: "20px" }} alt="" />),
        most_used: textCheckbox({ item, name: "most_used" }),

        Action: (
          <Button.List className="d-flex">
            <Button
              color="primary"
              icon="edit"
              onClick={() => {
                setEditField(item);
                setShowModal(true);
              }}
            />
            <Button color="danger" icon="trash" onClick={() => handlePublicWorkflowDelete(item.id)} />
          </Button.List>
        ),
      })),
  };

  useEffect(() => {
    if (
      data &&
      data.HexomaticPublicWorkflow &&
      data.HexomaticPublicWorkflow.getPublicWorkflows &&
      data.HexomaticPublicWorkflow.getPublicWorkflows.public_workflows
    ) {
      setDataLoading(loading);
      setWorkflowsList(data.HexomaticPublicWorkflow.getPublicWorkflows.public_workflows);
      setTotal(data.HexomaticPublicWorkflow.getPublicWorkflows.count);
    }
  }, [data]);

  return (
    <Grid.Row cards deck>
      <TableModal
        handleModal={handleModal}
        showModal={showModal}
        onSave={handleSave}
        editFiled={editField}
        errorMessage={errorMessage}
      />
      <div className="col-12 col-md-8"></div>
      <div className="col-12 col-md-4">
        <form onSubmit={(e) => handleSubmit(e)}>
          <Form.Group>
            <Form.Input
              label="Input"
              className="margin-bottom"
              icon="search"
              position="append"
              name="userEmail"
              placeholder="Type user email for search"
              value={emailText}
              onChange={(e) => setEmailText(e.target.value)}
            />
            <Grid.Row>
              <Grid.Col>
                <Form.Group label="From Date">
                  <DatePicker
                    dateFormat="yyyy-MM-dd"
                    selected={fromDate ? new Date(fromDate) : null}
                    onChange={handleFromDate}
                    showMonthDropdown
                    placeholderText="Select a date"
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col>
                <Form.Group label="To Date">
                  <DatePicker
                    dateFormat="yyyy-MM-dd"
                    selected={toDate ? new Date(toDate) : null}
                    onChange={handleToDate}
                    showMonthDropdown
                    placeholderText="Select a date"
                    minDate={fromDate && new Date(fromDate)}
                  />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>

            <Grid.Row className="mb-4">
              <Grid.Col>
                <Form.Checkbox
                  className="crs-point"
                  label="Most used"
                  checked={mostUsed}
                  onChange={() => setMostUsed(!mostUsed)}
                />
              </Grid.Col>
            </Grid.Row>
            <Button
              type="submit"
              icon="filter"
              color="primary"
              className=""
              loading={dataLoading}
              // onClick={() => setsearchEmail(emailText)}
            >
              Filter
            </Button>
          </Form.Group>
        </form>
      </div>
      {/* </Grid.Row> */}

      <Grid.Col md={10}></Grid.Col>
      <div className="col-12 col-md-2 col-xs-6">
        <Alert type="info">Result Count - {total}</Alert>
      </div>
      <div className="black-list-table-pagination">
        <ServerSidePagination data={workflowsList} total={total} onPageNum={onPageNumberChange}>
          <TableCard loading={dataLoading} data={workflowsList} tableData={tableData} />
        </ServerSidePagination>
      </div>

      {workflowsList.length ? (
        <div className="col-12 col-md-2 col-xs-12 offset-md-10">
          <Alert type="info">Result Count - {total}</Alert>
        </div>
      ) : null}
    </Grid.Row>
  );
};

export default PublicWorkFlows;
