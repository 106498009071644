import React, { useState, Fragment } from "react";
import { GET_PULLCONTACT_DATA } from "../../graphql/queries";
import PullContact from "./index";
import { Grid, Form } from "tabler-react";
import { useQuery } from "@apollo/react-hooks";

const PullContactQueries = () => {
  const [ipSearch, setIpSearch] = useState("");
  const [source, setSource] = useState("");
  const [pageNumberState, setPageNumberState] = useState(1);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [filterHexUsers, setFilterHexUsers] = useState(true);
  const [orderBy, setOrderBy] = useState("");
  const [orderByASC, setOrderByASC] = useState(false);
  const [status, setStatus] = useState("all");

  const { data, loading, refetch } = useQuery(GET_PULLCONTACT_DATA, {
    fetchPolicy: "no-cache",
    variables: {
      filter: {
        ip: ipSearch,
        source: source,
        status: status === "all" ? null : status,
        from: fromDate,
        to: toDate,
      },
      sortSettings: {
        sortField: "",
        sortDir: orderBy,
      },
      pageSettings: {
        page: pageNumberState,
        pageSize: 100,
      },
    },
  });

  const handleIpSearch = (value: string) => setIpSearch(value);
  const handlePageNumberChange = (pageNumber: number) =>
    setPageNumberState(pageNumber);
  const handleFromDate = (date: string) => setFromDate(date);
  const handleToDate = (date: string) => setToDate(date);

  const handleSource = (value: string) => setSource(value);

  return (
    <Fragment>
      {/* <Grid.Row>
        <Grid.Col md={12}>
          <Form.Checkbox
            label="Filter data from Hexact"
            name="example-radios"
            value="option1"
            checked={filterHexUsers}
            onChange={() => setFilterHexUsers(!filterHexUsers)}
          />
        </Grid.Col>
      </Grid.Row> */}
      <PullContact
        data={data}
        loading={loading}
        onHandleIpSearch={handleIpSearch}
        onPageNumberChange={handlePageNumberChange}
        onFromDate={handleFromDate}
        onToDate={handleToDate}
        onHandleSource={handleSource}
        onStatusChange={setStatus}
        refetchData={() => refetch()}
        filterHexProperties={filterHexUsers}
        setOrderByASC={setOrderByASC}
        orderByASC={orderByASC}
        setOrderBy={setOrderBy}
        variables={{
          filter: {
            ip: ipSearch,
            source: source,
            from: fromDate,
            to: toDate,
          },
          sortSettings: {
            sortField: "",
            sortDir: orderBy,
          },
          pageSettings: {
            page: pageNumberState,
            pageSize: 100,
          },
        }}
      />
    </Fragment>
  );
};

export default PullContactQueries;
