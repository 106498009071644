import React, { useState, useEffect } from "react";
import { Grid, Button, Alert, Form } from "tabler-react";

import ServerSidePagination from "../../components/pagination/serverSidePagination";
import TableCard from "../../components/tableCard";

interface ExcludedUsersProps {
  data: any;
  loading: boolean;
  refetchData: () => void;
  onPageNumberChange: (pageNumber: number) => void;
  onFilterDataChange: (data: string) => void;
  onDeleteExcludedUsers: (arg: number[]) => void;
  onAddExcludedUser: (arg: number) => void;
}

const ExcludedUsers = ({ refetchData, ...props }: ExcludedUsersProps): JSX.Element => {
  const [excludedUsersList, setExcludedUsersList] = useState([]);
  const [total, setTotal] = useState(0);
  const [dataLoading, setDataLoading] = useState(false);
  const [filterInput, setFilterInput] = useState("");
  const showButtons = localStorage.getItem("_rep");

  const tableData = {
    tData:
      excludedUsersList &&
      excludedUsersList.map((item) => ({
        ID: item.main_postgres_user_id,
        "HC MONGO USER ID": item.hc_mongo_user_id || "N/A",
        "HM MONGO USER ID": item.hm_mongo_user_id || "N/A",
        "WATCH MONGO USER ID": item.watch_mongo_user_id || "N/A",
      })),
  };

  useEffect(() => {
    if (
      props.data &&
      props.data.ExcludedUser &&
      props.data.ExcludedUser.getExcludedUsers &&
      props.data.ExcludedUser.getExcludedUsers.excludedUsers
    ) {
      setDataLoading(props.loading);
      setExcludedUsersList(props.data.ExcludedUser.getExcludedUsers.excludedUsers);
      setTotal(props.data.ExcludedUser.getExcludedUsers.total);
    }
  }, [props.data]);

  const handleInputChange = (e: React.FormEvent) => setFilterInput((e.target as HTMLInputElement).value);

  const handleSubmit = () => {
    props.onFilterDataChange(filterInput.trim());
  };

  const handleAddExclUser = () => {
    const num = +filterInput.trim();
    props.onAddExcludedUser(num);
    setFilterInput("");
  };

  const handleDeleteExclUser = () => {
    const arr = filterInput
      .trim()
      .split(",")
      .map((el) => +el);
    props.onDeleteExcludedUsers(arr);
    setFilterInput("");
  };

  return (
    <Grid>
      <Grid.Row>
        <div className="col-12 col-md-8"></div>
        <div className="col-12 col-md-4">
          <Form.Group>
            <Form.Input
              label="Input"
              className="margin-bottom"
              icon="search"
              position="append"
              placeholder="Search User by id"
              value={filterInput}
              onChange={handleInputChange}
            />

            <Button className="margin-bottom mr-2" onClick={handleSubmit} icon="filter" color="primary">
              Filter
            </Button>

            <Button className="margin-bottom mr-2" onClick={handleAddExclUser} color="primary">
              Exclude user
            </Button>

            <Button className="margin-bottom mr-2" onClick={handleDeleteExclUser} color="primary">
              Delete excluded user
            </Button>
          </Form.Group>
        </div>
      </Grid.Row>
      <Grid.Row className={showButtons === "true" ? "justify-content-between" : "justify-content-end"}>
        <Grid.Col md={9}></Grid.Col>
        <Grid.Col md={3}>
          <Alert type="info">Result Count - {total}</Alert>
        </Grid.Col>
      </Grid.Row>

      <Grid.Row cards deck>
        <ServerSidePagination data={excludedUsersList} total={total} onPageNum={props.onPageNumberChange}>
          <TableCard
            loading={dataLoading}
            data={excludedUsersList}
            tableData={tableData}
            onHeaderClick={() => {}}
          />
        </ServerSidePagination>
      </Grid.Row>
      <Grid.Row className="justify-content-end">
        {total ? (
          <Grid.Col md={3}>
            <Alert type="info">Result Count - {total}</Alert>
          </Grid.Col>
        ) : null}
      </Grid.Row>
    </Grid>
  );
};

export default ExcludedUsers;
