import React, { useEffect, useState } from 'react';
import { Grid, Button, Alert, Form } from "tabler-react";
import { ContentTextProps, blackListProps } from "../../types";
import ServerSidePagination from "../../components/pagination/serverSidePagination";
import moment from "moment";
import SearchInput from "../../components/searchInput";
import TableCard from "../../components/tableCard";
import TableModal from "./tableModal";
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_SHORTLINK_QUERY } from '../../graphql/queries';

import './style.css'

const Shortlink = (
  { data, loading, setsearchLink, searchLink, createShortlinks, onPageNumberChange }: any
): JSX.Element => {
  const [links, setLinks] = useState<Array<blackListProps>>([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [mutationCompleted, setMutationCompleted] = useState(false);
  const [editEmail, setEditEmail] = useState<any>();
  const [total, setTotal] = useState(0);
  const [drive, setDrive] = useState(false);
  const [getCSV, { data: CSVData, loading: CSVLoading }] = useLazyQuery(GET_SHORTLINK_QUERY);

  useEffect(() => {
    if (CSVData && CSVData.Shortlink && CSVData.Shortlink.getMany && CSVData.Shortlink.getMany.csv_url && !drive) {
      window.location.href=CSVData.Shortlink.getMany.csv_url
    } else if (CSVData && CSVData.Shortlink && CSVData.Shortlink.getMany && CSVData.Shortlink.getMany.csv_url && drive) {
      window.open(
        `https://api.hexometer.com/v2/app/social-callback/google/drive/v1/uploadFile?url=${CSVData.Shortlink.getMany.csv_url}`,
        '_blank',
      );
    }
  }, [CSVData]);

  const handleModal = () => {
    if (showModal) {
      setEditEmail(null);
      setErrorMessage("")
    }
    setShowModal(!showModal);
  }

  const handleSave = async ({ ...rest }) => {
    const { linksArr } = rest;
    const createResponse = await createShortlinks({
      variables: { links: linksArr },
    });

    const error = createResponse.data.ShortlinkOps.addMany.error;
    const message = createResponse.data.ShortlinkOps.addMany.message;
    if (error) {
      setErrorMessage(message);
      return false;
    } else {
      setMutationCompleted(true);
    }
    return true;
  };

  const handleKeywordMutate = (row: object | null) => {
    setShowModal(true);
  };
  const tableData = {
    tData: links && links.map((item: ContentTextProps) => (
      {
        // "ID": item.id,
        "Full link": item.link,
        "Shortlink": item.shortlink,
        "created at": moment(item.createdAt).format('DD-MM-YYYY'),
        // "Action":
        //   <Button.List>
        //     <Button color="primary" icon="edit" onClick={() => handleKeywordMutate(item)} />
        //     <Button color="danger" icon="trash" onClick={() => handleKeywordDelete(item)} />
        //   </Button.List>
      }
    ))
  };

  useEffect(() => {
      setDataLoading(loading);
    if (data && data.Shortlink && data.Shortlink.getMany) {
      setLinks(data.Shortlink.getMany.shortlinks)
      setTotal(data.Shortlink.getMany.total_count)
    }
  }, [data]);

  return (
    <Grid.Row cards deck>
      <TableModal
        handleModal={handleModal}
        showModal={showModal}
        onSave={handleSave}
        editEmail={editEmail}
        errorMessage={errorMessage}
      />
      <div className="col-12 col-md-12 col-xs-12">
        <Form.Group className="offset-9">
          <Form.Input
            icon="search"
            position="append"
            placeholder={'Search for link'}
            value={searchLink}
            onChange={(e: React.FormEvent) => {
              const inputText = (e.target as HTMLInputElement).value;
              setsearchLink(inputText);
            }}
          />
        </Form.Group>
      </div>
      <div className="col-12 col-md-6 col-xs-6">
        
        <Button.List>
        <Button
          className=""
          onClick={() => handleKeywordMutate(null)}
          color="primary"
        >Create</Button>
        <Button
          onClick={() => {getCSV({ variables: { settings: {download_csv: true}, drive: !drive}}); setDrive(false)}}
          loading={CSVLoading && !drive}
          color="primary"
          mr={1}
        >
          Get CSV Link
        </Button>
        <Button
          onClick={() => {getCSV({ variables: { settings: {download_csv: true}, drive: drive}}); setDrive(true)}}
          loading={CSVLoading && drive}
          color="primary"
        >
          Save to Drive
        </Button>
      </Button.List>
        <br />
      </div>
      
      <Grid.Col md={4}>
      </Grid.Col>
      <div className="col-12 col-md-2 col-xs-6">
        <Alert type="info">
          Result Count - {total}
        </Alert>
      </div>
      <div className="black-list-table-pagination">
        <ServerSidePagination
          data={links}
          total={total}
          onPageNum={onPageNumberChange}
        >
          <TableCard
            loading={dataLoading}
            data={links}
            tableData={tableData}
          />
        </ServerSidePagination>
      </div>

      {links.length ? <div className="col-12 col-md-2 col-xs-12 offset-md-10">
        <Alert type="info">
          Result Count - {total}
        </Alert>
      </div> : null}
    </Grid.Row>
  )
};

export default Shortlink;
