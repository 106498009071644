import React from "react";
import { KEYWORD_CREATE_MUTATION, KEYWORD_DELETE_MUTATION, KEYWORD_UPDATE_MUTATION } from "../../graphql/mutations";
import { GET_TRANSLATIONS } from "../../graphql/queries";
import Keywords from "./index";
import { useQuery, useMutation } from "@apollo/react-hooks";

const KeywordsQueries = () => {
  const { data, loading } = useQuery(GET_TRANSLATIONS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true
  });
  const [createKeyword] = useMutation(KEYWORD_CREATE_MUTATION, {
    refetchQueries: [{ query: GET_TRANSLATIONS }]
  });
  const [updateKeyword] = useMutation(KEYWORD_UPDATE_MUTATION, {
    refetchQueries: [{ query: GET_TRANSLATIONS }]
  });
  const [deleteKeyword] = useMutation(KEYWORD_DELETE_MUTATION, {
    refetchQueries: [{ query: GET_TRANSLATIONS }]
  });

  return (
    <Keywords
      data={data}
      loading={loading}
      createKeyword={createKeyword}
      updateKeyword={updateKeyword}
      deleteKeyword={deleteKeyword}
    />
  )
};


export default KeywordsQueries;
