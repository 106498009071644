import React, { useState, useEffect } from "react";
import { CREATE_AUTOMATION_TYPES, DELETE_AUTOMATION_TYPE, UPDATE_AUTOMATION_TYPE } from "../../graphql/mutations";
import { GET_AUTOMATION_TYPES } from "../../graphql/queries";
import AutomationTypes from "./index";
import { useQuery, useMutation } from "@apollo/react-hooks";

const AutomationTypesQueries = () => {
  const [typeList, setTypeList] = useState<any>([]);
  const [pageNumberState, setPageNumberState] = useState(1);
  const handlePageNumberChange = (pageNumber: number) => setPageNumberState(pageNumber);
  const [searchEmail, setsearchEmail] = useState<string>('')

  const { data, loading, refetch } = useQuery(GET_AUTOMATION_TYPES, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      settings: {
        filter: {
          limit: 100
        }
      }
    }
  })

  useEffect(() => {
    refetch()
  }, [pageNumberState])

  useEffect(() => {
    setTypeList(data)
  }, [data])

  useEffect(() => {
    refetchData()
  }, [searchEmail])

  const refetchData = async () => {
    await refetch()
  }

  const [deleteType] = useMutation(DELETE_AUTOMATION_TYPE)

  const [updateType] = useMutation(UPDATE_AUTOMATION_TYPE)


  const [createAutomationType] = useMutation(CREATE_AUTOMATION_TYPES, {
    onCompleted: () => {
      refetchData();
    }
  })

  return (
    <AutomationTypes
      data={typeList}
      loading={loading}
      searchEmail={searchEmail}
      setsearchEmail={setsearchEmail}
      onPageNumberChange={handlePageNumberChange}
      createAutomationType={createAutomationType}
      deleteType={deleteType}
      updateType={updateType}
    />
  )
};


export default AutomationTypesQueries;
