import React, { useEffect, useState } from "react";
import { Alert, Button, Form } from "tabler-react";
import ReactModal from "react-modal";

import { TableModalProps } from "../../types";
import { Category } from "./index";

ReactModal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    height: "50%",
  },
};

interface TableModalP extends TableModalProps {
  categoriesList: Category[];
}

const TableModal = ({
  handleModal,
  showModal,
  onSave,
  editValue,
  errorMessage,
  categoriesList,
}: TableModalP): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [scrapingRecipeId, setScrapingRecipeId] = useState(0);

  const [categoryIds, setCategoryIds] = useState<string[]>([]);
  const [currCategoryIds, setCurrCategoryIds] = useState<string[]>([]);

  const handleOk = async () => {
    setLoading(true);
    const formData = { scrapingRecipeId, categoryIds, currCategoryIds };
    const completed = await onSave({ ...formData });
    setLoading(false);
    if (completed) handleCancel();
  };

  const handleCancel = () => {
    setScrapingRecipeId(0);
    handleModal();
    setCurrCategoryIds([]);
    setCategoryIds([]);
  };

  useEffect(() => {
    if (editValue) {
      setScrapingRecipeId(editValue.id);

      if (editValue.categories) {
        const ct = editValue.categories.map((item: Category) => item.id);
        setCategoryIds(ct);
        setCurrCategoryIds(ct);
      }
    }
  }, [editValue]);

  useEffect(() => {
    if (window.innerWidth < 640) {
      customStyles.content.width = "90%";
    }
  }, []);

  return (
    <div style={{ height: 0 }}>
      <ReactModal
        isOpen={showModal}
        contentLabel="Select category name"
        style={customStyles}
        onRequestClose={handleCancel}
      >
        <h4>{editValue ? "Edit category name" : "Create category name"}</h4>
        {errorMessage ? <Alert type="danger">{errorMessage}</Alert> : null}
        <Form onSubmit={(e: React.FormEvent) => e.preventDefault()}>
          {categoriesList.length
            ? categoriesList.map((item: Category) => (
                <div key={item.id} className="d-flex">
                  <Form.Checkbox
                    className=""
                    label={item.name}
                    checked={categoryIds.includes(item.id)}
                    onChange={() => {
                      if (categoryIds.includes(item.id)) {
                        const category = categoryIds.filter((ctg: string) => ctg !== item.id);
                        setCategoryIds(category);
                      } else {
                        const category = [...categoryIds];
                        category.push(item.id);
                        setCategoryIds(category);
                      }
                    }}
                  />
                </div>
              ))
            : null}

          <Button.List style={{ marginTop: "5%" }}>
            <Button onClick={handleOk} color="primary" type="submit" loading={loading}>
              Save
            </Button>
            <Button onClick={handleCancel} color="secondary" type="button">
              Cancel
            </Button>
          </Button.List>
        </Form>
      </ReactModal>
    </div>
  );
};

export default TableModal;
