import React, { useEffect, useState } from "react";
import { Grid, Button, Alert, Form } from "tabler-react";
import { ContentTextProps, blackListProps } from "../../types";
import ServerSidePagination from "../../components/pagination/serverSidePagination";
import TableCard from "../../components/tableCard";
import TableModal from "./tableModal";
import ReactModal from "react-modal";

import "./style.css";
import { alertCustomStyles } from "../../helpers";

const PublicWorkflowCategories = ({
  data,
  loading,
  createAutomationCategory,
  deleteCategory,
  updateCategory,
  onPageNumberChange,
}: any): JSX.Element => {
  const [categoryList, setCategoryList] = useState<Array<blackListProps>>([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [editCategory, setEditCategory] = useState<any>(false);
  const [total, setTotal] = useState(0);
  const [editField, setEditField] = useState<any>(null);

  const handleModal = () => {
    if (showModal) {
      setEditField(null);
      setErrorMessage("");
    }
    setShowModal(!showModal);
  };

  const handleSave = async ({ ...rest }) => {
    const { name, shortDescription, longDescription, image } = rest;
    if (editCategory) {
    } else {
      const res = await createAutomationCategory({
        variables: {
          data: {
            name: name,
            short_description: shortDescription,
            long_description: longDescription,
            image: image,
          },
        },
      });
      if (
        res.data.HexomaticWorkflowCategoryOps &&
        !res.data.HexomaticWorkflowCategoryOps.createWorkflowCategory
      ) {
        handleModal();
        return;
      }
      const { error } =
        res.data.HexomaticWorkflowCategoryOps.createWorkflowCategory;
      if (error) {
        setErrorMessage(error);
        return;
      }
    }
    return true;
  };

  const onDelete = async (id: number) => {
    let deleteConfirm = confirm(
      `Are you sure you want to delete public workflow category with ID: ${id}`
    );
    if (!deleteConfirm) return;
    const res = await deleteCategory({
      variables: { id: id },
    });
    if (
      res.data.HexomaticWorkflowCategoryOps &&
      !res.data.HexomaticWorkflowCategoryOps.deleteWorkflowCategory
    ) {
      setCategoryList(categoryList.filter((item) => item.id !== id));
      return;
    }
    const { error_code } = res.data.HexomaticWorkflowCategoryOps.deleteWorkflowCategory;
    if (error_code) {
      setErrorMessage(error_code);
      return;
    }
  };

  const onUpdata = async ({ id, name, image, short_description, long_description }) => {
    const res = await updateCategory({
      variables: { data: { id, name, image, short_description, long_description } },
    });
    handleModal();
    if (
      res.data.HexomaticWorkflowCategoryOps &&
      !res.data.HexomaticWorkflowCategoryOps.updateWorkflowCategory
    ) {
      const newCategories = categoryList.map((item) => {
        if (item.id === id) {
          return { ...item, name: name,image: image, short_description: short_description, long_description: long_description  };
        }
        return item;
      });
      setCategoryList(newCategories);
      return;
    }
    const { error_code } = res.data.HexomaticWorkflowCategoryOps.updateWorkflowCategory;
    if (error_code) {
      setErrorMessage(error_code);
      return;
    }
  };

  const tableData = {
    tData:
      categoryList &&
      categoryList.map((item: ContentTextProps) => ({
        id: item.id,
        name: item.name,
        image: item.image,
        short_description: item.short_description,
        long_description: item.long_description,
        Action: (
          <Button.List>
            <Button
              color="primary"
              icon="edit"
              onClick={() => {
                setEditField(item);
                setShowModal(true);
              }}
            />
            <Button
              color="danger"
              icon="trash"
              onClick={() => onDelete(item.id)}
            />
          </Button.List>
        ),
      })),
  };

  useEffect(() => {
    if (
      data &&
      data.HexomaticWorkflowCategory &&
      data.HexomaticWorkflowCategory.getWorkflowCategories &&
      data.HexomaticWorkflowCategory.getWorkflowCategories.categories
    ) {
      setDataLoading(loading);
      setCategoryList(
        data.HexomaticWorkflowCategory.getWorkflowCategories.categories
      );
      // setTotal(data.EmailBlackList.adminGetMany.total)
    }
  }, [data]);

  return (
    <Grid.Row cards deck>
      <TableModal
        handleModal={handleModal}
        showModal={showModal}
        onUpdate={onUpdata}
        onSave={handleSave}
        editField={editField}
        errorMessage={errorMessage}
      />

      <ReactModal isOpen={!!errorMessage} style={alertCustomStyles}>
        {errorMessage ? <Alert type="danger">{errorMessage}</Alert> : null}
        <Button
          className=""
          onClick={() => setErrorMessage("")}
          color="primary"
        >
          OK
        </Button>
      </ReactModal>
      <div className="col-12 col-md-1 col-xs-6">
        <Button className="" onClick={() => setShowModal(true)} color="primary">
          Create
        </Button>
        <br />
      </div>
      <Grid.Col md={9}></Grid.Col>
      <div className="col-12 col-md-2 col-xs-6">
        <Alert type="info">Result Count - {total || categoryList.length}</Alert>
      </div>
      <div className="black-list-table-pagination">
        <ServerSidePagination
          data={categoryList}
          total={total}
          onPageNum={onPageNumberChange}
        >
          <TableCard
            loading={dataLoading}
            data={categoryList}
            tableData={tableData}
          />
        </ServerSidePagination>
      </div>

      {categoryList.length ? (
        <div className="col-12 col-md-2 col-xs-12 offset-md-10">
          <Alert type="info">
            Result Count - {total || categoryList.length}
          </Alert>
        </div>
      ) : null}
    </Grid.Row>
  );
};

export default PublicWorkflowCategories;
