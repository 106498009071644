import React, { useState, useEffect } from "react";
import { StandaloneFormPage, FormCard, FormTextInput } from "tabler-react";
import { setUserToken, setUserName } from "../../helpers";
import { LoginProps } from "../../types";

const Login = (props: LoginProps): JSX.Element => {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    error: false,
    errorMessage: '',
  });

  const [message, setMessage] = useState('');

  useEffect((): void => {
    if (props.data && props.data.AdminOps.login && props.data.AdminOps.login.error) {
      setFormData({
        ...formData,
        error: props.data.AdminOps.login.error,
        errorMessage: props.data.AdminOps.login.message,
      });
    } else if (props.data && props.data.AdminOps.login && !props.data.AdminOps.login.error) {
      // setToken(props.data);
      setMessage('we send confirmation token to your email address, please check and follow the link')
    }
  }, [props.data]);

  useEffect((): void => {
    if (formData.username) {
      setUserName(formData.username);
    }
  })

  const setToken = async (data: any): Promise<void> => {
    await setFormData({
      ...formData,
      username: '',
      password: '',
      error: false,
      errorMessage: '',
    });
    setUserToken(data.AdminOps.login.token);
    await props.history.push('/');
  };

  const fieldChangeHandler = (event: React.SyntheticEvent): void => {
    const target = event.target as HTMLInputElement;
    const { value, name } = target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const submitHandler = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    await props.adminLogin({
      variables: {
        username: formData.username,
        password: formData.password,
      }
    });
  };

  return (
    <StandaloneFormPage>
      <FormCard
        buttonText="Login"
        title="Login to your Account"
        onSubmit={submitHandler}
      >
        <FormTextInput
          required
          name="username"
          placeholder="Username"
          label="Username"
          onChange={fieldChangeHandler}
          value={formData.username}
          error={formData.errorMessage}
        />
        <FormTextInput
          required
          name="password"
          type="password"
          placeholder="Password"
          label="Password"
          onChange={fieldChangeHandler}
          value={formData.password}
          error={formData.errorMessage}
        />
      </FormCard>
      {message &&
        <span style={{ color: 'green' }}>{message}</span>
      }
    </StandaloneFormPage>
  )
};

export default Login;
