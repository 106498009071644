import React, { useState, Fragment } from "react";
import { GET_PROPERTIES } from "../../graphql/queries";
import {
  PROPERTY_DELETE_MUTATION,
  PROPERTY_UPDATE_MUTATION,
  AITASK_CREATE_MUTATION,
  PROPERTY_RESCAN_MUTATION,
} from "../../graphql/mutations";
import Properties from "./";
import { Grid, Form } from "tabler-react";
import { useQuery, useMutation } from "@apollo/react-hooks";

const PropertiesQueries = () => {
  const [filterDataState, setFilterDataState] = useState("");
  const [pageNumberState, setPageNumberState] = useState(1);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [filterHexUsers, setFilterHexUsers] = useState(true);
  const [pricingPack, setPricingPack] = useState("");
  const [paused, setPaused] = useState<boolean | undefined>();
  const [orderBy, setOrderBy] = useState("");
  const [orderByASC, setOrderByASC] = useState(false);
  const [shouldBeDeleted, setShouldBeDeleted] = useState(false);
  const [tasksMinCount, setTasksMinCount] = useState<undefined | number>();
  const [tasksMaxCount, setTasksMaxCount] = useState<undefined | number>();
  const [taskType, setTaskType] = useState("");
  const [countryCodeState, setCountryCodeState] = useState("");
  const [sharedState, setSharedState] = useState<boolean | undefined>();
  const [withoutPromo, setWithoutPromo] = useState<boolean>(false);
  const [techStateState, setTechStackState] = useState("");

  const { data, loading, refetch } = useQuery(GET_PROPERTIES, {
    variables: {
      input: {
        regFrom: fromDate,
        regTo: toDate,
        pageNumber: pageNumberState,
        searchQuery: filterDataState,
        hideHex: filterHexUsers,
      },
      // pricingPackage: pricingPack,
      paused,
      orderBy,
      orderByASC,
      scheduledToDelete: shouldBeDeleted,
      // tasksCountMin: tasksMinCount,
      // tasksCountMax: tasksMaxCount,
      // taskType,
      csvDownload: false,
      countryCode: countryCodeState,
      shared: sharedState,
      withoutPromo,
      techStack: techStateState,
      issue_group: taskType,
      issue_count_min: tasksMinCount,
      issue_count_max: tasksMaxCount,
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });
  const [updateProperty] = useMutation(PROPERTY_UPDATE_MUTATION, {
    refetchQueries: [{ query: GET_PROPERTIES }],
  });
  const [deleteProperty] = useMutation(PROPERTY_DELETE_MUTATION, {
    refetchQueries: [{ query: GET_PROPERTIES }],
  });
  const [createAITask] = useMutation(AITASK_CREATE_MUTATION, {
    refetchQueries: [{ query: GET_PROPERTIES }],
  });
  const [rescanProperty] = useMutation(PROPERTY_RESCAN_MUTATION, {
    refetchQueries: [{ query: GET_PROPERTIES }],
  });

  const handleFilterDataChange = (filterData: string) =>
    setFilterDataState(filterData);
  const handlePageNumberChange = (pageNumber: number) =>
    setPageNumberState(pageNumber);
  const handleFromDate = (date: string) => setFromDate(date);
  const handleToDate = (date: string) => setToDate(date);
  const handlePackageSelect = (pack: string) => setPricingPack(pack);
  const handlePropertyStatus = (paused: boolean | undefined) =>
    setPaused(paused);
  const handleScheduledToDelete = (deleted: boolean) =>
    setShouldBeDeleted(deleted);
  const handleTasksMinCountChange = (count: number) => setTasksMinCount(count);
  const handleTasksMaxCountChange = (count: number) => setTasksMaxCount(count);
  const handleTaskTypeSelect = (type: string) => setTaskType(type);
  const handleCountryCode = (code: string) => setCountryCodeState(code);
  const handleSelectShared = (shared: boolean | undefined) =>
    setSharedState(shared);
  const handleWithoutPromo = (noPromo: boolean) => setWithoutPromo(noPromo);
  const handleTechStackChange = (ts: string) => setTechStackState(ts);

  return (
    <Fragment>
      <Grid.Row>
        <Grid.Col md={12}>
          <Form.Checkbox
            label="Filter Properties from Hexact"
            name="example-radios"
            value="option1"
            checked={filterHexUsers}
            onChange={() => setFilterHexUsers(!filterHexUsers)}
          />
        </Grid.Col>
      </Grid.Row>
      <Properties
        data={data}
        loading={loading}
        onFilterDataChange={handleFilterDataChange}
        onPageNumberChange={handlePageNumberChange}
        onPackageSelect={handlePackageSelect}
        onPropertyIsPaused={handlePropertyStatus}
        onShceduledToDelete={handleScheduledToDelete}
        onFromDate={handleFromDate}
        onToDate={handleToDate}
        onTasksMinCount={handleTasksMinCountChange}
        onTasksMaxCount={handleTasksMaxCountChange}
        onTaskTypeSelect={handleTaskTypeSelect}
        onCountryCode={handleCountryCode}
        onSelectShared={handleSelectShared}
        onWithoutPromo={handleWithoutPromo}
        onTechStackChange={handleTechStackChange}
        deleteProperty={deleteProperty}
        updateProperty={updateProperty}
        rescanProperty={rescanProperty}
        refetchData={() => refetch()}
        filterHexProperties={filterHexUsers}
        createAITask={createAITask}
        setOrderByASC={setOrderByASC}
        orderByASC={orderByASC}
        setOrderBy={setOrderBy}
        variables={{
          input: {
            regFrom: fromDate,
            regTo: toDate,
            pageNumber: pageNumberState,
            searchQuery: filterDataState,
            hideHex: filterHexUsers,
          },
          // pricingPackage: pricingPack,
          paused,
          scheduledToDelete: shouldBeDeleted,
          tasksCountMin: tasksMinCount,
          tasksCountMax: tasksMaxCount,
          taskType,
          countryCode: countryCodeState,
          shared: sharedState,
          withoutPromo,
          techStack: techStateState,
        }}
      />
    </Fragment>
  );
};

export default PropertiesQueries;
