import React, { useEffect, useState, FormEvent } from "react";
import { Grid, Button, Form, Alert } from "tabler-react";
import moment from "moment";
import ServerSidePagination from "../../components/pagination/serverSidePagination";
import TableCard from "../../components/tableCard";
import { useQuery } from "@apollo/react-hooks";
import Chart from "../../components/chart";
import { PULLCONTACT_CHART } from "../../graphql/queries";
import DatePicker from "react-datepicker";
import { getCountArr } from "../../helpers";
import "./index.css";

const PullContact = ({ refetchData, ...props }: any) => {
  const [entities, setEntities] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [pagination, setPagination] = useState([]);
  const [ipSearch, setIpSearch] = useState("");
  const [source, setSource] = useState("");
  const [total, setTotal] = useState(0);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [status, setStatus] = useState("all");

  const [fromDateForSend, setFromDateForSend] = useState(null);
  const [toDateForSend, setToDateForSend] = useState(null);

  const chartDataQuery = useQuery(PULLCONTACT_CHART, {
    variables: { from: fromDateForSend, to: toDateForSend },
    fetchPolicy: "network-only",
  });

  const tableData = {
    tData:
      entities &&
      entities.map((item) => ({
        IP: item.ip,
        source: item.source,
        status: item.status,
        createdAt: moment(parseInt(item.createdAt))
          .utc()
          .format("DD-MM-YYYY hh:mm:ss a"),
      })),
  };

  useEffect(() => {
    if (
      props.data &&
      props.data.PullContact &&
      props.data.PullContact.getPullContactsData &&
      props.data.PullContact.getPullContactsData.pullContacts
    ) {
      setDataLoading(props.loading);
      setEntities(props.data.PullContact.getPullContactsData.pullContacts);
      setPagination(props.data.PullContact.getPullContactsData.pullContacts);
      setTotal(props.data.PullContact.getPullContactsData.count);
    }
  }, [props.data]);

  const handleInputChange = (e: FormEvent) =>
    setIpSearch((e.target as HTMLInputElement).value);

  const handleCountryCode = (e: FormEvent) =>
    setSource((e.target as HTMLInputElement).value);

  const handleFromDate = (date: Date) => {
    if (date) {
      return setFromDate(moment(date).format("YYYY-MM-DDT00:00:00.000"));
    }
    return setFromDate(null);
  };

  const handleToDate = (date: Date) => {
    if (date) {
      return setToDate(moment(date).format("YYYY-MM-DDT23:59:59.999"));
    }
    return setToDate(null);
  };

  const handleSubmit = (
    event,
    ipSearch: string,
    source: string,
    status: string,
    startDate: string,
    endDate: string
  ) => {
    event.preventDefault();
    props.onHandleIpSearch(ipSearch.trim());
    props.onFromDate(startDate);
    props.onToDate(endDate);
    props.onHandleSource(source);
    props.onStatusChange(status);
    setToDateForSend(endDate);
    setFromDateForSend(startDate);
  };

  const handleSortingClick = (column: string) => {
    if (column === "Created At") {
      props.setOrderBy("createdAt");
      props.setOrderByASC(!props.orderByASC);
    }

    if (column === "Updated At") {
      props.setOrderBy("updatedAt");
      props.setOrderByASC(!props.orderByASC);
    }
  };

  return (
    <Grid>
      <Grid.Row>
        <div className="col-12 col-md-8">
          {!chartDataQuery.loading &&
            chartDataQuery.data &&
            chartDataQuery.data.PullContact &&
            chartDataQuery.data.PullContact.getPullContactsTimeSeries && (
              <Chart
                yColName="Vists"
                regData={
                  chartDataQuery.data.PullContact.getPullContactsTimeSeries
                    .timeSeries
                }
                interval={getCountArr(
                  chartDataQuery.data.PullContact.getPullContactsTimeSeries
                    .timeSeries.length
                )}
                chartName="PullContacts visits"
              />
            )}
        </div>
        <div className="col-12 col-md-4">
          <form
            onSubmit={(e) =>
              handleSubmit(e, ipSearch, source, status, fromDate, toDate)
            }
          >
            <Form.Group>
              <Form.Input
                label="IP"
                className="margin-bottom"
                icon="search"
                position="append"
                placeholder="Search By IP"
                value={ipSearch}
                onChange={handleInputChange}
              />
              <Form.Input
                label="Source"
                className="margin-bottom"
                icon="search"
                position="append"
                placeholder="Search By Source"
                value={source}
                onChange={handleCountryCode}
              />
              <Form.Select
                onChange={(e) => setStatus(e.target.value)}
                label="Select Type"
              >
                <option>all</option>
                <option>success</option>
                <option>limitReached</option>
                <option>error</option>
              </Form.Select>
              <Grid.Row>
                <Grid.Col>
                  <Form.Group label="From Date">
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      selected={fromDate ? new Date(fromDate) : null}
                      onChange={handleFromDate}
                      showMonthDropdown
                      placeholderText="Select a date"
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col>
                  <Form.Group label="To Date">
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      selected={toDate ? new Date(toDate) : null}
                      onChange={handleToDate}
                      showMonthDropdown
                      placeholderText="Select a date"
                      minDate={fromDate && new Date(fromDate)}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
              <Button
                className="margin-bottom"
                type="submit"
                icon="filter"
                color="primary"
                loading={dataLoading}
              >
                Filter
              </Button>
            </Form.Group>
          </form>
        </div>
      </Grid.Row>
      <Grid.Row className="justify-content-between">
        <div className="col-12 col-md-3 col-xs-12"></div>
        <Grid.Col md={3}>
          <Alert type="info">Result Count - {total}</Alert>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row cards deck>
        <ServerSidePagination
          data={pagination}
          total={total}
          onPageNum={props.onPageNumberChange}
        >
          <TableCard
            loading={dataLoading}
            data={entities}
            tableData={tableData}
            onHeaderClick={handleSortingClick}
          />
        </ServerSidePagination>
      </Grid.Row>
      <Grid.Row className="justify-content-end">
        {total ? (
          <Grid.Col md={3}>
            <Alert type="info">Result Count - {total}</Alert>
          </Grid.Col>
        ) : null}
      </Grid.Row>
    </Grid>
  );
};

export default PullContact;
