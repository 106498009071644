import React, { useEffect, useState } from "react";
import { Alert, Button, Form } from "tabler-react";
import ReactModal from "react-modal";
import { TableModalProps } from "../../types";
import ReactMde from "react-mde";
import * as Showdown from "showdown";
import "react-mde/lib/styles/css/react-mde-all.css";

ReactModal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    height: "80%",
  },
};

const TaskModal = ({
  handleModal,
  showModal,
  onSave,
  errorMessage,
  property,
}: TableModalProps): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [priority, setPriority] = useState("error");
  const [id, setId] = useState(-1);
  const [tab, setTab] = useState<"write" | "preview">("preview");

  useEffect(() => {
    if (property && !isNaN(parseInt(property.id))) {
      setId(parseInt(property.id));
    }
  }, [property]);

  const handleOk = async () => {
    setLoading(true);
    const formData = { id, title, content, priority };
    const completed = await onSave({ ...formData });
    setLoading(false);
    if (completed) handleCancel();
  };

  const handleCancel = () => {
    setId(undefined);
    setTitle("");
    setContent("");
    handleModal();
  };

  const handleTitleChange = (e: React.FormEvent) => setTitle((e.target as HTMLInputElement).value);
  const handleContentChange = (value: string) => setContent(value);
  const handlePriorityChange = (e: React.FormEvent) => setPriority((e.target as HTMLInputElement).value);
  const handleTabChange = (tab: "write" | "preview") => setTab(tab);

  const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true,
  });

  return (
    <div style={{ height: 0 }}>
      <ReactModal
        isOpen={showModal}
        contentLabel="Create Task"
        style={customStyles}
        onRequestClose={handleCancel}
      >
        <h4>Create Task</h4>
        {errorMessage ? <Alert type="danger">{errorMessage}</Alert> : null}
        <Form onSubmit={(e: React.FormEvent) => e.preventDefault()}>
          <Form.Input
            required
            type="text"
            placeholder="title"
            label="Title"
            value={title}
            onChange={handleTitleChange}
          />
          <ReactMde
            onChange={handleContentChange}
            onTabChange={handleTabChange}
            value={content}
            generateMarkdownPreview={(markdown) => Promise.resolve(converter.makeHtml(markdown))}
            selectedTab={tab}
          />
          <Form.Select onChange={handlePriorityChange} label="Priority" value={priority}>
            <option>error</option>
            <option>warning</option>
          </Form.Select>
          <Button.List style={{ marginTop: "5%" }}>
            <Button onClick={handleOk} color="primary" type="submit" loading={loading}>
              Save
            </Button>
            <Button onClick={handleCancel} color="secondary" type="button">
              Cancel
            </Button>
          </Button.List>
        </Form>
      </ReactModal>
    </div>
  );
};

export default TaskModal;
