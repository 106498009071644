import React, { useState, useEffect, FC } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";

import { CREATE_AUTOMATION_CATEGORIES } from "../../graphql/mutations";
import { GET_SCRAPING_RECIPES } from "../../graphql/queries";
import ScrapingRecipes from "./index";

const ScrapingRecipesQueries: FC = () => {
  const [dataList, setDataList] = useState<any>([]);
  const [pageNumberState, setPageNumberState] = useState(1);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [filterInput, setFilterInput] = useState("");
  const [filterHexScans, setFilterHexScans] = useState(true);
  const [isPublic, setIsPublic] = useState<boolean | null>(null);

  const { data, loading, refetch } = useQuery(GET_SCRAPING_RECIPES, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      settings: {
        filter: {
          page: pageNumberState,
          date_from: fromDate,
          date_to: toDate,
          limit: 100,
          is_public: isPublic,
          search: {
            search_value: filterInput,
          },
          hideHex: filterHexScans,
        },
      },
    },
  });

  useEffect(() => {
    refetch();
  }, [pageNumberState]);

  useEffect(() => {
    setDataList(data);
  }, [data]);

  useEffect(() => {
    refetchData();
  }, [filterInput]);

  useEffect(() => {
    refetchData();
  }, [isPublic]);

  const refetchData = async () => {
    await refetch();
  };

  const handlePageNumberChange = (pageNumber: number) => setPageNumberState(pageNumber);

  const [createAutomationCategory] = useMutation(CREATE_AUTOMATION_CATEGORIES, {
    onCompleted: () => {
      refetchData();
    },
  });

  return (
    <ScrapingRecipes
      data={dataList}
      loading={loading}
      onPageNumberChange={handlePageNumberChange}
      createAutomationCategory={createAutomationCategory}
      setFromDate={setFromDate}
      setToDate={setToDate}
      setFilterInput={setFilterInput}
      setHideHex={setFilterHexScans}
      setIsPublic={setIsPublic}
      hideHex={filterHexScans}
      refetch={refetch}
    />
  );
};

export default ScrapingRecipesQueries;
