import React, { FC, useState, useEffect } from "react";
import TableCard from "../../components/tableCard";
import { Grid, Button } from "tabler-react";
import { HeaderDataProps, HData } from "../../types";
import Pagination from "../../components/pagination";
import SearchInput from "../../components/searchInput";
import TableModal from "./modal";

import "./styles.css";

const HeaderData: FC<HeaderDataProps> = ({ data, loading, createHeader, updateHeader, deleteHeader }) => {
  const [headerData, setHeaderData] = useState<Array<HData>>([]);
  const [headerDataPagination, setHeaderDataPagination] = useState<Array<HData>>([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [fullHeaderData, setFullHeaderData] = useState<Array<HData>>([]);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [mutationCompleted, setMutationCompleted] = useState(false);
  const [editHeader, setEditHeader] = useState<any>(null);

  const handleChange = (tr: Array<HData>) => setHeaderData(tr);

  const handleClick = (tr: Array<HData>) => setHeaderDataPagination(tr);

  const handleModal = () => {
    if (showModal) setEditHeader(null);
    setShowModal(!showModal);
  };

  const handleSave = async ({ ...rest }) => {
    if (editHeader) {
      const updateResponse = await updateHeader({
        variables: { id: rest.id, path: rest.path, title: rest.title, input: rest.metaTags }, //fix this field
      });

      const error = updateResponse.data.HeaderDataOps.update.error;
      const message = updateResponse.data.HeaderDataOps.update.message;
      if (error) {
        setErrorMessage(message);
        return false;
      } else {
        setMutationCompleted(true);
      }
    } else {
      const createResponse = await createHeader({
        variables: { path: rest.path, title: rest.title, input: rest.metaTags },
      });

      const error = createResponse.data.HeaderDataOps.create.error;
      const message = createResponse.data.HeaderDataOps.create.message;
      if (error) {
        setErrorMessage(message);
        return false;
      } else {
        setMutationCompleted(true);
      }
    }
    return true;
  };

  const handleHeaderDelete = async (row: HData) => {
    let deleteConfirm = confirm("Are you sure you want to delete Header Data?");
    if (deleteConfirm) {
      const deleteResponse = await deleteHeader({
        variables: { path: row.path },
      });
      const error = deleteResponse.data.HeaderDataOps.delete.error;
      const message = deleteResponse.data.HeaderDataOps.delete.message;
      if (error) {
        setErrorMessage(message);
      } else {
        setMutationCompleted(true);
      }
    }
  };

  const handleHeaderMutate = (row: object | null) => {
    setShowModal(true);
    setEditHeader(row);
  };

  const tableData = {
    tData:
      headerDataPagination &&
      headerDataPagination.map((hData) => ({
        Path: hData.path,
        Title: hData.title,
        "MetaTags Count": hData.metaTags ? hData.metaTags.length : 0,
        Action: (
          <Button.List>
            <Button color="primary" icon="edit" onClick={() => handleHeaderMutate(hData)} />
            <Button color="danger" icon="trash" onClick={() => handleHeaderDelete(hData)} />
          </Button.List>
        ),
      })),
  };

  useEffect(() => {
    if ((data.HeaderData && data.HeaderData.getMany && fullHeaderData.length === 0) || mutationCompleted) {
      setHeaderData(data.HeaderData.getMany);
      setDataLoading(loading);
      setFullHeaderData(data.HeaderData.getMany);
    }
  }, [data]);

  return (
    <Grid.Row cards>
      <Grid.Col width={12}>
        <TableModal
          handleModal={handleModal}
          showModal={showModal}
          onSave={handleSave}
          headerData={editHeader}
          errorMessage={errorMessage}
        />
        <div className="header-data-btn-input">
          <div className=".mobile-btn-767">
            <Button onClick={() => handleHeaderMutate(null)} color="primary">
              Create
            </Button>
          </div>
          <div className="mobile-search-input-767">
            <SearchInput
              data={fullHeaderData}
              placeholder="Search for Path, Title..."
              fields={[{ name: "path" }, { name: "title" }]}
              onSubmit={handleChange}
            />
          </div>
        </div>
        <TableCard loading={dataLoading} data={headerDataPagination} tableData={tableData} />
        <Pagination rowPerPage={50} data={headerData} onClick={handleClick} />
      </Grid.Col>
    </Grid.Row>
  );
};

export default HeaderData;
