import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { HEADER_DATA } from '../../graphql/queries';
import { HEADERDATA_DELETE_MUTATION, HEADERDATA_CREATE_MUTATION, HEADERDATA_UPDATE_MUTATION } from '../../graphql/mutations';
import HeaderData from './';

const HeaderDataQueries = () => {
  const { data, loading } = useQuery(HEADER_DATA, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });
  const [createHeader] = useMutation(HEADERDATA_CREATE_MUTATION, {
    refetchQueries: [{ query: HEADER_DATA }],
  });
  const [updateHeader] = useMutation(HEADERDATA_UPDATE_MUTATION, {
    refetchQueries: [{ query: HEADER_DATA }],
  });
  const [deleteHeader] = useMutation(HEADERDATA_DELETE_MUTATION, {
    refetchQueries: [{ query: HEADER_DATA }],
  });

  return (
    !loading && <HeaderData
      data={data}
      loading={loading}
      createHeader={createHeader}
      updateHeader={updateHeader}
      deleteHeader={deleteHeader}
    />
  )
};

export default HeaderDataQueries;
