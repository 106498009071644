import React, { useEffect, useState } from "react";
import { Grid, Button, Alert } from "tabler-react";
import { ContentTextProps, TranslationProps, KeywordsProps } from "../../types";
import moment from "moment";
import SearchInput from "../../components/searchInput";
import Pagination from "../../components/pagination";
import TableCard from "../../components/tableCard";
import TableModal from "./tableModal";
import "tabler-react/dist/Tabler.css";

import "./styles.css";

const Keywords = ({
  data,
  loading,
  createKeyword,
  updateKeyword,
  deleteKeyword,
}: KeywordsProps): JSX.Element => {
  const [translations, setTranslations] = useState<Array<TranslationProps>>([]);
  const [baseTranslations, setBaseTranslations] = useState([]);
  const [paginationTranslations, setPaginationTranslations] = useState<Array<TranslationProps>>([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [mutationCompleted, setMutationCompleted] = useState(false);
  const [editKeyword, setEditKeyword] = useState(null);

  const handleModal = () => {
    if (showModal) {
      setEditKeyword(null);
      setErrorMessage("");
    }
    setShowModal(!showModal);
  };

  const handleSave = async ({ ...rest }) => {
    if (editKeyword) {
      const updateResponse = await updateKeyword({
        variables: { key: rest.key, text: rest.text },
      });

      const error = updateResponse.data.KeywordOps.update.error;
      const message = updateResponse.data.KeywordOps.update.message;
      if (error) {
        setErrorMessage(message);
        return false;
      } else {
        setMutationCompleted(true);
      }
    } else {
      const createResponse = await createKeyword({
        variables: { key: rest.key, text: rest.text },
      });

      const error = createResponse.data.KeywordOps.create.error;
      const message = createResponse.data.KeywordOps.create.message;
      if (error) {
        setErrorMessage(message);
        return false;
      } else {
        setMutationCompleted(true);
      }
    }
    return true;
  };

  const handleClick = (tr: Array<TranslationProps>) => setTranslations(tr);
  const handleChange = (tr: Array<TranslationProps>) => setPaginationTranslations(tr);

  const handleKeywordDelete = async (row: ContentTextProps) => {
    let deleteConfirm = confirm("Are you sure you want to delete translation?");
    if (deleteConfirm) {
      const deleteResponse = await deleteKeyword({
        variables: { key: row.key },
      });
      const error = deleteResponse.data.KeywordOps.delete.error;
      const message = deleteResponse.data.KeywordOps.delete.message;
      if (error) {
        setErrorMessage(message);
      } else {
        setMutationCompleted(true);
      }
    }
  };

  const handleKeywordMutate = (row: object | null) => {
    setShowModal(true);
    setEditKeyword(row);
  };

  const tableData = {
    tData:
      translations &&
      translations.map((item: ContentTextProps) => ({
        Key: item.key,
        "Short Text": item.value.length >= 200 ? `${item.value.slice(0, 200)}...` : item.value,
        "Updated At": moment(item.updatedAt).format("DD-MM-YYYY"),
        "Created At": moment(item.createdAt).format("DD-MM-YYYY"),
        Action: (
          <Button.List>
            <Button color="primary" icon="edit" onClick={() => handleKeywordMutate(item)} />
            <Button color="danger" icon="trash" onClick={() => handleKeywordDelete(item)} />
          </Button.List>
        ),
      })),
  };

  useEffect(() => {
    if (
      (data && data.Keyword && data.Keyword.getAll.en && baseTranslations.length === 0) ||
      mutationCompleted
    ) {
      setDataLoading(loading);
      setBaseTranslations(data.Keyword.getAll.en);
      setPaginationTranslations(data.Keyword.getAll.en);
    }
  }, [data]);

  return (
    <Grid.Row cards deck>
      <div className="mobile-767">
        <TableModal
          handleModal={handleModal}
          showModal={showModal}
          onSave={handleSave}
          keyword={editKeyword}
          errorMessage={errorMessage}
        />
      </div>
      <div className="mobile-767">
        <Grid.Col md={12}>
          <SearchInput
            data={baseTranslations}
            placeholder="Search for Key, Short Text"
            onSubmit={handleChange}
            fields={[{ name: "key" }, { name: "value" }]}
          />
        </Grid.Col>
      </div>
      <div className="mobile-767 mobile-between">
        <Grid.Col md={1} ms={12}>
          <Button className="create-button" onClick={() => handleKeywordMutate(null)} color="primary">
            Create
          </Button>
        </Grid.Col>
        <Grid.Col md={2} ms={12}>
          <Alert type="info">Result Count - {paginationTranslations.length}</Alert>
        </Grid.Col>
      </div>
      <Grid.Col md={12} ms={12}>
        <div className="keyword-table-pagination">
          <Pagination rowPerPage={50} data={paginationTranslations} onClick={handleClick} />
        </div>
      </Grid.Col>
      <Grid.Col width={12}>
        <TableCard loading={dataLoading} data={translations} tableData={tableData} />
      </Grid.Col>
      <div className="mobile-767 mobile-pagination-767">
        <Grid.Col md={12}>
          <div className="keyword-table-pagination">
            <Pagination rowPerPage={50} data={paginationTranslations} onClick={handleClick} />
          </div>
        </Grid.Col>
      </div>
      <div className="mobile-767">
        {paginationTranslations.length ? (
          <Grid.Col md={3} ms={12} offsetMd={9}>
            <Alert type="info">Result Count - {paginationTranslations.length}</Alert>
          </Grid.Col>
        ) : null}
      </div>
    </Grid.Row>
  );
};

export default Keywords;
