import React, { useState } from 'react';
import AITasks from './aiTasks';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_TASKS } from "../../graphql/queries";
import './index.css'
import { AITASK_RESOLVE_MUTATION } from '../../graphql/mutations';


const TasksQueries = () => {
  const [pageNumberState, setPageNumberState] = useState(1);
  const [filterDataState, setFilterDataState] = useState("");
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [priorityState, setPriorityState] = useState('');
  const [resolvedState, setResolvedState] = useState('');

  const { data, loading, refetch } = useQuery(GET_TASKS, {
    variables: {
      input: {
        regFrom: fromDate,
        regTo: toDate,
        pageNumber: pageNumberState,
        searchQuery: filterDataState,
      },
      priority: priorityState,
      resolved: resolvedState
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });

  const [resolveTask] = useMutation(AITASK_RESOLVE_MUTATION);


  const handlePageNumberChange = (pageNumber: number) => setPageNumberState(pageNumber);
  const handleFilterDataChange = (filterData: string) => setFilterDataState(filterData);
  const handleFromDate = (date: string) => setFromDate(date);
  const handleToDate = (date: string) => setToDate(date);
  const handlePrioritySelect = (priority: string) => setPriorityState(priority);
  const hansleResolvedSelect = (resolved: string) => setResolvedState(resolved);

  return (
    <AITasks
      data={data}
      loading={loading}
      onPageNumberChange={handlePageNumberChange}
      refetchData={refetch}
      resolveTask={resolveTask}
      onFilterDataChange={handleFilterDataChange}
      onPrioritySelect={handlePrioritySelect}
      onResolvedSelect={hansleResolvedSelect}
      onFromDate={handleFromDate}
      onToDate={handleToDate}
    />
  )
};

export default TasksQueries;