import React, { useState, useEffect, FC } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";

import { UPDATE_PUBLIC_WORKFLOW, DELETE_PUBLIC_WORKFLOW } from "../../graphql/mutations";
import { GET_PUBLIC_WORKFLOWS } from "../../graphql/queries";

import PublicWorkflows from "./index";

const WorkflowsQueries: FC = () => {
  const [dataList, setDataList] = useState<any>([]);
  const [pageNumberState, setPageNumberState] = useState(1);
  const [searchWorkflow, setSearchWorkflow] = useState<string>("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [mostUsed, setMostUsed] = useState(false);

  const { data, loading, refetch } = useQuery(GET_PUBLIC_WORKFLOWS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      settings: {
        filter: {
          page: pageNumberState,
          limit: 100,
          date_from: fromDate,
          date_to: toDate,
          sort_by: "DESC",
          sort_by_creation_date: true,
          most_used: mostUsed ? true : undefined,
          search: {
            search_field: null,
            search_value: searchWorkflow,
          },
        },
        get_total: false,
      },
    },
  });

  const handlePageNumberChange = (pageNumber: number) => setPageNumberState(pageNumber);

  useEffect(() => {
    refetch();
  }, [pageNumberState]);

  useEffect(() => {
    setDataList(data);
  }, [data]);

  // useEffect(() => {
  //   refetchData();
  // }, [searchWorkflow]);

  const refetchData = async () => {
    await refetch();
  };

  const [updatePublicWorkflow] = useMutation(UPDATE_PUBLIC_WORKFLOW, {
    onCompleted: () => {
      refetchData();
    },
  });

  const [deletePublicWorkflow] = useMutation(DELETE_PUBLIC_WORKFLOW, {
    onCompleted: () => {
      refetchData();
    },
  });

  return (
    <PublicWorkflows
      data={dataList}
      loading={loading}
      searchWorkflow={searchWorkflow}
      setSearchWorkflow={setSearchWorkflow}
      onPageNumberChange={handlePageNumberChange}
      updatePublicWorkflow={updatePublicWorkflow}
      setMostUsed={setMostUsed}
      setFromDate={setFromDate}
      setToDate={setToDate}
      deletePublicWorkflow={deletePublicWorkflow}
      refetch={refetch}
    />
  );
};

export default WorkflowsQueries;
