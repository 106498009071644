import React, { useState, useEffect } from "react";
import { CREATE_AUTOMATION_CATEGORIES } from "../../graphql/mutations";
import { GET_WORKFLOWS } from "../../graphql/queries";
import AutomationCategories from "./index";
import { useQuery, useMutation } from "@apollo/react-hooks";

const WorkflowsQueries = () => {
  const [dataList, setDataList] = useState<any>([]);
  const [pageNumberState, setPageNumberState] = useState(1);
  const handlePageNumberChange = (pageNumber: number) =>
    setPageNumberState(pageNumber);
  const [searchEmail, setsearchEmail] = useState<string>("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [hideHexScans, setHideHexScans] = useState(true);

  const { data, loading, refetch } = useQuery(GET_WORKFLOWS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      settings: {
        filter: {
          page: pageNumberState,
          date_from: fromDate,
          date_to: toDate,
          limit: 100,
          search: {
            search_value: searchEmail,
          },
          hideHex: hideHexScans,
        },
      },
    },
  });

  useEffect(() => {
    refetch();
  }, [pageNumberState]);

  useEffect(() => {
    setDataList(data);
  }, [data]);

  // useEffect(() => {
  //   refetchData();
  // }, [searchEmail]);

  const refetchData = async () => {
    await refetch();
  };

  const [createAutomationCategory] = useMutation(CREATE_AUTOMATION_CATEGORIES, {
    onCompleted: () => {
      refetchData();
    },
  });

  return (
    <AutomationCategories
      data={dataList}
      loading={loading}
      searchEmail={searchEmail}
      setsearchEmail={setsearchEmail}
      onPageNumberChange={handlePageNumberChange}
      createAutomationCategory={createAutomationCategory}
      setFromDate={setFromDate}
      setToDate={setToDate}
      setHideHex={setHideHexScans}
      hideHex={hideHexScans}
    />
  );
};

export default WorkflowsQueries;
