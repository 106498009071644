import React, { useEffect, useState } from "react";
import { Alert, Button, Form } from "tabler-react";
import ReactModal from "react-modal";
import { TableModalProps } from "../../types";
import ReactMde from "react-mde";
import * as Showdown from "showdown";
import "react-mde/lib/styles/css/react-mde-all.css";

ReactModal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    height: "50%",
  },
};

const TableModal = ({
  handleModal,
  showModal,
  onSave,
  onUpdate,
  editField,
  errorMessage,
}: any): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [longDescription, setLongDescription] = useState("");
  const [image, setImage] = useState("");
  const [tab, setTab] = useState<"write" | "preview">("write");

  const handleOk = async () => {
    setLoading(true);
    if (editField) {
      await onUpdate({
        id: editField.id,
        name,
        short_description: shortDescription,
        long_description: longDescription,
        image,
      });
      setLoading(false);
      return;
    }
    const formData = { name, short_description: shortDescription, long_description: longDescription, image };
    const completed = await onSave({ ...formData });
    setLoading(false);
    // if (completed) handleCancel();
  };

  const handleCancel = () => {
    setName("");
    setShortDescription("");
    setLongDescription("");
    setImage("");
    handleModal();
  };

  useEffect(() => {
    if (window.innerWidth < 640) {
      customStyles.content.width = "90%";
    }
  }, []);

  useEffect(() => {
    if (editField) {
      setName(editField.name);
      setImage(editField.image);
      setShortDescription(editField.short_description);
      setLongDescription(editField.long_description);
    }
  }, [editField]);

  const handleEmailChange = (e: React.FormEvent) => setName((e.target as HTMLInputElement).value);

  const handleTabChange = (tab: "write" | "preview") => setTab(tab);

  const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true,
  });

  return (
    <div style={{ height: 0 }}>
      <ReactModal
        isOpen={showModal}
        contentLabel="Create category name"
        style={customStyles}
        onRequestClose={handleCancel}
      >
        <h4>{editField ? "Edit category name" : "Create category name"}</h4>
        {errorMessage ? <Alert type="danger">{errorMessage}</Alert> : null}
        <Form onSubmit={(e: React.FormEvent) => e.preventDefault()}>
          <Form.Input
            required
            type="text"
            placeholder="category name"
            label="name"
            value={name}
            onChange={handleEmailChange}
          />
          <Form.Input
            required
            type="text"
            placeholder="category image"
            label="image"
            value={image}
            onChange={(e: any) => setImage(e.target.value)}
          />
          <ReactMde
            onChange={(value: string) => setShortDescription(value)}
            onTabChange={handleTabChange}
            value={shortDescription}
            generateMarkdownPreview={(markdown) => Promise.resolve(converter.makeHtml(markdown))}
            selectedTab={tab}
            className="mb-2"
          />
          <ReactMde
            onChange={(value: string) => setLongDescription(value)}
            onTabChange={handleTabChange}
            value={longDescription}
            generateMarkdownPreview={(markdown) => Promise.resolve(converter.makeHtml(markdown))}
            selectedTab={tab}
            className="mb-2"
          />
          <Button.List style={{ marginTop: "5%" }}>
            <Button onClick={handleOk} color="primary" type="submit" loading={loading}>
              Save
            </Button>
            <Button onClick={handleCancel} color="secondary" type="button">
              Cancel
            </Button>
          </Button.List>
        </Form>
      </ReactModal>
      {errorMessage ? <Alert type="danger">{errorMessage}</Alert> : null}
    </div>
  );
};

export default TableModal;
