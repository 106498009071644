import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import { LOGIN_MUTATION } from "../../graphql/mutations";
import { History } from 'history';
import Login from "./";
import { getUserToken } from "../../helpers";

class LoginMutation extends Component<{history: History}> {
  componentDidMount(): void {
    if (getUserToken()) {
      this.props.history.push('/');
    }
  }

  render() {
    return (
      <Mutation mutation={LOGIN_MUTATION}>
        {(adminLogin: any, {data}: any) => (
          <Login
            state={this.props.history.location.state}
            history={this.props.history}
            data={data}
            adminLogin={adminLogin}
          />
        )}
      </Mutation>
    );
  }
}

export default LoginMutation;
