import React, { useState, useEffect } from "react";
import { Grid, Button, Alert, Form } from "tabler-react";
import moment from "moment";
import DatePicker from "react-datepicker";

import ServerSidePagination from "../../components/pagination/serverSidePagination";
import TableCard from "../../components/tableCard";

interface HexosparkCampaignProps {
  data: {
    HexosparkAdmin: {
      getCampaignsAdmin: {
        count: number;
        campaigns: any;
      };
    };
  };
  loading: boolean;
  orderByASC: boolean;
  variables: { settings: Object };
  refetchData: () => void;
  onPageNumberChange: (pageNumber: number) => void;
  onFilterSearch: (data: string) => void;
  onDBSelect: (data: string) => void;
  onFromDate: (date: string) => void;
  onToDate: (date: string) => void;
  setOrderBy: (column: string) => void | Promise<void>;
  setOrderByASC: (isASC: boolean) => void | Promise<void>;
  filterHexScans: boolean;
  setFilterHexScans: (val: boolean) => void;
}

const HexosparkCampaignQueries = ({ refetchData, ...props }: HexosparkCampaignProps): JSX.Element => {
  const [total, setTotal] = useState(0);
  const [dataLoading, setDataLoading] = useState(true);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [searchValue, setSeachvalue] = useState("");
  const [dbValue, setDBValue] = useState("US");

  const showButtons = localStorage.getItem("_rep");

  const [campaignsData, setCampaignsData] = useState([]);

  const [pagination, setPagination] = useState([]);

  // const [getCSV, { data, loading }] = useLazyQuery(GET_HEXOMATIC_SHARED_RECIPES);
  // const [uploadToDrive, { data: driveData, loading: driveDataLoading }] = useLazyQuery(
  //   GET_HEXOMATIC_SHARED_RECIPES
  // );

  // const workspaceChartData = useQuery(GET_CHART, {
  //   variables: { from: null, to: null, hideHex: props.filterHexScans },
  //   fetchPolicy: "network-only",
  //   notifyOnNetworkStatusChange: true,
  // });

  const tableData = {
    tData:
      campaignsData &&
      campaignsData.map((item) => ({
        ID: item.main_user_id || "N/A",
        "User email": item.main_user_email || "N/A",
        "Workspace ID": item.hs_workspace_id || "N/A",
        "Campaign name": item.name || "N/A",
        "Number of sent emails": item.sended_emails_count || "N/A",
        "Number of replies": item.replied_emails_count || "N/A",
        "Number of fails": item.failed_emails_count || "N/A",
      })),
  };

  useEffect(() => {
    if (
      props.data &&
      props.data.HexosparkAdmin &&
      props.data.HexosparkAdmin.getCampaignsAdmin &&
      props.data.HexosparkAdmin.getCampaignsAdmin.campaigns
    ) {
      setDataLoading(props.loading);
      setCampaignsData(props.data.HexosparkAdmin.getCampaignsAdmin.campaigns);
      setPagination(props.data.HexosparkAdmin.getCampaignsAdmin.campaigns);
      setTotal(props.data.HexosparkAdmin.getCampaignsAdmin.count);
    }
  }, [props.data]);

  // useEffect(() => {
  //   if (
  //     driveData &&
  //     driveData.HexosparkScrapingRecipe &&
  //     driveData.HexosparkScrapingRecipe.getSharedScrapingRecipesReport &&
  //     driveData.HexosparkScrapingRecipe.getSharedScrapingRecipesReport.csvURL
  //   ) {
  //     window.open(
  //       isProduction()
  //         ? `https://api.hexometer.com/v2/app/social-callback/googleDrive?admin=admin&report=sharedRecipes&url=${driveData.HexosparkScrapingRecipe.getSharedScrapingRecipesReport.csvURL}`
  //         : isTest()
  //         ? `https://api.hexact.io/v2/app/social-callback/googleDrive?admin=admin&report=sharedRecipes&url=${driveData.HexosparkScrapingRecipe.getSharedScrapingRecipesReport.csvURL}`
  //         : isDev()
  //         ? `http://37.186.119.181:4003/api/app/social-callback/googleDrive?admin=admin&report=sharedRecipes&url=${driveData.HexosparkScrapingRecipe.getSharedScrapingRecipesReport.csvURL}`
  //         : `http://localhost:4000/api/app/social-callback/googleDrive?admin=admin&report=sharedRecipes&url=${driveData.HexosparkScrapingRecipe.getSharedScrapingRecipesReport.csvURL}`,
  //       "_blank"
  //     );
  //   }
  // }, [driveData]);

  const handleSearch = (e: React.FormEvent) => setSeachvalue((e.target as HTMLInputElement).value);
  const handleDBSelect = (e: React.FormEvent) => setDBValue((e.target as HTMLSelectElement).value);

  const handleFromDate = (date: Date) => {
    if (date) {
      return setFromDate(moment(date).format("YYYY-MM-DDT00:00:00.000"));
    }
    return setFromDate(null);
  };

  const handleToDate = (date: Date) => {
    if (date) {
      return setToDate(moment(date).format("YYYY-MM-DDT23:59:59.999"));
    }
    return setToDate(null);
  };

  const handleSubmit = (
    event: React.FormEvent,
    searchVal: string,
    startDate: string,
    endDate: string,
    dbVal: string
  ) => {
    event.preventDefault();
    props.onFilterSearch(searchVal.trim());
    props.onFromDate(startDate);
    props.onToDate(endDate);
    props.onDBSelect(dbVal);
  };

  const handleSortingClick = (column: string) => {
    if (column === "Created At") {
      props.setOrderBy("createdAt");
      props.setOrderByASC(!props.orderByASC);
    }
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Col md={2}>
          <Form.Checkbox
            label="Filter Hexact shares"
            checked={props.filterHexScans}
            onChange={() => props.setFilterHexScans(!props.filterHexScans)}
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        {/* <div className="col-12 col-md-8">
          {!workspaceChartData.loading &&
            workspaceChartData.data &&
            workspaceChartData.data.HexosparkScrapingRecipe &&
            workspaceChartData.data.HexosparkScrapingRecipe.getSharedScrapingRecipesChart && (
              <Chart
                yColName="Shares"
                yColName2="Imports"
                regData={
                  workspaceChartData.data.HexosparkScrapingRecipe.getSharedScrapingRecipesChart.shares
                }
                regData2={
                  workspaceChartData.data.HexosparkScrapingRecipe.getSharedScrapingRecipesChart.imports
                }
                interval={getCountArr(
                  workspaceChartData.data.HexosparkScrapingRecipe.getSharedScrapingRecipesChart.length
                )}
                chartName="Shared scraping recipes"
              />
            )}
        </div> */}
        <div className="col-12 col-md-8"></div>
        <div className="col-12 col-md-4">
          <form onSubmit={(e) => handleSubmit(e, searchValue, fromDate, toDate, dbValue)}>
            <Form.Group>
              <Form.Input
                label="Input"
                className="margin-bottom"
                icon="search"
                position="append"
                placeholder="Search for user email, id"
                value={searchValue}
                onChange={handleSearch}
              />

              <Form.Select onChange={handleDBSelect} label="Database location" value={dbValue}>
                {["US", "EU"].map((pack) => (
                  <option key={pack} value={pack}>
                    {pack}
                  </option>
                ))}
              </Form.Select>

              <Form.Group label="From Date">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={fromDate ? new Date(fromDate) : null}
                  onChange={handleFromDate}
                  showMonthDropdown
                  placeholderText="Select a date"
                />
              </Form.Group>
              <Form.Group label="To Date">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={toDate ? new Date(toDate) : null}
                  onChange={handleToDate}
                  showMonthDropdown
                  placeholderText="Select a date"
                  minDate={fromDate && new Date(fromDate)}
                />
              </Form.Group>
              <Button className="margin-bottom" type="submit" icon="filter" color="primary">
                Filter
              </Button>
            </Form.Group>
          </form>
        </div>
      </Grid.Row>
      <Grid.Row className={showButtons === "true" ? "justify-content-between" : "justify-content-end"}>
        {/* {showButtons === "true" && (
          <div className="col-12 col-md-3 col-xs-12">
            <Button.List>
              <Button
                onClick={() =>
                  getCSV({
                    variables: { settings: { ...props.variables.settings, csvDownload: true } },
                  })
                }
                loading={loading}
                color="primary"
                mr={1}
              >
                Get CSV Link
              </Button>
              <Button
                onClick={() =>
                  uploadToDrive({
                    variables: { settings: { ...props.variables.settings, csvDownload: true } },
                  })
                }
                loading={driveDataLoading}
                color="primary"
              >
                Save to Drive
              </Button>
            </Button.List>
            {!loading &&
              data &&
              data.HexosparkScrapingRecipe &&
              data.HexosparkScrapingRecipe.getSharedScrapingRecipesReport &&
              data.HexosparkScrapingRecipe.getSharedScrapingRecipesReport.csvURL && (
                <Button
                  RootComponent="a"
                  href={data.HexosparkScrapingRecipe.getSharedScrapingRecipesReport.csvURL}
                  icon="download"
                >
                  Download CSV
                </Button>
              )}
            <br />
          </div>
        )} */}

        {/* <Grid.Col md={3}>
          <Alert type="info">Result Count - {total}</Alert>
        </Grid.Col> */}
      </Grid.Row>
      <Grid.Row cards deck>
        <ServerSidePagination data={pagination} total={total} onPageNum={props.onPageNumberChange}>
          <TableCard
            loading={dataLoading}
            data={campaignsData}
            tableData={tableData}
            onHeaderClick={handleSortingClick}
          />
        </ServerSidePagination>
      </Grid.Row>
    </Grid>
  );
};

export default HexosparkCampaignQueries;
