import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";

import { CREATE_ICE_BREAKER, DELETE_ICE_BREAKER, UPDATE_ICE_BREAKER } from "../../graphql/mutations";
import { GET_HEXOSPARK_ICE_BREAKERS } from "../../graphql/queries";

import IceBreaker from "./index";

const IceBreakerQueries = () => {
  const [iceBreakerList, setIceBreakerList] = useState<any>([]);
  const [pageNumberState, setPageNumberState] = useState(1);
  const [searchEmail, setsearchEmail] = useState<string>("");

  const handlePageNumberChange = (pageNumber: number) => setPageNumberState(pageNumber);

  const { data, loading, refetch } = useQuery(GET_HEXOSPARK_ICE_BREAKERS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    refetch();
  }, [pageNumberState]);

  useEffect(() => {
    setIceBreakerList(data);
  }, [data]);

  useEffect(() => {
    refetchData();
  }, [searchEmail]);

  const refetchData = async () => {
    await refetch();
  };

  const [deleteIceBreaker] = useMutation(DELETE_ICE_BREAKER);
  const [updateIceBreaker] = useMutation(UPDATE_ICE_BREAKER);

  const [createIceBreaker] = useMutation(CREATE_ICE_BREAKER, {
    onCompleted: () => {
      refetchData();
    },
  });

  return (
    <IceBreaker
      data={iceBreakerList}
      loading={loading}
      searchEmail={searchEmail}
      setsearchEmail={setsearchEmail}
      onPageNumberChange={handlePageNumberChange}
      createIceBreaker={createIceBreaker}
      deleteIceBreaker={deleteIceBreaker}
      updateIceBreaker={updateIceBreaker}
    />
  );
};

export default IceBreakerQueries;
