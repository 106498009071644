import React, { useEffect, useState, FC } from "react";
import { Grid, Button, Form, Alert } from "tabler-react";
import { UserLogProps } from "../../types";
import moment from "moment";
import ServerSidePagination from "../../components/pagination/serverSidePagination";
import TableCard from "../../components/tableCard";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import Chart from "../../components/chart";
import { USER_LOGS_CHART, GET_USER_LOGS } from "../../graphql/queries";
import DatePicker from "react-datepicker";
import { getCountArr, isProduction } from "../../helpers";

const UserLogs = (props: UserLogProps) => {
  const [baseUserLogs, setBaseUserLogs] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [userLogsPagination, setUserLogsPagination] = useState([]);
  const [filterInput, setFilterInput] = useState("");
  const [total, setTotal] = useState(0);
  const [registrationsFrom, setRegistrationsFrom] = useState(null);
  const [registrationsTo, setRegistrationsTo] = useState(null);
  const [loginsFrom, setLoginsFrom] = useState(null);
  const [loginsTo, setLoginsTo] = useState(null);
  const [actionType, setActionType] = useState("");
  const showButtons = localStorage.getItem("_rep");

  const [getCSV, { data, loading }] = useLazyQuery(GET_USER_LOGS);
  const [uploadToDrive, { data: driveData, loading: driveDataLoading }] =
    useLazyQuery(GET_USER_LOGS);

  const chartDataQuery = useQuery(USER_LOGS_CHART, {
    variables: { hideHexLogins: props.hideHexLogins },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (
      props &&
      props.data &&
      props.data.UserLog &&
      props.data.UserLog.adminGetMany &&
      props.data.UserLog.adminGetMany.userLogs
    ) {
      setDataLoading(props.loading);
      setBaseUserLogs(props.data.UserLog.adminGetMany.userLogs);
      setUserLogsPagination(props.data.UserLog.adminGetMany.userLogs);
      setTotal(props.data.UserLog.adminGetMany.total);
    }
  });

  useEffect(() => {
    if (
      driveData &&
      driveData.UserLog &&
      driveData.UserLog.adminGetMany &&
      driveData.UserLog.adminGetMany.csvURL
    ) {
      window.open(
        isProduction()
          ? `https://api.hexometer.com/v2/app/social-callback/googleDrive?admin=admin&report=userLog&url=${driveData.UserLog.adminGetMany.csvURL}`
          : `http://localhost:4000/api/app/social-callback/googleDrive?admin=admin&report=userLog&url=${driveData.UserLog.adminGetMany.csvURL}`,
        "_blank"
      );
    }
  }, [driveData]);

  const tableData = {
    tData:
      baseUserLogs &&
      baseUserLogs.map((item) => {
        return {
          Name: `${item.user.firstName} ${item.user.lastName}`,
          Email: item.user.email,
          "Registered At": moment(item.user.createdAt)
            .utc()
            .format("DD-MM-YYYY hh:mm:ss a"),
          Action: item.action,
          "Remote Address": item.remoteAddress,
          "Country Code": item.countryCode,
          "Login At": moment(item.createdAt)
            .utc()
            .format("DD-MM-YYYY hh:mm:ss a"),
        };
      }),
  };

  const handleInputChange = (e: React.FormEvent) =>
    setFilterInput((e.target as HTMLInputElement).value);

  const handleRegistrationsFrom = (date: Date) => {
    if (date) {
      return setRegistrationsFrom(
        moment(date).format("YYYY-MM-DDT00:00:00.000")
      );
    }
    return setRegistrationsFrom(null);
  };

  const handleActionTypeChange = (e: React.FormEvent) =>
    setActionType((e.target as HTMLInputElement).value);

  const handleRegistrationsTo = (date: Date) => {
    if (date) {
      return setRegistrationsTo(moment(date).format("YYYY-MM-DDT23:59:59.999"));
    }
    return setRegistrationsTo(null);
  };

  const handleLoginFrom = (date: Date) => {
    if (date) {
      return setLoginsFrom(moment(date).format("YYYY-MM-DDT00:00:00.000"));
    }
    return setLoginsFrom(null);
  };

  const handleLoginTo = (date: Date) => {
    if (date) {
      return setLoginsTo(moment(date).format("YYYY-MM-DDT23:59:59.999"));
    }
    return setLoginsTo(null);
  };

  const handleSubmit = (
    event: React.FormEvent,
    filterData: string,
    regFrom: string,
    regTo: string,
    logFrom: string,
    logTo: string,
    actionType: string
  ) => {
    event.preventDefault();
    props.onFilterDataChange(filterData.trim());
    props.onRegistrationsFrom(regFrom);
    props.onRegistrationsTo(regTo);
    props.onLoginsFrom(logFrom);
    props.onLoginsTo(logTo);
    if (actionType === "all") {
      props.setActionType("");
    } else {
      props.setActionType(actionType);
    }
  };

  const handleSortingClick = (column: string) => {
    if (column === "Login At") {
      props.setOrderBy("createdAt");
      props.setOrderByASC(!props.orderByASC);
    }
  };

  return (
    <Grid>
      <Grid.Row>
        <div className="col-12 col-md-8">
          {!chartDataQuery.loading &&
            chartDataQuery.data &&
            chartDataQuery.data.UserLog &&
            chartDataQuery.data.UserLog.adminUniqueLogsChart && (
              <Chart
                yColName="User Logs"
                regData={chartDataQuery.data.UserLog.adminUniqueLogsChart}
                interval={getCountArr(
                  chartDataQuery.data.UserLog.adminUniqueLogsChart.length
                )}
                chartName="User Logs Activity"
              />
            )}
        </div>
        <div className="col-12 col-md-4">
          <form
            onSubmit={(e) =>
              handleSubmit(
                e,
                filterInput,
                registrationsFrom,
                registrationsTo,
                loginsFrom,
                loginsTo,
                actionType
              )
            }
          >
            <Form.Group>
              <Form.Input
                label="Input"
                className="margin-bottom"
                icon="search"
                position="append"
                placeholder="Search for User Name, User Email"
                value={filterInput}
                onChange={handleInputChange}
              />
              <Form.Select label="Status" onChange={handleActionTypeChange}>
                <option value="all">all</option>
                <option value="login">login</option>
                <option value="cookieUser">cookieUser</option>
              </Form.Select>
              <Grid.Row>
                <Grid.Col>
                  <Form.Group label="Registrations From">
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      selected={
                        registrationsFrom ? new Date(registrationsFrom) : null
                      }
                      onChange={handleRegistrationsFrom}
                      showMonthDropdown
                      placeholderText="Select a date"
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col>
                  <Form.Group label="Registrations To">
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      selected={
                        registrationsTo ? new Date(registrationsTo) : null
                      }
                      onChange={handleRegistrationsTo}
                      showMonthDropdown
                      placeholderText="Select a date"
                      minDate={registrationsFrom && new Date(registrationsFrom)}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col>
                  <Form.Group label="Logins From">
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      selected={loginsFrom ? new Date(loginsFrom) : null}
                      onChange={handleLoginFrom}
                      showMonthDropdown
                      placeholderText="Select a date"
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col>
                  <Form.Group label="Logins To">
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      selected={loginsTo ? new Date(loginsTo) : null}
                      onChange={handleLoginTo}
                      showMonthDropdown
                      placeholderText="Select a date"
                      minDate={loginsFrom && new Date(loginsFrom)}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
              <Button type="submit" icon="filter" color="primary">
                Filter
              </Button>
            </Form.Group>
          </form>
        </div>
      </Grid.Row>
      <Grid.Row className={showButtons === 'true' ? "justify-content-between" : "justify-content-end"}>
        {showButtons === 'true' && (
          <div className="col-12 col-md-3 col-xs-12">
            <Button.List>
              <Button
                onClick={() =>
                  getCSV({
                    variables: { ...props.variables, csvDownload: true },
                  })
                }
                loading={loading}
                color="primary"
                mr={1}
              >
                Get CSV Link
              </Button>
              <Button
                onClick={() =>
                  uploadToDrive({
                    variables: { ...props.variables, csvDownload: true },
                  })
                }
                loading={driveDataLoading}
                color="primary"
              >
                Save to Drive
              </Button>
            </Button.List>
            {!loading &&
              data &&
              data.UserLog &&
              data.UserLog.adminGetMany &&
              data.UserLog.adminGetMany.csvURL && (
                <Button
                  RootComponent="a"
                  href={data.UserLog.adminGetMany.csvURL}
                  icon="download"
                >
                  Download CSV
                </Button>
              )}
            <br />
          </div>
        )}
        <Grid.Col md={3}>
          <Alert type="info">Result Count - {total}</Alert>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row cards deck>
        <ServerSidePagination
          data={userLogsPagination}
          total={total}
          onPageNum={props.onPageNumberChange}
        >
          <TableCard
            loading={dataLoading}
            data={baseUserLogs}
            tableData={tableData}
            onHeaderClick={handleSortingClick}
          />
        </ServerSidePagination>
      </Grid.Row>
      <Grid.Row className="justify-content-end">
        {total ? (
          <Grid.Col md={3}>
            <Alert type="info">Result Count - {total}</Alert>
          </Grid.Col>
        ) : null}
      </Grid.Row>
    </Grid>
  );
};

export default UserLogs;
