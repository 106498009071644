import React, { useEffect, useState } from "react";
import { Alert, Button, Form, Loader } from "tabler-react";
import ReactModal from "react-modal";
import { isValidEmail } from "../../helpers";
import { closeIcon, editIcon } from "../../image/svgIcons";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  SHARE_PROFILE,
  GET_SHARE_PROFILES,
  UPDATE_SHARE_PROFILE,
  DELETE_SHARE_PROFILE,
} from "../../graphql/mutations";
import "react-mde/lib/styles/css/react-mde-all.css";

ReactModal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    // height: "80%",
  },
};

const ShareAccountModal = ({ handleModal, showModal, id, owner_email }: any): JSX.Element => {
  //----------------------State------------------
  const [email, setEmail] = useState("");
  const [error, setError] = useState("error");
  const [editId, setEditId] = useState<null | number>(null);
  const [list, setList] = useState([]);
  //----------------------Queries---------------
  const [share, { loading: shareLoading }] = useMutation(SHARE_PROFILE);
  const [update, { loading: updateLoading }] = useMutation(UPDATE_SHARE_PROFILE);
  const {
    data,
    loading,
    refetch: getUsers,
  } = useQuery(GET_SHARE_PROFILES, {
    fetchPolicy: "no-cache",
    variables: { settings: { filter: { userId: id } } },
  });
  const [deleteEmail] = useMutation(DELETE_SHARE_PROFILE);
  //---------------------useEffect--------------
  useEffect(() => {
    getUsers();
  }, []);
  useEffect(() => {
    setError("");
  }, [email]);

  useEffect(() => {
    if (
      data &&
      data.UserSharedProfile &&
      data.UserSharedProfile.get &&
      data.UserSharedProfile.get.sharedProfile &&
      data.UserSharedProfile.get.sharedProfile.sharedUser
    ) {
      setList(data.UserSharedProfile.get.sharedProfile.sharedUser);
      setEmail("");
      setEditId(null);
    } else setList([]);
  }, [data]);
  //----------------------Functions---------------

  const handleInputChange = (e: any) => {
    setEmail(e.target.value);
  };

  const handleAdd = async () => {
    const { data } = await share({
      variables: {
        settings: {
          owner_email: owner_email,
          shared_user_emails: [email],
        },
      },
    });
    if (data && data.UserSharedProfileOps && !data.UserSharedProfileOps.create) getUsers();
    if (
      data &&
      data.UserSharedProfileOps &&
      data.UserSharedProfileOps.create &&
      data.UserSharedProfileOps.create.error_code
    )
      setError("Something went wrong.");
  };

  const handleUpdateEmail = async () => {
    const { data } = await update({
      variables: {
        settings: {
          id: editId,
          shared_user_email: email,
        },
      },
    });
    if (data && data.UserSharedProfileOps && !data.UserSharedProfileOps.update) getUsers();
    if (
      data &&
      data.UserSharedProfileOps &&
      data.UserSharedProfileOps.update &&
      data.UserSharedProfileOps.update.error_code
    )
      setError(data.UserSharedProfileOps.update.error_code);
    return;
  };

  const handleClick = () => {
    if (!email || !isValidEmail(email) || owner_email.trim() === email.trim()) {
      setError("Please enter valid email");
      return;
    }
    if (editId) {
      handleUpdateEmail();
      return;
    }
    handleAdd();
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") handleClick();
  };

  const handleDeleteEmail = async (id: number) => {
    const { data } = await deleteEmail({
      variables: {
        settings: {
          ids: [+id],
        },
      },
    });
    if (data && data.UserSharedProfileOps && !data.UserSharedProfileOps.deleteSharedProfile) getUsers();
    if (
      data &&
      data.UserSharedProfileOps &&
      data.UserSharedProfileOps.deleteSharedProfile &&
      data.UserSharedProfileOps.deleteSharedProfile.error_code
    )
      setError("Something went wrong.");
  };

  const handleEdit = (item: any) => {
    setEditId(+item.id);
    setEmail(item.email);
  };
  return (
    <div style={{ height: 0 }}>
      <ReactModal isOpen={showModal} contentLabel="impact" style={customStyles} onRequestClose={handleModal}>
        <h4>Share profile</h4>

        <Form.Group className="d-flex w-100 justify-content-center align-items-center input-button-wrapper">
          <Form.Input
            label="Email"
            className="w-100"
            icon="add"
            position="append"
            placeholder="Input email"
            value={email}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
          />
          <Button onClick={handleClick} color="primary" type="button" disabled={shareLoading}>
            {shareLoading || updateLoading ? (
              <Loader className="white-loader loader-small" />
            ) : editId ? (
              "Update"
            ) : (
              "Add"
            )}
          </Button>
          <div className="d-flex justify-content-end" style={{ marginBottom: "16px", gap: "10px" }}></div>
        </Form.Group>
        {error ? (
          <Alert className="share-profile-error" type="danger">
            {error}
          </Alert>
        ) : null}
        <div className="shared-emails-list">
          {list &&
            list.map((item) => (
              <div className="d-flex">
                <p>{item.email}</p>
                <p className="edit-email-icon" onClick={() => handleEdit(item)}>
                  {editIcon}
                </p>
                <p className="delete-email-icon" onClick={() => handleDeleteEmail(item.id)}>
                  {closeIcon}
                </p>
              </div>
            ))}
        </div>

        <div className="d-flex justify-content-end" style={{ marginBottom: "16px", gap: "10px" }}>
          <Button onClick={handleModal} color="secondary" type="button">
            Close
          </Button>
        </div>
      </ReactModal>
    </div>
  );
};

export default ShareAccountModal;
