import React, { useEffect, useState } from "react";
import { Alert, Button, Form } from "tabler-react";
import ReactModal from "react-modal";
import "react-mde/lib/styles/css/react-mde-all.css";
import { isValidEmail } from "../../helpers";
import {
  ADD_ONE_TIME_BONUS,
  UPDATE_HEXOMATIC_USER_CREDITS_PACKAGE,
  UPDATE_MAIN_USER_PREMIUM_CREDITS_PACKAGE,
  UPDATE_HEXOMETER_PACKAGE,
  UPDATE_HEXOWATCH_PACKAGE,
  USER_UPDATE_MUTATION,
  UPDATE_HEXOSPARK_PACKAGE,
  UPDATE_HEXOMATIC_USER_AUTOMATION_CREDITS,
  UPDATE_HEXOFY_PACKAGE,
  UPDATE_HEXOSCOPE_PACKAGE,
} from "../../graphql/mutations";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { GET_LIST_PRODUCTS } from "../../graphql/queries";

ReactModal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    // height: "80%",
  },
};

const UsersModal = ({
  handleModal,
  showModal,
  onSave,
  errorMessage,
  user,
  refetchData,
}: any): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState(-1);
  const [pricingPackage, setPricingPackage] = useState("");
  const [pricingPackageInterval, setPricingPackageInterval] = useState("");
  const [email, setEmail] = useState("");
  const [additionalScansCount, setAdditionalScansCount] = useState(0);
  const [addBonusCount, setAddBonusCount] = useState(0);
  const [automationCredits, setAutomationCredits] = useState(0);

  const [hexometer_pricing_package, setHexometerPricingPackage] = useState("");
  const [premiumCreditPackages, setPremiumCreditPackages] = useState([]);
  const [hexometerPackages, setHexometerPackages] = useState([]);
  const [hexowatchPackages, setHexowatchPackages] = useState([]);
  const [hexomaticPackages, setHexomaticPackages] = useState([]);
  const [hexosparkPackages, setHexosparkPackages] = useState([]);
  const [hexofyPackages, setHexofyPackages] = useState([]);
  const [hexoscopePackages, setHexoscopePackages] = useState([]);

  const [hexomaticPackagesList, setHexomaticPackagesList] = useState([]);
  const [hexometerPackagesList, setHexometerPackagesList] = useState([]);
  const [hexowatchPackagesList, setHexowatchPackagesList] = useState([]);
  const [hexosparkPackagesList, setHexosparkPackagesList] = useState([]);
  const [hexofyPackagesList, setHexofyPackagesList] = useState([]);
  const [hexoscopePackagesList, setHexoscopePackagesList] = useState([]);

  const [hexomaticPackagesInterval, setHexomaticPackagesInterval] = useState([]);
  const [hexometerPackagesInterval, setHexometerPackagesInterval] = useState([]);
  const [hexowatchPackagesInterval, setHexowatchPackagesInterval] = useState([]);
  const [hexosparkPackagesInterval, setHexosparkPackagesInterval] = useState([]);
  const [hexofyPackagesInterval, setHexofyPackagesInterval] = useState([]);
  const [hexoscopePackagesInterval, setHexoscopePackagesInterval] = useState([]);


  const [hexometer_pricing_package_interval, setHexometerPricingPackageInterval] = useState("");
  const [hexometer_pricing_package_count, setHexometerPricingPackageCount] = useState(0);

  const [hexomatic_pricing_package_interval, setHexomatic_pricing_package_interval] = useState("");
  const [hexomatic_pricing_package, setHexomatic_pricing_package] = useState("");
  const [hexomatic_pricing_package_count, setHexomatic_pricing_package_count] = useState(0);

  const [hexospark_pricing_package, setHexospark_pricing_package] = useState("");
  const [hexospark_pricing_package_interval, setHexospark_pricing_package_interval] = useState("");
  const [hexospark_pricing_package_count, setHexospark_pricing_package_count] = useState(0);

  const [hexofy_pricing_package, setHexofy_pricing_package] = useState("");
  const [hexofy_pricing_package_interval, setHexofy_pricing_package_interval] = useState("");
  const [hexofy_pricing_package_count, setHexofy_pricing_package_count] = useState(0);

  const [hexoscope_pricing_package, setHexoscope_pricing_package] = useState("");
  const [hexoscope_pricing_package_interval, setHexoscope_pricing_package_interval] = useState("");
  const [hexoscope_pricing_package_count, setHexoscope_pricing_package_count] = useState(0);

  const [isHexomaticPackageChanged, setIsHexomaticPackageChanged] = useState(false);
  const [isHexometerPackageChanged, setIsHexometerPackageChanged] = useState(false);
  const [isHexowatchPackageChanged, setIsHexowatchPackageChanged] = useState(false);
  const [isHexosparkPackageChanged, setIsHexosparkPackageChanged] = useState(false);
  const [isHexofyPackageChanged, setIsHexofyPackageChanged] = useState(false);
  const [isHexoscopePackageChanged, setIsHexoscopePackageChanged] = useState(false);


  const [errMsg, setErrMsg] = useState("");
  const [premium_pricing_package_interval, setPremium_pricing_package_interval] = useState("MONTHLY");
  const [premium_pricing_package, setPremium_pricing_package] = useState("");

  const [updateHexomaticUserAutomationCredits] = useMutation(UPDATE_HEXOMATIC_USER_AUTOMATION_CREDITS);
  const [updateHexomaticUserCredits_Pagckage] = useMutation(UPDATE_HEXOMATIC_USER_CREDITS_PACKAGE);
  const [updateHexometer_Pagckage] = useMutation(UPDATE_HEXOMETER_PACKAGE);
  const [updateHexowatchPackage] = useMutation(UPDATE_HEXOWATCH_PACKAGE);
  const [updateHexosparkPackage] = useMutation(UPDATE_HEXOSPARK_PACKAGE);
  const [updateHexofyPackage] = useMutation(UPDATE_HEXOFY_PACKAGE);
  const [updateHexoscopePackage] = useMutation(UPDATE_HEXOSCOPE_PACKAGE);

  const [updateUserPremiumCreditsPackage] = useMutation(UPDATE_MAIN_USER_PREMIUM_CREDITS_PACKAGE);
  const [addOneTimeBonus] = useMutation(ADD_ONE_TIME_BONUS);

  const { data: packages } = useQuery(GET_LIST_PRODUCTS, {
    variables: {
      settings: {
        productType: "",
        fromAdmin: true,
      },
    },
  });

  const [updateUser] = useMutation(USER_UPDATE_MUTATION);

  useEffect(() => {
    if (
      packages &&
      packages.Billing &&
      packages.Billing.listProducts &&
      packages.Billing.listProducts.products
    ) {
      //!--------------------- Hexometer ------------------
      if (packages.Billing.listProducts.products.hexometerProducts) {
        const list = packages.Billing.listProducts.products.hexometerProducts.filter(
          (item: any) => item.productType === "STRIPE"
        );

        const hexometerProducts = list.reverse().sort(function (a, b) {
          return a.name.length - b.name.length;
        });

        setHexometerPackages(list);

        //@ts-ignore
        const names = [...new Set([...Array.from(hexometerProducts, ({ name }) => name)])];
        //@ts-ignore
        const intervals = [...new Set([...Array.from(hexometerProducts, ({ interval }) => interval)])];
        setHexometerPackagesList(names);
        setHexometerPackagesInterval(intervals);
      }
      //!--------------------- Hexowatch ------------------
      if (packages.Billing.listProducts.products.hexowatchProducts) {
        const hexowatchProducts = packages.Billing.listProducts.products.hexowatchProducts.filter(
          (item: any) => item.productType === "STRIPE"
        );
        setHexowatchPackages(hexowatchProducts);

        //@ts-ignore
        const names = [...new Set([...Array.from(hexowatchProducts, ({ name }) => name)])].sort();
        const namesSorted = [...names.slice(-3), ...names.slice(0, names.length - 3)].sort(function (a, b) {
          return a.length - b.length;
        });
        //@ts-ignore
        const intervals = [...new Set([...Array.from(hexowatchProducts, ({ interval }) => interval)])];
        setHexowatchPackagesList(namesSorted);
        setHexowatchPackagesInterval(intervals);
      }
      //!--------------------- Hexomatic ------------------

      if (packages.Billing.listProducts.products.hexomaticProducts) {
        const hexomaticProducts = packages.Billing.listProducts.products.hexomaticProducts.filter(
          (item: any) => item.productType === "STRIPE"
        );
        setHexomaticPackages(hexomaticProducts);

        //@ts-ignore
        const names = [...new Set([...Array.from(hexomaticProducts, ({ name }) => name)])];
        //@ts-ignore
        const intervals = [...new Set([...Array.from(hexomaticProducts, ({ interval }) => interval)])];
        setHexomaticPackagesList(names);
        setHexomaticPackagesInterval(intervals);
      }

      //!-------------------- Hexospark ------------------

      if (packages.Billing.listProducts.products.hexosparkProducts) {
        const hexosparkProducts = packages.Billing.listProducts.products.hexosparkProducts.filter(
          (item: any) => item.productType === "STRIPE"
        );
        setHexosparkPackages(hexosparkProducts);

        const names = [...new Set([...Array.from(hexosparkProducts, ({ name }) => name)])];
        const intervals = [...new Set([...Array.from(hexosparkProducts, ({ interval }) => interval)])];
        setHexosparkPackagesList(names);
        setHexosparkPackagesInterval(intervals);
      }

      //!-------------------- Hexofy ------------------

      if (packages.Billing.listProducts.products.hexofyProducts) {
        const hexofyProducts = packages.Billing.listProducts.products.hexofyProducts.filter(
          (item: any) => item.productType === "STRIPE"
        );
        setHexofyPackages(hexofyProducts);

        const names = [...new Set([...Array.from(hexofyProducts, ({ name }) => name)])];
        const intervals = [...new Set([...Array.from(hexofyProducts, ({ interval }) => interval)])];
        setHexofyPackagesList(names);
        setHexofyPackagesInterval(intervals);
      }
      //!-------------------- Hexoscope ------------------

      if (packages.Billing.listProducts.products.hexoscopeProducts) {
        const hexoscopeProducts = packages.Billing.listProducts.products.hexoscopeProducts.filter(
          (item: any) => item.productType === "STRIPE"
        );
        setHexoscopePackages(hexoscopeProducts);

        const names = [...new Set([...Array.from(hexoscopeProducts, ({ name }) => name)])];
        const intervals = [...new Set([...Array.from(hexoscopeProducts, ({ interval }) => interval)])];
        setHexoscopePackagesList(names);
        setHexoscopePackagesInterval(intervals);
      }
      //!----------------------Premium_credits-----------------
      if (packages.Billing.listProducts.products.premiumCreditProducts) {
        const packagesList = packages.Billing.listProducts.products.premiumCreditProducts.filter(
          (item: any) => item.productType === "STRIPE"
        );

        let sortedMonthly = packagesList.filter(
          (sub: any) => sub.interval === "MONTHLY" && sub.name !== "FREE" && sub.name !== "MICROSOFT" && sub.name !== "ALIBABA"
        );
        let sortedAnnual = packagesList.filter(
          (sub: any) => sub.interval === "ANNUAL" && sub.name !== "FREE" && sub.name !== "MICROSOFT" && sub.name !== "ALIBABA"
        );
        const otherList = packagesList.filter((sub: any) => sub.name === "FREE" || sub.name === "MICROSOFT" || sub.name === 'ALIBABA');
        sortedMonthly = sortedMonthly
          .map((item: any) => {
            return {
              name: item.name,
              id: item.id,
              interval: item.interval,
              value: `PC ${item.amount} monthly`,
            };
          })
          .sort(function (a, b) {
            return a.name.length - b.name.length;
          });
        sortedAnnual = sortedAnnual
          .map((item: any) => {
            return {
              name: item.name,
              id: item.id,
              interval: item.interval,
              value: `PC ${item.amount} ${item.name.includes("SPECIAL") ? "Special" : ""} yearly`,
            };
          })
          .sort(function (a, b) {
            return a.name.length - b.name.length;
          });
        const finData = otherList
          .map((item: any) => {
            return {
              name: item.name,
              id: item.id,
              interval: item.interval,
              value: item.name,
            };
          })
          .concat(sortedMonthly, sortedAnnual);

        finData && setPremiumCreditPackages(finData);
      }
    }
  }, [packages]);

  const handleOk = async () => {
    let updateUserData = false;
    let updateConfig = false;
    let neededrefetchFetch = false;

    const formData = {
      id,
      pricingPackage: null,
      pricingPackageInterval: null,
      email,
      additionalScansCount: 0,
      hexometer_pricing_package: null,
      hexometer_pricing_package_interval: null,
      hexometer_pricing_package_count: 0,
    };

    if (pricingPackage !== user.pricingPackage || pricingPackageInterval !== user.pricingPackageInterval) {
      updateUserData = true;
      formData.pricingPackage = pricingPackage;
      formData.pricingPackageInterval = pricingPackageInterval;
    }

    if (automationCredits && automationCredits !== user.left_automation_credit) {
      const updateResponse = await updateHexomaticUserAutomationCredits({
        variables: {
          userId: +user.id,
          credits: automationCredits,
        },
      });
      if (
        updateResponse &&
        updateResponse.data &&
        updateResponse.data.UserOps &&
        updateResponse.data.UserOps.updateHexomaticUserCredits &&
        updateResponse.data.UserOps.updateHexomaticUserCredits.error
      ) {
        setLoading(false);
        setErrMsg(updateResponse.data.UserOps.updateHexomaticUserCredits.error);
      } else {
        refetchData();
        setLoading(false);
        handleCancel();
      }
    }

    if (email && email !== user.email) {
      if (!isValidEmail(email)) {
        setErrMsg("Please Enter Valid Email");
        return;
      } else {
        const updateResponse = await updateUser({
          variables: {
            id: +user.id,
            email: email,
          },
        });
        if (
          updateResponse &&
          updateResponse.data &&
          updateResponse.data.UserOps &&
          updateResponse.data.UserOps.adminUpdate &&
          updateResponse.data.UserOps.adminUpdate.error
        ) {
          setLoading(false);
          setErrMsg(updateResponse.data.UserOps.adminUpdate.message);
        } else {
          refetchData();
          setLoading(false);
          handleCancel();
        }
      }
    }
    if (typeof +additionalScansCount === "number" && !isNaN(+additionalScansCount)) {
      if (
        user.config
          ? user.config.additional_scans_count !== additionalScansCount
          : user.config === null && additionalScansCount !== 0
      ) {
        updateConfig = true;
      }
      formData.additionalScansCount = +additionalScansCount;
    }
    if (
      (hexometer_pricing_package !== user.hexometer_pricing_package ||
        hexometer_pricing_package_interval !== user.hexometer_pricing_package_interval ||
        hexometer_pricing_package_count !== user.hexometer_pricing_package_count) &&
      isHexometerPackageChanged
    ) {
      const { data } = await updateHexometer_Pagckage({
        variables: {
          specific_package_stacks_count: hexometer_pricing_package_count
            ? +hexometer_pricing_package_count
            : user.hexometer_pricing_package_count,
          userId: +user.id,
          productId: hexometerPackages.find(
            (item: any) =>
              item.name === hexometer_pricing_package &&
              (hexometer_pricing_package !== "FREE"
                ? hexometer_pricing_package_interval === item.interval
                : true)
          )
            ? hexometerPackages.find(
                (item: any) =>
                  item.name === hexometer_pricing_package &&
                  (hexometer_pricing_package !== "FREE"
                    ? hexometer_pricing_package_interval === item.interval
                    : true)
              ).id
            : "",
        },
      });
      if (
        data &&
        data.AdminOps &&
        data.AdminOps.updateHexometerPackageAdmin &&
        !data.AdminOps.updateHexometerPackageAdmin.error_code
      ) {
        neededrefetchFetch = true;
        setIsHexometerPackageChanged(false);
      } else {
        const { error_code } = data.AdminOps.updateHexometerPackageAdmin;
        if (error_code) {
          setLoading(false);
          setErrMsg(error_code);
          return;
        }
      }
    }

    if (updateUserData && isHexowatchPackageChanged) {
      if (pricingPackageInterval !== "MONTHLY" && pricingPackage === "MICROSOFT") {
        setErrMsg("Invalid interval provided");
        return;
      }

      const { data } = await updateHexowatchPackage({
        variables: {
          settings: {
            userId: +user.id,
            specific_package_stacks_count: 1,
            productId:
              pricingPackage === null && pricingPackageInterval === null
                ? "992e38e0-d5a7-4dc4-9b1e-8311a11f9fd5"
                : hexowatchPackages.find(
                    (item: any) =>
                      item.name === pricingPackage &&
                      (pricingPackage !== "FREE" ? pricingPackageInterval === item.interval : true)
                  )
                ? hexowatchPackages.find(
                    (item: any) =>
                      item.name === pricingPackage &&
                      (pricingPackage !== "FREE" ? pricingPackageInterval === item.interval : true)
                  ).id
                : "",
          },
        },
      });
      if (
        data &&
        data.AdminOps &&
        data.AdminOps.updateUserWatchPricingPackageAdmin &&
        !data.AdminOps.updateUserWatchPricingPackageAdmin.error_code
      ) {
        neededrefetchFetch = true;
        setIsHexowatchPackageChanged(false);
      } else {
        const { error_code } = data.AdminOps.updateUserWatchPricingPackageAdmin;
        if (error_code) {
          setLoading(false);
          setErrMsg(error_code);
          return;
        }
      }
    }
    if (
      (hexomatic_pricing_package !== user.hexomatic_pricing_package ||
        hexomatic_pricing_package_count !== user.hexomatic_pricing_package_count ||
        hexomatic_pricing_package_interval !== user.hexomatic_pricing_package_interval) &&
      isHexomaticPackageChanged
    ) {
      const objId = hexomaticPackages.find(
        (item: any) =>
          item.name === hexomatic_pricing_package && item.interval === hexomatic_pricing_package_interval
      );
      const { data } = await updateHexomaticUserCredits_Pagckage({
        variables: {
          settings: {
            productId: objId.id,
            userId: +user.id,
            credits_pricing_package_count: +hexomatic_pricing_package_count,
          },
        },
      });

      if (data.HexomaticAdminOps.updateHexomaticPackageAdmin.error_code === null) {
        neededrefetchFetch = true;
        setIsHexomaticPackageChanged(false);
      } else {
        const { error_code } = data.HexomaticAdminOps.updateHexomaticPackageAdmin;
        if (error_code) {
          setLoading(false);
          setErrMsg(error_code);
          return;
        }
      }
    }

    if (
      (hexospark_pricing_package !== user.hexospark_pricing_package ||
        hexospark_pricing_package_count !== user.hexospark_pricing_package_count ||
        hexospark_pricing_package_interval !== user.hexospark_pricing_package_interval) &&
      isHexosparkPackageChanged
    ) {
      const objId = hexosparkPackages.find(
        (item: any) =>
          item.name === hexospark_pricing_package && item.interval === hexospark_pricing_package_interval
      );
      if (!objId) {
        alert("Hexospark Package Interval is incorrect");
        return;
      }

      const { data } = await updateHexosparkPackage({
        variables: {
          settings: {
            productId: objId.id,
            userId: +user.id,
            packageCount: +hexospark_pricing_package_count,
          },
        },
      });

      if (data.HexosparkUserOps.updateHexosparkPackageAdmin.error_code === null) {
        neededrefetchFetch = true;
        setIsHexosparkPackageChanged(false);
      } else {
        const { error_code } = data.HexosparkUserOps.updateHexosparkPackageAdmin;
        if (error_code) {
          setLoading(false);
          setErrMsg(error_code);
          return;
        }
      }
    }

    //! hexofy

    if (
      (hexofy_pricing_package !== user.hexofy_pricing_package ||
        hexofy_pricing_package_count !== user.hexofy_pricing_package_count ||
        hexofy_pricing_package_interval !== user.hexofy_pricing_package_interval) &&
      isHexofyPackageChanged
    ) {
      const objId = hexofyPackages.find(
        (item: any) =>
          item.name === hexofy_pricing_package && item.interval === hexofy_pricing_package_interval
      );
      if (!objId) {
        alert("Hexofy Package Interval or Pricing Package is incorrect");
        return;
      }

      const { data } = await updateHexofyPackage({
        variables: {
          settings: {
            productId: objId.id,
            userId: +user.id,
            packageCount: +hexofy_pricing_package_count,
          },
        },
      });

      if (data.HexofyUserOps.updateHexofyPackageAdmin === null) {
        neededrefetchFetch = true;
        setIsHexofyPackageChanged(false);
      } else {
        const { error_code } = data.HexofyUserOps.updateHexofyPackageAdmin;
        if (error_code) {
          setLoading(false);
          setErrMsg(error_code);
          return;
        }
      }
    }

     //! hexoscope

     if (
      (hexoscope_pricing_package !== user.hexoscope_pricing_package ||
        hexoscope_pricing_package_count !== user.hexoscope_pricing_package_count ||
        hexoscope_pricing_package_interval !== user.hexoscope_pricing_package_interval) &&
      isHexoscopePackageChanged
    ) {
      const objId = hexoscopePackages.find(
        (item: any) =>
          item.name === hexoscope_pricing_package && item.interval === hexoscope_pricing_package_interval
      );
      if (!objId) {
        alert("Hexoscope Package Interval or Pricing Package is incorrect");
        return;
      }

      const { data } = await updateHexoscopePackage({
        variables: {
          settings: {
            productId: objId.id,
            userId: +user.id,
            packageCount: +hexoscope_pricing_package_count,
          },
        },
      });

      if (data.HexoscopeUserOps.updateHexoscopePackageAdmin === null) {
        neededrefetchFetch = true;
        setIsHexoscopePackageChanged(false);
      } else {
        const { error_code } = data.HexoscopeUserOps.updateHexoscopePackageAdmin;
        if (error_code) {
          setLoading(false);
          setErrMsg(error_code);
          return;
        }
      }
    }

    //////////////
    if (
      premium_pricing_package_interval !== user.premium_credits_pricing_package_interval ||
      premium_pricing_package !== user.premium_credits_pricing_package
    ) {
      const { data } = await updateUserPremiumCreditsPackage({
        variables: {
          settings: {
            userId: +user.id,
            premiumCreditsId: premiumCreditPackages.find((item: any) => item.name === premium_pricing_package)
              ? premiumCreditPackages.find((item: any) => item.name === premium_pricing_package).id
              : "",
          },
        },
      });
      if (data.AdminOps.updateMainUserPremiumCreditsPackageAdmin === null) {
        neededrefetchFetch = true;
      } else {
        const { error_code } = data.AdminOps.updateMainUserPremiumCreditsPackageAdmin;
        if (error_code) {
          setLoading(false);
          setErrMsg(error_code);
          return;
        }
        neededrefetchFetch = true;
      }
    }
    if (addBonusCount !== user.left_one_time_premium_credits) {
      if (user.left_one_time_premium_credits === null && addBonusCount === 0) {
        return;
      }
      if (!addBonusCount) return;
      const { data } = await addOneTimeBonus({
        variables: {
          settings: {
            user_id: +user.id,
            premium_credits_count: +addBonusCount,
          },
        },
      });
      if (data.HexomaticAdminOps.updateHexomaticUserOneTimePremiumCredits === null) {
        neededrefetchFetch = true;
      } else {
        const { error_code } = data.HexomaticAdminOps.updateHexomaticUserOneTimePremiumCredits;
        if (error_code) {
          setErrMsg(error_code);
          setLoading(false);
          return;
        }
      }
    }
    setLoading(true);
    const completed = await onSave({
      updateUserData: false,
      updateConfig,
      updateHexometerPackage: false,
      updateHexomatePackage: false,
      ...formData,
    });

    neededrefetchFetch && refetchData();
    setLoading(false);
    if (completed) handleCancel();
    setErrMsg("");
  };

  useEffect(() => {
    if (window.innerWidth < 640) {
      customStyles.content.width = "90%";
    }
  }, []);

  useEffect(() => {
    if (hexomatic_pricing_package === "FREE") {
      setHexomatic_pricing_package_interval("MONTHLY");
      setHexomatic_pricing_package_count(1);
    }
  }, [hexomatic_pricing_package]);

  useEffect(() => {
    if (hexospark_pricing_package === "FREE") {
      setHexospark_pricing_package_interval("MONTHLY");
      setHexospark_pricing_package_count(1);
    }
    if (hexospark_pricing_package === "PAID") {
      setHexospark_pricing_package_interval("LTD");
    }
  }, [hexospark_pricing_package]);

  useEffect(() => {
    if (hexofy_pricing_package === "FREE") {
      setHexofy_pricing_package_interval("MONTHLY");
      setHexofy_pricing_package_count(1);
    }
    if (hexofy_pricing_package === "PAID") {
      setHexofy_pricing_package_interval("LTD");
    }
  }, [hexofy_pricing_package]);

  useEffect(() => {
    if (hexoscope_pricing_package === "FREE") {
      setHexoscope_pricing_package_interval("MONTHLY");
      setHexoscope_pricing_package_count(1);
    }
    if (hexoscope_pricing_package === "PRE-LAUNCH") {
      setHexoscope_pricing_package_interval("LTD");
    }
  }, [hexoscope_pricing_package]);

  const handleCancel = () => {
    setId(undefined);
    handleModal();
    setIsHexomaticPackageChanged(false);
    setIsHexometerPackageChanged(false);
    setIsHexowatchPackageChanged(false);
    setIsHexofyPackageChanged(false);
    setIsHexosparkPackageChanged(false);
  };

  useEffect(() => {
    if (user) {
      setId(user.id);
      setPricingPackage(user.pricingPackage);
      setPricingPackageInterval(user.pricingPackageInterval);
      setHexometerPricingPackage(user.hexometer_pricing_package);
      setHexometerPricingPackageInterval(user.hexometer_pricing_package_interval);
      typeof user.hexometer_pricing_package_count === "number"
        ? setHexometerPricingPackageCount(user.hexometer_pricing_package_count)
        : setHexometerPricingPackageCount(0);
      setEmail(user.email);
      if (user.hexomatic_pricing_package) {
        setHexomatic_pricing_package(user.hexomatic_pricing_package);
      } else {
        setHexomatic_pricing_package("FREE");
      }
      if (user.hexomatic_pricing_package_count) {
        setHexomatic_pricing_package_count(user.hexomatic_pricing_package_count);
      } else {
        setHexomatic_pricing_package_count(0);
      }
      if (user.hexomatic_pricing_package_interval) {
        setHexomatic_pricing_package_interval(user.hexomatic_pricing_package_interval);
      } else {
        setHexomatic_pricing_package_interval("MONTHLY");
      }

      if (user.hexospark_pricing_package) {
        setHexospark_pricing_package(user.hexospark_pricing_package);
      } else {
        setHexospark_pricing_package("FREE");
      }
      if (user.hexospark_pricing_package_count) {
        setHexospark_pricing_package_count(user.hexospark_pricing_package_count);
      } else {
        setHexospark_pricing_package_count(0);
      }
      if (user.hexospark_pricing_package_interval) {
        setHexospark_pricing_package_interval(user.hexospark_pricing_package_interval);
      } else {
        setHexospark_pricing_package_interval("MONTHLY");
      }

      if (user.hexofy_pricing_package) {
        setHexofy_pricing_package(user.hexofy_pricing_package);
      } else {
        setHexofy_pricing_package("FREE");
      }
      if (user.hexofy_pricing_package_count) {
        setHexofy_pricing_package_count(user.hexofy_pricing_package_count);
      } else {
        setHexofy_pricing_package_count(0);
      }
      if (user.hexofy_pricing_package_interval) {
        setHexofy_pricing_package_interval(user.hexofy_pricing_package_interval);
      } else {
        setHexofy_pricing_package_interval("MONTHLY");
      }

      if (user.hexoscope_pricing_package) {
        setHexoscope_pricing_package(user.hexoscope_pricing_package);
      } else {
        setHexoscope_pricing_package("FREE");
      }
      if (user.hexoscope_pricing_package_count) {
        setHexoscope_pricing_package_count(user.hexoscope_pricing_package_count);
      } else {
        setHexoscope_pricing_package_count(0);
      }
      if (user.hexoscope_pricing_package_interval) {
        setHexoscope_pricing_package_interval(user.hexoscope_pricing_package_interval);
      } else {
        setHexoscope_pricing_package_interval("MONTHLY");
      }

      if (user.premium_credits_pricing_package) {
        setPremium_pricing_package(user.premium_credits_pricing_package);
      }
      if (user.premium_credits_pricing_package_interval) {
        setPremium_pricing_package_interval(user.premium_credits_pricing_package_interval);
      }
      if (user.left_one_time_premium_credits) {
        setAddBonusCount(user.left_one_time_premium_credits || 0);
      }
      if (user.left_automation_credit) {
        setAutomationCredits(user.left_automation_credit || 0);
      }

      user.config && user.config.additional_scans_count
        ? setAdditionalScansCount(user.config.additional_scans_count)
        : setAdditionalScansCount(0);
    }
  }, [user]);

  const handlePackageChange = (e: React.FocusEvent) => {
    setPricingPackage((e.target as HTMLInputElement).value);
    setIsHexowatchPackageChanged(true);
  };

  const handlePackageChangeInterval = (e: React.FocusEvent) => {
    setPricingPackageInterval((e.target as HTMLInputElement).value);
    setIsHexowatchPackageChanged(true);
  };

  const handleHexometerPricingPackage = (e: React.FocusEvent) => {
    setHexometerPricingPackage((e.target as HTMLInputElement).value);
    setIsHexometerPackageChanged(true);
  };

  const handleHexometerPricingPackageInterval = (e: React.FocusEvent) => {
    setHexometerPricingPackageInterval((e.target as HTMLInputElement).value);
    setIsHexometerPackageChanged(true);
  };

  const handleHexomaticPricingPackageInterval = (e: React.FocusEvent) => {
    setHexomatic_pricing_package_interval((e.target as HTMLInputElement).value);
    setIsHexomaticPackageChanged(true);
  };

  const handleHexomaticPricingPackage = (e: React.FocusEvent) => {
    let val = (e.target as HTMLInputElement).value;
    setHexomatic_pricing_package(val);
    setIsHexomaticPackageChanged(true);
  };

  const handleHexosparkPricingPackage = (e: React.FocusEvent) => {
    let val = (e.target as HTMLInputElement).value;
    setHexospark_pricing_package(val);
    setIsHexosparkPackageChanged(true);
  };

  const handleHexosparkPricingPackageInterval = (e: React.FocusEvent) => {
    setHexospark_pricing_package_interval((e.target as HTMLInputElement).value);
    setIsHexosparkPackageChanged(true);
  };

  const handleHexofyPricingPackage = (e: React.FocusEvent) => {
    let val = (e.target as HTMLInputElement).value;
    setHexofy_pricing_package(val);
    setIsHexofyPackageChanged(true);
  };

  const handleHexoscopePricingPackage = (e: React.FocusEvent) => {
    let val = (e.target as HTMLInputElement).value;
    setHexoscope_pricing_package(val);
    setIsHexoscopePackageChanged(true);
  };

  const handleHexofyPricingPackageInterval = (e: React.FocusEvent) => {
    setHexofy_pricing_package_interval((e.target as HTMLInputElement).value);
    setIsHexofyPackageChanged(true);
  };

  const handleHexoscopePricingPackageInterval = (e: React.FocusEvent) => {
    setHexoscope_pricing_package_interval((e.target as HTMLInputElement).value);
    setIsHexoscopePackageChanged(true);
  };

  const handlePremiumPricingPackage = (e: React.FocusEvent) => {
    let val = (e.target as HTMLInputElement).value;
    setPremium_pricing_package(val);
    const interval = val
      ? premiumCreditPackages.find((item: any) => item.name === val) &&
        premiumCreditPackages.find((item: any) => item.name === val).interval
      : "";
    setPremium_pricing_package_interval(interval);
  };

  const handlePremiumPricingPackageInterval = (e: React.FocusEvent) => {
    setPremium_pricing_package_interval((e.target as HTMLInputElement).value);
  };

  const handleEmail = (e) => {
    errMsg && setErrMsg("");
    setEmail(e.target.value.toLowerCase());
  };
  return (
    <div style={{ height: 0 }}>
      <ReactModal
        isOpen={showModal}
        contentLabel="Create Translation"
        style={customStyles}
        onRequestClose={handleCancel}
      >
        <h4>Edit User</h4>
        {errorMessage || errMsg ? <Alert type="danger">{errorMessage || errMsg}</Alert> : null}
        <Form onSubmit={(e: React.FormEvent) => e.preventDefault()}>
          <div className="row">
            <div className="col-md-4">
              <Form.Select
                onChange={handlePackageChange}
                label="HEXOWATCH Pricing Package"
                value={pricingPackage || "FREE"}
              >
                {hexowatchPackagesList.map((pack) => (
                  <option key={pack} value={pack}>
                    {pack}
                  </option>
                ))}
              </Form.Select>
            </div>
            <div className="col-md-4">
              <Form.Select
                onChange={handlePackageChangeInterval}
                label="Interval"
                value={pricingPackageInterval || "FREE"}
              >
                {hexowatchPackagesInterval.map((pack) => (
                  <option key={pack} value={pack}>
                    {pack}
                  </option>
                ))}
              </Form.Select>
            </div>
            <div className="col-md-4">
              <Form.Input
                required
                type="text"
                placeholder=""
                label="Additional Scans"
                value={additionalScansCount}
                onChange={(e: any) => setAdditionalScansCount(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <Form.Select
                onChange={handleHexometerPricingPackage}
                label="HEXOMETER Pricing Package"
                value={hexometer_pricing_package || "FREE"}
              >
                {hexometerPackagesList.map((pack) => (
                  <option key={pack} value={pack}>
                    {pack}
                  </option>
                ))}
              </Form.Select>
            </div>
            <div className="col-md-4">
              <Form.Select
                onChange={handleHexometerPricingPackageInterval}
                label="Interval"
                value={hexometer_pricing_package_interval || "FREE"}
              >
                {hexometerPackagesInterval.map((pack) => (
                  <option key={pack} value={pack}>
                    {pack}
                  </option>
                ))}
              </Form.Select>
            </div>
            <div className="col-md-4">
              <Form.Input
                required
                type="text"
                placeholder=""
                label="Count"
                value={hexometer_pricing_package_count}
                onChange={(e: any) => {
                  setHexometerPricingPackageCount(e.target.value);
                  setIsHexometerPackageChanged(true);
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <Form.Select
                onChange={handleHexomaticPricingPackage}
                label="HEXOMATIC Pricing Package"
                value={hexomatic_pricing_package || "FREE"}
              >
                {hexomaticPackagesList.map((pack) => (
                  <option key={pack} value={pack}>
                    {pack}
                  </option>
                ))}
              </Form.Select>
            </div>
            <div className="col-md-4">
              <Form.Select
                onChange={handleHexomaticPricingPackageInterval}
                label="Interval"
                value={hexomatic_pricing_package_interval || "FREE"}
              >
                {hexomaticPackagesInterval.map((pack) => (
                  <option key={pack} value={pack}>
                    {pack}
                  </option>
                ))}
              </Form.Select>
            </div>
            <div className="col-md-4">
              <Form.Input
                required
                type="text"
                placeholder=""
                label="Count"
                value={hexomatic_pricing_package_count}
                onChange={(e: any) => {
                  setHexomatic_pricing_package_count(e.target.value);
                  setIsHexomaticPackageChanged(true);
                }}
              />
            </div>
          </div>

          {/* //! -------------------------   Hexospark   ----------------- */}
          <div className="row">
            <div className="col-md-4">
              <Form.Select
                onChange={handleHexosparkPricingPackage}
                label="HEXOSPARK Pricing Package"
                value={hexospark_pricing_package || "FREE"}
              >
                {hexosparkPackagesList.map((pack) => (
                  <option key={pack} value={pack}>
                    {pack}
                  </option>
                ))}
              </Form.Select>
            </div>
            <div className="col-md-4">
              <Form.Select
                onChange={handleHexosparkPricingPackageInterval}
                label="Interval"
                value={hexospark_pricing_package_interval || "FREE"}
              >
                {hexosparkPackagesInterval.map((pack) => (
                  <option key={pack} value={pack}>
                    {pack}
                  </option>
                ))}
              </Form.Select>
            </div>
            <div className="col-md-4">
              <Form.Input
                required
                type="text"
                placeholder=""
                label="Count"
                value={hexospark_pricing_package_count}
                onChange={(e: any) => {
                  setHexospark_pricing_package_count(e.target.value);
                  setIsHexosparkPackageChanged(true);
                }}
              />
            </div>
          </div>

          {/* //! --------------------   Hexofy   ---------------------------*/}

          <div className="row">
            <div className="col-md-4">
              <Form.Select
                onChange={handleHexofyPricingPackage}
                label="HEXOFY Pricing Package"
                value={hexofy_pricing_package || "FREE"}
              >
                {hexofyPackagesList.map((pack) => (
                  <option key={pack} value={pack}>
                    {pack}
                  </option>
                ))}
              </Form.Select>
            </div>
            <div className="col-md-4">
              <Form.Select
                onChange={handleHexofyPricingPackageInterval}
                label="Interval"
                value={hexofy_pricing_package_interval || "FREE"}
              >
                {hexofyPackagesInterval.map((pack) => (
                  <option key={pack} value={pack}>
                    {pack}
                  </option>
                ))}
              </Form.Select>
            </div>
            <div className="col-md-4">
              <Form.Input
                required
                type="text"
                placeholder=""
                label="Count"
                value={hexofy_pricing_package_count}
                onChange={(e: any) => {
                  setHexofy_pricing_package_count(e.target.value);
                  setIsHexofyPackageChanged(true);
                }}
              />
            </div>
          </div>

           {/* //! --------------------   Hexoscope   ---------------------------*/}

           <div className="row">
            <div className="col-md-4">
              <Form.Select
                onChange={handleHexoscopePricingPackage}
                label="HEXOSCOPE Pricing Package"
                value={hexoscope_pricing_package || "FREE"}
              >
                {hexoscopePackagesList.map((pack) => (
                  <option key={pack} value={pack}>
                    {pack}
                  </option>
                ))}
              </Form.Select>
            </div>
            <div className="col-md-4">
              <Form.Select
                onChange={handleHexoscopePricingPackageInterval}
                label="Interval"
                value={hexoscope_pricing_package_interval || "FREE"}
              >
                {hexoscopePackagesInterval.map((pack) => (
                  <option key={pack} value={pack}>
                    {pack}
                  </option>
                ))}
              </Form.Select>
            </div>
            <div className="col-md-4">
              <Form.Input
                required
                type="text"
                placeholder=""
                label="Count"
                value={hexoscope_pricing_package_count}
                onChange={(e: any) => {
                  setHexoscope_pricing_package_count(e.target.value);
                  setIsHexoscopePackageChanged(true);
                }}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <Form.Select
                onChange={handlePremiumPricingPackage}
                label="Premium credits pricing package"
                value={premium_pricing_package || "FREE"}
              >
                {premiumCreditPackages.map(
                  (pack: { name: string; interval: string; value: string; id: string }) => (
                    <option key={pack.name} value={pack.name}>
                      {pack.value}
                    </option>
                  )
                )}
              </Form.Select>
            </div>
            <div className="col-md-4 d-none">
              <Form.Select
                onChange={handlePremiumPricingPackageInterval}
                label="interval"
                value={premium_pricing_package_interval || "FREE"}
              >
                {["ANNUAL", "MONTHLY"].map((pack) => (
                  <option
                    key={pack}
                    value={pack}
                    disabled={
                      (
                        premiumCreditPackages.find((item: any) => item.value === premium_pricing_package) ||
                        {}
                      ).interval !== pack
                    }
                  >
                    {pack}
                  </option>
                ))}
              </Form.Select>
            </div>
            <div className="col-md-4">
              <Form.Input
                required
                type="text"
                placeholder=""
                label="Bonus Credits"
                value={addBonusCount}
                onChange={(e: any) => setAddBonusCount(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <Form.Input
                required
                type="number"
                placeholder=""
                label="Automation credits"
                value={automationCredits}
                onChange={(e: any) => setAutomationCredits(+e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <Form.Input
                required
                type="text"
                placeholder="email"
                label="Email address"
                value={email}
                onChange={(e: any) => handleEmail(e)}
              />
            </div>
            <div className="col-md-4 d-flex align-items-end justify-content-end">
              <div className="d-flex  " style={{ marginBottom: "16px" }}>
                <Button.List>
                  <Button onClick={handleOk} color="primary" type="submit" loading={loading}>
                    Save
                  </Button>
                  <Button onClick={handleCancel} color="secondary" type="button">
                    Cancel
                  </Button>
                </Button.List>
              </div>
            </div>
          </div>
          {errorMessage || errMsg ? <Alert type="danger">{errorMessage || errMsg}</Alert> : null}
        </Form>
      </ReactModal>
    </div>
  );
};

export default UsersModal;
