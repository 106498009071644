import React, { useEffect, useState } from "react";
import { Alert, Button, Form } from "tabler-react";
import ReactModal from "react-modal";
import ReactMde from "react-mde";
import * as Showdown from "showdown";
import "react-mde/lib/styles/css/react-mde-all.css";

ReactModal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    height: "50%",
  },
};

const TableModal = ({
  handleModal,
  showModal,
  onSave,
  onUpdate,
  editField,
  errorMessage,
}: any): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");
  const [tab, setTab] = useState<"write" | "preview">("write");

  const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true,
  });
  const handleOk = async () => {
    setLoading(true);
    if (editField) {
      await onUpdate({ id: editField.id, body: text, category: "GENERAL" });
      setLoading(false);
      return;
    }
    const formData = { text };
    const completed = await onSave({ ...formData });
    setLoading(false);
    // if (completed) handleCancel();
  };

  const handleCancel = () => {
    handleModal();
  };

  useEffect(() => {
    if (window.innerWidth < 640) {
      customStyles.content.width = "90%";
    }
  }, []);

  useEffect(() => {
    editField && editField.body && setText(editField.body);
  }, [editField]);

  const handleTabChange = (tab: "write" | "preview") => setTab(tab);

  return (
    <div style={{ height: 0 }}>
      <ReactModal
        isOpen={showModal}
        contentLabel="Create ice breaker"
        style={customStyles}
        onRequestClose={handleCancel}
      >
        <h4>{editField ? "Edit ice breaker" : "Create ice breaker"}</h4>

        <Form onSubmit={(e: React.FormEvent) => e.preventDefault()}>
          {errorMessage ? <Alert type="danger">{errorMessage}</Alert> : null}
          <Form.Select
            onChange={(e: any) => {
              setText(text + e.target.value);
            }}
            label="Select Variable"
            value={""}
          >
            <option value="{{position}}">position</option>
            <option value="{{job title}}">job title</option>
            <option value="{{company}}">company</option>
            <option value="{{field of product}}">field of product</option>
            <option value="{{number of users}}">number of users</option>
            <option value="{{company field}}">company field</option>
            <option value="{{product proposition}}">product proposition</option>
            <option value="{{problem}}">problem</option>
            <option value="{{website}}">website</option>
            <option value="{{trade show name}}">trade show name</option>
            <option value="{{mutual connection’s name}}">mutual connection’s name</option>
            <option value="{{article headline}}">article headline</option>
            <option value="{{contactFirstName}}">contactFirstName</option>
            <option value="{{contactLastName}}">contactLastName</option>
            <option value="{{contactDescription}}">contactDescription</option>
            <option value="{{contactExperience}}">contactExperience</option>
            <option value="{{contactEducation}}">contactEducation</option>
            <option value="{{contactLicensesAndCertifications}}">contactLicensesAndCertifications</option>
            <option value="{{contactVolunteeringExperience}}">contactVolunteeringExperience</option>
            <option value="{{recommendations}}">recommendations</option>
            <option value="{{placeholder}}">placeholder</option>
            <option value="{{contactHonorsAndAwards}}">contactHonorsAndAwards</option>
            <option value="{{contactLanguage}}">contactLanguage</option>
            <option value="{{contactSkills}}">contactSkills</option>
            <option value="{{contactActivity}}">contactActivity</option>
          </Form.Select>
          <ReactMde
            onChange={(value: string) => setText(value)}
            onTabChange={handleTabChange}
            value={text}
            generateMarkdownPreview={(markdown) => Promise.resolve(converter.makeHtml(markdown))}
            selectedTab={tab}
            className="mb-2"
          />
          <Button.List style={{ marginTop: "5%" }}>
            <Button onClick={handleOk} color="primary" type="submit" loading={loading}>
              Save
            </Button>
            <Button onClick={handleCancel} color="secondary" type="button">
              Cancel
            </Button>
          </Button.List>
        </Form>
      </ReactModal>
      {errorMessage ? <Alert type="danger">{errorMessage}</Alert> : null}
    </div>
  );
};

export default TableModal;
