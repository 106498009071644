import React, { useEffect, useState, FC } from "react";
import { Grid, Button, Alert } from "tabler-react";
import ReactModal from "react-modal";

import { ContentTextProps } from "../../types";
import ServerSidePagination from "../../components/pagination/serverSidePagination";
import TableCard from "../../components/tableCard";
import TableModal from "./tableModal";

import { alertCustomStyles } from "../../helpers";
import "./style.css";

const ScrapingCategories: FC<any> = ({
  data,
  loading,
  createCategory,
  deleteCategory,
  updateCategory,
  onPageNumberChange,
}) => {
  const [categoryList, setCategoryList] = useState<any>([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [editCategory, setEditCategory] = useState<any>(false);
  const [total, setTotal] = useState(0);
  const [editField, setEditField] = useState<any>(null);

  const handleModal = () => {
    if (showModal) {
      setEditField(null);
      setErrorMessage("");
    }
    setShowModal(!showModal);
  };

  const handleSave = async ({ ...rest }) => {
    const { name, image, longDescription, shortDescription } = rest;
    if (editCategory) {
    } else {
      const res = await createCategory({
        variables: {
          data: {
            name,
            short_description: shortDescription,
            long_description: longDescription,
            image,
          },
        },
      });
      if (
        res.data.HexomaticScrapingCategoryOps &&
        !res.data.HexomaticScrapingCategoryOps.createScrapingCategory
      ) {
        handleModal();
        return;
      }
      const { error_code } = res.data.HexomaticScrapingCategoryOps.createScrapingCategory;
      if (error_code) {
        setErrorMessage(error_code);
        return;
      }
    }
    return true;
  };

  const onDelete = async (id: string) => {
    let deleteConfirm = confirm(`Are you sure you want to delete public scraping category with ID: ${id}`);
    if (!deleteConfirm) return;
    const res = await deleteCategory({
      variables: { id: id },
    });
    if (
      res.data.HexomaticScrapingCategoryOps &&
      !res.data.HexomaticScrapingCategoryOps.deleteScrapingCategory
    ) {
      setCategoryList(categoryList.filter((item) => item.id !== id));
      return;
    }
    const { error_code } = res.data.HexomaticScrapingCategoryOps.deleteScrapingCategory;
    if (error_code) {
      setErrorMessage(error_code);
      return;
    }
  };

  const onUpdate = async ({ id, name, image, longDescription, shortDescription }) => {
    const res = await updateCategory({
      variables: {
        data: { id, name, image, long_description: longDescription, short_description: shortDescription },
      },
    });
    handleModal();
    if (
      res.data.HexomaticScrapingCategoryOps &&
      !res.data.HexomaticScrapingCategoryOps.updateScrapingCategory
    ) {
      const newCategories = categoryList.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            name: name,
          };
        }
        return item;
      });
      setCategoryList(newCategories);
      return;
    }
    const { error_code } = res.data.HexomaticScrapingCategoryOps.updateScrapingCategory;
    if (error_code) {
      setErrorMessage(error_code);
      return;
    }
  };

  const tableData = {
    tData:
      categoryList &&
      categoryList.map((item: ContentTextProps) => ({
        id: item.id,
        name: item.name,
        image: item.image,
        short_description: item.short_description,
        long_description: item.long_description,
        Action: (
          <Button.List>
            <Button
              color="primary"
              icon="edit"
              onClick={() => {
                setEditField(item);
                setShowModal(true);
              }}
            />
            <Button color="danger" icon="trash" onClick={() => onDelete(item.id)} />
          </Button.List>
        ),
      })),
  };

  useEffect(() => {
    if (
      data &&
      data.HexomaticScrapingCategory &&
      data.HexomaticScrapingCategory.getScrapingCategories &&
      data.HexomaticScrapingCategory.getScrapingCategories.categories
    ) {
      setDataLoading(loading);
      setCategoryList(data.HexomaticScrapingCategory.getScrapingCategories.categories);
    }
  }, [data]);

  return (
    <Grid.Row cards deck>
      <TableModal
        handleModal={handleModal}
        showModal={showModal}
        onUpdate={onUpdate}
        onSave={handleSave}
        editField={editField}
        errorMessage={errorMessage}
      />

      <ReactModal isOpen={!!errorMessage} style={alertCustomStyles}>
        {errorMessage ? <Alert type="danger">{errorMessage}</Alert> : null}
        <Button className="" onClick={() => setErrorMessage("")} color="primary">
          OK
        </Button>
      </ReactModal>
      <div className="col-12 col-md-1 col-xs-6">
        <Button className="" onClick={() => setShowModal(true)} color="primary">
          Create
        </Button>
        <br />
      </div>
      <Grid.Col md={9}></Grid.Col>
      <div className="col-12 col-md-2 col-xs-6">
        <Alert type="info">Result Count - {total || categoryList.length}</Alert>
      </div>
      <div className="black-list-table-pagination">
        <ServerSidePagination data={categoryList} total={total} onPageNum={onPageNumberChange}>
          <TableCard loading={dataLoading} data={categoryList} tableData={tableData} />
        </ServerSidePagination>
      </div>

      {categoryList.length ? (
        <div className="col-12 col-md-2 col-xs-12 offset-md-10">
          <Alert type="info">Result Count - {total || categoryList.length}</Alert>
        </div>
      ) : null}
    </Grid.Row>
  );
};

export default ScrapingCategories;
