import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";

import {
  CREATE_AUTOMATION,
  DELETE_AUTOMATION,
  UPDATE_AUTOMATION,
  SYNC_AUTOMATION,
} from "../../graphql/mutations";
import {
  GET_AUTOMATIONS,
  GET_AUTOMATIONS_ID,
  GET_AUTOMATION_CATEGORIES,
  GET_AUTOMATION_TYPES,
} from "../../graphql/queries";
import Automations from "./index";

const AutomationQueries = () => {
  const [automationList, setAutomationList] = useState<any>([]);
  const [automationSuggestionsList, setAutomationSuggestionsList] = useState<any>([]);
  const [pageNumberState, setPageNumberState] = useState(1);
  const [filterDataState, setFilterDataState] = useState("");
  const [searchEmail, setSearchEmail] = useState<string>("");
  const [categoryList, setCategoryList] = useState<any>([]);
  const [automationTypeList, setAutomationTypeList] = useState<Array<any>>([]);

  const { data, loading, refetch } = useQuery(GET_AUTOMATIONS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      settings: {
        filter: {
          page: pageNumberState,
          limit: 100,
          search: {
            search_value: filterDataState,
          },
        },
      },
    },
  });
  const { data: list } = useQuery(GET_AUTOMATIONS_ID, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      settings: {
        filter: {
          page: 1,
          limit: 300,
          search: {
            search_value: "",
          },
        },
      },
    },
  });

  const { data: categoryData } = useQuery(GET_AUTOMATION_CATEGORIES, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      settings: {
        filter: {
          page: pageNumberState,
          limit: 100,
        },
      },
    },
  });

  const { data: typeData } = useQuery(GET_AUTOMATION_TYPES, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      settings: {
        filter: {
          page: pageNumberState,
          limit: 100,
        },
      },
    },
  });

  useEffect(() => {
    if (
      categoryData &&
      categoryData.HexomaticAdmin &&
      categoryData.HexomaticAdmin.getAutomationCategories.categories
    ) {
      const categories = categoryData.HexomaticAdmin.getAutomationCategories.categories;
      if (categories) {
        setCategoryList(categories.length === 1 ? [{ id: null, name: "" }, ...categories] : categories);
      }
      // setTotal(data.EmailBlackList.adminGetMany.total)
    }
  }, [categoryData]);

  useEffect(() => {
    if (typeData && typeData.HexomaticAdmin && typeData.HexomaticAdmin.getAutomationTypes.types) {
      const types = typeData.HexomaticAdmin.getAutomationTypes.types;
      if (types) {
        setAutomationTypeList(types.length === 1 ? [{ id: null, name: "" }, ...types] : types);
      }
      // setTotal(data.EmailBlackList.adminGetMany.total)
    }
  }, [typeData]);

  const [deleteAutomation] = useMutation(DELETE_AUTOMATION);

  const [updateAutomation] = useMutation(UPDATE_AUTOMATION);

  const [createAutomation] = useMutation(CREATE_AUTOMATION, {
    onCompleted: () => {
      refetchData();
    },
  });

  const [syncAutomation, { loading: syncLoading }] = useMutation(SYNC_AUTOMATION);

  useEffect(() => {
    refetch();
  }, [pageNumberState]);

  useEffect(() => {
    setAutomationList(data);
  }, [data]);

  useEffect(() => {
    if (
      list &&
      list.HexomaticAdmin &&
      list.HexomaticAdmin.getAutomations &&
      list.HexomaticAdmin.getAutomations.automations
    )
      setAutomationSuggestionsList(
        list.HexomaticAdmin.getAutomations.automations.map((item: { id: number; name: string }) => {
          return { label: item.name, value: item.id };
        })
      );
  }, [list]);

  useEffect(() => {
    refetchData();
  }, [searchEmail]);

  const refetchData = async () => {
    await refetch();
  };

  const handlePageNumberChange = (pageNumber: number) => setPageNumberState(pageNumber);
  const handleFilterDataChange = (filterData: string) => setFilterDataState(filterData);

  return (
    <Automations
      data={automationList}
      loading={loading}
      searchEmail={searchEmail}
      setSearchEmail={setSearchEmail}
      onPageNumberChange={handlePageNumberChange}
      createAutomation={createAutomation}
      categoryList={categoryList}
      automationTypeList={automationTypeList}
      deleteAutomation={deleteAutomation}
      updateAutomation={updateAutomation}
      refetchData={refetchData}
      syncAutomation={syncAutomation}
      syncLoading={syncLoading}
      onFilterDataChange={handleFilterDataChange}
      automationSuggestionsList={automationSuggestionsList}
    />
  );
};

export default AutomationQueries;
