import React, { useState } from "react";
import WatchNotifications from "./watchNotification";
import { useQuery } from "@apollo/react-hooks";
import { GET_WATCH_NOTIFICATIONS, GET_CONSTANT_TYPES } from "../../graphql/queries";

const WatchNotificationsQueries = () => {
  const [pageNumberState, setPageNumberState] = useState(1);
  const [filterDataState, setFilterDataState] = useState("");
  const [channelState, setChannelState] = useState("");
  const [typeState, setTypeState] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [orderByASC, setOrderByASC] = useState(false);
  const [hideHex, setHideHex] = useState(true);

  const { data, loading, refetch } = useQuery(GET_WATCH_NOTIFICATIONS, {
    variables: {
      input: {
        regFrom: fromDate,
        regTo: toDate,
        pageNumber: pageNumberState,
        searchQuery: filterDataState,
        hideHex: hideHex,
      },
      channel: channelState,
      type: typeState,
      orderBy,
      orderByASC,
      csvDownload: false,
      driveUpload: false,
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const { data: constData, loading: constLoading } = useQuery(GET_CONSTANT_TYPES, {
    variables: {
      requested_data: {
        hexowatch_notifications_description: true,
      },
    },
  });

  const handlePageNumberChange = (pageNumber: number) => setPageNumberState(pageNumber);
  const handleFilterDataChange = (filterData: string) => setFilterDataState(filterData);
  const handleChannelChange = (chnl: string) => setChannelState(chnl);
  const handleTypeChange = (tp: string) => setTypeState(tp);
  const handleFromDate = (date: string) => setFromDate(date);
  const handleToDate = (date: string) => setToDate(date);

  return (
    <WatchNotifications
      constants={constData}
      data={data}
      loading={loading}
      onPageNumberChange={handlePageNumberChange}
      refetchData={refetch}
      onFilterDataChange={handleFilterDataChange}
      onSelectChannel={handleChannelChange}
      onSelectType={handleTypeChange}
      onFromDate={handleFromDate}
      onToDate={handleToDate}
      setOrderByASC={setOrderByASC}
      orderByASC={orderByASC}
      setOrderBy={setOrderBy}
      setHideHex={setHideHex}
      hideHex={hideHex}
      variables={{
        input: {
          regFrom: fromDate,
          regTo: toDate,
          pageNumber: pageNumberState,
          searchQuery: filterDataState,
          hideHex: hideHex,
        },
        channel: channelState,
        type: typeState,
      }}
    />
  );
};

export default WatchNotificationsQueries;
