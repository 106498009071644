import { gql } from "apollo-boost";

const mutationFields = `
error
message
`;

export const LOGIN_MUTATION = gql`
  mutation AdminLogin($username: String!, $password: String!) {
    AdminOps {
      login(username: $username, password: $password) {
        ${mutationFields}
      }
    }
  }
`;

export const KEYWORD_DELETE_MUTATION = gql`
  mutation DeleteKeyword($key: String!) {
    KeywordOps {
      delete(key: $key) {
        ${mutationFields}
      }
    }
  }
`;

export const KEYWORD_CREATE_MUTATION = gql`
  mutation KEYWORD_CREATE_MUTATION($key: String!, $text: String!) {
    KeywordOps {
      create(key: $key, text: $text) {
        ${mutationFields}
      }
    }
  }
`;

export const KEYWORD_UPDATE_MUTATION = gql`
  mutation UpdateKeyword($key: String!, $text: String!) {
    KeywordOps {
      update(key: $key, text: $text) {
        ${mutationFields}
      }
    }
  }
`;

export const EMAIL_BLACKLIST_CREATE_MUTATUION = gql`
  mutation AdminAddEmail($email: String!) {
    EmailBlackListOps {
      adminAddEmail(email: $email) {
        ${mutationFields}
      }
    }
  }
`;

export const EMAIL_BLACKLIST_UPDATE_MUTATUION = gql`
  mutation AdminUpdateEmail($email: String!, $id: Int!) {
    EmailBlackListOps {
      adminUpdateEmail(email: $email, id: $id) {
        ${mutationFields}
      }
    }
  }
`;

export const EMAIL_BLACKLIST_DELETE_MUTATUION = gql`
  mutation AdminDeleteEmail($email: String!) {
    EmailBlackListOps {
      adminDeleteEmail(email: $email) {
        ${mutationFields}
      }
    }
  }
`;

export const HEADERDATA_DELETE_MUTATION = gql`
  mutation DeleteHeaderData ($path: String!) {
    HeaderDataOps {
      delete(path: $path) {
        ${mutationFields}
      }
    }
  }
`;

export const HEADERDATA_CREATE_MUTATION = gql`
  mutation CreateHeaderData ($path: String!, $title: String, $input: [HeaderDataInput] ) {
    HeaderDataOps {
      create(path: $path, title: $title, input: $input) {
        ${mutationFields}
      }
    }
  }
`;

export const HEADERDATA_UPDATE_MUTATION = gql`
  mutation UpdateHeaderData ($id: Int!, $path: String, $title: String, $input: [HeaderDataInput] ) {
    HeaderDataOps {
      update(id: $id, path: $path, title: $title, input: $input) {
        ${mutationFields}
      }
    }
  }
`;

export const SUBSCRIPTION_DELETE_MUTATION = gql`
  mutation DeleteSubscription($id: Int!) {
    ScanSubscriptionOps {
      delete(id: $id) {
        ${mutationFields}
      }
    }
  }
`;

export const SUBSCRIPTION_CREATE_MUTATION = gql`
  mutation CreateSubscription($url: String!, $tool: String!) {
    ScanSubscriptionOps {
      create(url: $url, tool: $tool) {
        ${mutationFields}
      }
    }
  }
`;

export const UPDATE_HEXOMATIC_USER_AUTOMATION_CREDITS = gql`
  mutation UPDATE_HEXOMATIC_USER_AUTOMATION_CREDITS($userId: Int, $credits: Int) {
    UserOps {
      updateHexomaticUserCredits(userId: $userId, credits: $credits) {
        message
        error
      }
    }
  }
`;

export const USER_DELETE_MUTATION = gql`
  mutation DeleteUser($id: ID!) {
    UserOps {
      delete(id: $id) {
        ${mutationFields}
      }
    }
  }
`;

export const USER_BLOCK_MUTATION = gql`
  mutation BlockUser($id: ID!) {
    UserOps {
      block(id: $id) {
        ${mutationFields}
      }
    }
  }
`;

export const USER_UNBLOCK_MUTATION = gql`
  mutation BlockUser($id: ID!) {
    UserOps {
      unblock(id: $id) {
        ${mutationFields}
      }
    }
  }
`;

export const USER_UPDATE_MUTATION = gql`
  mutation UpdateUser(
    $id: ID!,
    $email: String,
  ) {
    UserOps {
      adminUpdate(
        id: $id,
        email: $email,
      ) {
        ${mutationFields}
      }
    }
  }
`;

export const PROPERTY_DELETE_MUTATION = gql`
  mutation DeleteProperty($id: ID!) {
    PropertyOps {
      adminDelete(id: $id) {
        ${mutationFields}
      }
    }
  }
`;
export const PROPERTY_UPDATE_MUTATION = gql`
  mutation UpdateProperty($id: ID!, $name: String, $address: String, $hostname: String, $paused: Boolean, $pricingPackage: String) {
    PropertyOps {
      adminUpdate(id: $id, name: $name, address: $address, hostname: $hostname, paused: $paused, pricingPackage: $pricingPackage) {
        ${mutationFields}
      }
    }
  }
`;

export const PROPERTY_RESCAN_MUTATION = gql`
  mutation RescanProperty($property_id: Int) {
    AdminOps {
      rescanProperty(property_id: $property_id) {
        ${mutationFields}
      }
    }
  }
`;

export const GET_MONTHLY_SUMMARY_SCHEDULE_CONFIG = gql`
  query {
    Admin {
      getMonthlySummaryScheduleConfig {
        ongoing
        error
        message
      }
    }
  }
`;

export const SET_MONTHLY_SUMMARY_SCHEDULE_CONFIG = gql`
  mutation($ongoing: Boolean!) {
    AdminOps {
      setMonthlySummaryScheduleConfig(ongoing: $ongoing) {
        ${mutationFields}
      }
    }
  }
`;

export const SEND_PROPERTY_SUMMARY_EMAIL = gql`
  mutation ($from: String!, $to: String!, $email: String, $toAll: Boolean) {
    PropertyOps {
      adminSendSummary(from: $from, to: $to, email: $email, toAll: $toAll) {
        ${mutationFields}
      }
    }
  }
`;

export const AITASK_CREATE_MUTATION = gql`
  mutation CreateAITask($id: Int!, $title: String!, $content: String!, $priority: String!, $taskType: String!) {
    TaskOps {
      adminCreate(id: $id, title: $title, content: $content, priority: $priority, taskType: $taskType) {
        ${mutationFields}
      }
    }
  }
`;

export const AITASK_RESOLVE_MUTATION = gql`
  mutation ResolveAITask ($id: Int!) {
    TaskOps {
      adminResolve(id: $id) {
        ${mutationFields}
      }
    }
  }
`;

export const PROMOCODE_CREATE_MUTATION = gql`
  mutation CreatePromoCode ($targetPackage: String!, $targetValue: Float!, $expireAt: String!, $targetExtra: String, $prefix: String) {
    PromoCodeOps {
      create(targetPackage: $targetPackage, targetValue: $targetValue, expireAt: $expireAt, targetExtra: $targetExtra, prefix: $prefix) {
        ${mutationFields}
      }
    }
  }
`;

export const PROMOCODE_CANCEL_MUTATION = gql`
  mutation CancelPromoCode ($code: String!) {
    PromoCodeOps {
      invalidate(code: $code) {
        ${mutationFields}
      }
    }
  }
`;

export const WATCH_PROPERTY_DELETE_MUTATION = gql`
  mutation watchDeleteProperty($id: ID!) {
    WatchOps {
      adminDelete(id: $id) {
        ${mutationFields}
      }
    }
  }
`;

export const USER_WATCH_CONFIG_UPDATE = gql`
  mutation userWatchConfigUpdate($user_id: Int!, $additional_scans_count: Int!) {
    UserWatchSettingsOps {
      adminUpdate(user_id: $user_id, additional_scans_count: $additional_scans_count) {
        ${mutationFields}
      }
    }
  }
`;

export const SHORTLINK_CREATE_MUTATUION = gql`
  mutation ShortlinAddMany($links: [String]) {
    ShortlinkOps {
      addMany(links: $links) {
        ${mutationFields}
      }
    }
  }
`;

//hexomatic mutations

export const CREATE_AUTOMATION_CATEGORIES = gql`
  mutation createAutomationCategory($settings: CreateAutomationCategoryInput) {
    HexomaticAdminOps {
      createAutomationCategory(settings: $settings) {
        error_code
      }
    }
  }
`;

export const DELETE_AUTOMATION_CATEGORY = gql`
  mutation ($settings: DeleteAutomationCategoryInput) {
    HexomaticAdminOps {
      deleteAutomationCategory(settings: $settings) {
        error_code
      }
    }
  }
`;

export const UPDATE_AUTOMATION_CATEGORY = gql`
  mutation ($settings: UpdateAutomationCategoryInput) {
    HexomaticAdminOps {
      updateAutomationCategory(settings: $settings) {
        error_code
      }
    }
  }
`;

export const CREATE_AUTOMATION_TYPES = gql`
  mutation createAutomationType($settings: CreateAutomationTypeInput) {
    HexomaticAdminOps {
      createAutomationType(settings: $settings) {
        error_code
      }
    }
  }
`;

export const DELETE_AUTOMATION_TYPE = gql`
  mutation deleteAutomationType($settings: DeleteAutomationTypeInput) {
    HexomaticAdminOps {
      deleteAutomationType(settings: $settings) {
        error_code
      }
    }
  }
`;

export const UPDATE_AUTOMATION_TYPE = gql`
  mutation updateAutomationType($settings: UpdateAutomationTypeInput) {
    HexomaticAdminOps {
      updateAutomationType(settings: $settings) {
        error_code
      }
    }
  }
`;

export const CREATE_AUTOMATION = gql`
  mutation createAutomation($settings: CreateAutomationInput) {
    HexomaticAdminOps {
      createAutomation(settings: $settings) {
        error_code
      }
    }
  }
`;

export const DELETE_AUTOMATION = gql`
  mutation deleteAutomation($settings: DeleteAutomationInput) {
    HexomaticAdminOps {
      deleteAutomation(settings: $settings) {
        error_code
      }
    }
  }
`;

export const UPDATE_AUTOMATION = gql`
  mutation updateAutomation($settings: UpdateAutomationInput) {
    HexomaticAdminOps {
      updateAutomation(settings: $settings) {
        error_code
      }
    }
  }
`;

export const CREATE_SCHEMA = gql`
  mutation createJsonSchema($settings: CreateJsonSchemaInput) {
    HexomaticAdminOps {
      createJsonSchema(settings: $settings) {
        error_code
      }
    }
  }
`;

export const UPDATE_SCHEMA = gql`
  mutation updateJsonSchema($settings: UpdateJsonSchemaInput) {
    HexomaticAdminOps {
      updateJsonSchema(settings: $settings) {
        error_code
      }
    }
  }
`;

export const DELETE_SCHEMA = gql`
  mutation deleteJsonSchema($settings: DeleteJsonSchemaInput) {
    HexomaticAdminOps {
      deleteJsonSchema(settings: $settings) {
        error_code
      }
    }
  }
`;

export const ATTACH_SCHEMAS_TO_AUTOMATION = gql`
  mutation attachSchemaToAutomation($settings: AttachSchemaToAutomationAdminInput) {
    HexomaticAdminOps {
      attachSchemaToAutomation(settings: $settings) {
        error_code
      }
    }
  }
`;

export const DELETE_SCHEMAS_FROM_AUTOMATION = gql`
  mutation removeSchemaFromAutomation($settings: RemoveSchemaFromAutomationAdminInput) {
    HexomaticAdminOps {
      removeSchemaFromAutomation(settings: $settings) {
        error_code
      }
    }
  }
`;

export const CREATE_AUTOMATION_UNIT = gql`
  mutation createAutomationUnit($settings: CreateAutomationUnitInput) {
    HexomaticAdminOps {
      createAutomationUnit(settings: $settings) {
        error_code
      }
    }
  }
`;

export const DELETE_AUTOMATION_UNIT = gql`
  mutation deleteAutomationUnit($settings: DeleteAutomationUnitInput) {
    HexomaticAdminOps {
      deleteAutomationUnit(settings: $settings) {
        error_code
      }
    }
  }
`;

export const UPDATE_AUTOMATION_UNIT = gql`
  mutation updateAutomationUnit($settings: UpdateAutomationUnitInput) {
    HexomaticAdminOps {
      updateAutomationUnit(settings: $settings) {
        error_code
      }
    }
  }
`;

export const ADD_AUTOMATION_UNIT_PRICE = gql`
  mutation addUnitPriceToAutomation($settings: AddUnitPriceToAutomationAdminInput) {
    HexomaticAdminOps {
      addUnitPriceToAutomation(settings: $settings) {
        error_code
      }
    }
  }
`;

export const DELETE_AUTOMATION_UNIT_PRICE = gql`
  mutation removeUnitPriceFromAutomation($settings: RemoveUnitPriceFromAutomationAdminInput) {
    HexomaticAdminOps {
      removeUnitPriceFromAutomation(settings: $settings) {
        error_code
      }
    }
  }
`;

export const UPDATE_AUTOMATION_UNIT_PRICE = gql`
  mutation updateAutomationUnitPrice($settings: UpdateAutomationUnitPriceAdminInput) {
    HexomaticAdminOps {
      updateAutomationUnitPrice(settings: $settings) {
        error_code
      }
    }
  }
`;

export const SYNC_AUTOMATION = gql`
  mutation syncAutomations {
    HexomaticAdminOps {
      syncAutomations {
        error_code
      }
    }
  }
`;

export const ADD_ONE_TIME_BONUS = gql`
  mutation updateHexomaticUserOneTimePremiumCredits(
    $settings: UpdateHexomaticUserOneTimePremiumCreditsInput
  ) {
    HexomaticAdminOps {
      updateHexomaticUserOneTimePremiumCredits(settings: $settings) {
        error_code
      }
    }
  }
`;

export const UPDATE_HEXOMATIC_USER_CREDITS_PACKAGE = gql`
  mutation updateHexomaticPackageAdmin($settings: UpdateHexomaticPackageAdminInput) {
    HexomaticAdminOps {
      updateHexomaticPackageAdmin(settings: $settings) {
        error_code
      }
    }
  }
`;

export const UPDATE_MAIN_USER_PREMIUM_CREDITS_PACKAGE = gql`
  mutation updateMainUserPremiumCreditsPackageAdmin(
    $settings: UpdateHexomaticCreditsPricingPackageAdminInput
  ) {
    AdminOps {
      updateMainUserPremiumCreditsPackageAdmin(settings: $settings) {
        error_code
      }
    }
  }
`;
export const UPDATE_HEXOMETER_PACKAGE = gql`
  mutation updateHexometerPackageAdmin(
    $userId: Int
    $productId: String
    $specific_package_stacks_count: Int
  ) {
    AdminOps {
      updateHexometerPackageAdmin(
        userId: $userId
        productId: $productId
        specific_package_stacks_count: $specific_package_stacks_count
      ) {
        error_code
      }
    }
  }
`;

export const UPDATE_HEXOWATCH_PACKAGE = gql`
  mutation updateUserWatchPricingPackageAdmin($settings: UpdateHexowatchPricingAdminPackageInput) {
    AdminOps {
      updateUserWatchPricingPackageAdmin(settings: $settings) {
        error_code
      }
    }
  }
`;

export const UPDATE_HEXOSPARK_PACKAGE = gql`
  mutation UPDATE_HEXOSPARK_PACKAGE($settings: UpdateHexosparkPackageAdminInput) {
    HexosparkUserOps {
      updateHexosparkPackageAdmin(settings: $settings) {
        error_code
      }
    }
  }
`;

export const UPDATE_HEXOFY_PACKAGE = gql`
  mutation UPDATE_HEXOFY_PACKAGE($settings: UpdateHexofyPackageAdminInput) {
    HexofyUserOps {
      updateHexofyPackageAdmin(settings: $settings) {
        error_code
      }
    }
  }
`;

export const UPDATE_HEXOSCOPE_PACKAGE = gql`
  mutation UPDATE_HEXOSCOPE_PACKAGE($settings: UpdateHexoscopePackageAdminInput) {
    HexoscopeUserOps {
      updateHexoscopePackageAdmin(settings: $settings) {
        error_code
      }
    }
  }
`;

export const UPDATE_PUBLIC_WORKFLOW = gql`
  mutation UPDATE_PUBLIC_WORKFLOW($settings: AdminUpdatePublicWorkflowInput) {
    HexomaticPublicWorkflowOps {
      adminUpdatePublicWorkflow(settings: $settings) {
        error
        message
      }
    }
  }
`;

export const DELETE_PUBLIC_WORKFLOW = gql`
  mutation DELETE_PUBLIC_WORKFLOW($id: Int) {
    HexomaticPublicWorkflowOps {
      adminDeletePublicWorkflow(id: $id) {
        error
        message
      }
    }
  }
`;

//Public workflow categories

export const CREATE_PUBLIC_WORKFLOW_CATEGORIES = gql`
  mutation ($data: CreateWorkflowCategoryInput) {
    HexomaticWorkflowCategoryOps {
      createWorkflowCategory(data: $data) {
        error
        message
      }
    }
  }
`;

export const DELETE_PUBLIC_WORKFLOW_CATEGORY = gql`
  mutation ($id: Int) {
    HexomaticWorkflowCategoryOps {
      deleteWorkflowCategory(id: $id) {
        error
        message
      }
    }
  }
`;

export const UPDATE_PUBLIC_WORKFLOW_CATEGORY = gql`
  mutation ($data: CreateWorkflowCategoryInput) {
    HexomaticWorkflowCategoryOps {
      updateWorkflowCategory(data: $data) {
        error
        message
      }
    }
  }
`;

//Update emails

export const CREATE_EMAIL_CUSTOM_MESSAGE = gql`
  mutation ($settings: CreateCustomMessageInput) {
    CustomMessageOps {
      createCustomMessage(settings: $settings) {
        error
        message
      }
    }
  }
`;

export const UPDATE_EMAIL_CUSTOM_MESSAGE = gql`
  mutation ($settings: UpdateCustomMessageInput) {
    CustomMessageOps {
      updateCustomMessage(settings: $settings) {
        error
        message
      }
    }
  }
`;

export const DELETE_EMAIL_CUSTOM_MESSAGE = gql`
  mutation ($id: Int) {
    CustomMessageOps {
      deleteCustomMessage(id: $id) {
        error
        message
      }
    }
  }
`;

export const SEND_TEMPLATE_TEST_EMAIL = gql`
  mutation SEND_TEMPLATE_TEST_EMAIL($variables: SendAdminTestEmailVariables) {
    CustomMessageOps {
      sendAdminTestEmail(variables: $variables) {
        error
        message
      }
    }
  }
`;

export const CREATE_RUNDOM_TIME_AND_COUNT = gql`
  mutation CREATE_RUNDOM_TIME_AND_COUNT($settings: CreateRandomTimeAndCountConfigResultInput) {
    AdminOps {
      createRandomTimeAndCountConfig(settings: $settings) {
        error_code
      }
    }
  }
`;

export const UPDATE_PREMIUMS_COUNT = gql`
  mutation UPDATE_PREMIUMS_COUNT($settings: UpdatePromoLeftSeatsInput) {
    AdminOps {
      updatePromoLeftSeats(settings: $settings) {
        error
      }
    }
  }
`;

// Automation input/output types

export const CREATE_AUTOMATION_INPUT_OUTPUT_TYPE = gql`
  mutation GET_AUTOMATION_INPUT_OUTPUT_TYPES($settings: CreateAutomationInputTypeInput) {
    HexomaticAdminOps {
      createAutomationInputType(settings: $settings) {
        error_code
      }
    }
  }
`;

export const UPDATE_AUTOMATION_INPUT_OUTPUT_TYPE = gql`
  mutation UPDATE_AUTOMATION_INPUT_OUTPUT_TYPE($settings: UpdateAutomationInputTypeInput) {
    HexomaticAdminOps {
      updateAutomationInputType(settings: $settings) {
        error_code
      }
    }
  }
`;

export const DELETE_AUTOMATION_INPUT_OUTPUT_TYPE = gql`
  mutation DELETE_AUTOMATION_INPUT_OUTPUT_TYPE($settings: DeleteAutomationInputTypeInput) {
    HexomaticAdminOps {
      deleteAutomationInputType(settings: $settings) {
        error_code
      }
    }
  }
`;

export const ADD_EXCLUDED_USER = gql`
  mutation ADD_EXCLUDED_USER($mainUserId: Int) {
    ExcludedUserOps {
      addExludedUser(mainUserId: $mainUserId) {
        error
        message
      }
    }
  }
`;

export const DELETE_EXCLUDED_USERS = gql`
  mutation DELETE_EXCLUDED_USERS($mainUserIds: [Int]) {
    ExcludedUserOps {
      deleteExcludedUsers(mainUserIds: $mainUserIds) {
        error
        message
      }
    }
  }
`;

export const SHARE_PROFILE = gql`
  mutation SHARE_PROFILE($settings: CreateUserSharedProfileInput) {
    UserSharedProfileOps {
      create(settings: $settings) {
        error_code
      }
    }
  }
`;
export const UPDATE_SHARE_PROFILE = gql`
  mutation UPDATE_SHARE_PROFILE($settings: UpdateUserSharedProfileInput) {
    UserSharedProfileOps {
      update(settings: $settings) {
        error_code
      }
    }
  }
`;
export const GET_SHARE_PROFILES = gql`
  query GET_SHARE_PROFILES($settings: GetUserSharedProfilesInput) {
    UserSharedProfile {
      get(settings: $settings) {
        sharedProfile {
          id
          firstName
          lastName
          photo
          email
          sharedUser {
            id
            email
          }
        }
        count
        error_code
      }
    }
  }
`;
export const DELETE_SHARE_PROFILE = gql`
  mutation DELETE_SHARE_PROFILE($settings: DeleteUserSharedProfileInput) {
    UserSharedProfileOps {
      deleteSharedProfile(settings: $settings) {
        error_code
      }
    }
  }
`;

export const CREATE_ICE_BREAKER = gql`
  mutation CREATE_ICE_BREAKER($settings: CreateIceBreakerInput) {
    HexosparkAdminOps {
      createIceBreaker(settings: $settings) {
        error_code
      }
    }
  }
`;

export const UPDATE_ICE_BREAKER = gql`
  mutation UPDATE_ICE_BREAKER($settings: UpdateIceBreakerInput) {
    HexosparkAdminOps {
      updateIceBreaker(settings: $settings) {
        error_code
      }
    }
  }
`;
export const DELETE_ICE_BREAKER = gql`
  mutation DELETE_ICE_BREAKER($settings: DeleteIceBreakerInput) {
    HexosparkAdminOps {
      deleteIceBreaker(settings: $settings) {
        error_code
      }
    }
  }
`;

export const CREATE_PUBLIC_TAMPLATE = gql`
  mutation CREATE_PUBLIC_TAMPLATE($settings: AdminCreateEmailTemplateInput) {
    HexosparkAdminOps {
      adminCreatePublicEmailTemplates(settings: $settings) {
        error_code
      }
    }
  }
`;

export const UPDATE_PUBLIC_TAMPLATE = gql`
  mutation UPDATE_PUBLIC_TAMPLATE($settings: AdminUpdateEmailTemplateInput) {
    HexosparkAdminOps {
      adminUpdatePublicEmailTemplates(settings: $settings) {
        error_code
      }
    }
  }
`;
export const DELETE_PUBLIC_TAMPLATE = gql`
  mutation DELETE_PUBLIC_TAMPLATE($settings: AdminDeleteEmailTemplateInput) {
    HexosparkAdminOps {
      adminDeleteBulkPublicEmailTemplates(settings: $settings) {
        error_code
      }
    }
  }
`;

export const CREATE_PUBLIC_TAMPLATE_CATEGORIES = gql`
  mutation CREATE_PUBLIC_TAMPLATE_CATEGORIES($settings: CreatePublicEmailTemplateCategoryArgs) {
    HexosparkAdminOps {
      adminCreatePublicEmailTemplateCategory(settings: $settings) {
        error_code
      }
    }
  }
`;

export const UPDATE_PUBLIC_TAMPLATE_CATEGORIES = gql`
  mutation UPDATE_PUBLIC_TAMPLATE_CATEGORIES($settings: UpdatePublicEmailTemplateCategoryArgs) {
    HexosparkAdminOps {
      adminUpdatePublicEmailTemplateCategory(settings: $settings) {
        error_code
      }
    }
  }
`;
export const DELETE_PUBLIC_TAMPLATE_CATEGORIES = gql`
  mutation DELETE_PUBLIC_TAMPLATE_CATEGORIES($settings: DeletePublicEmailTemplateCategoryArgs) {
    HexosparkAdminOps {
      adminDeletePublicEmailTemplateCategory(settings: $settings) {
        error_code
      }
    }
  }
`;

export const CREATE_HEXOSPARK_MONTHLY_REPORT = gql`
  mutation CREATE_HEXOSPARK_MONTHLY_REPORT($settings: CreateMonthlyReportInput) {
    HexosparkAdminOps {
      createMonthlyReport(settings: $settings) {
        error_code
      }
    }
  }
`;

//! Scraping categories

export const CREATE_SCRAPING_CATEGORY = gql`
  mutation CREATE_SCRAPING_CATEGORY($data: CreateScrapingCategoryInput) {
    HexomaticScrapingCategoryOps {
      createScrapingCategory(data: $data) {
        error_code
      }
    }
  }
`;
export const UPDATE_SCRAPING_CATEGORY = gql`
  mutation UPDATE_SCRAPING_CATEGORY($data: CreateScrapingCategoryInput) {
    HexomaticScrapingCategoryOps {
      updateScrapingCategory(data: $data) {
        error_code
      }
    }
  }
`;
export const DELETE_SCRAPING_CATEGORY = gql`
  mutation DELETE_SCRAPING_CATEGORY($id: String) {
    HexomaticScrapingCategoryOps {
      deleteScrapingCategory(id: $id) {
        error_code
      }
    }
  }
`;

export const UPDATE_PUBLIC_SCRAPING_RECIPE_AND_CATEGORIES = gql`
  mutation UPDATE_PUBLIC_SCRAPING_RECIPE_AND_CATEGORIES(
    $settings: UpdatePublicScrapingRecipeAndCategoryInput
  ) {
    HexomaticPublicScrapingRecipeCategoryOps {
      updatePublicScrapingRecipeAndCategory(settings: $settings) {
        error_code
      }
    }
  }
`;

export const UPDATE_PUBLIC_SCRAPING_RECIPE = gql`
  mutation UPDATE_PUBLIC_SCRAPING_RECIPE($settings: UpdatePublicScrapingRecipeInput) {
    HexomaticPublicScrapingRecipeOps {
      updatePublicScrapingRecipe(settings: $settings) {
        error_code
      }
    }
  }
`;
