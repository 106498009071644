import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { GET_EXCLUDED_USERS } from "../../graphql/queries";
import { DELETE_EXCLUDED_USERS, ADD_EXCLUDED_USER } from "../../graphql/mutations";

import ExcludedUsers from "./excludedUsers";

const ExcludedUsersQueries = () => {
  const [pageNumberState, setPageNumberState] = useState(1);
  const [filterDataState, setFilterDataState] = useState("");

  const { data, loading, refetch } = useQuery(GET_EXCLUDED_USERS, {
    variables: {
      settings: {
        page: pageNumberState,
        limit: 100,
        csvDownload: false,
        driveUpload: false,
        searchInput: filterDataState,
      },
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const [addExcludedUser] = useMutation(ADD_EXCLUDED_USER);
  const [deleteExcludedUsers] = useMutation(DELETE_EXCLUDED_USERS);

  const handlePageNumberChange = (pageNumber: number) => setPageNumberState(pageNumber);
  const handleFilterDataChange = (filterData: string) => setFilterDataState(filterData);

  const handleAddExcludedUser = async (id: number) => {
    const res = await addExcludedUser({
      variables: {
        mainUserId: id,
      },
    });
    const message =
      res &&
      res.data &&
      res.data.ExcludedUserOps &&
      res.data.ExcludedUserOps.addExludedUser &&
      res.data.ExcludedUserOps.addExludedUser.message;

    if (!message) {
      await refetch();
    } else {
      alert(message);
    }
  };

  const handleDeleteExcludedUsers = async (idsArr: number[]) => {
    const res = await deleteExcludedUsers({
      variables: {
        mainUserIds: idsArr,
      },
    });

    const message =
      res &&
      res.data &&
      res.data.ExcludedUserOps &&
      res.data.ExcludedUserOps.deleteExcludedUsers &&
      res.data.ExcludedUserOps.deleteExcludedUsers.message;

    if (!message) {
      await refetch();
    } else {
      alert(message);
    }
  };

  return (
    <ExcludedUsers
      data={data}
      loading={loading}
      onPageNumberChange={handlePageNumberChange}
      refetchData={refetch}
      onFilterDataChange={handleFilterDataChange}
      onAddExcludedUser={handleAddExcludedUser}
      onDeleteExcludedUsers={handleDeleteExcludedUsers}
    />
  );
};

export default ExcludedUsersQueries;
