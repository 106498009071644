import React, { useState, useEffect } from "react";
import { GET_HEXOMATIC_TASKS } from "../../graphql/queries";
import AutomationCategories from "./index";
import { useQuery } from "@apollo/react-hooks";

const TasksQueries = () => {
  const [dataList, setDataList] = useState<any>([]);
  const [pageNumberState, setPageNumberState] = useState(1);
  const handlePageNumberChange = (pageNumber: number) =>
    setPageNumberState(pageNumber);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [creator, setCreator] = useState("");
  const [performer, setPerformer] = useState("");
  const [filterHexScans, setFilterHexScans] = useState(true);

  const { data, loading, refetch } = useQuery(GET_HEXOMATIC_TASKS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      settings: {
        filter: {
          creatorSearchQuery: creator,
          performerSearchQuery: performer,
          createdFrom: fromDate,
          createdTo: toDate,
          hideHex: filterHexScans,
        },
        page: pageNumberState,
        pageSize: 100,
      },
    },
  });

  useEffect(() => {
    refetch();
  }, [pageNumberState]);

  useEffect(() => {
    setDataList(data);
  }, [data]);

  useEffect(() => {
    refetchData();
  }, [creator, performer]);

  const refetchData = async () => {
    await refetch();
  };

  return (
    <AutomationCategories
      data={dataList}
      loading={loading}
      setCreator={setCreator}
      setPerformer={setPerformer}
      setHideHex={setFilterHexScans}
      hideHex={filterHexScans}
      searchCreator={creator}
      searchPerformer={performer}
      onPageNumberChange={handlePageNumberChange}
      setFromDate={setFromDate}
      setToDate={setToDate}
    />
  );
};

export default TasksQueries;
