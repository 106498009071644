import React, { useEffect, useState } from "react";
import { Alert, Button, Form } from "tabler-react";
import ReactModal from "react-modal";
import { TableModalProps } from "../../types";
import "react-mde/lib/styles/css/react-mde-all.css";
import TableCard from "../../components/tableCard";

ReactModal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "80%",
  },
};

const Modal = ({
  handleModal,
  showModal,
  onSave,
  errorMessage,
  headerData,
}: TableModalProps): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [path, setPath] = useState("");
  const [title, setTitle] = useState("");
  const [metaTags, setMetaTags] = useState([]);
  const [metaName, setMetaName] = useState("");
  const [metaContent, setMetaContent] = useState("");
  const [metaIndex, setMetaIndex] = useState(-1);
  const [id, setId] = useState(-1);

  const handleOk = async () => {
    setLoading(true);

    let completed = true;
    if (path && title) {
      const formData = { id, path, title, metaTags };
      completed = await onSave({ ...formData });
    }
    setLoading(false);
    if (completed) handleCancel();
  };

  const handleCancel = () => {
    setId(undefined);
    setPath("");
    setTitle("");
    setMetaTags([]);
    setMetaName("");
    setMetaContent("");
    handleModal();
  };

  useEffect(() => {
    if (window.innerWidth < 640) {
      customStyles.content.width = "90%";
    }
  }, []);

  useEffect(() => {
    if (headerData) {
      setId(headerData.id);
      setPath(headerData.path);
      setTitle(headerData.title);
      setMetaTags(
        headerData.metaTags.map((metaTag) => ({
          name: metaTag.name,
          content: metaTag.content,
        }))
      );
    }
  }, [headerData]);

  const handlePathChange = (e: React.FormEvent) => setPath((e.target as HTMLInputElement).value);
  const handleTitleChange = (e: React.FormEvent) => setTitle((e.target as HTMLInputElement).value);

  const handleAddMetaTag = () => {
    if ((metaIndex || metaIndex === 0) && metaName && metaContent) {
      const metaTagsArray = [...metaTags];
      metaTagsArray[metaIndex].name = metaName;
      metaTagsArray[metaIndex].content = metaContent;
      setMetaTags(metaTagsArray);
      setMetaIndex(undefined);
      setMetaName("");
      setMetaContent("");
    }
    if (metaName && metaContent && metaIndex === undefined) {
      const metaTagsArray = [
        ...metaTags,
        {
          name: metaName,
          content: metaContent,
        },
      ];
      setMetaTags(metaTagsArray);
      setMetaName("");
      setMetaContent("");
    }
  };
  const handleMetaNameChange = (e: React.SyntheticEvent) => setMetaName((e.target as HTMLInputElement).value);
  const handleMetaContentChange = (e: React.SyntheticEvent) =>
    setMetaContent((e.target as HTMLTextAreaElement).value);
  const handleMetaTagDelete = (idx: number) => {
    const metaTagsArray = [...metaTags];
    metaTagsArray.splice(idx, 1);
    setMetaTags(metaTagsArray);
  };
  const handleMetaTagEdit = (idx: number) => {
    setMetaIndex(idx);
    const metaTag = metaTags.find((meta: object, index: number) => index === idx);
    setMetaName(metaTag.name);
    setMetaContent(metaTag.content);
  };

  const tableData = {
    tData:
      metaTags &&
      metaTags.length !== 0 &&
      metaTags.map((meta, index) => ({
        Name: meta.name,
        Content: meta.content,
        Action: (
          <Button.List>
            <Button type="button" color="primary" icon="edit" onClick={() => handleMetaTagEdit(index)} />
            <Button
              disabled={!!metaName}
              type="button"
              color="danger"
              icon="trash"
              onClick={() => handleMetaTagDelete(index)}
            />
          </Button.List>
        ),
      })),
  };

  return (
    <div style={{ height: 0 }}>
      <ReactModal
        isOpen={showModal}
        contentLabel="Create Translation"
        style={customStyles}
        onRequestClose={handleCancel}
      >
        <h4>{headerData ? "Edit HeaderData" : "Create HeaderData"}</h4>
        {errorMessage ? (
          <Alert type="danger" isDismissible>
            {errorMessage}
          </Alert>
        ) : null}
        <Form onSubmit={(e: React.FormEvent) => e.preventDefault()}>
          <Form.Input
            disabled={headerData}
            required
            type="text"
            placeholder="path"
            label="Path"
            value={path}
            onChange={handlePathChange}
          />
          <Form.Input
            required
            type="text"
            placeholder="title"
            label="Title"
            value={title}
            onChange={handleTitleChange}
          />
          <Form.Input
            onChange={handleMetaNameChange}
            placeholder="MetaTag Name..."
            type="text"
            label="MetaTag Name"
            value={metaName}
          />
          <Form.Textarea
            onChange={handleMetaContentChange}
            placeholder="MetaTag Content..."
            type="text"
            label="MetaTag Content"
          >
            {metaContent}
          </Form.Textarea>
          <Button
            onClick={handleAddMetaTag}
            className="float-right mb-3"
            color="primary"
            type="button"
            loading={loading}
          >
            Add/Edit MetaTag
          </Button>
          {metaTags && metaTags.length !== 0 && (
            <TableCard loading={loading} data={metaTags} tableData={tableData} />
          )}
          <Button.List style={{ marginTop: "5%" }}>
            <Button onClick={handleOk} color="primary" type="submit" loading={loading}>
              Save
            </Button>
            <Button onClick={handleCancel} color="secondary" type="button">
              Cancel
            </Button>
          </Button.List>
        </Form>
      </ReactModal>
    </div>
  );
};

export default Modal;
