import React, { ChangeEvent, useEffect, useState } from "react";
import { Alert, Button, Form } from "tabler-react";
import ReactModal from "react-modal";
import ReactMde from "react-mde";
import * as Showdown from "showdown";
import "react-mde/lib/styles/css/react-mde-all.css";
import { GET_HEXOSPARK_PUBLIC_TEMPLATES_CATEGORIES } from "../../graphql/queries";
import { useQuery } from "@apollo/react-hooks";
import { MultiSelect } from "react-multi-select-component";

ReactModal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    height: "50%",
  },
};

const TableModal = ({
  handleModal,
  showModal,
  onSave,
  onUpdate,
  editField,
  errorMessage,
}: any): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");
  const [categoriesList, setCategoriesList] = useState<any>([]);
  const [formData, setFormData] = useState({
    name: "",
    subject: "",
    categories: [],
  });

  const [initialCategories, setInitialCategories] = useState<any>([]);

  const { data } = useQuery(GET_HEXOSPARK_PUBLIC_TEMPLATES_CATEGORIES, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      settings: {},
    },
  });

  useEffect(() => {
    if (data && data.HexosparkAdmin && data.HexosparkAdmin.adminGetPublicEmailTemplateCategories.categories) {
      setCategoriesList(
        data.HexosparkAdmin.adminGetPublicEmailTemplateCategories.categories.map((item: any) => {
          return { label: item.name, value: item.id };
        })
      );
    }
  }, [data]);

  const [tab, setTab] = useState<"write" | "preview">("write");

  const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true,
  });

  const handleOk = async () => {
    setLoading(true);
    if (editField) {
      await onUpdate({
        id: editField.id,
        body: text,
        categories: {
          deleted: initialCategories
            .filter((item: any) =>
              !formData.categories.find(
                (sub: any) => (sub && (sub.value || sub.id)) === (item && (item.value || item.sub))
              )
            )
            .map((item: any) => item && item.value)
            .filter((item: any) => item),
          inserted: formData.categories
            .filter(
              (item: any) =>
                !initialCategories.find(
                  (sub: any) => (sub && (sub.value || sub.id)) === (item && (item.value || item.sub))
                )
            )
            .map((item: any) => item && item.value)
            .filter((item: any) => item),
        },
        name: formData.name,
        subject: formData.subject,
      });
      setLoading(false);
      return;
    }

    const sendData = { text, ...formData };
    const completed = await onSave({ ...sendData });

    setLoading(false);
    // if (completed) handleCancel();
  };

  const handleCancel = () => {
    handleModal();
  };

  useEffect(() => {
    if (window.innerWidth < 640) {
      customStyles.content.width = "90%";
    }
  }, []);

  useEffect(() => {
    editField && editField.body && setText(editField.body);

    editField &&
      editField.name &&
      setFormData({
        name: editField.name,
        subject: editField.subject,
        categories: editField.categories,
      });
    editField && editField.name && setInitialCategories(editField.categories);
  }, [editField]);

  const handleTabChange = (tab: "write" | "preview") => setTab(tab);

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>, dataName: string): void => {
    const targetName = e.target.name || dataName;
    const value = e.target.value;
    setFormData((prev) => ({ ...prev, [targetName]: value }));
  };

  return (
    <div style={{ height: 0 }}>
      <ReactModal
        isOpen={showModal}
        contentLabel="Create public template"
        style={customStyles}
        onRequestClose={handleCancel}
      >
        <h4>{editField ? "Edit public template" : "Create public template"}</h4>

        <Form onSubmit={(e: React.FormEvent) => e.preventDefault()}>
          {errorMessage ? <Alert type="danger">{errorMessage}</Alert> : null}
          <div style={{ marginTop: "10px", marginBottom: "10px" }}>
            <label className="form-label">Category</label>
            <MultiSelect
              options={categoriesList}
              value={formData.categories}
              onChange={(data: any) => setFormData((prev) => ({ ...prev, categories: data }))}
              labelledBy="Select"
              hasSelectAll={false}
            />
          </div>
          <Form.Input
            required
            type="text"
            placeholder="name"
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
          <Form.Input
            required
            type="text"
            placeholder="subject"
            label="Subject"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
          />
          <ReactMde
            onChange={(value: string) => setText(value)}
            onTabChange={handleTabChange}
            value={text}
            generateMarkdownPreview={(markdown) => Promise.resolve(converter.makeHtml(markdown))}
            selectedTab={tab}
            className="mb-2"
          />

          <Button.List style={{ marginTop: "5%" }}>
            <Button onClick={handleOk} color="primary" type="submit" loading={loading}>
              Save
            </Button>
            <Button onClick={handleCancel} color="secondary" type="button">
              Cancel
            </Button>
          </Button.List>
        </Form>
      </ReactModal>
      {errorMessage ? <Alert type="danger">{errorMessage}</Alert> : null}
    </div>
  );
};

export default TableModal;
