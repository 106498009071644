import React, { useState, useEffect } from "react";
import { CREATE_PUBLIC_TAMPLATE_CATEGORIES, UPDATE_PUBLIC_TAMPLATE_CATEGORIES, DELETE_PUBLIC_TAMPLATE_CATEGORIES } from "../../graphql/mutations";
import { GET_HEXOSPARK_PUBLIC_TEMPLATES_CATEGORIES } from "../../graphql/queries";
import AutomationTypes from "./index";
import { useQuery, useMutation } from "@apollo/react-hooks";

const PublicTemplateCategoriesQueries = () => {
  const [typeList, setTypeList] = useState<any>([]);
  const [pageNumberState, setPageNumberState] = useState(1);
  const handlePageNumberChange = (pageNumber: number) => setPageNumberState(pageNumber);
  const [searchEmail, setsearchEmail] = useState<string>('')

  const { data, loading, refetch } = useQuery(GET_HEXOSPARK_PUBLIC_TEMPLATES_CATEGORIES, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      settings: {
        
      }
    }
  })

  useEffect(() => {
    refetch()
  }, [pageNumberState])

  useEffect(() => {
    setTypeList(data)
  }, [data])

  useEffect(() => {
    refetchData()
  }, [searchEmail])

  const refetchData = async () => {
    await refetch()
  }

  const [deleteType] = useMutation(DELETE_PUBLIC_TAMPLATE_CATEGORIES)

  const [updateType] = useMutation(UPDATE_PUBLIC_TAMPLATE_CATEGORIES)


  const [createAutomationType] = useMutation(CREATE_PUBLIC_TAMPLATE_CATEGORIES, {
    onCompleted: () => {
      refetchData();
    }
  })

  return (
    <AutomationTypes
      data={typeList}
      loading={loading}
      searchEmail={searchEmail}
      setsearchEmail={setsearchEmail}
      onPageNumberChange={handlePageNumberChange}
      createAutomationType={createAutomationType}
      deleteType={deleteType}
      updateType={updateType}
    />
  )
};


export default PublicTemplateCategoriesQueries;
