import React, { FC, useState } from "react";
import C3Chart from "react-c3js";
import moment from "moment";
import "c3/c3.css";
import { Card, Button } from "tabler-react";
import { ChartProps } from "../../types";

const intervals = [7, 30, 45, 90, 120, 150];
const minInterval = intervals[0];

const Chart: FC<ChartProps> = ({
  regData,
  chartName,
  yColName,
  yColName3,
  yColName2,
  regData2,
  regData3,
  interval,
  propColors,
  ...props
}) => {
  const [chartInterval, setChartInterval] = useState(minInterval);
  const [inputVal, setInputVal] = useState(7);
  // const [totalCount, setTotalCount] = useState(0)

  let data = {
    x: "x",
    columns: [
      ["x"],
      [yColName],
      [yColName2],
      [yColName3],
      [props.yColName4],
      [props.yColName5],
      [props.yColName6],
      [props.yColName7],
      [props.yColName8],
      [props.yColName9],
      [props.yColName10],
      [props.yColName11],
      [props.yColName12],
    ],
    type: "area-spline",
  };

  const color = {
    pattern: propColors ? propColors : ["#1f77b4", "#ff7f0e", "#000000", "#98ca33", "#e74c3c"],
  };

  const axis = {
    x: {
      type: "timeseries",
      tick: {
        count: 7,
        format: "%Y-%m-%d",
      },
    },
  };

  if (regData && !props.registerChart) {
    new Array(chartInterval).fill(0).map((item, index) => {
      const timeF = moment().subtract(index, "days").utc().format("YYYY-MM-DD");
      const reg = regData.find((r) => {
        let time = r.time;
        if (+r.time) {
          time = +r.time;
        }
        return moment(new Date(time)).utc().format("YYYY-MM-DD") === timeF;
      });
      data.columns[0].push(timeF);

      if (reg) {
        data.columns[1].push(reg.count);
      } else {
        data.columns[1].push("0");
      }

      if (regData2) {
        const chartData2 = regData2.find((r) => {
          let time = r.time;
          if (+r.time) {
            time = +r.time;
          }
          return moment(new Date(time)).utc().format("YYYY-MM-DD") === timeF;
        });

        if (chartData2) {
          data.columns[2].push(chartData2.count);
        } else {
          data.columns[2].push("0");
        }
      }

      if (regData3) {
        const chartData3 = regData3.find((r) => {
          let time = r.time;
          if (+r.time) {
            time = +r.time;
          }
          return moment(new Date(time)).utc().format("YYYY-MM-DD") === timeF;
        });

        if (chartData3) {
          data.columns[3].push(chartData3.count);
        } else {
          data.columns[3].push("0");
        }
      }

      if (props.regData4) {
        const chartData4 = props.regData4.find((r) => {
          let time = r.time;
          if (+r.time) {
            time = +r.time;
          }
          return moment(new Date(time)).utc().format("YYYY-MM-DD") === timeF;
        });

        if (chartData4) {
          data.columns[4].push(chartData4.count);
        } else {
          data.columns[4].push("0");
        }
      }

      if (props.regData5) {
        const chartData5 = props.regData5.find((r) => {
          let time = r.time;
          if (+r.time) {
            time = +r.time;
          }
          return moment(new Date(time)).utc().format("YYYY-MM-DD") === timeF;
        });

        if (chartData5) {
          data.columns[5].push(chartData5.count);
        } else {
          data.columns[5].push("0");
        }
      }
      if (props.regData6) {
        const chartData6 = props.regData6.find((r) => {
          let time = r.time;
          if (+r.time) {
            time = +r.time;
          }
          return moment(new Date(time)).utc().format("YYYY-MM-DD") === timeF;
        });

        if (chartData6) {
          data.columns[6].push(chartData6.count);
        } else {
          data.columns[6].push("0");
        }
      }
      if (props.regData7) {
        const chartData7 = props.regData7.find((r) => {
          let time = r.time;
          if (+r.time) {
            time = +r.time;
          }
          return moment(new Date(time)).utc().format("YYYY-MM-DD") === timeF;
        });

        if (chartData7) {
          data.columns[7].push(chartData7.count);
        } else {
          data.columns[7].push("0");
        }
      }
      if (props.regData8) {
        const chartData8 = props.regData8.find((r) => {
          let time = r.time;
          if (+r.time) {
            time = +r.time;
          }
          return moment(new Date(time)).utc().format("YYYY-MM-DD") === timeF;
        });

        if (chartData8) {
          data.columns[8].push(chartData8.count);
        } else {
          data.columns[8].push("0");
        }
      }
      if (props.regData9) {
        const chartData9 = props.regData9.find((r) => {
          let time = r.time;
          if (+r.time) {
            time = +r.time;
          }
          return moment(new Date(time)).utc().format("YYYY-MM-DD") === timeF;
        });

        if (chartData9) {
          data.columns[9].push(chartData9.count);
        } else {
          data.columns[9].push("0");
        }
      }
      if (props.regData10) {
        const chartData10 = props.regData10.find((r) => {
          let time = r.time;
          if (+r.time) {
            time = +r.time;
          }
          return moment(new Date(time)).utc().format("YYYY-MM-DD") === timeF;
        });

        if (chartData10) {
          data.columns[10].push(chartData10.count);
        } else {
          data.columns[10].push("0");
        }
      }

      if (props.regData11) {
        const chartData11 = props.regData11.find((r) => {
          let time = r.time;
          if (+r.time) {
            time = +r.time;
          }
          return moment(new Date(time)).utc().format("YYYY-MM-DD") === timeF;
        });

        if (chartData11) {
          data.columns[11].push(chartData11.count);
        } else {
          data.columns[11].push("0");
        }
      }
      if (props.regData12) {
        const chartData12 = props.regData12.find((r) => {
          let time = r.time;
          if (+r.time) {
            time = +r.time;
          }
          return moment(new Date(time)).utc().format("YYYY-MM-DD") === timeF;
        });

        if (chartData12) {
          data.columns[12].push(chartData12.count);
        } else {
          data.columns[12].push("0");
        }
      }
    });
  }

  if (regData && props.registerChart) {
    new Array(chartInterval).fill(0).map((item, index) => {
      const timeF = moment().subtract(index, "days").utc().format("YYYY-MM-DD");
      const reg = regData.find((r) => {
        let time = r.time;
        if (+r.time) {
          time = +r.time;
        }

        return moment(new Date(time)).utc().format("YYYY-MM-DD") === timeF;
      });
      data.columns[0].push(timeF);

      if (reg) {
        data.columns[1].push(reg.hexospark + reg.hexometer + reg.hexomatic + reg.hexowatch + reg.hexofy);
        data.columns[2].push(reg.hexometer);
        data.columns[3].push(reg.hexowatch);
        data.columns[4].push(reg.hexomatic);
        data.columns[5].push(reg.hexospark);
        data.columns[6].push(reg.hexofy);
      } else {
        data.columns[1].push("0");
        data.columns[2].push("0");
        data.columns[3].push("0");
        data.columns[4].push("0");
        data.columns[5].push("0");
        data.columns[6].push("0");
      }
    });
  }

  const onChangeVal = (e) => {
    if (isNaN(e.target.value)) {
      return;
    }
    setInputVal(+e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.keyCode === 13) {
      setChartInterval(inputVal);
    }
  };

  return (
    <Card>
      <Card.Header>{chartName}</Card.Header>
      <Card.Body classNaem={"chart-wrapper"}>
        {regData && <C3Chart data={data} axis={axis} color={color} />}
      </Card.Body>
      <Card.Footer>
        <span>Last </span>
        <input onKeyDown={handleKeyDown} type="text" value={inputVal} onChange={onChangeVal} />
        <span> Days </span>
        <button onClick={() => setChartInterval(inputVal)}>Get</button>
        {interval && <span> Max Days: {interval[interval.length - 1]}</span>}

        {/* <Button.List>
          {intervals.map(interval =>
            <Button
              onClick={() => setChartInterval(interval)}
              color={chartInterval === interval ? "active" : "secondary"}
              key={interval}
            >Last {interval} days</Button>
          )}
        </Button.List> */}
      </Card.Footer>
    </Card>
  );
};

export default Chart;
