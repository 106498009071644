import { isProduction } from "./../helpers/index";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

const useUploadFile = () => {
  //!We use k.shushan907@gmail.com account information (password: Hexomatic.123)
  const domain = isProduction() ? "https://uploader.hexospark.com/api" : "https://uploader.hexact.io/api";
  const url = `${domain}/uploader/app/file-uploads`;
  const delete_url = `${domain}/uploader/app/delete-files`;

  const ws = isProduction() ? "4ac75ff3-467e-47ef-a9ce-d8d7df1f76ed" : "e93fda44-0418-4500-bc05-cccede958b04";

  const uploadHash = isProduction()
    ? "a06366894f748d29a50f9b63fc9225ea-4be8143903/a06366894f748d29a50f9b63fc9225ea-44e91f36"
    : "7ebeb64cf886fda56538f49a3d9227a3-0ad2/7ebeb64cf886fda56538f49a3d9227a3-03d297";

  const uploadFile = async (files: any[]) => {
    const formData = new FormData();
    const stringArr: string[] = [];
    formData.append("uploadHash", uploadHash);
    formData.append("productType", "hexospark");
    formData.append("actionName", "crmContact");
    files.forEach((file: any) => {
      const files_id = uuidv4();
      stringArr.push(files_id);
      formData.append("files", file);
    });
    formData.append("fileIds", JSON.stringify(stringArr));
    formData.append("workspaceId", ws);
    const { data } = await axios(url, {
      method: "POST",
      data: formData,
    });
    return {
      files: data.uploadedFiles,
      gc_directory_path: data.gc_directory_path,
      error: data.message,
      file_error:
        data.uploadedFiles && data.uploadedFiles[0] && data.uploadedFiles[0].error
          ? data.uploadedFiles[0].message
          : "",
    };
  };

  const deleteFile = async (fileLinks: string[], gc_directory_path: string) => {
    const { data } = await axios.delete(delete_url, {
      data: {
        productType: "hexospark",
        uploadHash: uploadHash,
        fileLinks,
        workspaceId: ws,
        actionName: "crmContact",
        gc_directory_path,
      },
    });
    return data;
  };

  return [uploadFile, deleteFile];
};

export default useUploadFile;
