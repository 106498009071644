import React, { useEffect, useState } from "react";
import { Alert, Button, Form } from "tabler-react";
import ReactModal from "react-modal";
import { TableModalProps } from "../../types";
import "react-mde/lib/styles/css/react-mde-all.css";
import { pricingPacks } from "../../constants";

ReactModal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    height: "80%",
  },
};

const Modal = ({ handleModal, showModal, onSave, errorMessage, property }: TableModalProps): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [address, setAdress] = useState("");
  const [hostname, setHostname] = useState("");
  const [paused, setPaused] = useState(false);
  const [id, setId] = useState(-1);
  const [pricingPackage, setPricingPackage] = useState("");

  const handleOk = async () => {
    setLoading(true);

    // let completed = true;
    // if (name && address && hostname && pricingPackage) {
    const formData = { id, name, address, hostname, paused, pricingPackage };
    const completed = await onSave({ ...formData });
    // }
    setLoading(false);
    if (completed) handleCancel();
  };

  const handleCancel = () => {
    setId(undefined);
    setName("");
    setAdress("");
    setHostname("");
    handleModal();
  };

  useEffect(() => {
    if (window.innerWidth < 640) {
      customStyles.content.width = "90%";
    }
  }, []);

  useEffect(() => {
    if (property) {
      setId(property.id);
      setName(property.name);
      setAdress(property.address);
      setHostname(property.hostname);
      setPaused(property.paused);
      setPricingPackage(property.pricingPackage);
    }
  }, [property]);

  const handleNameChange = (e: React.FormEvent) => setName((e.target as HTMLInputElement).value);
  const handleAddressChange = (e: React.FormEvent) => setAdress((e.target as HTMLInputElement).value);
  const handleHostnameChange = (e: React.FormEvent) => setHostname((e.target as HTMLInputElement).value);
  const handlePackageChange = (e: React.FocusEvent) =>
    setPricingPackage((e.target as HTMLInputElement).value);
  const handlePausedChange = (e: React.FormEvent) => {
    if ((e.target as HTMLInputElement).value === "Yes") {
      setPaused(true);
    } else {
      setPaused(false);
    }
  };

  return (
    <div style={{ height: 0 }}>
      <ReactModal
        isOpen={showModal}
        contentLabel="Create Translation"
        style={customStyles}
        onRequestClose={handleCancel}
      >
        <h4>{property ? "Edit Property" : "Create Property"}</h4>
        {errorMessage ? <Alert type="danger">{errorMessage}</Alert> : null}
        <Form onSubmit={(e: React.FormEvent) => e.preventDefault()}>
          <Form.Input
            required
            type="text"
            placeholder="name"
            label="Name"
            value={name}
            onChange={handleNameChange}
          />
          <Form.Input
            required
            type="text"
            placeholder="address"
            label="Address"
            value={address}
            onChange={handleAddressChange}
          />
          <Form.Input
            required
            type="text"
            placeholder="hostname"
            label="Hostname"
            value={hostname}
            onChange={handleHostnameChange}
          />
          <Form.Select onChange={handlePackageChange} label="Pricing Package" value={pricingPackage}>
            {pricingPacks.map((pack) => (
              <option key={pack} value={pack}>
                {pack}
              </option>
            ))}
          </Form.Select>
          <Form.Select onChange={handlePausedChange} label="Is Paused" value={paused ? "Yes" : "No"}>
            <option value="No">No </option>
            <option value="Yes">Yes </option>
          </Form.Select>
          <Button.List style={{ marginTop: "5%" }}>
            <Button onClick={handleOk} color="primary" type="submit" loading={loading}>
              Save
            </Button>
            <Button onClick={handleCancel} color="secondary" type="button">
              Cancel
            </Button>
          </Button.List>
        </Form>
      </ReactModal>
    </div>
  );
};

export default Modal;
