import React, { useState, useEffect } from "react";
import { Grid, Button, Alert, Form } from "tabler-react";
import moment from "moment";
import { WatchNotificationProps, ContentTextProps } from "../../types";
import ServerSidePagination from "../../components/pagination/serverSidePagination";
import TableCard from "../../components/tableCard";
import DatePicker from "react-datepicker";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import {
  WATCH_NOTIFICATIONS_CHART,
  GET_WATCH_NOTIFICATIONS,
  GET_NOTIFICATION_TYPE_OPTIONS,
} from "../../graphql/queries";
import Chart from "../../components/chart";
import { getCountArr, isProduction } from "../../helpers";

const WatchNotifications = ({ refetchData, ...props }: WatchNotificationProps): JSX.Element => {
  const [baseNotifications, setBaseNotifications] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [notificationsPagination, setNotificationsPagination] = useState([]);
  const [total, setTotal] = useState(0);
  const [filterInput, setFilterInput] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [channel, setChannel] = useState("");
  const [type, setType] = useState("");
  const [constants, setConstants] = useState([]);
  const [showtypes, setShowtypes] = useState(false);
  const [typeOptions, setTypeOptions] = useState<any>(null);
  const showButtons = localStorage.getItem("_rep");

  const [getCSV, { data, loading }] = useLazyQuery(GET_WATCH_NOTIFICATIONS);
  const [uploadToDrive, { data: driveData, loading: driveDataLoading }] =
    useLazyQuery(GET_WATCH_NOTIFICATIONS);

  const emailsChartDataQuery = useQuery(WATCH_NOTIFICATIONS_CHART, {
    variables: { channel: "email" },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const tgChartDataQuery = useQuery(WATCH_NOTIFICATIONS_CHART, {
    variables: { channel: "telegram" },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const slackChartDataQuery = useQuery(WATCH_NOTIFICATIONS_CHART, {
    variables: { channel: "slack" },
    notifyOnNetworkStatusChange: true,
  });

  const smsChartDataQuery = useQuery(WATCH_NOTIFICATIONS_CHART, {
    variables: { channel: "sms" },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const getTypes = useQuery(GET_NOTIFICATION_TYPE_OPTIONS, {
    skip: !!typeOptions,
  });

  const channels = ["all", "email", "slack", "telegram", "sms"];

  useEffect(() => {
    if (
      getTypes &&
      getTypes.data &&
      getTypes.data.WatchNotification &&
      getTypes.data.WatchNotification.watchNotificationTypeFields
    ) {
      setTypeOptions([{ type: "all" }, ...getTypes.data.WatchNotification.watchNotificationTypeFields]);
    }
  }, [getTypes]);

  useEffect(() => {
    const hexowatch_notifications_description =
      props.constants &&
      props.constants.Admin &&
      props.constants.Admin.getConstantData &&
      props.constants.Admin.getConstantData.hexowatch_notifications_description;
    if (hexowatch_notifications_description) {
      setConstants(hexowatch_notifications_description);
    }
  }, [props.constants]);

  const tableConst = {
    tData:
      constants &&
      constants.map((item) => ({
        Type: item.type,
        Description: item.description,
      })),
  };

  const tableData = {
    tData:
      baseNotifications &&
      baseNotifications.map((item) => ({
        ID: item.id,
        Channel: item.channel,
        Type: item.type,
        "Created At": moment(item.createdAt).utc().format("DD-MM-YYYY hh:mm:ss a"),
        User: item.user && item.user.email,
        URL: item.watchProperty && item.watchProperty.address,
      })),
  };

  useEffect(() => {
    if (
      props.data &&
      props.data.WatchNotification &&
      props.data.WatchNotification.adminGetMany &&
      props.data.WatchNotification.adminGetMany.wpNotifications
    ) {
      setDataLoading(props.loading);
      setBaseNotifications(props.data.WatchNotification.adminGetMany.wpNotifications);
      setNotificationsPagination(props.data.WatchNotification.adminGetMany.wpNotifications);
      setTotal(props.data.WatchNotification.adminGetMany.total);
    }
  }, [props.data]);

  useEffect(() => {
    if (
      driveData &&
      driveData.WatchNotification &&
      driveData.WatchNotification.adminGetMany &&
      driveData.WatchNotification.adminGetMany.csvURL
    ) {
      window.open(
        isProduction()
          ? `https://api.hexometer.com/v2/app/social-callback/googleDrive?admin=admin&report=wpNotifications&url=${driveData.WatchNotification.adminGetMany.csvURL}`
          : `http://localhost:4000/api/app/social-callback/googleDrive?admin=admin&report=wpNotifications&url=${driveData.WatchNotification.adminGetMany.csvURL}`,
        "_blank"
      );
    }
  }, [driveData]);

  const handleInputChange = (e: React.FormEvent) => setFilterInput((e.target as HTMLInputElement).value);

  const handleSelectChannel = (e: React.FormEvent) => setChannel((e.target as HTMLInputElement).value);

  const handleSelectType = (e: React.FormEvent) => setType((e.target as HTMLInputElement).value);

  const handleFromDate = (date: Date) => {
    if (date) {
      return setFromDate(moment(date).format("YYYY-MM-DDT00:00:00.000"));
    }
    return setFromDate(null);
  };

  const handleToDate = (date: Date) => {
    if (date) {
      return setToDate(moment(date).format("YYYY-MM-DDT23:59:59.999"));
    }
    return setToDate(null);
  };

  const handleSubmit = (
    event: React.FormEvent,
    input: string,
    channelName: string,
    type: string,
    startDate: string,
    endDate: string
  ) => {
    event.preventDefault();
    props.onFilterDataChange(input.trim());
    props.onFromDate(startDate);
    props.onToDate(endDate);
    if (channelName !== "all") {
      props.onSelectChannel(channelName);
    } else {
      props.onSelectChannel("");
    }
    if (type !== "all") {
      props.onSelectType(type);
    } else {
      props.onSelectType("");
    }
  };

  const handleSortingClick = (column: string) => {
    if (column === "Created At") {
      props.setOrderBy("createdAt");
      props.setOrderByASC(!props.orderByASC);
    }
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Col md={2}>
          <Form.Checkbox
            label="Filter Hexact notifications"
            checked={props.hideHex}
            onChange={() => props.setHideHex(!props.hideHex)}
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <div className="col-12 col-md-8">
          {!emailsChartDataQuery.loading &&
            emailsChartDataQuery.data &&
            emailsChartDataQuery.data.WatchNotification &&
            emailsChartDataQuery.data.WatchNotification.watchNotificationsChart &&
            !tgChartDataQuery.loading &&
            tgChartDataQuery.data &&
            tgChartDataQuery.data.WatchNotification &&
            tgChartDataQuery.data.WatchNotification.watchNotificationsChart &&
            !slackChartDataQuery.loading &&
            slackChartDataQuery.data &&
            slackChartDataQuery.data.WatchNotification &&
            slackChartDataQuery.data.WatchNotification.watchNotificationsChart &&
            !smsChartDataQuery.loading &&
            smsChartDataQuery.data &&
            smsChartDataQuery.data.WatchNotification &&
            smsChartDataQuery.data.WatchNotification.watchNotificationsChart && (
              <Chart
                yColName="Email"
                yColName2="Telegram"
                yColName3="Slack"
                yColName4="Trello"
                yColName5="Sms"
                regData={emailsChartDataQuery.data.WatchNotification.watchNotificationsChart}
                regData2={tgChartDataQuery.data.WatchNotification.watchNotificationsChart}
                regData3={slackChartDataQuery.data.WatchNotification.watchNotificationsChart}
                regData5={smsChartDataQuery.data.WatchNotification.watchNotificationsChart}
                interval={getCountArr(
                  emailsChartDataQuery.data.WatchNotification.watchNotificationsChart.length
                )}
                chartName="Daily Sent Hexowatch Notifications"
              />
            )}
        </div>
        <div className="col-12 col-md-4">
          <form onSubmit={(e) => handleSubmit(e, filterInput, channel, type, fromDate, toDate)}>
            <Form.Group>
              <Form.Input
                label="Input"
                className="margin-bottom"
                icon="search"
                position="append"
                placeholder="Search User Email, User Name, Url"
                value={filterInput}
                onChange={handleInputChange}
              />
              <Form.Select onChange={handleSelectChannel} label="Channel">
                {channels.map((el) => (
                  <option key={el} value={el}>
                    {el}
                  </option>
                ))}
              </Form.Select>
              <Form.Select onChange={handleSelectType} label="Hexowatch Notification Type">
                {typeOptions &&
                  typeOptions.map((el: any) => (
                    <option key={el.type} value={el.type}>
                      {el.type}
                    </option>
                  ))}
              </Form.Select>
              <Form.Group label="From Date">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={fromDate ? new Date(fromDate) : null}
                  onChange={handleFromDate}
                  showMonthDropdown
                  placeholderText="Select a date"
                />
              </Form.Group>
              <Form.Group label="To Date">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={toDate ? new Date(toDate) : null}
                  onChange={handleToDate}
                  showMonthDropdown
                  placeholderText="Select a date"
                  minDate={fromDate && new Date(fromDate)}
                />
              </Form.Group>
              <Button className="margin-bottom" type="submit" icon="filter" color="primary">
                Filter
              </Button>
            </Form.Group>
          </form>
        </div>
      </Grid.Row>
      <Grid.Row className={showButtons === "true" ? "justify-content-between" : "justify-content-end"}>
        {showButtons === "true" && (
          <div className="col-12 col-md-3 col-xs-12">
            <Button.List>
              <Button
                onClick={() =>
                  getCSV({
                    variables: { ...props.variables, csvDownload: true },
                  })
                }
                loading={loading}
                color="primary"
                mr={1}
              >
                Get CSV Link
              </Button>
              <Button
                onClick={() =>
                  uploadToDrive({
                    variables: { ...props.variables, csvDownload: true },
                  })
                }
                loading={loading}
                color="primary"
              >
                Save to Drive
              </Button>
              <Button
                onClick={() => setShowtypes(!showtypes)}
                // loading={loading}
                color="primary"
              >
                show Types
              </Button>
            </Button.List>
            {!loading &&
              data &&
              data.WatchNotification &&
              data.WatchNotification.adminGetMany &&
              data.WatchNotification.adminGetMany.csvURL && (
                <Button RootComponent="a" href={data.WatchNotification.adminGetMany.csvURL} icon="download">
                  Download CSV
                </Button>
              )}
            <br />
          </div>
        )}
        <Grid.Col md={3}>
          <Alert type="info">Result Count - {total}</Alert>
        </Grid.Col>
      </Grid.Row>
      {showtypes && (
        <TableCard
          loading={dataLoading}
          data={constants}
          tableData={tableConst}
          onHeaderClick={handleSortingClick}
        />
      )}
      <Grid.Row cards deck>
        <ServerSidePagination
          data={notificationsPagination}
          total={total}
          onPageNum={props.onPageNumberChange}
        >
          <TableCard
            loading={dataLoading}
            data={baseNotifications}
            tableData={tableData}
            onHeaderClick={handleSortingClick}
          />
        </ServerSidePagination>
      </Grid.Row>
      <Grid.Row className="justify-content-end">
        {total ? (
          <Grid.Col md={3}>
            <Alert type="info">Result Count - {total}</Alert>
          </Grid.Col>
        ) : null}
      </Grid.Row>
    </Grid>
  );
};

export default WatchNotifications;
