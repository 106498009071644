import React, { useEffect, useState } from "react";
import { Alert, Button, Form } from "tabler-react";
import ReactModal from "react-modal";
import {
  ADD_AUTOMATION_UNIT_PRICE,
  DELETE_AUTOMATION_UNIT_PRICE,
  UPDATE_AUTOMATION_UNIT_PRICE,
} from "../../graphql/mutations";
import { GET_AUTOMATION_UNITS } from "../../graphql/queries";
import "react-mde/lib/styles/css/react-mde-all.css";
import { useMutation, useQuery } from "@apollo/react-hooks";

ReactModal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "50%",
  },
};

const TableModal = ({
  handleModal,
  showModal,
  onSave,
  editField,
  onUpdate,
  refetchData,
}: any): JSX.Element => {
  // const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  const [creditPrice, setCreditPrice] = useState(0);
  const [premiumCreditPrice, setPremiumCreditPrice] = useState(0);
  const [automationUnitList, setAutomationUnitList] = useState([]);
  const [showCreditFields, setShowCreditFields] = useState(false);
  const [unitItem, setUnitItem] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedUnits, setSelectedUnits] = useState([]);
  const [updateUnitItem, setUpdateUnitItem] = useState<any>(null);

  const [addAutomationUnitPrice] = useMutation(ADD_AUTOMATION_UNIT_PRICE);
  const [updateAutomationUnitPrice] = useMutation(UPDATE_AUTOMATION_UNIT_PRICE);
  const [deleteAutomationUnitPrice] = useMutation(DELETE_AUTOMATION_UNIT_PRICE);

  const addUnitPrice = async () => {
    setLoading(true);
    const res = await addAutomationUnitPrice({
      variables: {
        settings: {
          automation_id: editField.id,
          unit_id: unitItem.id,
          credit_price: +creditPrice,
          premium_credit_price: +premiumCreditPrice,
        },
      },
    });
    if (
      res.data.HexomaticAdminOps &&
      !res.data.HexomaticAdminOps.addUnitPriceFromAutomation
    ) {
      await refetchData();
      handleModal();
      setLoading(false);
      return;
    }
    const { error_code } =
      res.data.HexomaticAdminOps.addUnitPriceFromAutomation;
    if (error_code) {
      setLoading(false);
      setErrorMessage(error_code);
      return;
    }
  };

  const removeUnitPrice = async (id) => {
    let deleteConfirm = confirm(
      `Are you sure you want to delete automation unit price ID: ${id}`
    );
    if (!deleteConfirm) return;
    setLoading(true);
    const res = await deleteAutomationUnitPrice({
      variables: {
        settings: {
          price_id: id,
        },
      },
    });
    if (
      res.data.HexomaticAdminOps &&
      !res.data.HexomaticAdminOps.removeUnitPriceFromAutomation
    ) {
      await refetchData();
      handleModal();
      setLoading(false);
      return;
    }
    const { error_code } =
      res.data.HexomaticAdminOps.removeUnitPriceFromAutomation;
    if (error_code) {
      setLoading(false);
      setErrorMessage(error_code);
      return;
    }
  };

  const updateUnitPrice = async () => {
    setLoading(true);
    const res = await updateAutomationUnitPrice({
      variables: {
        settings: {
          price_id: updateUnitItem.id,
          credit_price: +creditPrice,
          premium_credit_price: +premiumCreditPrice,
        },
      },
    });
    if (
      res.data.HexomaticAdminOps &&
      !res.data.HexomaticAdminOps.updateUnitPriceFromAutomation
    ) {
      await refetchData();
      handleModal();
      setLoading(false);
      return;
    }
    const { error_code } =
      res.data.HexomaticAdminOps.updateUnitPriceFromAutomation;
    if (error_code) {
      setLoading(false);
      setErrorMessage(error_code);
      return;
    }
  };

  const {
    data,
    loading: dataLoading,
    refetch,
  } = useQuery(GET_AUTOMATION_UNITS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      settings: {
        filter: {
          limit: 100,
        },
      },
    },
  });

  useEffect(() => {
    if (
      data &&
      data.HexomaticAdmin &&
      data.HexomaticAdmin.getAutomationUnits.units
    ) {
      const { units } = data.HexomaticAdmin.getAutomationUnits;
      if (units[0] === null) return;
      setAutomationUnitList(units);
      const arr =
        editField.prices &&
        editField.prices[0] !== null &&
        editField.prices.map((item) => {
          const finded = units.find((unit) => unit.name === item.unit_name);
          return { ...finded, ...item };
        });
      setSelectedUnits(arr);
    }
  }, [data]);

  const handleCancel = () => {
    setName("");
    handleModal();
  };

  useEffect(() => {
    if (window.innerWidth < 640) {
      customStyles.content.width = "90%";
    }
  }, []);

  useEffect(() => {
    if (editField) {
      setName(editField.name);
    }
  }, [editField]);

  const openUpdateFields = (editUnit) => {
    if (updateUnitItem) {
      setUpdateUnitItem(null);
      setCreditPrice(0);
      setPremiumCreditPrice(0);
    } else {
      setUpdateUnitItem(editUnit);
      setCreditPrice(editUnit.credit_price);
      setPremiumCreditPrice(editUnit.premium_credit_price);
    }
  };

  const InputFields = () => {
    return (
      <>
        <>
          <Form.Input
            required
            type="text"
            placeholder="credit_price"
            label="credit price"
            value={creditPrice}
            onChange={(e: {
              target: { value: React.SetStateAction<number> };
            }) => setCreditPrice(e.target.value)}
          />
          <Form.Input
            required
            type="text"
            placeholder="premium_credit_price"
            label="premium credit price"
            value={premiumCreditPrice}
            onChange={(e: {
              target: { value: React.SetStateAction<number> };
            }) => setPremiumCreditPrice(e.target.value)}
          />
        </>
        <Button.List style={{ marginTop: "5px" }}>
          <Button
            onClick={() => {
              unitItem && addUnitPrice();
              updateUnitItem && updateUnitPrice();
            }}
            color="primary"
            type="submit"
            loading={loading}
          >
            Save
          </Button>
          <Button
            onClick={() => {
              setUnitItem(null);
              openUpdateFields(null);
            }}
            color="secondary"
            type="button"
          >
            Cancel
          </Button>
          )
        </Button.List>
      </>
    );
  };

  return (
    <div style={{ height: 0 }}>
      <ReactModal
        isOpen={showModal}
        contentLabel="Create type"
        style={customStyles}
        onRequestClose={handleCancel}
      >
        <h3>{editField ? "Unit Actions" : "Create automation Unit"}</h3>
        {errorMessage ? <Alert type="danger">{errorMessage}</Alert> : null}
        <br />
        <h4>Selected Units</h4>
        <div className="selected-fields">
          {selectedUnits &&
            selectedUnits.map((editUnit) => (
              <>
                <div className="item">
                  <span>
                    <b>price id</b>: {editUnit.id} <b>name</b>: {editUnit.name}{" "}
                    <b>credit price</b>: {editUnit.credit_price}{" "}
                    <b>premium credit price</b> {editUnit.premium_credit_price}
                  </span>
                  <Button.List>
                    <Button
                      color="primary"
                      icon="edit"
                      onClick={() => {
                        openUpdateFields(editUnit);
                        setShowCreditFields(true);
                      }}
                    />
                    <Button
                      color="danger"
                      icon="trash"
                      onClick={() => {
                        removeUnitPrice(editUnit.id);
                      }}
                    />
                  </Button.List>
                </div>
                {updateUnitItem &&
                  updateUnitItem.id === editUnit.id &&
                  InputFields()}
              </>
            ))}
        </div>
        <br />
        <h4>Unit list</h4>
        <div className="field-list">
          {automationUnitList.map((unit) => (
            <>
              <div
                key={unit.id}
                className="item"
                onClick={() => {
                  setUnitItem(unitItem ? null : unit);
                  setShowCreditFields(true);
                }}
              >
                <b>id</b>: {unit.id} <b>name</b>: {unit.name}
              </div>
              {unitItem && unitItem.id === unit.id && InputFields()}
            </>
          ))}
        </div>
        <br />
        <Form onSubmit={(e: React.FormEvent) => e.preventDefault()}>
          <Button.List style={{ marginTop: "5%" }}>
            <Button onClick={handleCancel} color="secondary" type="button">
              Cancel
            </Button>
          </Button.List>
        </Form>
      </ReactModal>
    </div>
  );
};

export default TableModal;
