import React, { useState, useEffect } from "react";
import { EMAIL_BLACKLIST_CREATE_MUTATUION, EMAIL_BLACKLIST_DELETE_MUTATUION, EMAIL_BLACKLIST_UPDATE_MUTATUION } from "../../graphql/mutations";
import { GET_EMAIL_BLACKLIST_LIST } from "../../graphql/queries";
import BlackListEmail from "./index";
import { useQuery, useMutation } from "@apollo/react-hooks";

const BlackListEmailQueries = () => {
  const [emailList, setEmailList] = useState<any>([]);
  const [pageNumberState, setPageNumberState] = useState(1);
  const handlePageNumberChange = (pageNumber: number) => setPageNumberState(pageNumber);
  const [searchEmail, setsearchEmail] = useState<string>('')

  const { data, loading, refetch } = useQuery(GET_EMAIL_BLACKLIST_LIST, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      page: pageNumberState,
      limit: 100,
      filter_by_email: searchEmail,
      sort_by: 'DESC',
      sort_by_creation_date: true
    }
  });

  useEffect(() => {
    refetch()
  }, [pageNumberState])

  useEffect(() => {
    setEmailList(data)
  }, [data])

  useEffect(() => {
    refetchData()
  }, [searchEmail])

  const refetchData = async () => {
    await refetch()
  }

  const [createBlackList] = useMutation(EMAIL_BLACKLIST_CREATE_MUTATUION, {
    onCompleted: () => {
      refetchData()
    }
  });
  const [updateBlackList] = useMutation(EMAIL_BLACKLIST_UPDATE_MUTATUION, {
    onCompleted: () => {
      refetchData()
    }
  });
  const [deleteBlackList] = useMutation(EMAIL_BLACKLIST_DELETE_MUTATUION, {
    onCompleted: () => {
      refetchData()
    }
  });

  return (
    <BlackListEmail
      data={emailList}
      loading={loading}
      searchEmail={searchEmail}
      setsearchEmail={setsearchEmail}
      onPageNumberChange={handlePageNumberChange}
      createBlackList={createBlackList}
      updateBlackList={updateBlackList}
      deleteBlackList={deleteBlackList}
    />
  )
};


export default BlackListEmailQueries;
