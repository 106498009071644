import React, { useEffect, useState, FormEvent } from "react";
import { Grid, Form, Button, Alert } from "tabler-react";
import { MobileInvitesProps } from "../../types";
import moment from "moment";
import TableCard from "../../components/tableCard";
import ServerSidePagination from "../../components/pagination/serverSidePagination";
import { MOBILE_INVITES } from "../../graphql/queries";
import { useLazyQuery } from "@apollo/react-hooks";
import DatePicker from "react-datepicker";
import { actions } from "../../constants";
import { isProduction } from "../../helpers";
import "./index.css";

const MobileInvites = (props: MobileInvitesProps): JSX.Element => {
  const [mobileInvites, setMobileInvites] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [scanLogsPagination, setScanLogsPagination] = useState([]);
  const [filterUserInput, setFilterUserInput] = useState("");
  const [filterRefferalInput, setFilterRefferalInput] = useState("");
  const [total, setTotal] = useState(0);
  const [selectProduct, setSelectProduct] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const showButtons = localStorage.getItem("_rep");

  const [getCSV, { data, loading }] = useLazyQuery(MOBILE_INVITES);
  const [uploadToDrive, { data: driveData, loading: driveDataLoading }] =
    useLazyQuery(MOBILE_INVITES);
  const tableData = {
    tData:
      mobileInvites &&
      mobileInvites.map((item: any) => {
        return {
          "User Email": item.userEmail || "No email",
          "Referral Email": item.referrerUserEmail,
          "Click date": moment(new Date(+item.created_at))
            .utc()
            .format("DD-MM-YYYY hh:mm:ss a"),
          "Action Name": item.action,
          "Referral Code": item.referralCode,
        };
      }),
  };

  useEffect(() => {
    if (props.data && props.data.Referral && props.data.Referral.getReferrals) {
      setDataLoading(props.loading);
      setMobileInvites(props.data.Referral.getReferrals.referrals);
      setScanLogsPagination(props.data.Referral.getReferrals.referrals);
      setTotal(props.data.Referral.getReferrals.count);
    }
  }, [props.data]);

  useEffect(() => {
    if (
      driveData &&
      driveData.Referral &&
      driveData.Referral.getReferrals &&
      driveData.Referral.getReferrals.csvUrl
    ) {
      window.open(
        isProduction()
          ? `https://api.hexometer.com/v2/app/social-callback/googleDrive?admin=admin&report=referral&url=${driveData.Referral.getReferrals.csvUrl}`
          : `http://localhost:4000/api/app/social-callback/googleDrive?admin=admin&report=referral&url=${driveData.Referral.getReferrals.csvUrl}`,
        "_blank"
      );
    }
  }, [driveData]);

  const handleUserInputChange = (e: FormEvent) =>
    setFilterUserInput((e.target as HTMLInputElement).value);
  const handleRefferalInputChange = (e: FormEvent) =>
    setFilterRefferalInput((e.target as HTMLInputElement).value);
  const handleSelectedProduct = (e: FormEvent) =>
    setSelectProduct((e.target as HTMLInputElement).value);

  const handleFromDate = (date: Date) => {
    if (date) {
      return setFromDate(moment(date).format("YYYY-MM-DDT00:00:00.000"));
    }
    return setFromDate(null);
  };

  const handleToDate = (date: Date) => {
    if (date) {
      return setToDate(moment(date).format("YYYY-MM-DDT23:59:59.999"));
    }
    return setToDate(null);
  };

  const handleSubmit = (
    event: FormEvent,
    filterUser: string,
    filterRefferal: string,
    actionName: string,
    startDate: string,
    endDate: string
  ) => {
    event.preventDefault();
    props.onFilterUser(filterUser.trim());
    props.onFilterRefferal(filterRefferal.trim());
    props.onFromDate(startDate);
    props.onToDate(endDate);

    if (actionName !== "Select action") {
      props.onActionSelect(actionName);
    } else {
      props.onActionSelect("");
    }
  };

  const handleSortingClick = (column: string) => {
    if (column === "Click date") props.setOrderBy("created_at");
    else if (column === "Action Name") props.setOrderBy("type");
    if (column === "Click date" || column === "Action Name")
      props.setOrderByASC(!props.orderByASC);
  };
  return (
    <Grid>
      <Grid.Row className="mb-5">
        <div className="col-12 col-md-8"></div>
        <div className="col-12 col-md-4">
          <form
            onSubmit={(e) =>
              handleSubmit(
                e,
                filterUserInput,
                filterRefferalInput,
                selectProduct,
                fromDate,
                toDate
              )
            }
          >
            <Form.Group>
              <Form.Input
                label="Input"
                className=""
                icon="search"
                position="append"
                placeholder="Search for User Email"
                value={filterUserInput}
                onChange={handleUserInputChange}
              />
              <Form.Input
                label="Input"
                className=""
                icon="search"
                position="append"
                placeholder="Search for Referral Email"
                value={filterRefferalInput}
                onChange={handleRefferalInputChange}
              />
              <Form.Select label="Action" onChange={handleSelectedProduct}>
                <option value="Select action">Select action</option>
                {actions.map((action) => (
                  <option key={action} value={action}>
                    {action}
                  </option>
                ))}
              </Form.Select>
              <Form.Group
                label="From Date"
                className="shortcutClicksDatePicker"
              >
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={fromDate ? new Date(fromDate) : null}
                  onChange={handleFromDate}
                  showMonthDropdown
                  placeholderText="Select a date"
                />
              </Form.Group>
              <Form.Group label="To Date" className="shortcutClicksDatePicker">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={toDate ? new Date(toDate) : null}
                  onChange={handleToDate}
                  showMonthDropdown
                  placeholderText="Select a date"
                  minDate={fromDate && new Date(fromDate)}
                />
              </Form.Group>
              <Button type="submit" icon="filter" color="primary">
                Filter
              </Button>
            </Form.Group>
          </form>
        </div>
      </Grid.Row>
      <Grid.Row className={showButtons === 'true' ? "justify-content-between" : "justify-content-end"}>
        {showButtons === 'true' && (
          <div className="col-12 col-md-3 col-xs-12">
            <Button.List>
              <Button
                onClick={() =>
                  getCSV({
                    variables: {
                      settings: {
                        ...props.variables,
                        csvDownload: true,
                      },
                    },
                  })
                }
                loading={loading}
                color="primary"
                mr={1}
              >
                Get CSV Link
              </Button>
              <Button
                onClick={() =>
                  uploadToDrive({
                    variables: {
                      settings: {
                        ...props.variables,
                        csvDownload: true,
                      },
                    },
                  })
                }
                loading={driveDataLoading}
                color="primary"
              >
                Save to Drive
              </Button>
            </Button.List>
            {!loading &&
              data &&
              data.Referral &&
              data.Referral.getReferrals &&
              data.Referral.getReferrals.csvUrl && (
                <Button
                  RootComponent="a"
                  href={data.Referral.getReferrals.csvUrl}
                  icon="download"
                  className="mt-2"
                >
                  Download CSV
                </Button>
              )}
            <br />
          </div>
        )}
        <Grid.Col md={3}>
          <Alert type="info">Result Count - {total}</Alert>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row cards deck>
        <ServerSidePagination
          data={scanLogsPagination}
          total={total}
          onPageNum={props.onPageNumberChange}
        >
          <TableCard
            loading={dataLoading}
            data={mobileInvites}
            tableData={tableData}
            onHeaderClick={handleSortingClick}
          />
        </ServerSidePagination>
      </Grid.Row>
      <Grid.Row className="justify-content-end">
        {total ? (
          <Grid.Col md={3}>
            <Alert type="info">Result Count - {total}</Alert>
          </Grid.Col>
        ) : null}
      </Grid.Row>
    </Grid>
  );
};

export default MobileInvites;
