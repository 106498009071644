import React, { useState, useEffect } from "react";
import {
  CREATE_EMAIL_CUSTOM_MESSAGE,
  DELETE_EMAIL_CUSTOM_MESSAGE,
  UPDATE_EMAIL_CUSTOM_MESSAGE,
} from "../../graphql/mutations";
import { GET_EMAIL_CUSTOM_MESSAGE } from "../../graphql/queries";
import EmailCusomMessages from "./index";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { ToastProvider } from "react-toast-notifications";

const EmailCustomMessagesQueries = () => {
  const [categoryList, setCategoryList] = useState<any>([]);
  const [pageNumberState, setPageNumberState] = useState(1);
  const handlePageNumberChange = (pageNumber: number) => setPageNumberState(pageNumber);
  const [searchEmail, setsearchEmail] = useState<string>('')


  const { data, loading, refetch } = useQuery(GET_EMAIL_CUSTOM_MESSAGE, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      filter: {
        from: null,
        to: null,
        product: null,
        search: null,
        templates_id: [],
      }, 
      pageSettings: {
        page: 1,
        pageSize: 100,
      },
      sortSettings: {
        sortField: null,
        sortDir: null,
      }
    }
  })

  useEffect(() => {
    refetch()
  }, [pageNumberState])

  useEffect(() => {
    setCategoryList(data);
  }, [data])

  useEffect(() => {
    refetchData()
  }, [searchEmail])

  const refetchData = async () => {
    await refetch()
  }

  const [deleteCategory] = useMutation(DELETE_EMAIL_CUSTOM_MESSAGE)

  const [updateCategory] = useMutation(UPDATE_EMAIL_CUSTOM_MESSAGE)

  const [createCategory] = useMutation(CREATE_EMAIL_CUSTOM_MESSAGE, {
    onCompleted: () => {
      refetchData();
    }
  })

  return (
    <ToastProvider>
      <EmailCusomMessages
        data={categoryList}
        loading={loading}
        searchEmail={searchEmail}
        setsearchEmail={setsearchEmail}
        onPageNumberChange={handlePageNumberChange}
        createAutomationCategory={createCategory}
        deleteCategory={deleteCategory}
        updateCategory={updateCategory}
      />
    </ToastProvider>
  )
};


export default EmailCustomMessagesQueries;
