import React, { useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Site, Page } from "tabler-react";
import { Route, Switch } from "react-router";
import {
  BrowserRouter,
  Redirect,
  //@ts-ignore
  useLocation,
  //@ts-ignore
  useHistory,
} from "react-router-dom";

import { GET_ADMIN } from "./graphql/queries";

import Footer from "./components/footer";
import Menu from "./components/menu";
import DateAndTime from "./components/dateAndTime";
import LoginMutation from "./pages/login/login.mutation";
import Home from "./pages/home/metrics.queries";
import KeywordsQueries from "./pages/keywords/keywords.queries";
import BlackListEmailQueries from "./pages/blackListEmail/blackListEmail.queries";
import UsersQueries from "./pages/users/users.queries";
import ScanLogsQueries from "./pages/scanLogs/scanLogs.queries";
import ShorthcutClicks from "./pages/shorthcutClicks/shorthcutClicks.queries";
import HeaderDataQueries from "./pages/headerData/headerData.queries";
import PropertiesQueries from "./pages/properties/properties.queries";
import UtilitiesPage from "./pages/utility";
import UserLogsQueries from "./pages/userLogs/userLogs.queries";
import TasksQueries from "./pages/aiTasks";
import PromoCodesQueries from "./pages/promoCodes";
import NotificationsQueries from "./pages/notifications";
import ChannelsQueries from "./pages/channels";
import QuestionnairesQueries from "./pages/questionnaire";
import WatchPropertiesQueries from "./pages/watchProperties/watchProperties.queries";
import WatchNotificationsQueries from "./pages/watchNotifications";
import subscribedEmails from "./pages/subscribedEmails/subscribedEmails.queries";
import additionalEmail from "./pages/additionalEmail/index";
import shortlink from "./pages/shortlink/shortlink.queries";
import Automation from "./pages/automations/automation.queries";
import AutomationCategories from "./pages/automationCategories/automationCategories.queries";
import PublicWorkflowCategories from "./pages/publicWorkflowCategories/publicWorkflowCategories.queries";
import ScrapingCategories from "./pages/scrapingRecipeCategories/scrapingRecipeCategories.queries";
import PublicScrapingRecipes from "./pages/publicScrapingRecipes/publicScrapingRecipes.queries";
import EmailCustomMessages from "./pages/emailCustomMessages/emailCustomMessages.queries";
import AutomationTypes from "./pages/automationTypes/automationTypes.queries";
import ScrapingRecipes from "./pages/scrapingRecipes/scrapingRecipes.queries";
import WorkFlows from "./pages/workflows/workflows.queries";
import PublicWorkFlows from "./pages/publicWorkflows/workflows.queries";
import JsonSchemas from "./pages/jsonSchemas/jsonSchemasTypes.queries";
import AutomationConfig from "./pages/automationConfig/automationConfig.queries";
import AutomationUnits from "./pages/automationUnits/automationUnits.queries";
import HexomaticCreditsUsage from "./pages/hexomaticCreditsUsage/credit";
import HexomaticAutomationCreditsUsage from "./pages/hexomaticAutomationCreditsUsage";
import HexomaticTrafficUsage from "./pages/hexomaticTrafficUsage/traffic";
import HexomaticNotificationsQueries from "./pages/hexomaticNotifications";
import HexomaticSurvey from "./pages/hexomaticSurvey";
import HexomaticSharedRecipes from "./pages/hexomaticSharedRecipes";
import HexomaticSharedWorkflows from "./pages/hexomaticSharedWorkflows";
import HexomaticAutomationInuptOutputTypes from "./pages/automationInputOutputTypes/automationInputOutputTypes.queries";
import YesNoAutomation from "./pages/hexomaticTasks/tasks.queries";
import PullContact from "./pages/pullContact/pullcontact";
import DiscoverProfile from "./pages/discoverProfile/discoverProfile";
import Fullpagescreencapture from "./pages/fullpagescreencapture/fullpagescreencapture";
import Random from "./pages/random";
import ExcludedUsers from "./pages/excludedUsers";

import HexosparkIceBreaker from "./pages/iceBreaker/iceBreaker.queries";
import HexosparkPublicTemplates from "./pages/hexosparkPublicTemblate/publicTemplate.queries";
import HexosparkPublicTemplatesCategories from "./pages/hexosparkPublicTemplateCategories/publicTemplateCategories.queries";
import HexosparkWorkspaces from "./pages/hexosparkWorkspaces";
import HexosparkWorkspacesStorage from "./pages/hexosparkWorkspacesStorage";
import HexosparkEmailAccounts from "./pages/hexosparkEmailAccounts";
import HexosparkContactsOrOrganizations from "./pages/hexosparkContactsOrOrganizations";
import HexosparkCampaigns from "./pages/hexosparkCampaigns";
import HexosparkEmailTemplatesPerWorkspaces from "./pages/hexosparkEmailTemplatesPerWorkspaces";
import HexosaprkMonthlyReport from "./pages/hexosaprkMonthlyReport";

import MobileInvites from "./pages/mobileInvites/mobileInvites.queries";
import CancellationFeedbacks from "./pages/cancellationFeedbacks/cancellationFeedbacks.queries";
import StartengineWelcomePack from "./pages/startengineWelcomePack/startenginewelcomepack.queries";
import DemoRequests from "./pages/demoRequests/demoRequests.queries";
import WebsiteWordCount from "./pages/websiteWordCount/websiteWordCount";
import FreeTools from "./pages/freeTools/freeTools.queries";
import Hotjar from "./pages/hotjarCsv";

import { getUserToken, getUserName, clearCookies, setUserToken } from "./helpers";

import "tabler-react/dist/Tabler.css";
import "react-datepicker/dist/react-datepicker.css";
import "./styles/index.css";
import RandomPremiums from "./pages/randomPremiums";

const accountDropdownProps = {
  avatarURL: "./",
  name: getUserName(),
  options: [
    { icon: "settings", value: "Settings", to: "/settings" },
    "divider",
    { icon: "log-out", value: "Logout", to: "/login", onClick: clearCookies },
  ],
};

// @ts-ignore
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (getUserToken() ? <Component {...props} /> : <Redirect to="/login" />)}
  />
);

const App = (): JSX.Element => {
  const { data, loading } = useQuery(GET_ADMIN);
  let location = useLocation();
  let history = useHistory();

  useEffect(() => {
    const search = location.search;
    if (search && search.includes("token")) {
      const token = search.split("=")[1];
      setUserToken(token);
      history.push("/");
      window.location.reload();
    }
  }, [location]);

  useEffect(() => {
    if (window.location.host.includes("localhost")) {
      return;
    }
    if (
      data &&
      data.Admin &&
      data.Admin.adminGet &&
      !data.Admin.adminGet.username &&
      !window.location.href.includes("/login")
    ) {
      clearCookies();
      window.location.pathname = "/login";
    }
  }, [data]);

  useEffect(() => {
    if (
      data &&
      data.Admin &&
      data.Admin.adminGet &&
      data.Admin.adminGet.settings &&
      typeof data.Admin.adminGet.settings.csvDownload === "boolean"
    ) {
      localStorage.setItem("_rep", `${data.Admin.adminGet.settings.csvDownload}`);
    } else {
      localStorage.setItem("_rep", `${true}`);
    }
  }, [data]);

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/login" component={LoginMutation} />
        {data && data.Admin && data.Admin.adminGet && data.Admin.adminGet.username && (
          <Site.Wrapper
            headerProps={{
              imageURL: "https://hexact.io/img/hexact-logo.svg",
              navItems: <DateAndTime />,
              accountDropdown: accountDropdownProps,
            }}
            navProps={{
              items:
                data && data.Admin && data.Admin.adminGet && data.Admin.adminGet.viewer ? null : (
                  <Menu hexomate />
                ),
            }}
            footerProps={{ copyright: <Footer /> }}
          >
            <Page.Main>
              <div className="container-fluid mt-5">
                <PrivateRoute exact path="/" component={Home} />
                {!loading && data && data.Admin && data.Admin.adminGet && !data.Admin.adminGet.viewer && (
                  <>
                    <PrivateRoute exact path="/keywords" component={KeywordsQueries} />
                    <PrivateRoute exact path="/users" component={UsersQueries} />
                    <PrivateRoute exact path="/scan-logs" component={ScanLogsQueries} />
                    <PrivateRoute exact path="/header-data" component={HeaderDataQueries} />
                    <PrivateRoute exact path="/properties" component={PropertiesQueries} />
                    <PrivateRoute exact path="/utility" component={UtilitiesPage} />
                    <PrivateRoute exact path="/user-logs" component={UserLogsQueries} />
                    <PrivateRoute exact path="/ai-tasks" component={TasksQueries} />
                    <PrivateRoute exact path="/promocodes" component={PromoCodesQueries} />
                    <PrivateRoute exact path="/notifications" component={NotificationsQueries} />
                    <PrivateRoute exact path="/channels" component={ChannelsQueries} />
                    <PrivateRoute exact path="/questionnaires" component={QuestionnairesQueries} />
                    <PrivateRoute exact path="/hexowatch-properties" component={WatchPropertiesQueries} />
                    <PrivateRoute
                      exact
                      path="/hexowatch-notifications"
                      component={WatchNotificationsQueries}
                    />
                    <PrivateRoute exact path="/black-list-email" component={BlackListEmailQueries} />
                    <PrivateRoute exact path="/subscribed-email" component={subscribedEmails} />
                    <PrivateRoute exact path="/additional-email" component={additionalEmail} />
                    <PrivateRoute exact path="/shortlink" component={shortlink} />
                    <PrivateRoute exact path="/shortcut-clicks" component={ShorthcutClicks} />
                    <PrivateRoute exact path="/referral-link-stats" component={MobileInvites} />
                    <PrivateRoute exact path="/cancellation-feedbacks" component={CancellationFeedbacks} />
                    <PrivateRoute exact path="/startengine-welcome-pack" component={StartengineWelcomePack} />
                    <PrivateRoute exact path="/demo-requests" component={DemoRequests} />
                    <PrivateRoute exact path="/hexomatic-automation" component={Automation} />
                    <PrivateRoute
                      exact
                      path="/hexomatic-automation-categories"
                      component={AutomationCategories}
                    />
                    <PrivateRoute exact path="/hexomatic-automation-types" component={AutomationTypes} />
                    <PrivateRoute exact path="/hexomatic-scraping-recipes" component={ScrapingRecipes} />
                    <PrivateRoute exact path="/hexomatic-workflows" component={WorkFlows} />
                    <PrivateRoute exact path="/hexomatic-public-workflows" component={PublicWorkFlows} />
                    <PrivateRoute
                      exact
                      path="/hexomatic-public-workflow-categories"
                      component={PublicWorkflowCategories}
                    />
                    <PrivateRoute
                      exact
                      path="/hexomatic-scraping-categories"
                      component={ScrapingCategories}
                    />
                    <PrivateRoute
                      exact
                      path="/hexomatic-public-scraping-recipes"
                      component={PublicScrapingRecipes}
                    />
                    <PrivateRoute exact path="/hexomatic-json-schema" component={JsonSchemas} />
                    <PrivateRoute exact path="/hexomatic-automation-config" component={AutomationConfig} />
                    <PrivateRoute exact path="/hexomatic-automation-units" component={AutomationUnits} />
                    <PrivateRoute exact path="/hexomatic-credits-usage" component={HexomaticCreditsUsage} />
                    <PrivateRoute
                      exact
                      path="/hexomatic-automation-credits-usage"
                      component={HexomaticAutomationCreditsUsage}
                    />
                    <PrivateRoute exact path="/hexomatic-traffic-usage" component={HexomaticTrafficUsage} />
                    <PrivateRoute
                      exact
                      path="/hexomatic-notifications"
                      component={HexomaticNotificationsQueries}
                    />
                    <PrivateRoute exact path="/hexomatic-survey" component={HexomaticSurvey} />
                    <PrivateRoute exact path="/hexomatic-shared-recipes" component={HexomaticSharedRecipes} />
                    <PrivateRoute
                      exact
                      path="/hexomatic-shared-workflows"
                      component={HexomaticSharedWorkflows}
                    />
                    <PrivateRoute
                      exact
                      path="/hexomatic-automation-inupt-output-types"
                      component={HexomaticAutomationInuptOutputTypes}
                    />
                    <PrivateRoute exact path="/hexomatic-tasks" component={YesNoAutomation} />
                    <PrivateRoute exact path="/pull-contacts" component={PullContact} />
                    <PrivateRoute exact path="/discover-profile" component={DiscoverProfile} />
                    <PrivateRoute exact path="/fullpagescreencapture" component={Fullpagescreencapture} />
                    <PrivateRoute exact path="/inspect-content" component={WebsiteWordCount} />
                    <PrivateRoute exact path="/free-tools" component={FreeTools} />
                    <PrivateRoute exact path="/email-custom-messages" component={EmailCustomMessages} />
                    <PrivateRoute exact path="/random" component={Random} />
                    <PrivateRoute exact path="/random-premiums" component={RandomPremiums} />
                    <PrivateRoute exact path="/excluded-users" component={ExcludedUsers} />
                    <PrivateRoute exact path="/hotjar-csv" component={Hotjar} />
                    <PrivateRoute exact path="/hexospark-ice-breaker" component={HexosparkIceBreaker} />
                    <PrivateRoute exact path="/hexospark-workspaces" component={HexosparkWorkspaces} />
                    <PrivateRoute
                      exact
                      path="/hexospark-workspaces-storage"
                      component={HexosparkWorkspacesStorage}
                    />
                    <PrivateRoute exact path="/hexospark-email-accounts" component={HexosparkEmailAccounts} />
                    <PrivateRoute
                      exact
                      path="/hexospark-contacts-or-organizations"
                      component={HexosparkContactsOrOrganizations}
                    />
                    <PrivateRoute exact path="/hexospark-campaigns" component={HexosparkCampaigns} />

                    <PrivateRoute
                      exact
                      path="/hexospark-public-templates"
                      component={HexosparkPublicTemplates}
                    />
                    <PrivateRoute
                      exact
                      path="/hexospark-public-templates-categories"
                      component={HexosparkPublicTemplatesCategories}
                    />
                    <PrivateRoute
                      exact
                      path="/hexospark-email-templates-per-workspace"
                      component={HexosparkEmailTemplatesPerWorkspaces}
                    />
                    <PrivateRoute exact path="/hexospark-monthly-report" component={HexosaprkMonthlyReport} />
                  </>
                )}
              </div>
            </Page.Main>
          </Site.Wrapper>
        )}
      </Switch>
    </BrowserRouter>
  );
};

export default App;
