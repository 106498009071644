import React, { useEffect, useState } from "react";
import { Alert, Button, Form } from "tabler-react";
import ReactModal from "react-modal";
import { TableModalProps } from "../../types";
import ReactMde from "react-mde";
import * as Showdown from "showdown";
import "react-mde/lib/styles/css/react-mde-all.css";
import "./styles.css";

ReactModal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    height: "80%",
  },
};

const TableModal = ({
  handleModal,
  showModal,
  onSave,
  keyword,
  errorMessage,
}: TableModalProps): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState("");
  const [text, setText] = useState("");
  const [tab, setTab] = useState<"write" | "preview">("preview");

  const handleOk = async () => {
    setLoading(true);
    const formData = { key, text };
    const completed = await onSave({ ...formData });
    setLoading(false);
    if (completed) handleCancel();
  };

  const handleCancel = () => {
    setKey("");
    setText("");
    setTab("write");
    handleModal();
  };

  useEffect(() => {
    if (keyword) {
      setKey(keyword.key);
      setText(keyword.value);
    }
  }, [keyword]);

  const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true,
    customizedHeaderId: true,
  });

  useEffect(() => {
    if (window.innerWidth < 640) {
      customStyles.content.width = "90%";
    }
  }, []);

  const handleKeyChange = (e: React.FormEvent) => setKey((e.target as HTMLInputElement).value.trim());
  const handleTextChange = (value: string) => setText(value);

  const handleTabChange = (tab: "write" | "preview") => setTab(tab);

  return (
    <div style={{ height: 0 }}>
      <ReactModal
        isOpen={showModal}
        contentLabel="Create Translation"
        style={customStyles}
        // className="table-modal-767"
        onRequestClose={handleCancel}
      >
        <h4>{keyword ? "Edit Keyword" : "Create Keyword"}</h4>
        {errorMessage ? <Alert type="danger">{errorMessage}</Alert> : null}
        <Form onSubmit={(e: React.FormEvent) => e.preventDefault()}>
          <Form.Input
            disabled={keyword}
            required
            type="text"
            placeholder="key"
            label="Key"
            value={key}
            onChange={handleKeyChange}
          />
          <ReactMde
            onChange={handleTextChange}
            onTabChange={handleTabChange}
            value={text}
            generateMarkdownPreview={(markdown) => Promise.resolve(converter.makeHtml(markdown))}
            selectedTab={tab}
          />
          <Button.List style={{ marginTop: "5%" }}>
            <Button onClick={handleOk} color="primary" type="submit" loading={loading}>
              Save
            </Button>
            <Button onClick={handleCancel} color="secondary" type="button">
              Cancel
            </Button>
          </Button.List>
        </Form>
      </ReactModal>
    </div>
  );
};

export default TableModal;
