import React, { useState, Fragment, useEffect, useReducer } from "react";
import {
  GET_USERS,
  // GET_HEXOMETER_PRICING_PACK_NAMES,
  // GET_HEXOWATCH_PRICING_PACK_NAMES,
  // GET_HEXOMATIC_PRICING_PACK_NAMES,
  GET_LIST_PRODUCTS,
} from "../../graphql/queries";
import {
  USER_DELETE_MUTATION,
  USER_BLOCK_MUTATION,
  USER_UNBLOCK_MUTATION,
  USER_UPDATE_MUTATION,
  USER_WATCH_CONFIG_UPDATE,
} from "../../graphql/mutations";
import Users from "./";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Grid, Form, Loader } from "tabler-react";
import { HEXOMATE_PRICING_PACKAGE, HEXOMETER_PRICING_PACKAGES, watchPricingPacks } from "../../constants";

const UsersQueries = () => {
  const [filterDataState, setFilterDataState] = useState("");
  const [filterRefererChange, onFilterRefererChange] = useState("");
  const [pageNumberState, setPageNumberState] = useState(1);
  const [regFrom, setRegFrom] = useState("");
  const [regTo, setRegTo] = useState("");
  const [logFrom, setLogFrom] = useState("");
  const [logTo, setLogTo] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [orderByASC, setOrderByASC] = useState(false);
  const [userType, setUserType] = useState<string | undefined>();
  const [countryCodeState, setCountryCodeState] = useState("");
  const [sourceState, setSourceState] = useState("");
  const [type, setType] = useState("");
  const [pricingPack, setPricingPack] = useState("");
  const [idEvenOrOdd, setIdEvenOrOdd] = useState<"even" | "odd" | "">("");
  const [pricingPackInterval, setPricingPackInterval] = useState("");
  const [hexometer_pricing_package, setHexometerPricingPackage] = useState("");
  const [hexometer_pricing_package_interval, setHexometerPricingPackageInterval] = useState("");
  const [premium_credit_subscription, setPremium_credit_subscription] = useState("");
  const [premium_credit_subscription_interval, setPremium_credit_subscription_interval] = useState("");
  const [hexometer_pricing_package_count, setHexometerPricingPackageCount] = useState(null);
  const [userData, setUserData] = useState<any>(null);
  const [userStatus, setUserStatus] = useState<string | undefined>("");

  const [hexomate_pricing_package_interval, setHexomate_pricing_package_interval] = useState("");
  const [hexomate_pricing_package, setHexomate_pricing_package] = useState("");
  const [hexomate_pricing_package_count, setHexomate_pricing_package_count] = useState(null);

  const [hexospark_pricing_package, setHexospark_pricing_package] = useState("");
  const [hexospark_pricing_package_interval, setHexospark_pricing_package_interval] = useState("");
  const [hexospark_pricing_package_count, setHexospark_pricing_package_count] = useState(null);

  const [hexofy_pricing_package, setHexofy_pricing_package] = useState("");
  const [hexofy_pricing_package_interval, setHexofy_pricing_package_interval] = useState("");
  const [hexofy_pricing_package_count, setHexofy_pricing_package_count] = useState(null);

  const [hexoscope_pricing_package, setHexoscope_pricing_package] = useState("");
  const [hexoscope_pricing_package_interval, setHexoscope_pricing_package_interval] = useState("");
  const [hexoscope_pricing_package_count, setHexoscope_pricing_package_count] = useState(null);

  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const [filterImpact, setFilterImpact] = useState(false);
  const [isPaidUser, setIsPaidUser] = useState(false);
  const [showPaymentInfo, setShowPaymentInfo] = useState(false);
  const [filterHexUsers, setFilterHexUsers] = useReducer((prev) => !prev, true);

  const [twofaType, setTwofaType] = useState<any>(null);
  const [twofaStatus, setTwofaStatus] = useState<any>(null);
  const [showApolloData, setShowApolloData] = useState(null);
  const [showApolloPeopleData, setShowApolloPeopleData] = useState(null);
  const [showOnlyInvited, setShowOnlyInvited] = useState(null);
  const [layer1, setLayer1] = useState(null);
  const [layer2, setLayer2] = useState(null);

  // const [hexowatchPricingPackName, setHexowatchPricingPackName] = useState<{ name: string }[]>([]);
  // const [hexomaticPricingPackName, setHexomaticPricingPackName] = useState<{ name: string }[]>([]);
  // const [hexometerPricingPackName, setHexometerPricingPackName] = useState<{ name: string }[]>([]);
  const [userPremiumPricingPackName, setUserPremiumPricingPackName] = useState<
    { name: string; interval: string; value: string; id: string }[]
  >([]);

  const { data: userPremiumPack } = useQuery(GET_LIST_PRODUCTS, {
    variables: {
      settings: {
        productType: "",
        fromAdmin: true,
      },
    },
  });

  // const { data: watchPackName } = useQuery(GET_HEXOWATCH_PRICING_PACK_NAMES, {
  //   variables: {
  //     hideHex: filterHexUsers,
  //   },
  // });

  // const { data: maticPackName } = useQuery(GET_HEXOMATIC_PRICING_PACK_NAMES, {
  //   variables: {
  //     hideHex: filterHexUsers,
  //   },
  // });
  // const { data: meterPackName } = useQuery(GET_HEXOMETER_PRICING_PACK_NAMES, {
  //   variables: {
  //     hideHex: filterHexUsers,
  //   },
  // });

  const {
    data,
    loading,
    refetch: refetchUsers,
  } = useQuery(GET_USERS, {
    fetchPolicy: "no-cache",
    variables: {
      settings: {
        input: {
          regFrom: regFrom,
          regTo: regTo,
          pageNumber: pageNumberState,
          searchQuery: filterDataState,
          // searchQuery: "Vlad.k",
        },
        active: false,
        logFrom: logFrom,
        logTo: logTo,
        csvDownload: false,
        orderBy,
        orderByASC,
        userType,
        countryCode: countryCodeState,
        source: sourceState,
        type,
        user_status: userStatus,
        pricingPackage: !isPaidUser ? pricingPack : null,
        pricingPackageInterval: !isPaidUser ? pricingPackInterval : null,
        hexometer_pricing_package: !isPaidUser ? hexometer_pricing_package : null,
        hexometer_pricing_package_interval: !isPaidUser ? hexometer_pricing_package_interval : null,
        hexometer_pricing_package_count: !isPaidUser ? hexometer_pricing_package_count : null,
        hexomatic_pricing_package: !isPaidUser ? hexomate_pricing_package : null,
        hexomatic_pricing_package_interval: !isPaidUser ? hexomate_pricing_package_interval : null,
        hexomatic_pricing_package_count: !isPaidUser ? hexomate_pricing_package_count : null,
        hexosparkPricingPackage: !isPaidUser ? hexospark_pricing_package : null,
        hexosparkPricingPackageInterval: !isPaidUser ? hexospark_pricing_package_interval : null,
        hexosparkPricingPackageCount: !isPaidUser ? hexospark_pricing_package_count : null,
        hexofyPricingPackage: !isPaidUser ? hexofy_pricing_package : null,
        hexofyPricingPackageInterval: !isPaidUser ? hexofy_pricing_package_interval : null,
        hexofyPricingPackageCount: !isPaidUser ? hexofy_pricing_package_count : null,
        hexoscopePricingPackage: !isPaidUser ? hexoscope_pricing_package : null,
        hexoscopePricingPackageInterval: !isPaidUser ? hexoscope_pricing_package_interval : null,
        hexoscopePricingPackageCount: !isPaidUser ? hexoscope_pricing_package_count : null,
        referer_search_value: filterRefererChange,
        integration: selectedIntegration,
        impactFilter: filterImpact,
        isPaidUser: isPaidUser,
        includePayments: showPaymentInfo,
        twofa_type: twofaType,
        twofa_status: twofaStatus,
        apollo_data: showApolloData,
        apollo_people_data: showApolloPeopleData,
        showOnlyInvited: showOnlyInvited || undefined,
        layer: layer1 && layer2 ? undefined : layer1 ? "first" : layer2 ? "second" : undefined,
        hideHex: filterHexUsers,
        premiumCreditSubscription: !isPaidUser ? premium_credit_subscription : null,
        premiumCreditSubscriptionInterval: !isPaidUser ? premium_credit_subscription_interval : null,
        idEvenOrOdd,
      },
      notifyOnNetworkStatusChange: true,
    },
  });

  const [deleteUser] = useMutation(USER_DELETE_MUTATION);
  const [blockUser] = useMutation(USER_BLOCK_MUTATION);
  const [unblockUser] = useMutation(USER_UNBLOCK_MUTATION);
  const [updateUser] = useMutation(USER_UPDATE_MUTATION);
  const [updateWatchConfig] = useMutation(USER_WATCH_CONFIG_UPDATE);

  useEffect(() => {
    setUserData(data);
  }, [data]);

  useEffect(() => {
    // if (meterPackName && meterPackName.User && meterPackName.User.getHexometerPricingPackageNames) {
    //   const list = meterPackName.User.getHexometerPricingPackageNames.concat([{ name: "ANY_PAID"}, {name: "NOT_PAID"}]);
    //   setHexometerPricingPackName(list);
    // }
    // if (maticPackName && maticPackName.User && maticPackName.User.getHexomaticPricingPackageNames) {
    //   const list = maticPackName.User.getHexomaticPricingPackageNames.concat([{ name: "ANY_PAID"}, {name: "NOT_PAID"}]);
    //   console.log('list', list)
    //   setHexomaticPricingPackName(list);
    // }
    // if (watchPackName && watchPackName.User && watchPackName.User.getHexowatchPricingPackageNames) {
    //   const list = watchPackName.User.getHexowatchPricingPackageNames.concat([{ name: "ANY_PAID"}, {name: "NOT_PAID"}]);
    //   setHexowatchPricingPackName(list);
    // }

    if (
      userPremiumPack &&
      userPremiumPack.Billing &&
      userPremiumPack.Billing.listProducts &&
      userPremiumPack.Billing.listProducts.products &&
      userPremiumPack.Billing.listProducts.products.premiumCreditProducts
    ) {
      let sortedMonthly = userPremiumPack.Billing.listProducts.products.premiumCreditProducts.filter(
        (sub: any) => sub.interval === "MONTHLY" && sub.name !== "FREE" && sub.name !== "MICROSOFT"
      );
      let sortedAnnual = userPremiumPack.Billing.listProducts.products.premiumCreditProducts.filter(
        (sub: any) => sub.interval === "ANNUAL" && sub.name !== "FREE" && sub.name !== "MICROSOFT"
      );
      const otherList = userPremiumPack.Billing.listProducts.products.premiumCreditProducts.filter(
        (sub: any) => sub.name === "FREE" || sub.name === "MICROSOFT"
      );

      sortedMonthly = sortedMonthly
        .map((item: any) => {
          return {
            name: item.name,
            id: item.id,
            interval: item.interval,
            value: `PC ${item.amount} monthly`,
          };
        })
        .sort(function (a, b) {
          return a.name.length - b.name.length;
        });

      sortedAnnual = sortedAnnual
        .map((item: any) => {
          return {
            name: item.name,
            id: item.id,
            interval: item.interval,
            value: `PC ${item.amount} ${item.name.includes("SPECIAL") ? "Special" : ""} yearly`,
          };
        })
        .sort(function (a, b) {
          return a.name.length - b.name.length;
        });
      const finData = otherList
        .map((item: any) => {
          return {
            name: item.name,
            id: item.id,
            interval: item.interval,
            value: item.name,
          };
        })
        .concat(sortedMonthly, sortedAnnual)
        .concat([
          { name: "ANY_PAID", value: "ANY_PAID" },
          { name: "NOT_PAID", value: "NOT_PAID" },
        ]);
      finData && setUserPremiumPricingPackName(finData);
    }
  }, [userPremiumPack]);

  const handleFilterDataChange = (filterData: string) => setFilterDataState(filterData);
  const handlePageNumberChange = (pageNumber: number) => setPageNumberState(pageNumber);
  const handleRegistrationsFrom = (date: string) => setRegFrom(date);
  const handleRegistrationsTo = (date: string) => setRegTo(date);
  const handleLoginsFrom = (date: string) => setLogFrom(date);
  const handleLoginsTo = (date: string) => setLogTo(date);
  const handleUserTypeChange = (type: string) => setUserType(type);
  const handleCountryCode = (code: string) => setCountryCodeState(code);
  const handleSource = (source: string) => setSourceState(source);
  const handleType = (type: string) => setType(type);
  const handlePackageSelect = (pack: string) => setPricingPack(pack);
  const handlePackageIntervalSelect = (pack: string) => setPricingPackInterval(pack);

  const handleHexometerPricingPackage = (pack: string) => setHexometerPricingPackage(pack);
  const handleHexometerPricingPackageInterval = (int: string) => setHexometerPricingPackageInterval(int);
  const handleHexometerPricingPackageCount = (count: number) => setHexometerPricingPackageCount(count);

  const handleHexomatePricingPackage = (pack: string) => setHexomate_pricing_package(pack);
  const handleHexomatePricingPackageInterval = (int: string) => setHexomate_pricing_package_interval(int);
  const handleHexomatePricingPackageCount = (count: number) => setHexomate_pricing_package_count(count);

  const handleHexosparkPricingPackage = (pack: string) => setHexospark_pricing_package(pack);
  const handleHexosparkPricingPackageInterval = (int: string) => setHexospark_pricing_package_interval(int);
  const handleHexosparkPricingPackageCount = (count: number) => setHexospark_pricing_package_count(count);

  const handleHexofyPricingPackage = (pack: string) => setHexofy_pricing_package(pack);
  const handleHexofyPricingPackageInterval = (int: string) => setHexofy_pricing_package_interval(int);
  const handleHexofyPricingPackageCount = (count: number) => setHexofy_pricing_package_count(count);

  const handleHexoscopePricingPackage = (pack: string) => setHexoscope_pricing_package(pack);
  const handleHexoscopePricingPackageInterval = (int: string) => setHexoscope_pricing_package_interval(int);
  const handleHexoscopePricingPackageCount = (count: number) => setHexoscope_pricing_package_count(count);

  const handlePremiumPackage = (pack: string) => setPremium_credit_subscription(pack);
  const handlePremiumPackageInterval = (interval: string) =>
    setPremium_credit_subscription_interval(interval);

  return (
    <Fragment>
      <Grid.Row>
        <Grid.Col md={12} className="d-flex">
          <Form.Checkbox
            label="Filter Hexact team users"
            checked={filterHexUsers}
            onChange={setFilterHexUsers}
            disabled={loading}
          />
          {loading && <Loader className="loader-small" />}
        </Grid.Col>
      </Grid.Row>

      <Users
        data={userData}
        loading={loading}
        hexometerPricingPackName={HEXOMETER_PRICING_PACKAGES}
        hexomaticPricingPackName={HEXOMATE_PRICING_PACKAGE}
        hexowatchPricingPackName={watchPricingPacks}
        userPremiumPricingPackName={userPremiumPricingPackName}
        onPremiumPackage={handlePremiumPackage}
        onPremiumPackageInterval={handlePremiumPackageInterval}
        onFilterDataChange={handleFilterDataChange}
        onFilterRefererChange={onFilterRefererChange}
        onPageNumberChange={handlePageNumberChange}
        onRegistrationsFrom={handleRegistrationsFrom}
        onRegistrationsTo={handleRegistrationsTo}
        onLoginsFrom={handleLoginsFrom}
        onLoginsTo={handleLoginsTo}
        onCountryCode={handleCountryCode}
        onSourceSelect={handleSource}
        deleteUser={deleteUser}
        blockUser={blockUser}
        unblockUser={unblockUser}
        updateUser={updateUser}
        updateWatchConfig={updateWatchConfig}
        refetchData={refetchUsers}
        setOrderBy={setOrderBy}
        setOrderByASC={setOrderByASC}
        orderByASC={orderByASC}
        onUserTypeChange={handleUserTypeChange}
        onTypeSelect={handleType}
        onUserStatusChange={setUserStatus}
        onPackageSelect={handlePackageSelect}
        onPackageIntervalSelect={handlePackageIntervalSelect}
        onHexometerPackageSelect={handleHexometerPricingPackage}
        onHexometerPackageIntervalSelect={handleHexometerPricingPackageInterval}
        onHexometerPackageCount={handleHexometerPricingPackageCount}
        onHexomatePackageSelect={handleHexomatePricingPackage}
        onHexomatePackageIntervalSelect={handleHexomatePricingPackageInterval}
        onHexomatePackageCount={handleHexomatePricingPackageCount}
        onHexosparkPackageSelect={handleHexosparkPricingPackage}
        onHexosparkPackageIntervalSelect={handleHexosparkPricingPackageInterval}
        onHexosparkPackageCount={handleHexosparkPricingPackageCount}
        onHexofyPackageSelect={handleHexofyPricingPackage}
        onHexofyPackageIntervalSelect={handleHexofyPricingPackageInterval}
        onHexofyPackageCount={handleHexofyPricingPackageCount}
        onHexoscopePackageSelect={handleHexoscopePricingPackage}
        onHexoscopePackageIntervalSelect={handleHexoscopePricingPackageInterval}
        onHexoscopePackageCount={handleHexoscopePricingPackageCount}
        setSelectedIntegration={setSelectedIntegration}
        filterImpact={filterImpact}
        setFilterImpact={setFilterImpact}
        setIsPaidUser={setIsPaidUser}
        isPaidUser={isPaidUser}
        setShowPaymentInfo={setShowPaymentInfo}
        showPaymentInfo={showPaymentInfo}
        setTwofaType={setTwofaType}
        setTwofaStatus={setTwofaStatus}
        setShowApolloData={setShowApolloData}
        setShowApolloPeopleData={setShowApolloPeopleData}
        setShowOnlyInvited={setShowOnlyInvited}
        setLayer1={setLayer1}
        setLayer2={setLayer2}
        setIdEvenOrOdd={setIdEvenOrOdd}
        variables={{
          input: {
            regFrom: regFrom,
            regTo: regTo,
            pageNumber: pageNumberState,
            searchQuery: filterDataState,
          },
          active: false,
          logFrom: logFrom,
          logTo: logTo,
          orderBy,
          orderByASC,
          userType,
          countryCode: countryCodeState,
          source: sourceState,
          type,
          pricingPackage: !isPaidUser ? pricingPack : null,
          pricingPackageInterval: !isPaidUser ? pricingPackInterval : null,
          hexometer_pricing_package: !isPaidUser ? hexometer_pricing_package : null,
          hexometer_pricing_package_interval: !isPaidUser ? hexometer_pricing_package_interval : null,
          hexometer_pricing_package_count: !isPaidUser ? hexometer_pricing_package_count : null,

          hexosparkPricingPackage: !isPaidUser ? hexospark_pricing_package : null,
          hexosparkPricingPackageInterval: !isPaidUser ? hexospark_pricing_package_interval : null,
          hexosparkPricingPackageCount: !isPaidUser ? hexospark_pricing_package_count : null,

          hexofyPricingPackage: !isPaidUser ? hexofy_pricing_package : null,
          hexofyPricingPackageInterval: !isPaidUser ? hexofy_pricing_package_interval : null,
          hexofyPricingPackageCount: !isPaidUser ? hexofy_pricing_package_count : null,

          hexoscopePricingPackage: !isPaidUser ? hexoscope_pricing_package : null,
          hexoscopePricingPackageInterval: !isPaidUser ? hexoscope_pricing_package_interval : null,
          hexoscopePricingPackageCount: !isPaidUser ? hexoscope_pricing_package_count : null,

          hexomatic_pricing_package: !isPaidUser ? hexomate_pricing_package : null,
          hexomatic_pricing_package_interval: !isPaidUser ? hexomate_pricing_package_interval : null,
          hexomatic_pricing_package_count: !isPaidUser ? hexomate_pricing_package_count : null,

          referer_search_value: filterRefererChange,
          user_status: userStatus,
          premiumCreditSubscription: !isPaidUser ? premium_credit_subscription : null,
          premiumCreditSubscriptionInterval: !isPaidUser ? premium_credit_subscription_interval : null,
          integration: selectedIntegration,
          impactFilter: filterImpact,
          isPaidUser: isPaidUser,
          includePayments: showPaymentInfo,
          twofa_type: twofaType,
          twofa_status: twofaStatus,
          apollo_data: showApolloData,
          apollo_people_data: showApolloPeopleData,
          showOnlyInvited: showOnlyInvited || undefined,
          layer: layer1 && layer2 ? undefined : layer1 ? "first" : layer2 ? "second" : undefined,
          hideHex: filterHexUsers,
        }}
      />
    </Fragment>
  );
};

export default UsersQueries;
