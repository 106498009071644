import React, { useEffect, useState, FormEvent } from "react";
import { Grid, Button, Form, Icon, Alert } from "tabler-react";
import { ContentTextProps, UserProps, UsersProps } from "../../types";
import moment from "moment";
import ServerSidePagination from "../../components/pagination/serverSidePagination";
import TableCard from "../../components/tableCard";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import Chart from "../../components/chart";
import {
  // USERS_REGISTRATION_CHART,
  GET_USERS,
  // USERS_BLOCKAGE_CHART,
  // USERS_DELETION_CHART,
  GET_ACTIVE_USERS_TIME_SERIES,
  GET_NOT_ACTIVE_USERS_TIME_SERIES,
  GET_SUPER_USER_DATA,
  GET_IMPACT_ACTIONS,
  CHANGE_IS_PERFORMER,
  GET_USERS_REPORT_DOWNLOAD_STATUS,
} from "../../graphql/queries";

import DatePicker from "react-datepicker";
import { getCountArr, integrationOptions, isProduction, removeUserInfo } from "../../helpers";
import {
  watchPricingPacks,
  watchPricingPackIntervals,
  HEXOMETER_PRICING_PACKAGES,
  HEXOMETER_PRICING_PACKAGE_INTERVALS,
  HEXOMATE_PRICING_PACKAGE,
  HEXOMATE_PRICING_PACKAGE_INTERVAL,
  HEXOSPARK_PRICING_PACKAGE_INTERVALS,
  HEXOSPARK_PRICING_PACKAGES,
  PREMIUM_CREDITS_INTERVAL,
  HEXOFY_PRICING_PACKAGE_INTERVALS,
  HEXOFY_PRICING_PACKAGES,
  HEXOSCOPE_PRICING_PACKAGE_INTERVALS,
  HEXOSCOPE_PRICING_PACKAGES,
} from "../../constants";
import UsersModal from "./usersModal";
import ImpactModal from "./impactModal";
import workerIcon from "../../image/worker.svg";
import "./index.css";
import { shareIcon } from "../../image/svgIcons";
import ShareAccountModal from "./shareAccountModal";
import impactImg from "../../image/impact.png";

const Users = ({
  deleteUser,
  blockUser,
  refetchData,
  unblockUser,
  updateUser,
  updateWatchConfig,
  ...props
}: UsersProps): JSX.Element => {
  const [baseUsers, setBaseUsers] = useState<Array<UserProps>>([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [userPagination, setUserPagination] = useState<Array<UserProps>>([]);
  const [filterInput, setFilterInput] = useState("");
  const [filterReferer, setFilterReferer] = useState("");
  const [total, setTotal] = useState(0);
  const [registrationsFrom, setRegistrationsFrom] = useState(null);
  const [registrationsTo, setRegistrationsTo] = useState(null);
  const [loginsFrom, setLoginsFrom] = useState(null);
  const [loginsTo, setLoginsTo] = useState(null);
  const [userType, setUserType] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [mutationCompleted, setMutationCompleted] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [source, setSource] = useState("");
  const [type, setType] = useState("");
  const [selectPackage, setSelectPackage] = useState("");
  const [selectPackageInterval, setSelectPackageInterval] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isHexomaticUser, setIsHexomaticUser] = useState(false);
  const [isHexosparkUser, setIsHexosparkUser] = useState(false);
  const [isHexofyUser, setIsHexofyUser] = useState(false);
  const [isHexowatchUser, setIsHexowatchUser] = useState(false);
  const [editUser, setEditUser] = useState<any>();
  const [userStatus, setUserStatus] = useState("");
  const [superUserId, setSuperUserId] = useState(null);
  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const showButtons = localStorage.getItem("_rep");

  //! Hexometer

  const [selectHexometerPackage, setHexometerPackage] = useState("");
  const [selectHexometerPackageInterval, setHexometerPackageInterval] = useState("");
  const [selectHexometerPackageCount, setHexometerPackageCount] = useState("");

  //! Hexospark

  const [selectHexosparkPackage, setHexosparkPackage] = useState("");
  const [selectHexosparkPackageInterval, setHexosparkPackageInterval] = useState("");
  const [selectHexosparkPackageCount, setHexosparkPackageCount] = useState("");

  //! Hexofy

  const [selectHexofyPackage, setHexofyPackage] = useState("");
  const [selectHexofyPackageInterval, setHexofyPackageInterval] = useState("");
  const [selectHexofyPackageCount, setHexofyPackageCount] = useState("");

  //! Hexoscope

  const [selectHexoscopePackage, setHexoscopePackage] = useState("");
  const [selectHexoscopePackageInterval, setHexoscopePackageInterval] = useState("");
  const [selectHexoscopePackageCount, setHexoscopePackageCount] = useState("");

  const [selectHexomatePackage, setHexomatePackage] = useState("");
  const [selectHexomatePackageInterval, setHexomatePackageInterval] = useState("");
  const [selectHexomatePackageCount, setHexomatePackageCount] = useState("");
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);

  const [filterImpact, setFilterImpact] = useState(false);
  const [showImpactModal, setShowImpactModal] = useState(false);
  const [impactData, setImpactData] = useState<any>(null);
  const [isPaidUser, setIsPaidUser] = useState(false);
  const [showPaymentInfo, setShowPaymentInfo] = useState(false);
  const [showApolloData, setShowApolloData] = useState(false);
  const [showApolloPeopleData, setShowApolloPeopleData] = useState(false);
  const [showOnlyInvited, setShowOnlyInvited] = useState(false);
  const [layer1, setLayer1] = useState(true);
  const [layer2, setLayer2] = useState(true);

  const [twofaType, setTwofaType] = useState<string>("all");
  const [twofaStatus, setTwofaStatus] = useState<string>("all");

  const [premiumCredits, setPremiumCredits] = useState("");
  const [idEvenOrOdd, setIdEvenOrOdd] = useState<"even" | "odd" | "">("");

  const [premiumCreditsInterval, setPremiumCreditsInterval] = useState("");

  const [showShareAccountModal, setShowShareAccountModal] = useState({ show: false, id: null, email: "" });

  const [csvStatusDataInterval, setCsvStatusDataInterval] = useState(0);
  const [csvUrl, setCsvUrl] = useState("");
  const [googleDriveLoading, setGoogleDriveLoading] = useState(false);
  const [csvLoading, setCsvLoading] = useState(false);

  const { data: csvStatusData, loading: csvStatusDataLoading } = useQuery(GET_USERS_REPORT_DOWNLOAD_STATUS, {
    pollInterval: csvStatusDataInterval,
  });

  const [getImpactQuery, { loading: impactLoading, data: impactDataFrom }] = useLazyQuery(GET_IMPACT_ACTIONS);

  const [getCSV, { data, loading }] = useLazyQuery(GET_USERS, {
    onError() {
      setCsvStatusDataInterval(10000);
      setCsvLoading(true);
    },
  });

  const [uploadToDrive, { data: driveData, loading: driveDataLoading }] = useLazyQuery(GET_USERS, {
    onError() {
      setCsvStatusDataInterval(10000);
      setGoogleDriveLoading(true);
    },
  });
  const [changePerformer, { loading: performerLoading }] = useMutation(CHANGE_IS_PERFORMER);

  // GET_ACTIVE_USERS_TIME_SERIES
  // const chartDataQuery = useQuery(USERS_REGISTRATION_CHART, {
  //   fetchPolicy: "network-only",
  //   notifyOnNetworkStatusChange: true
  // });
  // const blockedUsersChartQuery = useQuery(USERS_BLOCKAGE_CHART, {
  //   fetchPolicy: "network-only",
  //   notifyOnNetworkStatusChange: true
  // });
  // const deletedUsersChartQuery = useQuery(USERS_DELETION_CHART, {
  //   fetchPolicy: "network-only",
  //   notifyOnNetworkStatusChange: true
  // })

  useEffect(() => {
    const status =
      csvStatusData &&
      csvStatusData.User &&
      csvStatusData.User.getCsvUrlInfo &&
      csvStatusData.User.getCsvUrlInfo.message;
    const csvUrl =
      csvStatusData &&
      csvStatusData.User &&
      csvStatusData.User.getCsvUrlInfo &&
      csvStatusData.User.getCsvUrlInfo.csvURL;
    const isDrive =
      csvStatusData &&
      csvStatusData.User &&
      csvStatusData.User.getCsvUrlInfo &&
      csvStatusData.User.getCsvUrlInfo.googleDrive;

    if (status === "pending") {
      setCsvStatusDataInterval(10000);
      isDrive ? setGoogleDriveLoading(true) : setCsvLoading(true);
    } else {
      setCsvStatusDataInterval(0);
      setGoogleDriveLoading(false);
      setCsvLoading(false);
    }
    if (csvUrl && !isDrive) {
      setCsvUrl(csvUrl);
      setCsvStatusDataInterval(0);
      setCsvLoading(false);
    } else if (csvUrl && isDrive) {
      window.open(
        isProduction()
          ? `https://api.hexometer.com/v2/app/social-callback/googleDrive?admin=admin&report=users&url=${csvUrl}`
          : `http://localhost:4000/api/app/social-callback/googleDrive?admin=admin&report=users&url=${csvUrl}`,
        "_blank"
      );
      setGoogleDriveLoading(false);
      setCsvStatusDataInterval(0);
    }
  }, [csvStatusData]);

  const [toggle, setToggle] = useState(true);
  const [fetchLoading, setFetchLoading] = useState(false);

  const [getSuperUserData, { data: superUserData, loading: superDataLoading }] =
    useLazyQuery(GET_SUPER_USER_DATA);

  const activeUsersChartQuery = useQuery(GET_ACTIVE_USERS_TIME_SERIES, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (impactDataFrom && impactDataFrom.User && impactDataFrom.User.getCustomerImpactActions) {
      setImpactData(impactDataFrom.User.getCustomerImpactActions);
      setShowImpactModal(true);
    }
  }, [impactDataFrom]);

  const getUserImpactActions = (id: any) => {
    getImpactQuery({
      variables: {
        id: id,
      },
    });
  };

  const NotActiveUsersChartQuery = useQuery(GET_NOT_ACTIVE_USERS_TIME_SERIES, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const isVerified = ["all"];

  const tableData = {
    tData:
      baseUsers &&
      baseUsers.map((item: UserProps) => {
        if (props.data.User.getMany.includePayments) {
          return {
            id: `${item.id}`,
            Name: `${item.firstName} ${item.lastName}`,
            Email: item.email,
            phone: item.phone || "N/A",
            "PRPT count": item.hexometer_properties_count,
            Date: moment(item.createdAt).utc().format("DD-MM-YYYY hh:mm:ss a"),
            "Login Source": item.loginSource,
            "2FA": (item.twofa && item.twofa.status && item.twofa.type) || "N/A",
            "Inviter id": item.inviter_id || "N/A",
            "Invitee layer": item.layer || "N/A",
            impact: item.impact_data ? (
              <Button
                color="grey"
                loading={impactLoading}
                onClick={() => {
                  getUserImpactActions(item.id);
                }}
              >
                <img src={impactImg} className="hexomatic-dash-icon" alt="impact" />
              </Button>
            ) : (
              "Other"
            ),
            // Impact: item.impact_data
            //   ? item.impact_data.click_id
            //     ? item.impact_data.click_id
            //     : item.impact_data.media_partner_id
            //   : "Other",
            Stripe: +(item.netSpentStripe || 0).toFixed(2),
            "Stripe refunds": +(item.netRefundedStripe || 0).toFixed(2),
            "AppSumo codes": +(item.netAppsumoCodes || 0).toFixed(2),
            "AppSumo codes refunded": +(item.netAppsumoCodesRefund || 0).toFixed(2),
            "Net Paypal spent": +(item.netSpentPaypal || 0).toFixed(2),
            "Net Paypal refunded": +(item.netRefundedPaypal || 0).toFixed(2),
            "Net refunded": +(item.netRefund || 0).toFixed(2),
            "Stripe balance": +(item.stripeBalance || 0).toFixed(2),
            "Net paid": +(item.netPaid || 0).toFixed(2), //(((item.netSpentStripe || 0) + +(item.netAppsumoCodes || 0))).toFixed(2),
            Referer: item.referer && item.referer.document_referrer ? item.referer.document_referrer : "N/A",
            "Country/Ip": item.countryCode && item.countryCode !== "N/A" ? item.countryCode : item.ip,
            "DB location": item.db_location || "N/A",
            CITY: item.detectedCountry ? item.detectedCountry : "N/A",
            Verified: item.verified ? (
              <div className="verified-user">
                <Icon name="user-check" />
              </div>
            ) : (
              <div className="not-verified-user">
                <Icon name="user-x" />
              </div>
            ),
            "Last Login": item.lastLogin
              ? moment(item.lastLogin).utc().format("DD-MM-YYYY hh:mm:ss a")
              : "N/A",
            Active: item.active ? "Yes" : "No",
            "Hexowatch Plan":
              item.pricingPackage &&
              item.pricingPackageInterval &&
              `${item.pricingPackage}_${item.pricingPackageInterval}${
                item.watch_payment_type ? "_" + item.watch_payment_type[0].toUpperCase() : ""
              }`,
            "Additional Scans":
              item.config && item.config.additional_scans_count ? item.config.additional_scans_count : 0,
            "Hexometer Plan": (() => {
              if (
                item.hexometer_pricing_package &&
                item.hexometer_pricing_package_interval &&
                item.hexometer_pricing_package_count
              ) {
                return (
                  `${item.hexometer_pricing_package}_${item.hexometer_pricing_package_interval}` +
                  `(${item.hexometer_pricing_package_count})${
                    item.hm_payment_type ? "_" + item.hm_payment_type[0].toUpperCase() : ""
                  }`
                );
              } else {
                return "";
              }
            })(),
            "Hexomatic Plan": (() => {
              if (
                item.hexomatic_pricing_package &&
                item.hexomatic_pricing_package_interval &&
                item.hexomatic_pricing_package_count
              ) {
                return (
                  `${item.hexomatic_pricing_package}_${item.hexomatic_pricing_package_interval}` +
                  `(${item.hexomatic_pricing_package_count})${
                    item.hc_payment_type ? "_" + item.hc_payment_type[0].toUpperCase() : ""
                  }`
                );
              } else {
                return "";
              }
            })(),
            "Hexospark Plan": (() => {
              if (
                item.hexospark_pricing_package &&
                item.hexospark_pricing_package_interval &&
                item.hexospark_pricing_package_count
              ) {
                return (
                  `${item.hexospark_pricing_package}_${item.hexospark_pricing_package_interval}` +
                  `(${item.hexospark_pricing_package_count})${
                    item.hs_payment_type ? "_" + item.hs_payment_type[0].toUpperCase() : ""
                  }`
                );
              } else {
                return "";
              }
            })(),
            "Hexofy Plan": (() => {
              if (
                item.hexofy_pricing_package &&
                item.hexofy_pricing_package_interval &&
                item.hexofy_pricing_package_count
              ) {
                return (
                  `${item.hexofy_pricing_package}_${item.hexofy_pricing_package_interval}` +
                  `(${item.hexofy_pricing_package_count})${
                    item.hf_payment_type ? "_" + item.hf_payment_type[0].toUpperCase() : ""
                  }`
                );
              } else {
                return "";
              }
            })(),
            "Hexoscope Plan": (() => {
              if (
                item.hexoscope_pricing_package &&
                item.hexoscope_pricing_package_interval &&
                item.hexoscope_pricing_package_count
              ) {
                return (
                  `${item.hexoscope_pricing_package}_${item.hexoscope_pricing_package_interval}` +
                  `(${item.hexoscope_pricing_package_count})${
                    item.hsc_payment_type ? "_" + item.hsc_payment_type[0].toUpperCase() : ""
                  }`
                );
              } else {
                return "";
              }
            })(),
            "CREDITS PLAN": `${item.premium_credits_pricing_package}_${
              item.premium_credits_pricing_package_interval
            }${item.pc_payment_type ? "_" + item.pc_payment_type[0].toUpperCase() : ""}`,
            "BONUS CREDITS": (
              <div style={{ display: "flex", alignItems: "center" }}>
                <span style={{ marginRight: "10px" }}>{item.left_one_time_premium_credits}</span>
                {/* <div>
                  <Button
                    color="primary"
                    loading={
                      isHexomaticUser &&
                      item.id === superUserId &&
                      (superDataLoading || fetchLoading)
                    }
                    onClick={() => {
                      // setSuperUserId(item.id);
                      setEditUser(item);
                      setShowAddBonusModal(true);
                    }}
                  >
                    +
                  </Button> */}
                {/* </div> */}
              </div>
            ),
            "Should Be Deleted":
              item.shouldBeDeletedAt &&
              moment(parseInt(item.shouldBeDeletedAt)).utc().format("DD-MM-YYYY hh:mm:ss a"),
            "Block / Unblock": (
              <Button.List style={{ display: "flex" }}>
                <Button
                  color="secondary"
                  loading={isHexosparkUser && item.id === superUserId && (superDataLoading || fetchLoading)}
                  onClick={() => {
                    setSuperUserId(item.id);
                    getSuperUserData({ variables: { toggle: !toggle } });
                    setToggle(!toggle);
                    setIsHexosparkUser(true);
                    setIsHexomaticUser(false);
                    setIsHexowatchUser(false);
                  }}
                >
                  <img
                    src="https://hexospark.com/favicon.ico"
                    className="hexospark-dash-icon"
                    alt="hexospark"
                    style={{ width: "20px", height: "auto" }}
                  />
                </Button>

                <Button
                  color="secondary"
                  loading={isHexomaticUser && item.id === superUserId && (superDataLoading || fetchLoading)}
                  onClick={() => {
                    setSuperUserId(item.id);
                    getSuperUserData({ variables: { toggle: !toggle } });
                    setToggle(!toggle);
                    setIsHexomaticUser(true);
                    setIsHexowatchUser(false);
                    setIsHexosparkUser(false);
                  }}
                >
                  <img
                    src="https://hexomatic.com/favicon.ico"
                    className="hexomatic-dash-icon"
                    alt="hexomatic"
                    style={{ width: "20px", height: "auto" }}
                  />
                </Button>

                <Button
                  color="secondary"
                  loading={isHexowatchUser && item.id === superUserId && (superDataLoading || fetchLoading)}
                  onClick={() => {
                    setSuperUserId(item.id);
                    getSuperUserData({ variables: { toggle: !toggle } });
                    setToggle(!toggle);
                    setIsHexowatchUser(true);
                    setIsHexomaticUser(false);
                    setIsHexosparkUser(false);
                  }}
                >
                  <img
                    src="https://hexowatch.com/favicon.ico"
                    className="hexomatic-dash-icon"
                    alt="hexomatic"
                    style={{ width: "20px", height: "auto" }}
                  />
                </Button>
                <Button
                  color="secondary"
                  loading={
                    !isHexomaticUser &&
                    !isHexowatchUser &&
                    !isHexosparkUser &&
                    item.id === superUserId &&
                    (superDataLoading || fetchLoading)
                  }
                  onClick={() => {
                    setSuperUserId(item.id);
                    getSuperUserData({ variables: { toggle: !toggle } });
                    setToggle(!toggle);
                    setIsHexomaticUser(false);
                    setIsHexowatchUser(false);
                    setIsHexosparkUser(false);
                  }}
                >
                  <img
                    src="https://hexometer.com/favicon.ico"
                    className="hexomatic-dash-icon"
                    alt="hexomatic"
                    style={{ width: "20px", height: "auto" }}
                  />
                </Button>
                <Button color="primary" icon="edit" onClick={() => handleUserMutate(item)} />
                {typeof item.isPerformer === "boolean" && (
                  <Button onClick={() => handlePerformer(item)} color={item.isPerformer ? "green" : "red"}>
                    <img src={workerIcon} alt="performer" />
                  </Button>
                )}
                <Button
                  color={item.active ? "grey" : "red"}
                  icon={item.active ? "lock" : "unlock"}
                  onClick={() => (item.active ? handleUserBlock(item) : handleUserUnblock(item))}
                />

                {item.shouldBeDeletedAt && (
                  <Button
                    disabled={
                      new Date(parseInt(item.shouldBeDeletedAt)).setUTCHours(0, 0, 0, 0) >
                      new Date().setUTCHours(0, 0, 0, 0)
                    }
                    color="danger"
                    icon="trash"
                    onClick={() => handleUserDelete(item)}
                  />
                )}
                <Button
                  className="d-flex align-items-center justify-content-center"
                  color="primary"
                  onClick={() => setShowShareAccountModal({ show: true, id: +item.id, email: item.email })}
                >
                  {shareIcon}
                </Button>
              </Button.List>
            ),
          };
        } else {
          return {
            id: `${item.id}`,
            Name: `${item.firstName} ${item.lastName}`,
            Email: item.email,
            phone: item.phone || "N/A",
            "PRPT count": item.hexometer_properties_count,
            Date: moment(item.createdAt).utc().format("DD-MM-YYYY hh:mm:ss a"),
            "Login Source": item.loginSource,
            "2FA": (item.twofa && item.twofa.status && item.twofa.type) || "N/A",
            "Inviter id": item.inviter_id || "N/A",
            "Invitee layer": item.layer || "N/A",
            impact: item.impact_data ? (
              <Button
                color="grey"
                loading={impactLoading}
                onClick={() => {
                  getUserImpactActions(item.id);
                }}
              >
                <img src={impactImg} className="hexomatic-dash-icon" alt="impact" />
              </Button>
            ) : (
              "Other"
            ),
            // Impact: item.impact_data
            //   ? item.impact_data.click_id
            //     ? item.impact_data.click_id
            //     : item.impact_data.media_partner_id
            //   : "Other",
            Referer: item.referer && item.referer.document_referrer ? item.referer.document_referrer : "N/A",
            "Country/Ip": item.countryCode && item.countryCode !== "N/A" ? item.countryCode : item.ip,
            "DB location": item.db_location || "N/A",
            CITY: item.detectedCountry ? item.detectedCountry : "N/A",
            Verified: item.verified ? (
              <div className="verified-user">
                <Icon name="user-check" />
              </div>
            ) : (
              <div className="not-verified-user">
                <Icon name="user-x" />
              </div>
            ),
            "Last Login": item.lastLogin
              ? moment(item.lastLogin).utc().format("DD-MM-YYYY hh:mm:ss a")
              : "N/A",
            Active: item.active ? "Yes" : "No",
            "Hexowatch Plan":
              item.pricingPackage &&
              item.pricingPackageInterval &&
              `${item.pricingPackage}_${item.pricingPackageInterval}${
                item.watch_payment_type ? "_" + item.watch_payment_type[0].toUpperCase() : ""
              }`,
            "Additional Scans":
              item.config && item.config.additional_scans_count ? item.config.additional_scans_count : 0,
            "Hexometer Plan": (() => {
              if (
                item.hexometer_pricing_package &&
                item.hexometer_pricing_package_interval &&
                item.hexometer_pricing_package_count
              ) {
                return (
                  `${item.hexometer_pricing_package}_${item.hexometer_pricing_package_interval}` +
                  `(${item.hexometer_pricing_package_count})${
                    item.hm_payment_type ? "_" + item.hm_payment_type[0].toUpperCase() : ""
                  }`
                );
              } else {
                return "";
              }
            })(),
            "Hexomatic Plan": (() => {
              if (
                item.hexomatic_pricing_package &&
                item.hexomatic_pricing_package_interval &&
                item.hexomatic_pricing_package_count
              ) {
                return (
                  `${item.hexomatic_pricing_package}_${item.hexomatic_pricing_package_interval}` +
                  `(${item.hexomatic_pricing_package_count})${
                    item.hc_payment_type ? "_" + item.hc_payment_type[0].toUpperCase() : ""
                  }`
                );
              } else {
                return "";
              }
            })(),
            "Hexospark Plan": (() => {
              if (
                item.hexospark_pricing_package &&
                item.hexospark_pricing_package_interval &&
                item.hexospark_pricing_package_count
              ) {
                return (
                  `${item.hexospark_pricing_package}_${item.hexospark_pricing_package_interval}` +
                  `(${item.hexospark_pricing_package_count})${
                    item.hs_payment_type ? "_" + item.hs_payment_type[0].toUpperCase() : ""
                  }`
                );
              } else {
                return "";
              }
            })(),
            "Hexofy Plan": (() => {
              if (
                item.hexofy_pricing_package &&
                item.hexofy_pricing_package_interval &&
                item.hexofy_pricing_package_count
              ) {
                return (
                  `${item.hexofy_pricing_package}_${item.hexofy_pricing_package_interval}` +
                  `(${item.hexofy_pricing_package_count})${
                    item.hf_payment_type ? "_" + item.hf_payment_type[0].toUpperCase() : ""
                  }`
                );
              } else {
                return "";
              }
            })(),
            "Hexoscope Plan": (() => {
              if (
                item.hexoscope_pricing_package &&
                item.hexoscope_pricing_package_interval &&
                item.hexoscope_pricing_package_count
              ) {
                return (
                  `${item.hexoscope_pricing_package}_${item.hexoscope_pricing_package_interval}` +
                  `(${item.hexoscope_pricing_package_count})${
                    item.hsc_payment_type ? "_" + item.hsc_payment_type[0].toUpperCase() : ""
                  }`
                );
              } else {
                return "";
              }
            })(),
            "CREDITS PLAN": `${item.premium_credits_pricing_package}_${
              item.premium_credits_pricing_package_interval
            }${item.pc_payment_type ? "_" + item.pc_payment_type[0].toUpperCase() : ""}`,
            "BONUS CREDITS": (
              <div style={{ display: "flex", alignItems: "center" }}>
                <span style={{ marginRight: "10px" }}>{item.left_one_time_premium_credits}</span>
                {/* <div>
                  <Button
                    color="primary"
                    loading={
                      isHexomaticUser &&
                      item.id === superUserId &&
                      (superDataLoading || fetchLoading)
                    }
                    onClick={() => {
                      // setSuperUserId(item.id);
                      setEditUser(item);
                      setShowAddBonusModal(true);
                    }}
                  >
                    +
                  </Button> */}
                {/* </div> */}
              </div>
            ),
            "Should Be Deleted":
              item.shouldBeDeletedAt &&
              moment(parseInt(item.shouldBeDeletedAt)).utc().format("DD-MM-YYYY hh:mm:ss a"),
            "Block / Unblock": (
              <Button.List style={{ display: "flex" }}>
                <Button
                  color="secondary"
                  loading={isHexosparkUser && item.id === superUserId && (superDataLoading || fetchLoading)}
                  onClick={() => {
                    setSuperUserId(item.id);
                    getSuperUserData({ variables: { toggle: !toggle } });
                    setToggle(!toggle);
                    setIsHexosparkUser(true);
                    setIsHexomaticUser(false);
                    setIsHexowatchUser(false);
                  }}
                >
                  <img
                    src="https://hexospark.com/favicon.ico"
                    className="hexospark-dash-icon"
                    alt="hexospark"
                    style={{ width: "20px", height: "auto" }}
                  />
                </Button>
                <Button
                  color="secondary"
                  loading={isHexomaticUser && item.id === superUserId && (superDataLoading || fetchLoading)}
                  onClick={() => {
                    setSuperUserId(item.id);
                    getSuperUserData({ variables: { toggle: !toggle } });
                    setToggle(!toggle);
                    setIsHexomaticUser(true);
                    setIsHexosparkUser(false);
                    setIsHexowatchUser(false);
                  }}
                >
                  <img
                    src="https://hexomatic.com/favicon.ico"
                    className="hexomatic-dash-icon"
                    alt="hexomatic"
                    style={{ width: "20px", height: "auto" }}
                  />
                </Button>
                <Button
                  color="secondary"
                  loading={isHexowatchUser && item.id === superUserId && (superDataLoading || fetchLoading)}
                  onClick={() => {
                    setSuperUserId(item.id);
                    getSuperUserData({ variables: { toggle: !toggle } });
                    setToggle(!toggle);
                    setIsHexowatchUser(true);
                    setIsHexomaticUser(false);
                    setIsHexosparkUser(false);
                  }}
                >
                  <img
                    src="https://hexowatch.com/favicon.ico"
                    className="hexomatic-dash-icon"
                    alt="hexomatic"
                    style={{ width: "20px", height: "auto" }}
                  />
                </Button>
                <Button
                  color="secondary"
                  loading={
                    !isHexomaticUser &&
                    !isHexowatchUser &&
                    !isHexosparkUser &&
                    item.id === superUserId &&
                    (superDataLoading || fetchLoading)
                  }
                  onClick={() => {
                    setSuperUserId(item.id);
                    getSuperUserData({ variables: { toggle: !toggle } });
                    setToggle(!toggle);
                    setIsHexomaticUser(false);
                    setIsHexowatchUser(false);
                    setIsHexosparkUser(false);
                  }}
                >
                  <img
                    src="https://hexometer.com/favicon.ico"
                    className="hexomatic-dash-icon"
                    alt="hexomatic"
                    style={{ width: "20px", height: "auto" }}
                  />
                </Button>
                <Button color="primary" icon="edit" onClick={() => handleUserMutate(item)} />
                {typeof item.isPerformer === "boolean" && (
                  <Button onClick={() => handlePerformer(item)} color={item.isPerformer ? "green" : "red"}>
                    <img src={workerIcon} alt="performer" />
                  </Button>
                )}
                <Button
                  color={item.active ? "grey" : "red"}
                  icon={item.active ? "lock" : "unlock"}
                  onClick={() => (item.active ? handleUserBlock(item) : handleUserUnblock(item))}
                />

                {item.shouldBeDeletedAt && (
                  <Button
                    disabled={
                      new Date(parseInt(item.shouldBeDeletedAt)).setUTCHours(0, 0, 0, 0) >
                      new Date().setUTCHours(0, 0, 0, 0)
                    }
                    color="danger"
                    icon="trash"
                    onClick={() => handleUserDelete(item)}
                  />
                )}
                <Button
                  className="d-flex align-items-center justify-content-center"
                  color="primary"
                  onClick={() => setShowShareAccountModal({ show: true, id: +item.id, email: item.email })}
                >
                  {shareIcon}
                </Button>
              </Button.List>
            ),
          };
        }
      }),
  };

  const redirectToDashboard = async (token: string, isHexomaticUser: boolean, isHexosparkUser: boolean) => {
    if (isHexomaticUser) {
      window.open(
        window.location.host === "37.186.119.181:3277"
          ? `http://37.186.119.181:3278/ZGFzaC1yZWRpcmVjdA==/?token=${token}&user_id=${+superUserId}`
          : window.location.host === "localhost:3277"
          ? `http://localhost:3278/ZGFzaC1yZWRpcmVjdA==/?token=${token}&user_id=${+superUserId}`
          : `https://hexomatic.com/ZGFzaC1yZWRpcmVjdA==/?token=${token}&user_id=${+superUserId}`,
        "_blank"
      );
      return;
    }

    if (isHexosparkUser) {
      window.open(
        window.location.host === "37.186.119.181:3277"
          ? `http://37.186.119.181:3282/ZGFzaC1yZWRpcmVjdA==/?token=${token}&user_id=${+superUserId}`
          : window.location.host === "localhost:3277"
          ? `http://localhost:3282/ZGFzaC1yZWRpcmVjdA==/?token=${token}&user_id=${+superUserId}`
          : `https://hexospark.com/ZGFzaC1yZWRpcmVjdA==/?token=${token}&user_id=${+superUserId}`,
        "_blank"
      );
      return;
    }

    setFetchLoading(true);
    const newObj = JSON.stringify({
      token: token,
      user_id: +superUserId,
      productType: "hexometer",
    });

    const inputUrl =
      window.location.host === "37.186.119.181:3277"
        ? "http://37.186.119.181:4000/api/app/super_user_auth_LXbkXE4TGn"
        : "https://api.hexometer.com/v2/app/super_user_auth_LXbkXE4TGn";
    const openUrl =
      window.location.host === "37.186.119.181:3277"
        ? `http://37.186.119.181:3276/${isHexowatchUser ? "hexowatch" : "properties"}`
        : `https://dash.hexometer.com/${isHexowatchUser ? "hexowatch" : "properties"}`;

    fetch(inputUrl, {
      redirect: "follow",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: "POST",
      body: newObj,
    })
      .then(() => {
        setFetchLoading(false);
        window.open(openUrl, "_blank");
      })
      .catch(({ error }) => {
        setFetchLoading(false);
      });
  };

  useEffect(() => {
    if (superUserData && superUserData.Admin && superUserData.Admin) {
      const { error, message, token } = superUserData.Admin.getSuperUserData;
      if (error) {
        setErrorMessage(message);
        return;
      }
      removeUserInfo();
      redirectToDashboard(token, isHexomaticUser, isHexosparkUser).catch((err) => setErrorMessage(err));
    }
  }, [superUserData, isHexomaticUser, isHexosparkUser]);

  useEffect(() => {
    if (props.data && props.data.User && props.data.User.getMany && props.data.User.getMany.users) {
      setDataLoading(props.loading);
      setBaseUsers(props.data.User.getMany.users);
      setUserPagination(props.data.User.getMany.users);
      setTotal(props.data.User.getMany.total);
    }
  }, [props.data]);

  useEffect(() => {
    if (driveData && driveData.User && driveData.User.getMany && driveData.User.getMany.csvURL) {
      window.open(
        isProduction()
          ? `https://api.hexometer.com/v2/app/social-callback/googleDrive?admin=admin&report=users&url=${driveData.User.getMany.csvURL}`
          : `http://localhost:4000/api/app/social-callback/googleDrive?admin=admin&report=users&url=${driveData.User.getMany.csvURL}`,
        "_blank"
      );
      setGoogleDriveLoading(false);
      setCsvStatusDataInterval(0);
    }
  }, [driveData]);

  useEffect(() => {
    if (data && data.User && data.User.getMany && data.User.getMany.csvURL) {
      setCsvStatusDataInterval(0);
      setCsvLoading(false);
    }
  }, [data]);

  const handleInputChange = (e: FormEvent) => setFilterInput((e.target as HTMLInputElement).value);

  const handleCountryCode = (e: FormEvent) => setCountryCode((e.target as HTMLInputElement).value);

  const handleReferer = (e: FormEvent) => setFilterReferer((e.target as HTMLInputElement).value);

  const handleSelectUsersType = (e: FormEvent) => setUserType((e.target as HTMLInputElement).value);

  const handleSelectUsersStatus = (e: FormEvent) => {
    if ((e.target as HTMLInputElement).value === "All") {
      setUserStatus(undefined);
      return;
    }
    setUserStatus((e.target as HTMLInputElement).value);
  };

  const handleSelectSource = (e: FormEvent) => setSource((e.target as HTMLInputElement).value);

  const handleSelectType = (e: FormEvent) => setType((e.target as HTMLInputElement).value);

  const handleSelectPricingPack = (e: FormEvent) => setSelectPackage((e.target as HTMLInputElement).value);

  const handleSelectPricingPackInterval = (e: FormEvent) =>
    setSelectPackageInterval((e.target as HTMLInputElement).value);

  //! Hexometer

  const handleHexometerSelectPricingPack = (e: FormEvent) =>
    setHexometerPackage((e.target as HTMLInputElement).value);

  const handleHexometerSelectPricingPackInterval = (e: FormEvent) =>
    setHexometerPackageInterval((e.target as HTMLInputElement).value);

  const handleHexometerSelectPricingPackCount = (e: FormEvent) =>
    setHexometerPackageCount((e.target as HTMLInputElement).value);

  //! Hexospark

  const handleHexosparkSelectPricingPack = (e: FormEvent) =>
    setHexosparkPackage((e.target as HTMLInputElement).value);

  const handleHexosparkSelectPricingPackInterval = (e: FormEvent) =>
    setHexosparkPackageInterval((e.target as HTMLInputElement).value);

  const handleHexosparkSelectPricingPackCount = (e: FormEvent) =>
    setHexosparkPackageCount((e.target as HTMLInputElement).value);

  //! Hexofy

  const handleHexofySelectPricingPack = (e: FormEvent) =>
    setHexofyPackage((e.target as HTMLInputElement).value);

  const handleHexofySelectPricingPackInterval = (e: FormEvent) =>
    setHexofyPackageInterval((e.target as HTMLInputElement).value);

  const handleHexofySelectPricingPackCount = (e: FormEvent) =>
    setHexofyPackageCount((e.target as HTMLInputElement).value);

  //! Hexoscope

  const handleHexoscopeSelectPricingPack = (e: FormEvent) =>
    setHexoscopePackage((e.target as HTMLInputElement).value);

  const handleHexoscopeSelectPricingPackInterval = (e: FormEvent) =>
    setHexoscopePackageInterval((e.target as HTMLInputElement).value);

  const handleHexoscopeSelectPricingPackCount = (e: FormEvent) =>
    setHexoscopePackageCount((e.target as HTMLInputElement).value);

  //! Hexomate

  const handleHexomateSelectPricingPack = (e: FormEvent) =>
    setHexomatePackage((e.target as HTMLInputElement).value);

  const handleHexomateSelectPricingPackInterval = (e: FormEvent) =>
    setHexomatePackageInterval((e.target as HTMLInputElement).value);

  const handleHexomateSelectPricingPackCount = (e: FormEvent) =>
    setHexomatePackageCount((e.target as HTMLInputElement).value);

  const handlePremiumCredits = (e: FormEvent) => setPremiumCredits((e.target as HTMLInputElement).value);
  const handlePremiumCreditsInterval = (e: FormEvent) =>
    setPremiumCreditsInterval((e.target as HTMLInputElement).value);

  const handleRegistrationsFrom = (date: Date) => {
    if (date) {
      return setRegistrationsFrom(moment(date).format("YYYY-MM-DDT00:00:00.000"));
    }
    return setRegistrationsFrom(null);
  };

  const handleRegistrationsTo = (date: Date) => {
    if (date) {
      return setRegistrationsTo(moment(date).format("YYYY-MM-DDT23:59:59.999"));
    }
    return setRegistrationsTo(null);
  };

  const handleLoginFrom = (date: Date) => {
    if (date) {
      return setLoginsFrom(moment(date).format("YYYY-MM-DDT00:00:00.000"));
    }
    return setLoginsFrom(null);
  };

  const handleLoginTo = (date: Date) => {
    if (date) {
      return setLoginsTo(moment(date).format("YYYY-MM-DDT23:59:59.999"));
    }
    return setLoginsTo(null);
  };

  const handleSubmit = (
    event: FormEvent,
    data: string,
    userType: string,
    userSelect: string,
    regFrom: string,
    regTo: string,
    logFrom: string,
    logTo: string,
    ctrCode: string,
    src: string,
    type: string,
    packageName: string,
    packageInterval: string,
    selectHexometerPackage: string,
    selectHexometerPackageInterval: string,
    selectHexometerPackageCount: string,
    selectHexomatePackage: string,
    selectHexomatePackageInterval: string,
    selectHexomatePackageCount: string,
    filterReferer: string,
    twofaStatus: string | null,
    twofaType: string | null,
    selectHexosparkPackage: string,
    selectHexosparkPackageInterval: string,
    selectHexosparkPackageCount: string,
    premiumCredits: string | null,
    premiumCreditsInterval: string | null,
    idEvenOrOdd: "" | "even" | "odd",
    selectHexofyPackage: string,
    selectHexofyPackageInterval: string,
    selectHexofyPackageCount: string,
    selectHexoscopePackage: string,
    selectHexoscopePackageInterval: string,
    selectHexoscopePackageCount: string
  ) => {
    event.preventDefault();
    props.onFilterDataChange(data.trim());
    props.onFilterRefererChange(filterReferer);
    props.onRegistrationsFrom(regFrom);
    props.onRegistrationsTo(regTo);
    props.onLoginsFrom(logFrom);
    props.onLoginsTo(logTo);
    props.onUserTypeChange(userType);
    props.onUserStatusChange(userSelect);
    props.onCountryCode(ctrCode);
    props.setFilterImpact(filterImpact);
    props.setIsPaidUser(isPaidUser);
    props.setShowPaymentInfo(showPaymentInfo);
    props.setShowApolloData(showApolloData);
    props.setShowApolloPeopleData(showApolloPeopleData);
    props.setShowOnlyInvited(showOnlyInvited);
    props.setLayer1(layer1);
    props.setLayer2(layer2);
    props.setIdEvenOrOdd(idEvenOrOdd);

    if (premiumCredits === "all") {
      props.onPremiumPackage("");
    } else {
      props.onPremiumPackage(premiumCredits);
    }
    if (premiumCreditsInterval === "all") {
      props.onPremiumPackageInterval("");
    } else {
      props.onPremiumPackageInterval(premiumCreditsInterval);
    }

    if (src === "all") {
      props.onSourceSelect("");
    } else {
      props.onSourceSelect(src);
    }
    if (type === "all") {
      props.onTypeSelect("");
    } else {
      props.onTypeSelect(type);
    }

    if (packageName === "all") {
      props.onPackageSelect("");
    } else {
      props.onPackageSelect(packageName);
    }

    if (packageInterval === "all") {
      props.onPackageIntervalSelect("");
    } else {
      props.onPackageIntervalSelect(packageInterval);
    }

    if (selectHexometerPackage === "all") {
      props.onHexometerPackageSelect("");
    } else {
      props.onHexometerPackageSelect(selectHexometerPackage);
    }

    if (selectHexometerPackageInterval === "all") {
      props.onHexometerPackageIntervalSelect("");
    } else {
      props.onHexometerPackageIntervalSelect(selectHexometerPackageInterval);
    }

    const converted_to_number = parseInt(selectHexometerPackageCount);
    if (!isNaN(converted_to_number)) {
      props.onHexometerPackageCount(converted_to_number);
    }

    if (selectHexomatePackage === "all") {
      props.onHexomatePackageSelect("");
    } else {
      props.onHexomatePackageSelect(selectHexomatePackage);
    }

    if (selectHexomatePackageInterval === "all") {
      props.onHexomatePackageIntervalSelect("");
    } else {
      props.onHexomatePackageIntervalSelect(selectHexomatePackageInterval);
    }

    if (selectHexosparkPackage === "all") {
      props.onHexosparkPackageSelect("");
    } else {
      props.onHexosparkPackageSelect(selectHexosparkPackage);
    }

    if (selectHexosparkPackageInterval === "all") {
      props.onHexosparkPackageIntervalSelect("");
    } else {
      props.onHexosparkPackageIntervalSelect(selectHexosparkPackageInterval);
    }

    const hexosparkCountNum = parseInt(selectHexosparkPackageCount);
    if (!isNaN(hexosparkCountNum)) {
      props.onHexosparkPackageCount(hexosparkCountNum);
    }

    if (selectHexofyPackage === "all") {
      props.onHexofyPackageSelect("");
    } else {
      props.onHexofyPackageSelect(selectHexofyPackage);
    }

    if (selectHexofyPackageInterval === "all") {
      props.onHexofyPackageIntervalSelect("");
    } else {
      props.onHexofyPackageIntervalSelect(selectHexofyPackageInterval);
    }

    const hexofyCountNum = parseInt(selectHexofyPackageCount);
    if (!isNaN(hexofyCountNum)) {
      props.onHexofyPackageCount(hexofyCountNum);
    }

    if (selectHexoscopePackage === "all") {
      props.onHexoscopePackageSelect("");
    } else {
      props.onHexoscopePackageSelect(selectHexoscopePackage);
    }

    if (selectHexoscopePackageInterval === "all") {
      props.onHexoscopePackageIntervalSelect("");
    } else {
      props.onHexoscopePackageIntervalSelect(selectHexoscopePackageInterval);
    }

    const hexoscopeCountNum = parseInt(selectHexoscopePackageCount);
    if (!isNaN(hexoscopeCountNum)) {
      props.onHexoscopePackageCount(hexoscopeCountNum);
    }

    if (twofaType === "all") {
      props.setTwofaType(null);
    } else {
      props.setTwofaType(twofaType);
    }

    if (twofaStatus === "all") {
      props.setTwofaStatus(null);
    } else {
      props.setTwofaStatus(twofaStatus === "enabled" ? true : false);
    }

    const converted_to_number2 = parseInt(selectHexomatePackageCount);
    if (!isNaN(converted_to_number2)) {
      props.onHexomatePackageCount(converted_to_number2);
    }

    if (selectedIntegration && selectedIntegration.toLowerCase() === "all") {
      props.setSelectedIntegration(null);
    } else {
      props.setSelectedIntegration(selectedIntegration);
    }
  };

  const handleUserBlock = async (row: ContentTextProps) => {
    const blockConfirm = confirm("Are you sure you want to block User?");
    if (blockConfirm) {
      const blockResponse = await blockUser({
        variables: { id: row.id },
      });
      const error = blockResponse.data.UserOps.block.error;
      const message = blockResponse.data.UserOps.block.message;
      if (error) {
        setErrorMessage(message);
      } else {
        setMutationCompleted(true);
        refetchData();
      }
    }
  };

  const handleUserUnblock = async (row: ContentTextProps) => {
    const unblockConfirm = confirm("Are you sure you want to unblock User?");
    if (unblockConfirm) {
      const unblockResponse = await unblockUser({
        variables: { id: row.id },
      });
      const error = unblockResponse.data.UserOps.unblock.error;
      const message = unblockResponse.data.UserOps.unblock.message;
      if (error) {
        setErrorMessage(message);
      } else {
        setMutationCompleted(true);
        refetchData();
      }
    }
  };

  const handleUserDelete = async (row: ContentTextProps) => {
    const deleteConfirm = confirm("Are you sure you want to delete User?");
    if (deleteConfirm) {
      const deleteResponse = await deleteUser({
        variables: { id: row.id },
      });

      const error = deleteResponse.data.UserOps.delete.error;
      const message = deleteResponse.data.UserOps.delete.message;
      if (error) {
        setErrorMessage(message);
      } else {
        setMutationCompleted(true);
        refetchData();
      }
    }
  };

  const handleSortingClick = (column: string) => {
    if (column === "Last Login") {
      props.setOrderBy("lastLogin");
      props.setOrderByASC(!props.orderByASC);
    }

    if (column === "Registration Date") {
      props.setOrderBy("createdAt");
      props.setOrderByASC(!props.orderByASC);
    }

    if (column === "Should Be Deleted At") {
      props.setOrderBy("shouldBeDeletedAt");
      props.setOrderByASC(!props.orderByASC);
    }
  };

  const handleModal = () => {
    if (showModal) {
      setEditUser(null);
      setErrorMessage("");
    }
    setShowModal(!showModal);
  };

  const handleSave = async ({ ...rest }) => {
    let sendData: any = {
      id: rest.id,
      email: rest.email,
    };
    if (rest.pricingPackage) {
      sendData.pricingPackage = rest.pricingPackage;
      sendData.pricingPackageInterval = rest.pricingPackageInterval;
    }
    if (rest.updateHexometerPackage) {
      sendData.hexometer_pricing_package = rest.hexometer_pricing_package;
      sendData.hexometer_pricing_package_interval = rest.hexometer_pricing_package_interval;
      sendData.hexometer_pricing_package_count = parseInt(rest.hexometer_pricing_package_count);
    }
    if (rest.updateHexomatePackage) {
      sendData.hexomate_pricing_package = rest.hexomate_pricing_package;
      sendData.hexomate_pricing_package_interval = rest.hexomate_pricing_package_interval;
      sendData.hexomate_pricing_package_count = parseInt(rest.hexomate_pricing_package_count);
    }

    if (editUser) {
      let updateResponse: any = null;
      let updateWatchConfigResponse: any = null;

      if (rest.updateConfig) {
        updateWatchConfigResponse = await updateWatchConfig({
          variables: {
            user_id: +rest.id,
            additional_scans_count: rest.additionalScansCount,
          },
        });
      }
      if (updateResponse || updateWatchConfigResponse) {
        await refetchData();
        const error =
          (updateResponse && updateResponse.data.UserOps.adminUpdate.error) ||
          (updateWatchConfigResponse &&
            updateWatchConfigResponse.data.UserWatchSettingsOps.adminUpdate.error);
        const message =
          (updateResponse && updateResponse.data.UserOps.adminUpdate.message) ||
          (updateWatchConfigResponse &&
            updateWatchConfigResponse.data.UserWatchSettingsOps.adminUpdate.message);
        if (error) {
          setErrorMessage(message);
          return false;
        } else {
          setMutationCompleted(true);
        }
      }
    }
    return true;
  };

  const handleUserMutate = (row: object | null) => {
    setShowModal(true);
    setEditUser(row);
  };
  const handlePerformer = async (row: any) => {
    //if (performerLoading) return;
    const res: any = await changePerformer({
      variables: {
        userId: +row.id,
        enabled: !row.isPerformer,
      },
    });
    if (
      res &&
      res.data &&
      res.data.HexomaticHumanValidationsTasksOps &&
      res.data.HexomaticHumanValidationsTasksOps.enableUserToPerformHumanValidationTask &&
      !res.data.HexomaticHumanValidationsTasksOps.enableUserToPerformHumanValidationTask.error
    ) {
      refetchData();
    }
  };

  return (
    <Grid>
      <Grid.Row>
        <div className="col-12 col-md-8">
          {/* {!chartDataQuery.loading &&
            chartDataQuery.data &&
            chartDataQuery.data.User &&
            chartDataQuery.data.User.registrationChart &&
            !blockedUsersChartQuery.loading &&
            blockedUsersChartQuery.data &&
            blockedUsersChartQuery.data.User &&
            blockedUsersChartQuery.data.User.blockageChart &&
            !deletedUsersChartQuery.loading &&
            deletedUsersChartQuery.data &&
            deletedUsersChartQuery.data.User &&
            deletedUsersChartQuery.data.User.deletionChart &&
            <Chart
              yColName="Registered Users"
              yColName2="Blocked Users"
              yColName3="Deleted Users"
              regData={chartDataQuery.data.User.registrationChart}
              regData2={blockedUsersChartQuery.data.User.blockageChart}
              regData3={deletedUsersChartQuery.data.User.deletionChart}
              chartName="Users activity"
            />
          } */}

          {!activeUsersChartQuery.loading &&
            activeUsersChartQuery.data &&
            activeUsersChartQuery.data.Admin &&
            activeUsersChartQuery.data.Admin.getActiveUsersTimeSeries &&
            !NotActiveUsersChartQuery.loading &&
            NotActiveUsersChartQuery.data &&
            NotActiveUsersChartQuery.data.Admin &&
            NotActiveUsersChartQuery.data.Admin.getNotActiveUsersTimeSeries && (
              <Chart
                yColName="Active Users"
                yColName2="Other Users"
                regData={activeUsersChartQuery.data.Admin.getActiveUsersTimeSeries.time_series}
                regData2={NotActiveUsersChartQuery.data.Admin.getNotActiveUsersTimeSeries.time_series}
                interval={getCountArr(
                  activeUsersChartQuery.data.Admin.getActiveUsersTimeSeries.time_series.length
                )}
                chartName="Users activity"
              />
            )}
        </div>
        <div className="col-12 col-md-4">
          <form
            onSubmit={(e) =>
              handleSubmit(
                e,
                filterInput,
                userType,
                userStatus,
                registrationsFrom,
                registrationsTo,
                loginsFrom,
                loginsTo,
                countryCode,
                source,
                type,
                selectPackage,
                selectPackageInterval,
                selectHexometerPackage,
                selectHexometerPackageInterval,
                selectHexometerPackageCount,
                selectHexomatePackage,
                selectHexomatePackageInterval,
                selectHexomatePackageCount,
                filterReferer,
                twofaStatus,
                twofaType,
                selectHexosparkPackage,
                selectHexosparkPackageInterval,
                selectHexosparkPackageCount,
                premiumCredits,
                premiumCreditsInterval,
                idEvenOrOdd,
                selectHexofyPackage,
                selectHexofyPackageInterval,
                selectHexofyPackageCount,
                selectHexoscopePackage,
                selectHexoscopePackageInterval,
                selectHexoscopePackageCount
              )
            }
          >
            <Form.Group>
              <Form.Select onChange={handleSelectType} label="Select Type">
                <option>all</option>
                <option>hexometer</option>
                <option>hexowatch</option>
                <option>hexomatic</option>
                <option>hexospark</option>
                <option>hexofy</option>
                <option>hexoscope</option>
              </Form.Select>
              <Form.Input
                label="Input"
                className="margin-bottom"
                icon="search"
                position="append"
                placeholder="Search for name, email, phone number"
                value={filterInput}
                onChange={handleInputChange}
              />
              <Form.Input
                label="Country Code"
                className="margin-bottom"
                icon="search"
                position="append"
                placeholder="Search for country code"
                value={countryCode}
                onChange={handleCountryCode}
              />
              <Form.Select onChange={handleSelectUsersType} label="Select Users">
                {isVerified.map((el) => (
                  <option key={el} value={el}>
                    {el}
                  </option>
                ))}
                <option>Scheduled to Delete_TODAY</option>
                <option>Scheduled to Delete</option>
                <option>With Hexometer Property</option>
                <option value="Without Hexometer Property">Without Hexometer Property</option>
                {/* <option>With Hexometer Paid Property</option>
                <option>With Hexometer Paid Property Without PromoCode</option>
                <option
                  value="
                 PromoCode"
                >
                  With Hexometer PromoCode
                </option>
                <option>With Hexometer Only Free Property</option> */}
                <option>With Hexowatch Property</option>
                <option>Without Hexomatic</option>
              </Form.Select>
              <Form.Select onChange={handleSelectSource} label="Select Source">
                <option>all</option>
                <option>google</option>
                <option>website</option>
                <option>invited</option>
              </Form.Select>
              <Form.Select onChange={handleSelectUsersStatus} label="User status">
                <option value={undefined}>All</option>
                <option value={"VERIFIED"}>verified</option>
                <option value={"NOT_VERIFIED"}>not verified</option>
                <option value={"BLOCKED"}>blocked</option>
                <option value={"ACTIVE"}>active</option>
              </Form.Select>
              <div className="d-flex flex-wrap">
                <div className="d-flex mr-4">
                  <span style={{ marginRight: "5px" }}>{"Any paid"}</span>
                  <Form.Checkbox label=" " checked={isPaidUser} onChange={() => setIsPaidUser(!isPaidUser)} />
                </div>
                <div className="d-flex mr-4">
                  <span style={{ marginRight: "5px" }}>{"Show payment info"}</span>
                  <Form.Checkbox
                    label=" "
                    checked={showPaymentInfo}
                    onChange={() => setShowPaymentInfo(!showPaymentInfo)}
                  />
                </div>
                <div className="d-flex mr-4">
                  <span style={{ marginRight: "5px" }}>{"Show only Invited"}</span>
                  <Form.Checkbox
                    label=" "
                    checked={showOnlyInvited}
                    onChange={() => setShowOnlyInvited(!showOnlyInvited)}
                  />
                </div>
                {showOnlyInvited && (
                  <>
                    <div className="d-flex mr-4">
                      <span style={{ marginRight: "5px" }}>{"Layer First"}</span>
                      <Form.Checkbox label=" " checked={layer1} onChange={() => setLayer1(!layer1)} />
                    </div>
                    <div className="d-flex mr-4">
                      <span style={{ marginRight: "5px" }}>{"Layer Second"}</span>
                      <Form.Checkbox label=" " checked={layer2} onChange={() => setLayer2(!layer2)} />
                    </div>
                  </>
                )}
                <div className="d-flex mr-4">
                  <span style={{ marginRight: "5px" }}>{"Apollo data"}</span>
                  <Form.Checkbox
                    label=" "
                    checked={showApolloData}
                    onChange={() => setShowApolloData(!showApolloData)}
                  />
                </div>
                <div className="d-flex">
                  <span style={{ marginRight: "5px" }}>{"Apollo people data"}</span>
                  <Form.Checkbox
                    label=" "
                    checked={showApolloPeopleData}
                    onChange={() => setShowApolloPeopleData(!showApolloPeopleData)}
                  />
                </div>
              </div>
              {showAdvancedSettings && (
                <>
                  <Form.Input
                    label="Referer"
                    className="margin-bottom"
                    icon="search"
                    position="append"
                    placeholder="Search for referer"
                    value={filterReferer}
                    onChange={handleReferer}
                  />

                  <Form.Select onChange={(e) => setSelectedIntegration(e.target.value)} label="Integrations">
                    <option value={undefined}>All</option>
                    {integrationOptions.map((item) => (
                      <option key={item.name} value={item.value}>
                        {item.name}
                      </option>
                    ))}

                    {/* <option value={'discord'}>discord</option>
                <option value={'email'}>email</option>
                <option value={'ACTIVE'}>trello</option>
                <option value={'ACTIVE'}>telegram</option>
                <option value={'sms'}>sms</option> */}
                  </Form.Select>
                  <Grid.Row>
                    <Grid.Col>
                      <Form.Select
                        onChange={(e) => setIdEvenOrOdd(e.target.value)}
                        label="Filter user by odd or even id"
                      >
                        <option value={""}>All</option>
                        <option value={"odd"}>Odd</option>
                        <option value={"even"}>Even</option>
                      </Form.Select>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col>
                      <Form.Select onChange={(e) => setTwofaStatus(e.target.value)} label="2FA status">
                        <option value={"all"}>All</option>
                        <option value={"enabled"}>enabled</option>
                        <option value={"disabled"}>disabled</option>
                      </Form.Select>
                    </Grid.Col>
                    <Grid.Col>
                      <Form.Select onChange={(e) => setTwofaType(e.target.value)} label="2FA type">
                        <option value={"all"}>All</option>
                        <option value={"app"}>app</option>
                        <option value={"email"}>email</option>
                      </Form.Select>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col>
                      <Form.Select onChange={handlePremiumCredits} label="Premium credits">
                        <option value="all">all</option>
                        {props.userPremiumPricingPackName.map(
                          (pack: { name: string; interval: string; value: string; id: string }) => (
                            <option key={pack.name} value={pack.name}>
                              {pack.value}
                            </option>
                          )
                        )}
                      </Form.Select>
                    </Grid.Col>

                    <Grid.Col>
                      <Form.Select onChange={handlePremiumCreditsInterval} label="Premium credits Interval">
                        <option value="all">all</option>
                        {PREMIUM_CREDITS_INTERVAL.filter((item) => item !== "").map((pack) => (
                          <option key={pack} value={pack}>
                            {pack}
                          </option>
                        ))}
                      </Form.Select>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col>
                      <Form.Select onChange={handleSelectPricingPack} label="Hexowatch Package">
                        <option value="all">all</option>
                        {watchPricingPacks.map((pack) => (
                          <option key={pack} value={pack}>
                            {pack}
                          </option>
                        ))}
                      </Form.Select>
                    </Grid.Col>
                    <Grid.Col>
                      <Form.Select
                        onChange={handleSelectPricingPackInterval}
                        label="Hexowatch Package Interval"
                      >
                        <option value="all">all</option>
                        {watchPricingPackIntervals.map((pack) => (
                          <option key={pack} value={pack}>
                            {pack}
                          </option>
                        ))}
                      </Form.Select>
                    </Grid.Col>
                  </Grid.Row>
                  {/* //! ------------------------  Hexomatic ------------------------ */}
                  <Grid.Row>
                    <Grid.Col>
                      <Form.Select onChange={handleHexomateSelectPricingPack} label="Hexomatic Package">
                        <option value="all">all</option>
                        {HEXOMATE_PRICING_PACKAGE.map((pack) => (
                          <option key={pack} value={pack}>
                            {pack}
                          </option>
                        ))}
                      </Form.Select>
                    </Grid.Col>
                    <Grid.Col>
                      <Form.Select
                        onChange={handleHexomateSelectPricingPackInterval}
                        label="Hexomatic Package Interval"
                      >
                        <option value="all">all</option>
                        {HEXOMATE_PRICING_PACKAGE_INTERVAL.filter((item) => item !== "").map((pack) => (
                          <option key={pack} value={pack}>
                            {pack}
                          </option>
                        ))}
                      </Form.Select>
                    </Grid.Col>
                    <Grid.Col>
                      <Form.Input
                        label="Hexomatic Package Count"
                        placeholder="Count"
                        value={selectHexomatePackageCount}
                        onChange={handleHexomateSelectPricingPackCount}
                      />
                    </Grid.Col>
                  </Grid.Row>
                  {/* //! ------------------------  Hexometer ------------------------ */}

                  <Grid.Row>
                    <Grid.Col>
                      <Form.Select onChange={handleHexometerSelectPricingPack} label="Hexometer Package">
                        <option value="all">all</option>
                        {HEXOMETER_PRICING_PACKAGES.map((pack) => (
                          <option key={pack} value={pack}>
                            {pack}
                          </option>
                        ))}
                      </Form.Select>
                    </Grid.Col>
                    <Grid.Col>
                      <Form.Select
                        onChange={handleHexometerSelectPricingPackInterval}
                        label="Hexometer Package Interval"
                      >
                        <option value="all">all</option>
                        {HEXOMETER_PRICING_PACKAGE_INTERVALS.map((pack) => (
                          <option key={pack} value={pack}>
                            {pack}
                          </option>
                        ))}
                      </Form.Select>
                    </Grid.Col>
                    <Grid.Col>
                      <Form.Input
                        label="Hexometer Package Count"
                        placeholder="Count"
                        value={selectHexometerPackageCount}
                        onChange={handleHexometerSelectPricingPackCount}
                      />
                    </Grid.Col>
                  </Grid.Row>
                  {/* //! ------------------------  Hexospark ------------------------ */}

                  <Grid.Row>
                    <Grid.Col>
                      <Form.Select onChange={handleHexosparkSelectPricingPack} label="Hexospark Package">
                        <option value="all">all</option>
                        {HEXOSPARK_PRICING_PACKAGES.map((pack) => (
                          <option key={pack} value={pack}>
                            {pack}
                          </option>
                        ))}
                      </Form.Select>
                    </Grid.Col>
                    <Grid.Col>
                      <Form.Select
                        onChange={handleHexosparkSelectPricingPackInterval}
                        label="Hexospark Package Interval"
                      >
                        <option value="all">all</option>
                        {HEXOSPARK_PRICING_PACKAGE_INTERVALS.map((pack) => (
                          <option key={pack} value={pack}>
                            {pack}
                          </option>
                        ))}
                      </Form.Select>
                    </Grid.Col>
                    <Grid.Col>
                      <Form.Input
                        label="Hexospark Package Count"
                        placeholder="Count"
                        value={selectHexosparkPackageCount}
                        onChange={handleHexosparkSelectPricingPackCount}
                      />
                    </Grid.Col>
                  </Grid.Row>

                  {/* //! ------------------------  Hexofy ------------------------ */}

                  <Grid.Row>
                    <Grid.Col>
                      <Form.Select onChange={handleHexofySelectPricingPack} label="Hexofy Package">
                        <option value="all">all</option>
                        {HEXOFY_PRICING_PACKAGES.map((pack) => (
                          <option key={pack} value={pack}>
                            {pack}
                          </option>
                        ))}
                      </Form.Select>
                    </Grid.Col>
                    <Grid.Col>
                      <Form.Select
                        onChange={handleHexofySelectPricingPackInterval}
                        label="Hexofy Package Interval"
                      >
                        <option value="all">all</option>
                        {HEXOFY_PRICING_PACKAGE_INTERVALS.map((pack) => (
                          <option key={pack} value={pack}>
                            {pack}
                          </option>
                        ))}
                      </Form.Select>
                    </Grid.Col>
                    <Grid.Col>
                      <Form.Input
                        label="Hexofy Package Count"
                        placeholder="Count"
                        value={selectHexofyPackageCount}
                        onChange={handleHexofySelectPricingPackCount}
                      />
                    </Grid.Col>
                  </Grid.Row>

                  {/* //! ------------------------  Hexoscope ------------------------ */}

                  <Grid.Row>
                    <Grid.Col>
                      <Form.Select onChange={handleHexoscopeSelectPricingPack} label="Hexoscope Package">
                        <option value="all">all</option>
                        {HEXOSCOPE_PRICING_PACKAGES.map((pack) => (
                          <option key={pack} value={pack}>
                            {pack}
                          </option>
                        ))}
                      </Form.Select>
                    </Grid.Col>
                    <Grid.Col>
                      <Form.Select
                        onChange={handleHexoscopeSelectPricingPackInterval}
                        label="Hexoscope Package Interval"
                      >
                        <option value="all">all</option>
                        {HEXOSCOPE_PRICING_PACKAGE_INTERVALS.map((pack) => (
                          <option key={pack} value={pack}>
                            {pack}
                          </option>
                        ))}
                      </Form.Select>
                    </Grid.Col>
                    <Grid.Col>
                      <Form.Input
                        label="Hexoscope Package Count"
                        placeholder="Count"
                        value={selectHexoscopePackageCount}
                        onChange={handleHexoscopeSelectPricingPackCount}
                      />
                    </Grid.Col>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Col>
                      <Form.Group label="Registrations From">
                        <DatePicker
                          dateFormat="yyyy-MM-dd"
                          selected={registrationsFrom ? new Date(registrationsFrom) : registrationsFrom}
                          onChange={handleRegistrationsFrom}
                          showMonthDropdown
                          placeholderText="Select a date"
                        />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col>
                      <Form.Group label="Registrations To">
                        <DatePicker
                          dateFormat="yyyy-MM-dd"
                          selected={registrationsTo ? new Date(registrationsTo) : registrationsTo}
                          onChange={handleRegistrationsTo}
                          showMonthDropdown
                          placeholderText="Select a date"
                          minDate={registrationsFrom ? new Date(registrationsFrom) : null}
                        />
                      </Form.Group>
                    </Grid.Col>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Col>
                      <Form.Group label="Logins From">
                        <DatePicker
                          dateFormat="yyyy-MM-dd"
                          selected={loginsFrom ? new Date(loginsFrom) : loginsFrom}
                          onChange={handleLoginFrom}
                          showMonthDropdown
                          placeholderText="Select a date"
                        />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col>
                      <Form.Group label="Logins To">
                        <DatePicker
                          dateFormat="yyyy-MM-dd"
                          selected={loginsTo ? new Date(loginsTo) : loginsTo}
                          onChange={handleLoginTo}
                          showMonthDropdown
                          placeholderText="Select a date"
                          minDate={loginsFrom ? new Date(loginsFrom) : null}
                        />
                      </Form.Group>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col md={12}>
                      <Form.Checkbox
                        label="Impact ID"
                        name="example-radios"
                        value="option1"
                        checked={filterImpact}
                        onChange={() => setFilterImpact(!filterImpact)}
                      />
                    </Grid.Col>
                  </Grid.Row>
                </>
              )}
              <Button type="submit" icon="filter" color="primary" className="" loading={dataLoading}>
                Filter
              </Button>{" "}
              <Button
                type="submit"
                color="primary"
                className=""
                onClick={() => setShowAdvancedSettings(!showAdvancedSettings)}
              >
                {showAdvancedSettings ? "Hide" : "Show"} advanced settings
              </Button>
            </Form.Group>
          </form>
        </div>
      </Grid.Row>
      <Grid.Row className={showButtons === "true" ? "justify-content-between" : "justify-content-end"}>
        {showButtons === "true" && (
          <div className="col-12 col-md-3 col-xs-12">
            <Button.List>
              <Button
                onClick={() =>
                  getCSV({
                    variables: { settings: { ...props.variables, csvDownload: true } },
                  })
                }
                loading={loading || csvLoading}
                color="primary"
                mr={1}
              >
                Get CSV Link
              </Button>
              <Button
                onClick={() =>
                  uploadToDrive({
                    variables: { settings: { ...props.variables, csvDownload: true } },
                  })
                }
                loading={driveDataLoading || googleDriveLoading}
                color="primary"
              >
                Save to Drive
              </Button>
            </Button.List>
            {!loading &&
              !csvStatusDataLoading &&
              !csvStatusDataLoading &&
              ((data && data.User && data.User.getMany && data.User.getMany.csvURL) || csvUrl) && (
                <Button
                  RootComponent="a"
                  href={(data && data.User && data.User.getMany && data.User.getMany.csvURL) || csvUrl}
                  icon="download"
                >
                  Download CSV
                </Button>
              )}
            <br />
          </div>
        )}
        <Grid.Col md={3}>
          <Alert type="info">Result Count - {total}</Alert>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row cards deck className="user-table">
        {showModal && (
          <UsersModal
            handleModal={handleModal}
            showModal={showModal}
            onSave={handleSave}
            user={editUser}
            errorMessage={errorMessage}
            refetchData={refetchData}
          />
        )}

        {showImpactModal && (
          <ImpactModal
            handleModal={() => setShowImpactModal(false)}
            showModal={showImpactModal}
            errorMessage={errorMessage}
            data={impactData || []}
          />
        )}
        {showShareAccountModal.show && (
          <ShareAccountModal
            handleModal={() => setShowShareAccountModal({ show: false, id: null, email: "" })}
            showModal={showShareAccountModal.show}
            owner_email={showShareAccountModal.email}
            id={showShareAccountModal.id}
          />
        )}
        {/* <AddBonusModal
          handleModal={() => setShowAddBonusModal(false)}
          showModal={showAddBonusModal}
          refetchData={refetchData}
          user={editUser}
          errorMessage={errorMessage}
        /> */}

        <ServerSidePagination data={userPagination} total={total} onPageNum={props.onPageNumberChange}>
          <TableCard
            loading={dataLoading}
            data={baseUsers}
            tableData={tableData}
            onHeaderClick={handleSortingClick}
          />
        </ServerSidePagination>
      </Grid.Row>
      <Grid.Row className="justify-content-end">
        {total ? (
          <Grid.Col md={3}>
            <Alert type="info">Result Count - {total}</Alert>
          </Grid.Col>
        ) : null}
      </Grid.Row>
    </Grid>
  );
};

export default Users;
