import React, { useState, FC } from "react";
import { useQuery } from "@apollo/react-hooks";
import { GET_HEXOSPARK_CAMPAIGNS } from "../../graphql/queries";
import HexosparkCampaigns from "./campaigns.queries";

const HexosparkCampaignsQueries: FC = () => {
  const [orderByASC, setOrderByASC] = useState(false);
  const [filterHexScans, setFilterHexScans] = useState(true);

  const [pageNumberState, setPageNumberState] = useState(1);

  const [searchVal, setSearchVal] = useState("");
  const [dbValue, setDBValue] = useState("US");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [orderBy, setOrderBy] = useState("");

  const { data, loading, refetch } = useQuery(GET_HEXOSPARK_CAMPAIGNS, {
    variables: {
      settings: {
        dbLocation: dbValue,
        from: fromDate,
        to: toDate,
        accountSearchInput: searchVal,
        page: pageNumberState,
        limit: 100,
        sortField: orderBy,
        sortDir: orderByASC ? "ASC" : "DESC",
        hideHex: filterHexScans,
      },
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const handlePageNumberChange = (pageNumber: number) => setPageNumberState(pageNumber);
  const handleSearchValue = (arg: string) => setSearchVal(arg);
  const handleDBSelect = (arg: string) => setDBValue(arg);

  const handleFromDate = (date: string) => setFromDate(date);
  const handleToDate = (date: string) => setToDate(date);

  return (
    <HexosparkCampaigns
      data={data}
      loading={loading}
      onPageNumberChange={handlePageNumberChange}
      refetchData={refetch}
      onFilterSearch={handleSearchValue}
      onDBSelect={handleDBSelect}
      onFromDate={handleFromDate}
      onToDate={handleToDate}
      setOrderByASC={setOrderByASC}
      orderByASC={orderByASC}
      setOrderBy={setOrderBy}
      setFilterHexScans={setFilterHexScans}
      filterHexScans={filterHexScans}
      variables={{
        settings: {
          settings: {
            dbLocation: dbValue,
            from: fromDate,
            to: toDate,
            senderEmailSearchInput: searchVal,
            page: pageNumberState,
            limit: 100,
            sortField: orderBy,
            sortDir: orderByASC ? "ASC" : "DESC",
          },
        },
      }}
    />
  );
};

export default HexosparkCampaignsQueries;
