import React, {
  useEffect,
  useState,
  FormEvent,
  ReactComponentElement,
  FC,
} from "react";
import { Grid, Button, Form, Alert } from "tabler-react";
import { ContentTextProps, PropertiesProps } from "../../types";
import moment from "moment";
import ServerSidePagination from "../../components/pagination/serverSidePagination";
import TableCard from "../../components/tableCard";
import TableModal from "./modal";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import Chart from "../../components/chart";
import {
  PROPERTY_SUBSCRIPTIONS_CHART,
  PROPERTIES_DELETION_CHART,
  GET_PROPERTIES,
} from "../../graphql/queries";
import DatePicker from "react-datepicker";
import { pricingPacks } from "../../constants";
import TaskModal from "./taskModal";
import { getCountArr, isProduction } from "../../helpers";

import "./index.css";

const Properties = ({
  deleteProperty,
  updateProperty,
  refetchData,
  createAITask,
  rescanProperty,
  ...props
}: PropertiesProps): JSX.Element => {
  const [baseProperties, setBaseProperties] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [propertyPagination, setPropertyPagination] = useState([]);
  const [filterInput, setFilterInput] = useState("");
  const [total, setTotal] = useState(0);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [mutationCompleted, setMutationCompleted] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [editProperty, setEditProperty] = useState<any>();
  const [selectPackage, setSelectPackage] = useState("");
  const [propertyStatus, setPropertyStatus] = useState("");
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [minCountState, setMinCountState] = useState("");
  const [maxCountState, setMaxCountState] = useState("");
  const [taskTypeState, setTaskTypeState] = useState("Select tasktype");
  const [countryCode, setCountryCode] = useState("");
  const [shared, setShared] = useState("");
  const [techStack, setFilterTechStack] = useState("");
  const showButtons = localStorage.getItem("_rep");

  const [getCSV, { data, loading }] = useLazyQuery(GET_PROPERTIES);
  const [uploadToDrive, { data: driveData, loading: driveDataLoading }] =
    useLazyQuery(GET_PROPERTIES);

  const chartDataQuery = useQuery(PROPERTY_SUBSCRIPTIONS_CHART, {
    variables: { hidePropertiesFromHex: props.filterHexProperties },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const deletedPropertiesChartQuery = useQuery(PROPERTIES_DELETION_CHART, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const handleModal = () => {
    if (showModal) {
      setEditProperty(null);
      setErrorMessage("");
    }
    setShowModal(!showModal);
  };

  const handleSave = async ({ ...rest }) => {
    if (editProperty) {
      const updateResponse = await updateProperty({
        variables: {
          id: rest.id,
          name: rest.name,
          address: rest.address,
          hostname: rest.hostname,
          paused: rest.paused,
        },
      });

      const error = updateResponse.data.PropertyOps.adminUpdate.error;
      const message = updateResponse.data.PropertyOps.adminUpdate.message;
      if (error) {
        setErrorMessage(message);
        return false;
      } else {
        refetchData();
        setMutationCompleted(true);
      }
    }
    return true;
  };

  const handlePropertyMutate = (row: object | null) => {
    setShowModal(true);
    setEditProperty(row);
  };

  const handleCreateAITask = (row: object) => {
    setShowTaskModal(true);
    setEditProperty(row);
  };

  const handleRescanProperty = async (row: ContentTextProps) => {
    let rescanConfirm = confirm("Are you sure you want to rescan Property?");
    if (rescanConfirm) {
      const rescanRespone = await rescanProperty({
        variables: {
          property_id: row.id && +row.id,
        },
      });
      const error = rescanRespone.data.AdminOps.rescanProperty.error;
      const message = rescanRespone.data.AdminOps.rescanProperty.message;
      if (error) {
        alert(message);
      } else {
        setMutationCompleted(true);
        refetchData();
      }
    }
  };

  const handleTaskModal = () => {
    if (showTaskModal) {
      setEditProperty(null);
      setErrorMessage("");
    }
    setShowTaskModal(!showTaskModal);
  };

  const handleTaskModalSave = async ({ ...rest }) => {
    const createResponse = await createAITask({
      variables: {
        id: rest.id,
        title: rest.title,
        content: rest.content,
        priority: rest.priority,
        taskType: "ai",
      },
    });

    const error = createResponse.data.TaskOps.adminCreate.error;
    const message = createResponse.data.TaskOps.adminCreate.message;
    if (error) {
      setErrorMessage(message);
      return false;
    } else {
      setMutationCompleted(true);
    }
    return true;
  };

  const tableData = {
    tData:
      baseProperties &&
      baseProperties.map((item) => ({
        ID: item.id,
        Hostname: item.hostname,
        "Is Paused": item.paused ? "Yes" : "No",
        "Created At": moment(item.createdAt)
          .utc()
          .format("DD-MM-YYYY hh:mm:ss a"),
        "Updated At": moment(item.updatedAt)
          .utc()
          .format("DD-MM-YYYY hh:mm:ss a"),
        "User Email": item.userEmail ? item.userEmail : "N/A",
        "Country/Ip":
          item.countryCode && item.countryCode !== "N/A"
            ? item.ip
              ? item.countryCode + " / " + item.ip.split(", ")[0]
              : item.countryCode + " / " + "N/A"
            : "N/A " + " / " + (item.ip ? item.ip.split(", ")[0] : "N/A"),
        PACKAGE:
          item.pricingPackage === "free-annual" ||
          item.pricingPackage === "free-month"
            ? "free"
            : item.pricingPackage,
        Promocode: item.promoCode,
        "Should Be Deleted At": item.scheduledToDeleteAt
          ? moment(parseInt(item.scheduledToDeleteAt))
              .utc()
              .format("DD-MM-YYYY hh:mm:ss a")
          : "N/A",
        "Managers Ids": item.sharedUsersIds.join(", "),
        "Tech Stack": item.techStack && item.techStack.join(", "),
        Action: (
          <Button.List>
            <Button
              color="primary"
              icon="edit"
              onClick={() => handlePropertyMutate(item)}
            />
            <Button
              color="danger"
              icon="trash"
              onClick={() => handlePropertyDelete(item)}
            />
            <Button
              color="warning"
              icon="refresh-ccw"
              onClick={() => handleRescanProperty(item)}
            />
            <Button onClick={() => handleCreateAITask(item)}>Add Task</Button>
          </Button.List>
        ),
      })),
  };

  useEffect(() => {
    if (
      props.data &&
      props.data.Property &&
      props.data.Property.adminGetMany &&
      props.data.Property.adminGetMany.properties
    ) {
      setDataLoading(props.loading);
      setBaseProperties(props.data.Property.adminGetMany.properties);
      setPropertyPagination(props.data.Property.adminGetMany.properties);
      setTotal(props.data.Property.adminGetMany.total);
    }
  }, [props.data]);

  useEffect(() => {
    if (
      driveData &&
      driveData.Property &&
      driveData.Property.adminGetMany &&
      driveData.Property.adminGetMany.csvURL
    ) {
      window.open(
        isProduction()
          ? `https://api.hexometer.com/v2/app/social-callback/googleDrive?admin=admin&report=property&url=${driveData.Property.adminGetMany.csvURL}`
          : `http://localhost:4000/api/app/social-callback/googleDrive?admin=admin&report=property&url=${driveData.Property.adminGetMany.csvURL}`,
        "_blank"
      );
    }
  }, [driveData]);

  const handleInputChange = (e: FormEvent) =>
    setFilterInput((e.target as HTMLInputElement).value);

  const handleMinCountChnage = (e: FormEvent) =>
    setMinCountState((e.target as HTMLInputElement).value);

  const handleMaxCountChange = (e: FormEvent) =>
    setMaxCountState((e.target as HTMLInputElement).value);

  const handleSelectPricingPack = (e: FormEvent) =>
    setSelectPackage((e.target as HTMLInputElement).value);

  const handleTaskTypeSelect = (e: FormEvent) =>
    setTaskTypeState((e.target as HTMLInputElement).value);

  const handleCountryCode = (e: FormEvent) =>
    setCountryCode((e.target as HTMLInputElement).value);

  const handleSelectShared = (e: React.FormEvent) =>
    setShared((e.target as HTMLInputElement).value);

  const handleTechStackChange = (e: FormEvent) =>
    setFilterTechStack((e.target as HTMLInputElement).value);

  const handlePropertyStatusChange = (e: FormEvent) =>
    setPropertyStatus((e.target as HTMLInputElement).value);

  const handleFromDate = (date: Date) => {
    if (date) {
      return setFromDate(moment(date).format("YYYY-MM-DDT00:00:00.000"));
    }
    return setFromDate(null);
  };

  const handleToDate = (date: Date) => {
    if (date) {
      return setToDate(moment(date).format("YYYY-MM-DDT23:59:59.999"));
    }
    return setToDate(null);
  };

  const handleSubmit = (
    event: FormEvent,
    data: string,
    packageName: string,
    startDate: string,
    endDate: string,
    status: string,
    minCount: string,
    maxCount: string,
    taskType: string,
    ctrCode: string,
    shared: string,
    tech: string
  ) => {
    event.preventDefault();
    props.onFilterDataChange(data.trim());
    props.onFromDate(startDate);
    props.onToDate(endDate);
    props.onCountryCode(ctrCode);
    props.onTechStackChange(tech);

    switch (status) {
      case "paused":
        props.onPropertyIsPaused(true);
        props.onShceduledToDelete(false);
        props.onWithoutPromo(false);
        break;
      case "active":
        props.onPropertyIsPaused(false);
        props.onShceduledToDelete(false);
        props.onWithoutPromo(false);
        break;
      case "scheduled to delete":
        props.onShceduledToDelete(true);
        props.onPropertyIsPaused(undefined);
        props.onWithoutPromo(false);
        break;
      case "without promocode":
        props.onShceduledToDelete(false);
        props.onPropertyIsPaused(undefined);
        props.onWithoutPromo(true);
        break;
      default:
        props.onPropertyIsPaused(undefined);
        props.onShceduledToDelete(false);
        props.onWithoutPromo(false);
    }

    if (packageName === "Select package") {
      props.onPackageSelect("");
    } else {
      props.onPackageSelect(packageName);
    }

    if (taskType === "Select tasktype") {
      props.onTaskTypeSelect("");
    } else {
      props.onTaskTypeSelect(taskType);
      minCount &&
        !isNaN(parseInt(minCount)) &&
        parseInt(minCount) > -1 &&
        props.onTasksMinCount(parseInt(minCount));
      maxCount &&
        !isNaN(parseInt(maxCount)) &&
        parseInt(maxCount) > -1 &&
        props.onTasksMaxCount(parseInt(maxCount));
    }

    if (shared === "all") {
      props.onSelectShared(undefined);
    }
    if (shared === "shared") {
      props.onSelectShared(true);
    }
    if (shared === "not shared") {
      props.onSelectShared(false);
    }
  };

  const handlePropertyDelete = async (row: ContentTextProps) => {
    let deleteConfirm = confirm("Are you sure you want to delete Property?");
    if (deleteConfirm) {
      const deleteResponse = await deleteProperty({
        variables: { id: row.id },
      });
      const error = deleteResponse.data.PropertyOps.adminDelete.error;
      const message = deleteResponse.data.PropertyOps.adminDelete.message;
      if (error) {
        setErrorMessage(message);
      } else {
        setMutationCompleted(true);
        refetchData();
      }
    }
  };

  const handleSortingClick = (column: string) => {
    if (column === "Created At") {
      props.setOrderBy("createdAt");
      props.setOrderByASC(!props.orderByASC);
    }

    if (column === "Updated At") {
      props.setOrderBy("updatedAt");
      props.setOrderByASC(!props.orderByASC);
    }
  };

  return (
    <Grid>
      <Grid.Row>
        <div className="col-12 col-md-8">
          {!chartDataQuery.loading &&
            chartDataQuery.data &&
            chartDataQuery.data.Property &&
            chartDataQuery.data.Property.subscriptionsChart &&
            !deletedPropertiesChartQuery.loading && (
              <Chart
                yColName="Subscribed Properties"
                yColName2="Deleted Properties"
                regData={chartDataQuery.data.Property.subscriptionsChart}
                regData2={
                  deletedPropertiesChartQuery.data.Property.deletionChart
                }
                interval={getCountArr(
                  chartDataQuery.data.Property.subscriptionsChart.length
                )}
                chartName="Property Subscription Activity"
              />
            )}
        </div>
        <div className="col-12 col-md-4">
          <form
            onSubmit={(e) =>
              handleSubmit(
                e,
                filterInput,
                selectPackage,
                fromDate,
                toDate,
                propertyStatus,
                minCountState,
                maxCountState,
                taskTypeState,
                countryCode,
                shared,
                techStack
              )
            }
          >
            <Form.Group>
              <Form.Input
                label="Input"
                className="margin-bottom"
                icon="search"
                position="append"
                placeholder="Search for address, user name, email, promocode"
                value={filterInput}
                onChange={handleInputChange}
              />
              <Form.Input
                label="Country Code"
                className="margin-bottom"
                icon="search"
                position="append"
                placeholder="Search for country code"
                value={countryCode}
                onChange={handleCountryCode}
              />
              <Form.Input
                label="Tech Stack"
                className="margin-bottom"
                icon="search"
                position="append"
                placeholder="Search for Tech Stack"
                value={techStack}
                onChange={handleTechStackChange}
              />
              <Form.Select
                label="pricing package"
                onChange={handleSelectPricingPack}
              >
                <option value="Select package">Select package</option>
                {pricingPacks.map((tool) => (
                  <option key={tool} value={tool}>
                    {tool}
                  </option>
                ))}
              </Form.Select>
              <Form.Select label="Status" onChange={handlePropertyStatusChange}>
                <option value="all">all</option>
                <option value="paused">paused</option>
                <option value="active">active</option>
                <option value="scheduled to delete">scheduled to delete</option>
                <option value="without promocode">without promocode</option>
              </Form.Select>
              <Form.Select onChange={handleSelectShared} label="Select Shared">
                {["all", "shared", "not shared"].map((el) => (
                  <option key={el} value={el}>
                    {el}
                  </option>
                ))}
              </Form.Select>
              <Grid.Row>
                <Grid.Col width={12}>
                  <Form.Select
                    label="Issue groups"
                    onChange={handleTaskTypeSelect}
                  >
                    <option value="Select tasktype">Issue groups</option>
                    <option value="HEALTH">HEALTH</option>
                    <option value="PERFORMANCE">PERFORMANCE</option>
                    <option value="USER_EXPERIENCE">USER_EXPERIENCE</option>
                    <option value="SEO">SEO</option>
                    <option value="SECURITY">SECURITY</option>
                  </Form.Select>
                </Grid.Col>
                {taskTypeState !== "Select tasktype" ? (
                  <>
                    <Grid.Col md={6}>
                      <Form.Group label="Issue Min Count">
                        <Form.Input
                          type="text"
                          value={minCountState}
                          onChange={handleMinCountChnage}
                        />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col md={6}>
                      <Form.Group label="Issue Max Count">
                        <Form.Input
                          type="text"
                          value={maxCountState}
                          onChange={handleMaxCountChange}
                        />
                      </Form.Group>
                    </Grid.Col>
                  </>
                ) : null}
              </Grid.Row>
              <Grid.Row>
                <Grid.Col>
                  <Form.Group label="From Date">
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      selected={fromDate ? new Date(fromDate) : null}
                      onChange={handleFromDate}
                      showMonthDropdown
                      placeholderText="Select a date"
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col>
                  <Form.Group label="To Date">
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      selected={toDate ? new Date(toDate) : null}
                      onChange={handleToDate}
                      showMonthDropdown
                      placeholderText="Select a date"
                      minDate={fromDate && new Date(fromDate)}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
              <Button
                className="margin-bottom"
                type="submit"
                icon="filter"
                color="primary"
                loading={dataLoading}
              >
                Filter
              </Button>
            </Form.Group>
          </form>
        </div>
      </Grid.Row>
      <Grid.Row className={showButtons === 'true' ? "justify-content-between" : "justify-content-end"}>
        {showButtons === 'true' && (
          <div className="col-12 col-md-3 col-xs-12">
            <Button.List>
              <Button
                onClick={() =>
                  getCSV({
                    variables: { ...props.variables, csvDownload: true },
                  })
                }
                loading={loading}
                color="primary"
              >
                Get CSV Link
              </Button>
              <Button
                onClick={() =>
                  uploadToDrive({
                    variables: { ...props.variables, csvDownload: true },
                  })
                }
                loading={driveDataLoading}
                color="primary"
              >
                Save to Drive
              </Button>
            </Button.List>
            {!loading &&
              data &&
              data.Property &&
              data.Property.adminGetMany &&
              data.Property.adminGetMany.csvURL && (
                <Button
                  RootComponent="a"
                  href={data.Property.adminGetMany.csvURL}
                  icon="download"
                >
                  Download CSV
                </Button>
              )}
            <br />
          </div>
        )}
        <Grid.Col md={3}>
          <Alert type="info">Result Count - {total}</Alert>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row cards deck>
        <TableModal
          handleModal={handleModal}
          showModal={showModal}
          onSave={handleSave}
          property={editProperty}
          errorMessage={errorMessage}
        />
        <TaskModal
          errorMessage={errorMessage}
          showModal={showTaskModal}
          onSave={handleTaskModalSave}
          property={editProperty}
          handleModal={handleTaskModal}
        />
        <ServerSidePagination
          data={propertyPagination}
          total={total}
          onPageNum={props.onPageNumberChange}
        >
          <TableCard
            loading={dataLoading}
            data={baseProperties}
            tableData={tableData}
            onHeaderClick={handleSortingClick}
          />
        </ServerSidePagination>
      </Grid.Row>
      <Grid.Row className="justify-content-end">
        {total ? (
          <Grid.Col md={3}>
            <Alert type="info">Result Count - {total}</Alert>
          </Grid.Col>
        ) : null}
      </Grid.Row>
    </Grid>
  );
};

export default Properties;
