import React, { useEffect, useState } from "react";
import { Grid, Button, Alert, Form } from "tabler-react";
import { ContentTextProps, blackListProps } from "../../types";
import ServerSidePagination from "../../components/pagination/serverSidePagination";
import TableCard from "../../components/tableCard";
import TableModal from "./tableModal";

import "./style.css";
import ReactModal from "react-modal";
import { alertCustomStyles } from "../../helpers";

const Automations = ({
  data,
  loading,
  refetchData,
  deleteSchemaFromAutomation,
  attachSchemaToAutomation,
  schemaList,
  onPageNumberChange,
}: any): JSX.Element => {
  const [automationList, setAutomationList] = useState<Array<blackListProps>>(
    []
  );
  const [dataLoading, setDataLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [total, setTotal] = useState(0);
  const [editField, setEditField] = useState<any>(null);
  const [outputIds, setOutputIds] = useState<any>(null);
  const [schemaType, setSchemaType] = useState<any>(null);
  const [inputIds, setInputIds] = useState<any>(null);

  const handleModal = () => {
    if (showModal) {
      setOutputIds(null);
      setInputIds(null);
      setEditField(null);
      setSchemaType(null);
      setErrorMessage("");
    }
    setShowModal(false);
  };

  const onAttachIds = async ({ automationId, schemaId, type }) => {
    const res = await attachSchemaToAutomation({
      variables: {
        settings: {
          automation_id: automationId,
          output_schema_ids: type === "output" ? [schemaId] : null,
          input_schema_ids: type === "input" ? [schemaId] : null,
        },
      },
    });
    if (
      res.data.HexomaticAdminOps &&
      res.data.HexomaticAdminOps.attachSchemaToAutomation &&
      res.data.HexomaticAdminOps.attachSchemaToAutomation.error_code
    ) {
      setErrorMessage(
        res.data.HexomaticAdminOps.attachSchemaToAutomation.error_code
      );
    } else {
      type === "output" &&
        setOutputIds([...(outputIds ? outputIds : []), schemaId]);
      type === "input" &&
        setInputIds([...(inputIds ? inputIds : []), schemaId]);
      refetchData();
    }
  };

  const onDeleteAttachedIs = async ({ automationId, schemaId, type }) => {
    const res = await deleteSchemaFromAutomation({
      variables: {
        settings: {
          automation_id: automationId,
          output_schema_ids: type === "output" ? [schemaId] : null,
          input_schema_ids: type === "input" ? [schemaId] : null,
        },
      },
    });
    if (
      res.data.HexomaticAdminOps &&
      res.data.HexomaticAdminOps.removeSchemaFromAutomation &&
      res.data.HexomaticAdminOps.removeSchemaFromAutomation.error_code
    ) {
      setErrorMessage(
        res.data.HexomaticAdminOps.removeSchemaFromAutomation.error_code
      );
    } else {
      type === "output" &&
        setOutputIds([...outputIds.filter((item) => item !== schemaId)]);
      type === "input" &&
        setInputIds([...inputIds.filter((item) => item !== schemaId)]);
      refetchData();
    }
  };

  const textCheckbox = ({ item, name }) => {
    return (
      <div style={{ display: "flex" }}>
        <span style={{ marginRight: "5px" }}>
          {name === "active"
            ? item[name]
              ? "active"
              : "not active"
            : item[name]
            ? "new"
            : null}
        </span>
      </div>
    );
  };

  const inputOutputSchema = ({ ids, type, field }) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Button.List>
          <Button
            color="primary"
            icon="edit"
            onClick={() => {
              ids[0] !== null
                ? type === "input"
                  ? setInputIds(ids)
                  : setOutputIds(ids)
                : null;
              setSchemaType(type === "input" ? "input" : "output");
              setEditField(field);
              setShowModal(true);
            }}
          />
        </Button.List>
        {ids &&
          ids.map((item) => <span style={{ marginLeft: "5px" }}>{item}</span>)}
      </div>
    );
  };

  const tableData = {
    tData:
      automationList &&
      automationList.map((item: ContentTextProps) => ({
        id: item.id,
        name: item.name,
        short_description: item.short_description,
        is_active: textCheckbox({ item, name: "active" }),
        input_schema_ids: inputOutputSchema({
          ids: item.input_schema_ids,
          type: "input",
          field: item,
        }),
        output_schema_ids: inputOutputSchema({
          ids: item.output_schema_ids,
          type: "output",
          field: item,
        }),
        // "Action":
        //   <Button.List>
        //     <Button color="primary" icon="edit" onClick={() => { setEditField(item); setShowModal(true) }} />
        //   </Button.List>
      })),
  };

  useEffect(() => {
    if (
      data &&
      data.HexomaticAdmin &&
      data.HexomaticAdmin.getAutomationsWithIO.automations
    ) {
      setDataLoading(loading);
      setAutomationList(data.HexomaticAdmin.getAutomationsWithIO.automations);
      // setTotal(data.EmailBlackList.adminGetMany.total)
    }
  }, [data]);

  return (
    <Grid.Row cards deck>
      {showModal && (
        <TableModal
          handleModal={handleModal}
          showModal={showModal}
          onAttachIds={onAttachIds}
          onDeleteAttachedIs={onDeleteAttachedIs}
          editField={editField}
          errorMessage={errorMessage}
          schemaList={schemaList}
          outputIds={outputIds}
          inputIds={inputIds}
          schemaType={schemaType}
        />
      )}
      <ReactModal isOpen={!!errorMessage} style={alertCustomStyles}>
        {errorMessage ? <Alert type="danger">{errorMessage}</Alert> : null}
        <Button
          className=""
          onClick={() => setErrorMessage("")}
          color="primary"
        >
          OK
        </Button>
      </ReactModal>
      <div className="col-12 col-md-1 col-xs-6">
        <Button className="" onClick={() => setShowModal(true)} color="primary">
          Create
        </Button>
        <br />
      </div>
      <Grid.Col md={9}></Grid.Col>
      <div className="col-12 col-md-2 col-xs-6">
        <Alert type="info">
          Result Count - {total || automationList.length}
        </Alert>
      </div>
      <div className="black-list-table-pagination">
        <ServerSidePagination
          data={automationList}
          total={total}
          onPageNum={onPageNumberChange}
        >
          <TableCard
            loading={dataLoading}
            data={automationList}
            tableData={tableData}
          />
        </ServerSidePagination>
      </div>

      {automationList.length ? (
        <div className="col-12 col-md-2 col-xs-12 offset-md-10">
          <Alert type="info">
            Result Count - {total || automationList.length}
          </Alert>
        </div>
      ) : null}
    </Grid.Row>
  );
};

export default Automations;
