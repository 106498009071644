import React, { useEffect, useState } from "react";
import { Alert, Button, Form } from "tabler-react";
import ReactModal from "react-modal";
import { TableModalProps } from "../../types";
import "react-mde/lib/styles/css/react-mde-all.css";

ReactModal.setAppElement('#root');

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    height: '50%'
  }
};

const TableModal = (
  { handleModal, showModal, onSave, errorMessage }: TableModalProps
): JSX.Element => {

  const [loading, setLoading] = useState(false);
  const [links, setLinks] = useState('');
  const [text, setText] = useState("");

  const handleOk = async () => {
    setLoading(true);
    let linksArr = links.split(',');
    linksArr = linksArr.map((item: any) => item.trim());
    const formData = { linksArr };
    const completed = await onSave({ ...formData });
    setLoading(false);
    if (completed) handleCancel();
  };

  const handleCancel = () => {
    setLinks("");
    handleModal();
  };

  useEffect(() => {
    if(window.innerWidth < 640) {
      customStyles.content.width = '90%'
    }
  }, [])

  return (
    <div style={{ height: 0 }}>
      <ReactModal
        isOpen={showModal}
        contentLabel="Input links"
        style={customStyles}
        onRequestClose={handleCancel}
      >
        <h4>Input links</h4>
        {errorMessage ?
          <Alert type="danger">
            {errorMessage}
          </Alert> : null}
        <Form onSubmit={(e: React.FormEvent) => e.preventDefault()}>
          <Form.Textarea
            required
            type="text"
            placeholder='link1, link2, link3...'
            label="Links"
            value={links}
            onChange={(e) => setLinks(e.target.value)}
          />
          <Button.List style={{ marginTop: "5%" }}>
            <Button onClick={handleOk} color="primary" type="submit" loading={loading}>Save</Button>
            <Button onClick={handleCancel} color="secondary" type="button">Cancel</Button>
          </Button.List>
        </Form>
      </ReactModal>
    </div>
  )
};

export default TableModal;
