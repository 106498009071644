import React, { useState } from 'react';
import { Form } from "tabler-react";
import { ContentTextProps, SearchInputProps } from "../../types";
import './styles.css'

const SearchInput = ({ data, fields, onSubmit, placeholder }: SearchInputProps): JSX.Element => {
  const [searchString, setSearchString] = useState("");

  const filterData = (searchText: string) => {
    let filteredData = [...data];
    let searchedString = searchText.trim().toLowerCase();
    for (let fieldName of fields) {
      filteredData = [...data];
      const name = fieldName.name;

      if (fieldName.concat) {
        const concat = fieldName.concat;
        filteredData = filteredData.filter((i: ContentTextProps) => `${i[name]} ${i[concat]}`.toLowerCase().includes(searchedString));
        filteredData = filteredData.sort((a: ContentTextProps, b: ContentTextProps) => (`${a[name]} ${a[concat]}`.toLowerCase().indexOf(searchedString) > `${b[name]} ${b[concat]}`.toLowerCase().indexOf(searchedString)) ? 1 : -1);
      } else {
        filteredData = filteredData.filter((i: ContentTextProps) => `${i[name]}`.toLowerCase().includes(searchedString));
        filteredData = filteredData.sort((a: ContentTextProps, b: ContentTextProps) => (`${a[name]}`.toLowerCase().indexOf(searchedString) > `${b[name]}`.toLowerCase().indexOf(searchedString)) ? 1 : -1);
      }
      if (filteredData.length > 0) break;
    }
    return filteredData;
  };

  return (
    <form onSubmit={(e: React.FormEvent ) => {
      e.preventDefault();
      onSubmit(filterData(searchString));
    }}>
      <Form.Group className="offset-9">
        <Form.Input
          icon="search"
          position="append"
          placeholder={placeholder}
          value={searchString}
          onChange={(e: React.FormEvent) => {
            const inputText = (e.target as HTMLInputElement).value;
            setSearchString(inputText);
          }}
        />
      </Form.Group>
    </form>
  )
};

export default SearchInput;
