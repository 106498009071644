import React, { useState, Fragment } from "react";
import { MOBILE_INVITES } from "../../graphql/queries";
import MobileInvites from ".";
//import { Grid, Form } from "tabler-react";
import { useQuery } from "@apollo/react-hooks";

const MobileInvitesQueries = () => {
  const [filterUser, setFilterUser] = useState("");
  const [filterRefferal, setFilterRefferal] = useState("");
  const [pageNumberState, setPageNumberState] = useState(1);
  const [actionName, setActionName] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [filterHexUsers, setFilterHexUsers] = useState(true);
  const [orderBy, setOrderBy] = useState("");
  const [orderByASC, setOrderByASC] = useState(false);

  const { data, loading } = useQuery(MOBILE_INVITES, {
    fetchPolicy: "no-cache",
    variables: {
      settings: {
        filter: {
          from: fromDate,
          to: toDate,
          action: actionName,
          referrerUserSearchInput: filterRefferal,
          userSearchInput: filterUser,
          //hideHex: filterHexUsers,
        },
        //sort: orderBy,
        //sortDir: orderByASC ? "ASC" : "DESC",
        page: pageNumberState,
        pageSize: 100,
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const handleFilterUser = (filterData: string) => setFilterUser(filterData);
  const handleFilterRefferal = (filterData: string) =>
    setFilterRefferal(filterData);
  const handlePageNumberChange = (pageNumber: number) =>
    setPageNumberState(pageNumber);
  const handleActionSelect = (actionName: string) => setActionName(actionName);
  const handleFromDate = (date: string) => setFromDate(date);
  const handleToDate = (date: string) => setToDate(date);

  return (
    <Fragment>
      {/* <Grid.Row>
        <Grid.Col md={2}>
          <Form.Checkbox
            label="Filter Hexact Scans"
            checked={filterHexUsers}
            onChange={() => setFilterHexUsers(!filterHexUsers)}
          />
        </Grid.Col>
     </Grid.Row>*/}
      <MobileInvites
        data={data}
        loading={loading}
        onFilterUser={handleFilterUser}
        onFilterRefferal={handleFilterRefferal}
        onPageNumberChange={handlePageNumberChange}
        onActionSelect={handleActionSelect}
        onFromDate={handleFromDate}
        onToDate={handleToDate}
        filterHexScans={filterHexUsers}
        setOrderBy={setOrderBy}
        setOrderByASC={setOrderByASC}
        orderByASC={orderByASC}
        variables={{
          filter: {
            from: fromDate,
            to: toDate,
            action: actionName,
            referrerUserSearchInput: filterUser,
            userSearchInput: filterRefferal,
          },
          //sort: orderBy,
          //sortDir: orderByASC ? "ASC" : "DESC",
          page: pageNumberState,
          pageSize: 100,
        }}
      />
    </Fragment>
  );
};

export default MobileInvitesQueries;
