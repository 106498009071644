import React, { useState, useEffect } from "react";
import { CREATE_SCHEMA, DELETE_SCHEMA, UPDATE_SCHEMA } from "../../graphql/mutations";
import { GET_SCHEMAS } from "../../graphql/queries";
import JsonSchemas from "./index";
import { useQuery, useMutation } from "@apollo/react-hooks";


const JsonSchemasTypesQueries = () => {
  const [typeList, setTypeList] = useState<any>([]);
  const [pageNumberState, setPageNumberState] = useState(1);
  const handlePageNumberChange = (pageNumber: number) => setPageNumberState(pageNumber);
  const [searchEmail, setsearchEmail] = useState<string>('')

  const { data, loading, refetch } = useQuery(GET_SCHEMAS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      settings: {
        filter: {
          limit: 100
        }
      }
    }
  })

  useEffect(() => {
    refetch()
  }, [pageNumberState])

  useEffect(() => {
    setTypeList(data)
  }, [data])

  useEffect(() => {
    refetchData()
  }, [searchEmail])

  const refetchData = async () => {
    await refetch()
  }

  const [deleteSchema] = useMutation(DELETE_SCHEMA)

  const [updateSchema] = useMutation(UPDATE_SCHEMA)


  const [createJsonSchema] = useMutation(CREATE_SCHEMA, {
    onCompleted: () => {
      refetchData();
    }
  })

  return (
    <JsonSchemas
      data={typeList}
      loading={loading}
      searchEmail={searchEmail}
      setsearchEmail={setsearchEmail}
      onPageNumberChange={handlePageNumberChange}
      createJsonSchema={createJsonSchema}
      deleteSchema={deleteSchema}
      updateSchema={updateSchema}
    />
  )
};


export default JsonSchemasTypesQueries;
