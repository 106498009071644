import React, { Fragment, useEffect, useState } from "react";
import { Icon } from "tabler-react";
//@ts-ignore
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { Form } from "tabler-react";
import "./index.css";

const hexometerLinks = [
  { name: "hexometer", path: null },
  { name: "Hexometer properties", path: "/properties" },
  { name: "Hexometer notifications", path: "/notifications" },
];

const hexomaticLinks = [
  { name: "hexomatic", path: null },
  { name: "Automations", path: "/hexomatic-automation" },
  { name: "Automation categories", path: "/hexomatic-automation-categories" },
  { name: "Automation types", path: "/hexomatic-automation-types" },
  { name: "Automation units", path: "/hexomatic-automation-units" },
  { name: "Hexomatic notifications", path: "/hexomatic-notifications" },
  { name: "Scraping recipes", path: "/hexomatic-scraping-recipes" },
  { name: "Public Scraping recipes", path: "/hexomatic-public-scraping-recipes" },
  { name: "Scraping recipe categories", path: "/hexomatic-scraping-categories" },
  { name: "Workflows", path: "/hexomatic-workflows" },
  { name: "Public workflows", path: "/hexomatic-public-workflows" },
  {
    name: "Public workflow categories",
    path: "/hexomatic-public-workflow-categories",
  },
  { name: "Shared recipes", path: "/hexomatic-shared-recipes" },
  { name: "Shared workflows", path: "/hexomatic-shared-workflows" },
  { name: "Automation inupt/output types", path: "/hexomatic-automation-inupt-output-types" },
  { name: "JSON schemas (DEV)", path: "/hexomatic-json-schema" },
  { name: "Automation config (DEV)", path: "/hexomatic-automation-config" },
  { name: "Traffic usage", path: "/hexomatic-traffic-usage" },
  { name: "Credits usage", path: "/hexomatic-credits-usage" },
  {
    name: "Automations credits usage",
    path: "/hexomatic-automation-credits-usage",
  },
  { name: "Tasks", path: "/hexomatic-tasks" },
  { name: "Survey", path: "/hexomatic-survey" },
];

const hexowatchLinks = [
  { name: "hexowatch", path: null },
  { name: "Hexowatch properties", path: "/hexowatch-properties" },
  { name: "Hexowatch notifications", path: "/hexowatch-notifications" },
];

const hexosparkLinks: { name: string; path: string | null }[] = [
  { name: "hexospark", path: null },
  { name: "Campaigns", path: "/hexospark-campaigns" },
  { name: "Contacts/Organizations", path: "/hexospark-contacts-or-organizations" },
  { name: "Email Accounts", path: "/hexospark-email-accounts" },
  { name: "Email Templates", path: "/hexospark-email-templates-per-workspace" },
  { name: "Ice breaker", path: "/hexospark-ice-breaker" },
  { name: "Public Templates", path: "/hexospark-public-templates" },
  { name: "Public Templates Categories", path: "/hexospark-public-templates-categories" },
  { name: "Workspaces", path: "/hexospark-workspaces" },
  { name: "Workspaces Storage", path: "/hexospark-workspaces-storage" },
  { name: "Monthly Reports", path: "/hexospark-monthly-report" },
];

const webSitesLinks: { name: string; path: string | null }[] = [
  { name: "Other products", path: null },
  { name: "PullContacts", path: "/pull-contacts" },
  { name: "DiscoverProfile", path: "/discover-profile" },
  { name: "InspectContent", path: "/inspect-content" },
  { name: "Fullpagescreencapture", path: "/fullpagescreencapture" },
];

const Menu = ({ hexomatic }: any): JSX.Element => {
  let history = useHistory();
  const [value, setValue] = useState("");
  const [clicked, setClicked] = useState(false);
  let location = useLocation();

  useEffect(() => {
    !clicked && setValue("");
    setClicked(false);
  }, [location.pathname]);

  function handleClick(path: string) {
    if (!path) return;
    setValue(path);
    setClicked(true);
    history.push(path);
  }

  return (
    <div className="header-menu-wrapper">
      <div className="navs-wrapper">
        <li className="nav-item">
          <NavLink activeClassName="nav-link active" to="/" exact>
            <Icon name="home" />
            Home
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink activeClassName="nav-link active" to="/keywords">
            <Icon name="key" />
            Keywords
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink activeClassName="nav-link active" to="/users">
            <Icon name="users" />
            Users
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink activeClassName="nav-link active" to="/scan-logs">
            <Icon name="file-text" />
            Scan Logs
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink activeClassName="nav-link active" to="/header-data">
            <Icon name="layout" />
            Header Data
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink activeClassName="nav-link active" to="/utility">
            <Icon name="info" />
            Utilities
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink activeClassName="nav-link active" to="/user-logs">
            <Icon name="file-text" />
            User Logs
          </NavLink>
        </li>
        {/* <li className="nav-item">
      <NavLink
        activeClassName="nav-link active"
        to="/ai-tasks"
      >
        <Icon name="list" />
        AI Tasks
      </NavLink>
    </li> */}
        <li className="nav-item">
          <NavLink activeClassName="nav-link active" to="/promocodes">
            <Icon name="star" />
            Promocode
          </NavLink>
        </li>
        {/* <li className="nav-item">
          <NavLink activeClassName="nav-link active" to="/notifications">
            <Icon name="bell" />
            Notifications
          </NavLink>
        </li> */}
        <li className="nav-item">
          <NavLink activeClassName="nav-link active" to="/channels">
            <Icon name="bell" />
            Active Channels
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink activeClassName="nav-link active" to="/questionnaires">
            <Icon name="help-circle" />
            Questionnaires
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink activeClassName="nav-link active" to="/black-list-email">
            Black List Email
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink activeClassName="nav-link active" to="/subscribed-email">
            Subscribed Emails
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink activeClassName="nav-link active" to="/additional-email">
            Additional Email
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink activeClassName="nav-link active" to="/shortlink">
            Shortlinks
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink activeClassName="nav-link active" to="/shortcut-clicks">
            Shortcut Clicks
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink activeClassName="nav-link active" to="/referral-link-stats">
            Referral Link Stats
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink activeClassName="nav-link active" to="/free-tools">
            Hexomatic Free Tools and Extension
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink activeClassName="nav-link active" to="/email-custom-messages">
            Email Custom Messages
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink activeClassName="nav-link active" to="/random">
            Random Seats Left (LTD)
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink activeClassName="nav-link active" to="/random-premiums">
            Random Seats Left (Premium Credits)
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink activeClassName="nav-link active" to="/excluded-users">
            Excluded users
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink activeClassName="nav-link active" to="/hotjar-csv">
            Hotjar CSV
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink activeClassName="nav-link active" to="/cancellation-feedbacks">
            Cancellation Feedbacks
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink activeClassName="nav-link active" to="/startengine-welcome-pack">
            StartEngine welcome pack
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink activeClassName="nav-link active" to="/demo-requests">
            Demo requests
          </NavLink>
        </li>
      </div>
      <div className="selects-block" onSubmit={(e: React.FormEvent) => e.preventDefault()}>
        <div className="select-wrapper">
          <img src="https://hexometer.com/favicon.ico" alt="hexometer" />
          <Form.Select
            onChange={(e: React.FocusEvent) => {
              handleClick((e.target as HTMLInputElement).value);
            }}
            // label="Hexomatic"
            value={value}
          >
            {hexometerLinks.map((pack) => (
              <option key={pack.name} value={pack.path}>
                {pack.name}
              </option>
            ))}
          </Form.Select>
        </div>
        <div className="select-wrapper">
          <img src="https://hexomatic.com/favicon.ico" alt="hexomatic" />
          {
            <Form.Select
              onChange={(e: React.FocusEvent) => {
                handleClick((e.target as HTMLInputElement).value);
              }}
              // label="hexometer"
              value={value}
            >
              {hexomaticLinks.map((pack) => (
                <option key={pack.name} value={pack.path}>
                  {pack.name}
                </option>
              ))}
            </Form.Select>
          }
        </div>
        <div className="select-wrapper">
          <img src="https://hexowatch.com/favicon.ico" alt="hexowatch" />
          {
            <Form.Select
              onChange={(e: React.FocusEvent) => {
                handleClick((e.target as HTMLInputElement).value);
              }}
              // label="hexowatch"
              value={value}
            >
              {hexowatchLinks.map((pack) => (
                <option key={pack.name} value={pack.path}>
                  {pack.name}
                </option>
              ))}
            </Form.Select>
          }
        </div>
        <div className="select-wrapper">
          <img src="https://hexospark.com/favicon.ico" alt="hexospark" />
          {
            <Form.Select
              onChange={(e: React.FocusEvent) => {
                handleClick((e.target as HTMLInputElement).value);
              }}
              value={value}
            >
              {hexosparkLinks.map((pack) => (
                <option key={pack.name} value={pack.path}>
                  {pack.name}
                </option>
              ))}
            </Form.Select>
          }
        </div>
        <div className="select-wrapper">
          {/* <img src="https://hexowatch.com/favicon.ico" alt="hexowatch" /> */}
          {
            <Form.Select
              onChange={(e: React.FocusEvent) => {
                handleClick((e.target as HTMLInputElement).value);
              }}
              // label="hexowatch"
              value={value}
            >
              {webSitesLinks.map((pack) => (
                <option key={pack.name} value={pack.path}>
                  {pack.name}
                </option>
              ))}
            </Form.Select>
          }
        </div>
      </div>
    </div>
  );
};

export default Menu;
