import React, { useState } from 'react';
import Channels from './channels';
import { useQuery } from '@apollo/react-hooks';
import { GET_CHANNELS } from "../../graphql/queries";


const ChannelsQueries = () => {
  const [pageNumberState, setPageNumberState] = useState(1);
  const [filterDataState, setFilterDataState] = useState("");
  const [channelState, setChannelState] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [orderByASC, setOrderByASC] = useState(false);

  const { data, loading, refetch } = useQuery(GET_CHANNELS, {
    variables: {
      input: {
        regFrom: fromDate,
        regTo: toDate,
        pageNumber: pageNumberState,
        searchQuery: filterDataState,
      },
      name: channelState,
      orderBy, 
      orderByASC,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });

  const handlePageNumberChange = (pageNumber: number) => setPageNumberState(pageNumber);
  const handleFilterDataChange = (filterData: string) => setFilterDataState(filterData);
  const handleChannelChange = (chnl: string) => setChannelState(chnl);
  const handleFromDate = (date: string) => setFromDate(date);
  const handleToDate = (date: string) => setToDate(date);

  return (
    <Channels
      data={data}
      loading={loading}
      onPageNumberChange={handlePageNumberChange}
      refetchData={refetch}
      onFilterDataChange={handleFilterDataChange}
      onSelectChannel={handleChannelChange}
      onFromDate={handleFromDate}
      onToDate={handleToDate}
      setOrderByASC={setOrderByASC}
      orderByASC={orderByASC}
      setOrderBy={setOrderBy}
      variables={{
        input: {
          regFrom: fromDate,
          regTo: toDate,
          pageNumber: pageNumberState,
          searchQuery: filterDataState,
        },
        name: channelState,
      }}
    />
  )
};

export default ChannelsQueries;