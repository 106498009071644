import React, { FC } from "react";
import { Card, Form, Grid } from "tabler-react";
import C3Chart from "react-c3js";
//@ts-ignore
import { useHistory } from "react-router-dom";
import { refreshIcon } from "../../image/svgIcons";
import "./styles.css";

interface PiechartWithoutDatesProps {
  data: any[];
  title: string;
  cardOptions?: JSX.Element;
  colors?: any;
  limit?: number;
  showDataLenght?: boolean;
  reload?: boolean;
  showSelect?: {
    label: string;
    value: string;
    options: string[];
    handleSelect: (arg: string) => void;
  };
  reloadData?: () => void;
}

const PiechartWithoutDates: FC<PiechartWithoutDatesProps> = ({
  data,
  title,
  cardOptions,
  colors: propColor,
  limit,
  showDataLenght,
  reload,
  showSelect,
  reloadData,
}) => {
  const { push } = useHistory();

  const colors = propColor && propColor ? { ...propColor } : null;
  const newData =
    limit && data.length > limit
      ? data.slice(0, limit).map((toolObj) => [`${toolObj.count} ${toolObj.field}`, toolObj.count])
      : data.map((toolObj) => {
          const newField = `${toolObj.count} ${toolObj.field}`;
          //color key must have same name what newField like this '13 Active'
          if (colors) colors[newField] = propColor[toolObj.field];
          return [newField, toolObj.count];
        });

  const handleNavigation = () => {
    switch (title) {
      default:
        push("/");
        break;
    }
  };

  const onRefresh = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    reloadData();
  };

  return (
    <Card className="print-only">
      <div className="graph-link" onClick={handleNavigation}>
        <Card.Header className={reload ? "between" : ""}>
          <Card.Title className="print-only">
            {showDataLenght
              ? title
              : title === "Hexomatic credits usage"
              ? `${title}`
              : `${title} - ${
                  data.length === 0
                    ? 0
                    : title === "Hexomatic traffic usage" || title === "Hexowatch traffic usage"
                    ? `${Math.round(
                        +data.map((toolObj: any) => +toolObj.count).reduce((acc: any, el: any) => acc + el, 0)
                      )}
                      mb
                      `
                    : +data
                        .map((toolObj: any) => +toolObj.count)
                        .reduce((acc: any, el: any) => acc + el, 0)
                        .toFixed(2)
                }`}
          </Card.Title>

          {reload && (
            <Card.Title className="pichart-title">
              {cardOptions}
              <span className="refresh-icon" onClick={onRefresh}>
                {refreshIcon}
              </span>
            </Card.Title>
          )}
        </Card.Header>
      </div>
      <Card.Body>
        <div
          style={{
            maxWidth: newData.length > 16 ? "100%" : "450px",
            height: newData.length > 40 ? "600px" : "",
          }}
        >
          <C3Chart
            data={{
              columns: newData,
              type: "pie",
              colors: colors ? colors : {},
            }}
            pie={{
              label: {
                format: (value) => (title === "Hexomatic traffic usage" ? `${value} mb` : value),
              },
            }}
            legend={{
              position: "right",
            }}
            size={{
              height: newData.length > 40 ? 500 : "",
            }}
          />
        </div>
      </Card.Body>
      <Card.Footer className="no-print">
        {showSelect && (
          <Grid.Row>
            <Grid.Col sm={6} md={4}>
              <Form.Group>
                <Form.Select
                  onChange={(e: React.FormEvent) =>
                    showSelect.handleSelect((e.target as HTMLSelectElement).value)
                  }
                  label={showSelect.label}
                  value={showSelect.value}
                >
                  {showSelect.options.map((pack) => (
                    <option key={pack} value={pack}>
                      {pack}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
        )}
      </Card.Footer>
    </Card>
  );
};

export default PiechartWithoutDates;
