import React, { useEffect, useState, FormEvent } from "react";
import { Grid, Form, Button, Alert } from "tabler-react";
import { StartEngineWelcomePackProps } from "../../types";
import moment from "moment";
import TableCard from "../../components/tableCard";
import ServerSidePagination from "../../components/pagination/serverSidePagination";
import { STARTENGINE_REPORT } from "../../graphql/queries";
import { useLazyQuery } from "@apollo/react-hooks";
import DatePicker from "react-datepicker";
import { isProduction } from "../../helpers";
import "./index.css";

const StartEngineWelcomePack = (props: StartEngineWelcomePackProps): JSX.Element => {
  const [StartEngineWelcomePack, setStartEngineWelcomePack] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [scanLogsPagination, setScanLogsPagination] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [total, setTotal] = useState(0);
  const [selectProduct, setSelectProduct] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const showButtons = localStorage.getItem("_rep");

  const [getCSV, { data, loading }] = useLazyQuery(STARTENGINE_REPORT);
  const [uploadToDrive, { data: driveData, loading: driveDataLoading }] =
    useLazyQuery(STARTENGINE_REPORT);
  const tableData = {
    tData:
      StartEngineWelcomePack &&
      StartEngineWelcomePack.map((item: any) => {
        return {
          // "Id": item.id || "No id",
          Email: item.email || "No email",
          "First name": item.first_name,
          "Last name": item.last_name,
          Referer: item.referrer || '',
          Created_at: item.created_at,
        };
      }),
  };

  useEffect(() => {
    if (props.data && props.data.Admin && props.data.Admin.getStartEngineReport) {
      setDataLoading(props.loading);
      setStartEngineWelcomePack(props.data.Admin.getStartEngineReport.data);
      setScanLogsPagination(props.data.Admin.getStartEngineReport.data);
      setTotal(props.data.Admin.getStartEngineReport.count);
    }
  }, [props.data]);

  useEffect(() => {
    if (
      driveData &&
      driveData.Admin &&
      driveData.Admin.getStartEngineReport &&
      driveData.Admin.getStartEngineReport.csvUrl
    ) {
      window.open(
        isProduction()
          ? `https://api.hexometer.com/v2/app/social-callback/googleDrive?admin=admin&report=referral&url=${driveData.Admin.getStartEngineReport.csvUrl}`
          : `http://localhost:4000/api/app/social-callback/googleDrive?admin=admin&report=referral&url=${driveData.Admin.getStartEngineReport.csvUrl}`,
        "_blank"
      );
    }
  }, [driveData]);

  const handleSearchTextInputChange = (e: FormEvent) => setSearchText((e.target as HTMLInputElement).value);
  const handleSelectedProduct = (e: FormEvent) => setSelectProduct((e.target as HTMLInputElement).value);

  const handleFromDate = (date: Date) => {
    if (date) {
      return setFromDate(moment(date).format("YYYY-MM-DDT00:00:00.000"));
    }
    return setFromDate(null);
  };

  const handleToDate = (date: Date) => {
    if (date) {
      return setToDate(moment(date).format("YYYY-MM-DDT23:59:59.999"));
    }
    return setToDate(null);
  };

  const handleSubmit = (
    event: FormEvent,
    searchText: string,
    actionName: string,
    startDate: string,
    endDate: string
  ) => {
    event.preventDefault();
    props.onSearchText(searchText.trim());
    props.onFromDate(startDate);
    props.onToDate(endDate);

    if (actionName !== "Select action") {
      props.onProductSelect(actionName);
    } else {
      props.onProductSelect("");
    }
  };

  const handleSortingClick = (column: string) => {
    if (column === "Click date") props.setOrderBy("created_at");
    else if (column === "Action Name") props.setOrderBy("type");
    if (column === "Click date" || column === "Action Name") props.setOrderByASC(!props.orderByASC);
  };
  return (
    <Grid>
      <Grid.Row className="mb-5">
        <div className="col-12 col-md-8"></div>
        <div className="col-12 col-md-4">
          <form onSubmit={(e) => handleSubmit(e, searchText, selectProduct, fromDate, toDate)}>
            <Form.Group>
              <Form.Input
                label="Input"
                className=""
                icon="search"
                position="append"
                placeholder="Search by user ID or email"
                value={searchText}
                onChange={handleSearchTextInputChange}
              />
              <Form.Group label="From Date" className="shortcutClicksDatePicker">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={fromDate ? new Date(fromDate) : null}
                  onChange={handleFromDate}
                  showMonthDropdown
                  placeholderText="Select a date"
                />
              </Form.Group>
              <Form.Group label="To Date" className="shortcutClicksDatePicker">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={toDate ? new Date(toDate) : null}
                  onChange={handleToDate}
                  showMonthDropdown
                  placeholderText="Select a date"
                  minDate={fromDate && new Date(fromDate)}
                />
              </Form.Group>
              <Button type="submit" icon="filter" color="primary">
                Filter
              </Button>
            </Form.Group>
          </form>
        </div>
      </Grid.Row>
      <Grid.Row className={showButtons === "true" ? "justify-content-between" : "justify-content-end"}>
        {showButtons === "true" && (
          <div className="col-12 col-md-3 col-xs-12">
            <Button.List>
              <Button
                onClick={() =>
                  getCSV({
                    variables: {
                      settings: {
                        ...props.variables,
                        csvDownload: true,
                      },
                    },
                  })
                }
                loading={loading}
                color="primary"
                mr={1}
              >
                Get CSV Link
              </Button>
              <Button
                onClick={() =>
                  uploadToDrive({
                    variables: {
                      settings: {
                        ...props.variables,
                        csvDownload: true,
                      },
                    },
                  })
                }
                loading={driveDataLoading}
                color="primary"
              >
                Save to Drive
              </Button>
            </Button.List>
            {!loading &&
              data &&
              data.Admin &&
              data.Admin.getStartEngineReport &&
              data.Admin.getStartEngineReport.csvUrl && (
                <Button
                  RootComponent="a"
                  href={data.Admin.getStartEngineReport.csvUrl}
                  icon="download"
                  className="mt-2"
                >
                  Download CSV
                </Button>
              )}
            <br />
          </div>
        )}
        <Grid.Col md={3}>
          <Alert type="info">Result Count - {total}</Alert>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row cards deck>
        <ServerSidePagination data={scanLogsPagination} total={total} onPageNum={props.onPageNumberChange}>
          <TableCard
            loading={dataLoading}
            data={StartEngineWelcomePack}
            tableData={tableData}
            onHeaderClick={handleSortingClick}
          />
        </ServerSidePagination>
      </Grid.Row>
      <Grid.Row className="justify-content-end">
        {total ? (
          <Grid.Col md={3}>
            <Alert type="info">Result Count - {total}</Alert>
          </Grid.Col>
        ) : null}
      </Grid.Row>
    </Grid>
  );
};

export default StartEngineWelcomePack;
