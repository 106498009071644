import React, { FC, useState, useEffect } from "react";
import { Grid, Button, Form, Loader, Card } from "tabler-react";
import { useMutation, useQuery, useLazyQuery } from "@apollo/react-hooks";

import { CREATE_RUNDOM_TIME_AND_COUNT, UPDATE_PREMIUMS_COUNT } from "../../graphql/mutations";
import {
  GET_RANDOM_COUNT_PREMIUMS,
  GET_RANDOM_TIME_AND_COUNT,
  RUN_RUNDOM_TIME_AND_COUNTS,
} from "../../graphql/queries";

interface RandomProps {}

const RandomPremiums: FC<RandomProps> = (props) => {
  const [count, setCount] = useState({
    // PC_SPECIAL_499: 0,
    // PC_SPECIAL_999: 0,
    left_count: 0,
  });
  const [error, setError] = useState("");

  const { data, loading: dataLoading, refetch } = useQuery(GET_RANDOM_COUNT_PREMIUMS);
  const [updateCount, { loading }] = useMutation(UPDATE_PREMIUMS_COUNT);

  useEffect(() => {
    if (data && data.Admin && data.Admin.getPromoLeftSeats && data.Admin.getPromoLeftSeats.seats) {
      const obj = JSON.parse(data.Admin.getPromoLeftSeats.seats);
      obj &&
        setCount({
          // PC_SPECIAL_499: obj.PC_SPECIAL_499 || 0,
          // PC_SPECIAL_999: obj.PC_SPECIAL_999 || 0,
          left_count: obj.left_count || 0,
        });
    }
  }, [data]);

  const handleSubmit = async () => {
    const { data } = await updateCount({
      variables: {
        settings: {
          // PC_SPECIAL_499: count.PC_SPECIAL_499,
          // PC_SPECIAL_999: count.PC_SPECIAL_999,
          left_count: count.left_count,
        },
      },
    });
    if (
      data &&
      data.AdminOps &&
      data.AdminOps.updatePromoLeftSeats &&
      data.AdminOps.updatePromoLeftSeats.error
    ) {
      setError(data.AdminOps.updatePromoLeftSeats.error);
    }
  };

  return (
    <Grid>
      {dataLoading ? (
        <Card>
          <Loader className="position-static" />
        </Card>
      ) : (
        <Grid.Row>
          <div className="col-4 mx-auto">
            <div>Special Premium Credits Seats Left</div>
            <Form.Group>
              <Form.Input
                placeholder="count"
                label="PC 3 Year Promo"
                value={count.left_count}
                onChange={(e: { target: { value: React.SetStateAction<number> } }) =>
                  setCount({
                    ...count,
                    left_count: +e.target.value,
                  })
                }
              />
              {/* <Form.Input
                placeholder="count"
                label="PC Special $499/year"
                value={count.PC_SPECIAL_499}
                onChange={(e: { target: { value: React.SetStateAction<number> } }) =>
                  setCount({
                    ...count,
                    PC_SPECIAL_499: +e.target.value,
                  })
                }
              />

              <Form.Input
                placeholder="count"
                label="PC Special $999/year"
                value={count.PC_SPECIAL_999}
                onChange={(e: { target: { value: React.SetStateAction<number> } }) =>
                  setCount({
                    ...count,
                    PC_SPECIAL_999: +e.target.value,
                  })
                }
              /> */}

              <Button className="margin-bottom" onClick={handleSubmit} color="primary" loading={loading}>
                Save
              </Button>
            </Form.Group>
          </div>
        </Grid.Row>
      )}
    </Grid>
  );
};

export default RandomPremiums;
