import React, { useEffect, useState } from "react";
import { Grid, Button, Alert } from "tabler-react";
import ReactModal from "react-modal";

import { alertCustomStyles } from "../../helpers";
import { ContentTextProps } from "../../types";

import ServerSidePagination from "../../components/pagination/serverSidePagination";
import TableCard from "../../components/tableCard";
import TableModal from "./tableModal";

import "./style.css";

const HexosparkPublicTamplate = ({
  data,
  loading,
  createPublicTemplate,
  deletePublicTemplate,
  updatePublicTemplate,
  onPageNumberChange,
  refetch,
}: any): JSX.Element => {
  const [publicTemplateList, setPublicTemplateList] = useState<any>([]);

  const [dataLoading, setDataLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [editCategory, setEditCategory] = useState<any>(false);
  const [total, setTotal] = useState(0);
  const [editField, setEditField] = useState<any>(null);

  const handleModal = () => {
    if (showModal) {
      setEditField(null);
      setErrorMessage("");
    }
    setShowModal(!showModal);
  };

  const handleSave = async ({ ...rest }) => {
    const { text, categories, subject, name } = rest;

    if (editCategory) {
    } else {
      const res = await createPublicTemplate({
        variables: {
          settings: {
            categories: categories.map((item: any) => item.value),
            category: "SALES",
            body: text,
            subject,
            name,
            public: true,
          },
        },
      });
      if (res.data.HexosparkAdminOps && !res.data.HexosparkAdminOps.adminCreatePublicEmailTemplates) {
        handleModal();
        return;
      }
      const { error_code } = res.data.HexosparkAdminOps.adminCreatePublicEmailTemplates;
      if (error_code) {
        setErrorMessage(error_code);
        return;
      }
    }
    return true;
  };

  const onDelete = async (id: number) => {
    let deleteConfirm = confirm(`Are you sure you want to delete public template with ID: ${id}`);
    if (!deleteConfirm) return;
    const res = await deletePublicTemplate({
      variables: { settings: { ids: [id] } },
    });
    if (res.data.HexosparkAdminOps && !res.data.HexosparkAdminOps.adminDeleteBulkPublicEmailTemplates) {
      setPublicTemplateList(publicTemplateList.filter((item) => item.id !== id));
      return;
    }
    const { error_code } = res.data.HexosparkAdminOps.adminDeleteBulkPublicEmailTemplates;
    if (error_code) {
      setErrorMessage(error_code);
      return;
    }
  };
  const onUpdate = async ({ id, body, categories, name, subject }) => {
    const res = await updatePublicTemplate({
      variables: {
        settings: {
          id,
          body,
          categories,
          subject,
          name,
        },
      },
    });

    handleModal();
    if (res.data.HexosparkAdminOps && !res.data.HexosparkAdminOps.adminUpdatePublicEmailTemplates) {
      // const newCategories = publicTemplateList.map((item) => {
      //   if (item.id === id) {
      //     return { ...item, body, categories, name, subject };
      //   }
      //   return item;
      // });

      // setPublicTemplateList(newCategories);
      refetch();
      return;
    }
    const { error_code } = res.data.HexosparkAdminOps.adminUpdatePublicEmailTemplates;
    if (error_code) {
      setErrorMessage(error_code);
      return;
    }
  };

  const tableData = {
    tData:
      publicTemplateList &&
      publicTemplateList.map((item: ContentTextProps) => ({
        ID: item.id,
        "CREATED AT": item.created_at,
        NAME: item.name,
        SUBJECT: item.subject,
        BODY: item.body,
        CATEGORIES: item.categories.map((item: any) => item.label).join(", "),
        Action: (
          <Button.List>
            <Button
              color="primary"
              icon="edit"
              onClick={() => {
                setEditField(item);
                setShowModal(true);
              }}
            />
            <Button color="danger" icon="trash" onClick={() => onDelete(item.id)} />
          </Button.List>
        ),
      })),
  };

  useEffect(() => {
    if (
      data &&
      data.HexosparkAdmin &&
      data.HexosparkAdmin.adminGetPublicEmailTemplates.publicEmailTemplates
    ) {
      setDataLoading(loading);
      setPublicTemplateList(data.HexosparkAdmin.adminGetPublicEmailTemplates.publicEmailTemplates);
      setTotal(data.HexosparkAdmin.adminGetPublicEmailTemplates.count);
    }
  }, [data]);

  return (
    <Grid.Row cards deck>
      <TableModal
        handleModal={handleModal}
        showModal={showModal}
        onUpdate={onUpdate}
        onSave={handleSave}
        editField={editField}
        errorMessage={errorMessage}
      />

      <ReactModal isOpen={!!errorMessage} style={alertCustomStyles}>
        {errorMessage ? <Alert type="danger">{errorMessage}</Alert> : null}
        <Button className="" onClick={() => setErrorMessage("")} color="primary">
          OK
        </Button>
      </ReactModal>
      <div className="col-12 col-md-1 col-xs-6">
        <Button className="" onClick={() => setShowModal(true)} color="primary">
          Create
        </Button>
        <br />
      </div>
      <Grid.Col md={9}></Grid.Col>
      <div className="col-12 col-md-2 col-xs-6">
        <Alert type="info">Result Count - {total || publicTemplateList.length}</Alert>
      </div>
      <div className="black-list-table-pagination">
        <ServerSidePagination data={publicTemplateList} total={total} onPageNum={onPageNumberChange}>
          <TableCard loading={dataLoading} data={publicTemplateList} tableData={tableData} />
        </ServerSidePagination>
      </div>

      {publicTemplateList.length ? (
        <div className="col-12 col-md-2 col-xs-12 offset-md-10">
          <Alert type="info">Result Count - {total || publicTemplateList.length}</Alert>
        </div>
      ) : null}
    </Grid.Row>
  );
};

export default HexosparkPublicTamplate;
