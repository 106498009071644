import React, { useEffect, useState } from "react";
import { Alert, Button, Form } from "tabler-react";
import ReactModal from "react-modal";
import "react-mde/lib/styles/css/react-mde-all.css";

ReactModal.setAppElement('#root');

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    height: '50%'
  }
};

const TableModal = (
  { handleModal, showModal, onSave, editField, onUpdate, errorMessage }: any
): JSX.Element => {

  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState("");
  const [jsonSchema, setJsonSchema] = useState("")
  const [errMsg, setErrMsg] = useState("")

  const handleOk = async () => {
    errMsg && setErrMsg("")
    if (!jsonSchema) {
      setErrMsg('before save please fill schema field')
      return
    }
    if(editField) {
      await onUpdate({id: editField.id, schema: jsonSchema, comment})
      setLoading(false);
      return
    }
    setLoading(true);
    const formData = { schema: jsonSchema, comment };
    const completed = await onSave({ ...formData });
    setLoading(false);
    if (completed) handleCancel();
  };

  const handleCancel = () => {
    setComment("");
    setJsonSchema("")
    handleModal();
  };


  useEffect(() => {
    if (window.innerWidth < 640) {
      customStyles.content.width = '90%'
    }
  }, [])


  useEffect(() => {
    if (editField) {
      setComment(editField.comment)
      setJsonSchema(editField.schema)
    }
  }, [editField])


  return (
    <div style={{ height: 0 }}>
      <ReactModal
        isOpen={showModal}
        contentLabel="Create type"
        style={customStyles}
        onRequestClose={handleCancel}
      >
        <h4>{editField ? "Edit type" : "Create automation type"}</h4>
        {errorMessage || errMsg ?
          <Alert type="danger">
            {errorMessage || errMsg}
          </Alert> : null}
        <Form onSubmit={(e: React.FormEvent) => e.preventDefault()}>
          <Form.Textarea
            required
            type="text"
            placeholder='comment'
            label="comment"
            value={comment}
            onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => setComment(e.target.value)}
          />
          <Form.Textarea
            required
            type="text"
            placeholder='json schema'
            label="json schema"
            value={jsonSchema}
            onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => setJsonSchema(e.target.value)}
          />
          {errorMessage || errMsg ?
            <Alert type="danger">
              {errorMessage || errMsg}
            </Alert> : null}
          <Button.List style={{ marginTop: "5%" }}>
            <Button onClick={handleOk} color="primary" type="submit" loading={loading}>Save</Button>
            <Button onClick={handleCancel} color="secondary" type="button">Cancel</Button>
          </Button.List>
        </Form>
      </ReactModal>
    </div>
  )
};

export default TableModal;
