import React, { ChangeEvent, FormEvent, SyntheticEvent, useEffect, useState } from "react";
import moment from "moment";
import { Grid, Button, Alert, Form } from "tabler-react";
import DatePicker from "react-datepicker";
import { useMutation, useQuery } from "@apollo/react-hooks";

import ServerSidePagination from "../../components/pagination/serverSidePagination";
import TableCard from "../../components/tableCard";

import { GET_SCRAPING_CATEGORIES } from "../../graphql/queries";
import {
  UPDATE_PUBLIC_SCRAPING_RECIPE,
  UPDATE_PUBLIC_SCRAPING_RECIPE_AND_CATEGORIES,
} from "../../graphql/mutations";
import { ContentTextProps } from "../../types";
import TableModal from "./tableModal";

import "./style.css";

export interface Category {
  name: string;
  id: string;
}

const PublicScrapingRecipes = ({
  data,
  loading,
  searchEmail,
  onPageNumberChange,
  setHideHex,
  hideHex,
  ...props
}: any): JSX.Element => {
  const [publicScrapingRecipesList, setPublicScrapingRecipesList] = useState<Category[]>([]);
  const [categoriesList, setCategoriesList] = useState<Category[]>([]);

  const [errorMessage, setErrorMessage] = useState("");
  const [filterInput, setFilterInput] = useState("");

  const [editValue, setEditValue] = useState<any>();

  const [dataLoading, setDataLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [mostUsed, setMostUsed] = useState(false);

  const [total, setTotal] = useState(0);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [filterCategoryIds, setFilterCategoryIds] = useState<string[] | null>(null);

  const [updatePublicScrapingCategory] = useMutation(UPDATE_PUBLIC_SCRAPING_RECIPE_AND_CATEGORIES);
  const [updatePublicScrapingRecipe] = useMutation(UPDATE_PUBLIC_SCRAPING_RECIPE);

  const { data: categoriesData } = useQuery(GET_SCRAPING_CATEGORIES, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (
      categoriesData &&
      categoriesData.HexomaticScrapingCategory &&
      categoriesData.HexomaticScrapingCategory.getScrapingCategories &&
      categoriesData.HexomaticScrapingCategory.getScrapingCategories.categories
    ) {
      setCategoriesList(categoriesData.HexomaticScrapingCategory.getScrapingCategories.categories);
    }
  }, [categoriesData]);

  const handleModal = () => {
    if (showModal) {
      setEditValue(null);
      setErrorMessage("");
    }
    setShowModal(!showModal);
  };

  const handleSubmit = (event: FormEvent, input: string) => {
    event.preventDefault();
    props.setFilterInput(input.trim());
    props.setToDate(toDate);
    props.setFromDate(fromDate);
    props.setCategoryIds(filterCategoryIds);
    props.setIsNew(isNew);
    props.setMostUsed(mostUsed);
  };

  const handleFromDate = (date: Date) => {
    if (date) {
      return setFromDate(moment(date).format("YYYY-MM-DDT00:00:00.000"));
    }
    setFromDate(null);
  };

  const handleToDate = (date: Date) => {
    if (date) {
      return setToDate(moment(date).format("YYYY-MM-DDT23:59:59.999"));
    }
    setToDate(null);
  };

  const handleSave = async ({ ...rest }) => {
    const { scrapingRecipeId, categoryIds, currCategoryIds } = rest;

    if (!scrapingRecipeId) {
      return false;
    }

    const added: string[] = [];
    const deleted: string[] = [];

    categoryIds.forEach((item: string) => {
      if (!currCategoryIds.includes(item)) {
        added.push(item);
      }
    });

    currCategoryIds.forEach((item: string) => {
      if (!categoryIds.includes(item)) {
        deleted.push(item);
      }
    });

    try {
      const res = await updatePublicScrapingCategory({
        variables: {
          settings: {
            id: scrapingRecipeId,
            categoryIds: {
              added,
              deleted,
            },
          },
        },
      });
      if (!res.data.HexomaticPublicScrapingRecipeCategoryOps.updatePublicScrapingRecipeAndCategory) {
        props.refetch();
      }
    } catch (error) {
      console.log(error);
      return false;
    }

    return true;
  };

  const textCheckbox = ({ item, name }: { item: any; name: string }) => {
    return (
      <div style={{ display: "flex" }}>
        <span style={{ marginRight: "10px", textTransform: "capitalize" }}>{name.replace(/_/g, " ")}</span>
        <Form.Checkbox
          className="crs-point"
          label=" "
          checked={item[name]}
          onChange={async (e: SyntheticEvent) => {
            e.stopPropagation();

            const res = await updatePublicScrapingRecipe({
              variables: {
                settings: {
                  id: item.id,
                  is_new: name === "is_new" ? !item.is_new : null,
                  most_used: name === "most_used" ? !item.most_used : null,
                },
              },
            });

            if (!res.data.HexomaticPublicScrapingRecipeOps.updatePublicScrapingRecipe) {
              props.refetch();
            }
          }}
        />
      </div>
    );
  };

  const tableData = {
    tData:
      publicScrapingRecipesList &&
      publicScrapingRecipesList.map((item: ContentTextProps) => ({
        id: item.id,
        user_email: item.user_email,
        name: item.name,
        url: item.url,
        // actions: item.actions,
        status: item.status,
        category:
          item.categories && item.categories.length
            ? item.categories.map((item: Category) => <p key={item.id}>{item.name}</p>)
            : "N/A",
        is_new: textCheckbox({ item, name: "is_new" }),
        most_used: textCheckbox({ item, name: "most_used" }),

        Action: (
          <Button.List>
            <Button
              color="primary"
              icon="edit"
              onClick={() => {
                setEditValue(item);
                setShowModal(true);
              }}
            />
            {/* <Button color="danger" icon="trash" onClick={() => console.log("delete")} /> */}
          </Button.List>
        ),
      })),
  };

  useEffect(() => {
    if (
      data &&
      data.HexomaticPublicScrapingRecipe &&
      data.HexomaticPublicScrapingRecipe.getPublicScrapingRecipes.public_scraping_recipes
    ) {
      setDataLoading(loading);
      setPublicScrapingRecipesList(
        data.HexomaticPublicScrapingRecipe.getPublicScrapingRecipes.public_scraping_recipes
      );
      setTotal(data.HexomaticPublicScrapingRecipe.getPublicScrapingRecipes.total);
    }
  }, [data]);

  const handleInputChange = (e: React.FormEvent) => setFilterInput((e.target as HTMLInputElement).value);

  const handleSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "all") {
      setFilterCategoryIds(null);
      return;
    }
    setFilterCategoryIds([e.target.value]);
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Col md={2}>
          <Form.Checkbox
            label="Filter Hexact recipes"
            checked={hideHex}
            onChange={() => setHideHex(!hideHex)}
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row cards deck>
        <TableModal
          categoriesList={categoriesList}
          handleModal={handleModal}
          showModal={showModal}
          onSave={handleSave}
          editValue={editValue}
          errorMessage={errorMessage}
        />
        <div className="col-12 col-md-8"></div>
        <div className="col-12 col-md-4">
          <form onSubmit={(e) => handleSubmit(e, filterInput)}>
            <Form.Group>
              <Grid.Row>
                <Grid.Col>
                  <Form.Group label="From Date">
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      selected={fromDate ? new Date(fromDate) : null}
                      onChange={handleFromDate}
                      showMonthDropdown
                      placeholderText="Select a date"
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col>
                  <Form.Group label="To Date">
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      selected={toDate ? new Date(toDate) : null}
                      onChange={handleToDate}
                      showMonthDropdown
                      placeholderText="Select a date"
                      minDate={fromDate && new Date(fromDate)}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>

              <Grid.Row>
                <Grid.Col>
                  <Form.Input
                    label="Input"
                    className="margin-bottom"
                    icon="search"
                    position="append"
                    placeholder="Search for User Email, User Name"
                    value={filterInput}
                    onChange={handleInputChange}
                  />
                </Grid.Col>
              </Grid.Row>

              <Grid.Row>
                <Grid.Col>
                  <Form.Select label="Select" onChange={handleSelect}>
                    <option value="all">All</option>

                    {categoriesList.map((item: Category) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Form.Select>
                </Grid.Col>
              </Grid.Row>

              <Grid.Row className="mb-4">
                <Grid.Col>
                  <Form.Checkbox
                    className="crs-point"
                    label="Is New"
                    checked={isNew}
                    onChange={() => setIsNew(!isNew)}
                  />
                  <Form.Checkbox
                    className="crs-point"
                    label="Most used"
                    checked={mostUsed}
                    onChange={() => setMostUsed(!mostUsed)}
                  />
                </Grid.Col>
              </Grid.Row>

              <Button
                type="submit"
                icon="filter"
                color="primary"
                className=""
                loading={dataLoading}
                // onClick={() => {}}
              >
                Filter
              </Button>
            </Form.Group>
          </form>
        </div>

        {/* <div className="col-12 col-md-1 col-xs-6">
        <Button
          className=""
          onClick={() => handleKeywordMutate(null)}
          color="primary"
        >Create</Button>
        <br />
      </div> */}
        <Grid.Col md={10}></Grid.Col>
        <div className="col-12 col-md-2 col-xs-6">
          <Alert type="info">Result Count - {total}</Alert>
        </div>
        <div className="black-list-table-pagination">
          <ServerSidePagination data={publicScrapingRecipesList} total={total} onPageNum={onPageNumberChange}>
            <TableCard loading={dataLoading} data={publicScrapingRecipesList} tableData={tableData} />
          </ServerSidePagination>
        </div>

        {publicScrapingRecipesList.length ? (
          <div className="col-12 col-md-2 col-xs-12 offset-md-10">
            <Alert type="info">Result Count - {total}</Alert>
          </div>
        ) : null}
      </Grid.Row>
    </Grid>
  );
};

export default PublicScrapingRecipes;
