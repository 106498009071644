import React, { useState, useEffect } from "react";
import { Alert, Grid, Button, Form } from "tabler-react";
import moment from "moment";
import { useMutation } from "@apollo/react-hooks";
import DatePicker from "react-datepicker";
import { CREATE_HEXOSPARK_MONTHLY_REPORT } from "../../graphql/mutations";

interface HexosparkMonthlyReportProps {
  data?: {
    HexosparkAdmin: {
      getHsWorkspacesPerUserAdmin: {
        count: number;
        result: any;
      };
    };
  };
  loading?: boolean;
  orderByASC: boolean;
  variables: { settings: Object };
  refetchData?: () => void;
  onFilterSearch?: (data: string) => void;
  setOrderBy: (column: string) => void | Promise<void>;
  setOrderByASC: (isASC: boolean) => void | Promise<void>;
  filterHexScans: boolean;
  setFilterHexScans: (val: boolean) => void;
  onToDate: (date: string) => void;
}

const HexosparkMonthlyReport = ({ refetchData, ...props }: HexosparkMonthlyReportProps): JSX.Element => {
  const [total, setTotal] = useState(0);
  const [toDate, setToDate] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [activityValue, setActivityValue] = useState("All");

  const [sendHexosparkMonthlyReport] = useMutation(CREATE_HEXOSPARK_MONTHLY_REPORT);

  const handleSendHexosparkMonthlyReport = async (test?: boolean) => {
    setErrorMessage('');
    setSuccessMessage('');
    const { data } = await sendHexosparkMonthlyReport({
      variables: {
        settings: {
          activity: !(activityValue === "All"),
          period: toDate,
          test: !!test,
        },
      },
    });

    const message =
      data &&
      data.HexosparkAdminOps &&
      data.HexosparkAdminOps.createMonthlyReport &&
      data.HexosparkAdminOps.createMonthlyReport.error_code;

    if (message) {
      setErrorMessage(message);
    } else {
      setSuccessMessage("Sending monthly reports has been successfully started");
    }
  };

  useEffect(() => {
    if (
      props.data &&
      props.data.HexosparkAdmin &&
      props.data.HexosparkAdmin.getHsWorkspacesPerUserAdmin &&
      props.data.HexosparkAdmin.getHsWorkspacesPerUserAdmin.result
    ) {
      setTotal(props.data.HexosparkAdmin.getHsWorkspacesPerUserAdmin.count);
    }
  }, [props.data]);

  const handleSearch = (e: React.FormEvent) => setActivityValue((e.target as HTMLInputElement).value);

  const handleToDate = (date: Date) => {
    if (date) {
      setToDate(moment(date).format("yyyy-MM"));
      return;
    }
    setToDate(null);
  };

  const handleSubmit = (event: React.FormEvent, endDate: string) => {
    event.preventDefault();
    props.onToDate(endDate);
  };

  const openConfirmationPopup = (test?: boolean) => {
    if (!toDate) {
      setErrorMessage("Please specify the period");
      return;
    }
    const confirmed = confirm(
      test
        ? "Are you sure you want to send monthly reports to test accounts"
        : "Are you sure you want to sending monthly reports to all Hexospark users"
    );
    if (!confirmed) return;
    handleSendHexosparkMonthlyReport(!!test);
  };

  return (
    <Grid>
      <Grid.Row>
        <div className="col-12 col-md-8"></div>
        <div className="col-12 col-md-4">
          <form onSubmit={(e) => handleSubmit(e, toDate)}>
            <Form.Group>
              <Form.Select onChange={handleSearch} label="Activity" value={activityValue}>
                {["All", "Only active"].map((pack) => (
                  <option key={pack} value={pack}>
                    {pack}
                  </option>
                ))}
              </Form.Select>
              <Form.Group label="Period">
                <DatePicker
                  dateFormat="yyyy-MM"
                  selected={toDate ? new Date(toDate) : null}
                  onChange={handleToDate}
                  showMonthDropdown
                  placeholderText="Select a date"
                  showMonthYearPicker
                />
              </Form.Group>

              <div>
                <Button
                  onClick={() => openConfirmationPopup(true)}
                  className="margin-bottom"
                  type="submit"
                  color="primary"
                >
                  Send test email
                </Button>
                <Button
                  onClick={() => openConfirmationPopup()}
                  className="margin-bottom ml-5"
                  type="submit"
                  color="primary "
                >
                  Send
                </Button>
              </div>

              <div>
                <span>
                  <strong>"Send test email"</strong> will be sent to all Hexact users
                </span>
              </div>
              {errorMessage ? <Alert type="danger">{errorMessage}</Alert> : null}
              {successMessage ?  <Alert type="success">{successMessage}</Alert> : null}
            </Form.Group>
          </form>
        </div>
      </Grid.Row>
    </Grid>
  );
};

export default HexosparkMonthlyReport;
