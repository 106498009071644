import React, { useState, useEffect } from "react";
import {
  CREATE_AUTOMATION_UNIT,
  DELETE_AUTOMATION_UNIT,
  UPDATE_AUTOMATION_UNIT,
} from "../../graphql/mutations";
import { GET_AUTOMATION_UNITS } from "../../graphql/queries";
import AutomationUnits from "./index";
import { useQuery, useMutation } from "@apollo/react-hooks";

const AutomationUnitQueries = () => {
  const [dataList, setDataList] = useState<any>([]);
  const [pageNumberState, setPageNumberState] = useState(1);
  const handlePageNumberChange = (pageNumber: number) =>
    setPageNumberState(pageNumber);
  const [searchEmail, setsearchEmail] = useState<string>("");

  const { data, loading, refetch } = useQuery(GET_AUTOMATION_UNITS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      settings: {
        filter: {
          limit: 100,
        },
      },
    },
  });

  useEffect(() => {
    refetch();
  }, [pageNumberState]);

  useEffect(() => {
    setDataList(data);
  }, [data]);

  useEffect(() => {
    refetchData();
  }, [searchEmail]);

  const refetchData = async () => {
    await refetch();
  };

  const [deleteUnit] = useMutation(DELETE_AUTOMATION_UNIT);

  const [updateUnit] = useMutation(UPDATE_AUTOMATION_UNIT);

  const [createAutomationUnit] = useMutation(CREATE_AUTOMATION_UNIT, {
    onCompleted: () => {
      refetchData();
    },
  });

  return (
    <AutomationUnits
      data={dataList}
      loading={loading}
      searchEmail={searchEmail}
      setsearchEmail={setsearchEmail}
      onPageNumberChange={handlePageNumberChange}
      createAutomationUnit={createAutomationUnit}
      deleteUnit={deleteUnit}
      updateUnit={updateUnit}
    />
  );
};

export default AutomationUnitQueries;
