import React, { useState, useEffect, FC } from "react";
import { useQuery } from "@apollo/react-hooks";

import { GET_PUBLIC_SCRAPING_RECIPES } from "../../graphql/queries";
import PublicScrapingRecipes from "./index";

const PublicScrapingRecipesQueries: FC = () => {
  const [dataList, setDataList] = useState<any>([]);
  const [pageNumberState, setPageNumberState] = useState(1);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [isNew, setIsNew] = useState(false);
  const [mostUsed, setMostUsed] = useState(false);

  const [filterInput, setFilterInput] = useState("");

  const [categoryIds, setCategoryIds] = useState<string[] | null>(null);

  const [filterHexScans, setFilterHexScans] = useState(true);

  const { data, loading, refetch } = useQuery(GET_PUBLIC_SCRAPING_RECIPES, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      settings: {
        filter: {
          page: pageNumberState,
          date_from: fromDate,
          date_to: toDate,
          limit: 100,
          is_new: isNew ? true : undefined,
          most_used: mostUsed ? true : undefined,
          search: {
            search_value: filterInput,
          },
          categoryIds,
          hideHex: filterHexScans,
        },
      },
    },
  });

  useEffect(() => {
    refetch();
  }, [pageNumberState]);

  useEffect(() => {
    setDataList(data);
  }, [data]);

  useEffect(() => {
    refetchData();
  }, [filterInput]);

  const refetchData = async () => {
    await refetch();
  };

  const handlePageNumberChange = (pageNumber: number) => setPageNumberState(pageNumber);

  return (
    <PublicScrapingRecipes
      data={dataList}
      setCategoryIds={setCategoryIds}
      loading={loading}
      onPageNumberChange={handlePageNumberChange}
      setFromDate={setFromDate}
      setIsNew={setIsNew}
      setMostUsed={setMostUsed}
      setToDate={setToDate}
      setFilterInput={setFilterInput}
      setHideHex={setFilterHexScans}
      hideHex={filterHexScans}
      refetch={refetch}
    />
  );
};

export default PublicScrapingRecipesQueries;
