import React, { useEffect, useState, FormEvent, FC } from "react";
import { Grid, Button, Form, Alert } from "tabler-react";
import { ContentTextProps, PropertiesProps } from "../../types";
import moment from "moment";
import ServerSidePagination from "../../components/pagination/serverSidePagination";
import TableCard from "../../components/tableCard";
import TableModal from "./modal";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import Chart from "../../components/chart";
import {
  GET_GRAPH_TRAFFIC_AND_CREDITS,
  GET_USER_DAILY_TRAFFIC_AND_CREDIT,
} from "../../graphql/queries";
import DatePicker from "react-datepicker";
import TaskModal from "./taskModal";
import { getCountArr, isProduction } from "../../helpers";
import { filterButtons } from "../../components/piechart";

import "./index.css";

const Credit: FC = () => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [userTotalCount, setUserTotalCount] = useState(0);

  const [errorMessage, setErrorMessage] = useState("");
  const [mutationCompleted, setMutationCompleted] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [editProperty, setEditProperty] = useState<any>();
  const [showTaskModal, setShowTaskModal] = useState(false);

  const [orderBy, setOrderBy] = useState("");
  const [orderByASC, setOrderByASC] = useState(false);
  const [pageNumberState, setPageNumberState] = useState(1);
  const [tbData, setTbData] = useState([] as any);
  const [searchEmail, setSearchEmail] = useState("");
  const showButtons = localStorage.getItem("_rep");
  const [filterHexScans, setFilterHexScans] = useState(true);

  const [inputValues, setInputValues] = useState({
    userEmail: "",
  });
  const [dateValues, setDateValues] = useState({
    fmDate: null,
    tDate: null,
  });

  const [creditsUsageChartFilter, setCreditsUsageChartFilter] = useState(
    filterButtons[5]
  );

  const handleInputChange = (e: any): void => {
    const { name, value } = e.target;

    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  const handlePageNumberChange = (pageNumber: number) =>
    setPageNumberState(pageNumber);

  const handleModal = () => {
    if (showModal) {
      setEditProperty(null);
      setErrorMessage("");
    }
    setShowModal(!showModal);
  };

  const handleSave = async ({ ...rest }) => {
    return true;
  };

  const [getCSV, { data: CSVData, loading: CSVLoading }] = useLazyQuery(
    GET_USER_DAILY_TRAFFIC_AND_CREDIT
  );

  const [uploadToDrive, { data: driveData, loading: driveDataLoading }] =
    useLazyQuery(GET_USER_DAILY_TRAFFIC_AND_CREDIT);

  const {
    data: trafficAndCredicData,
    error: trafficAndCredicDataError,
    loading: trafficAndCredicDataLoading,
  } = useQuery(GET_GRAPH_TRAFFIC_AND_CREDITS, {
    variables: {
      settings: {
        from: creditsUsageChartFilter.fromDate,
        to: creditsUsageChartFilter.toDate,
        hideHex: filterHexScans,
      },
    },
  });

  const {
    data: trafficAndCredicDataTable,
    error: trafficAndCredicDataTableError,
    loading: trafficAndCredicDataTableLoading,
  } = useQuery(GET_USER_DAILY_TRAFFIC_AND_CREDIT, {
    variables: {
      settings: {
        email_search_value: searchEmail,
        filter: {
          limit: 100,
          page: pageNumberState,
          date_from: dateValues.fmDate,
          date_to: dateValues.tDate,
        },
        hideHex: filterHexScans,
      },
    },
  });

  useEffect(() => {
    if (
      driveData &&
      driveData.HexomaticAdmin &&
      driveData.HexomaticAdmin.usersDailyStats &&
      driveData.HexomaticAdmin.usersDailyStats.csvURL
    ) {
      window.open(
        isProduction()
          ? `https://api.hexometer.com/v2/app/social-callback/googleDrive?admin=admin&report=userDailyStatsReports&url=${driveData.HexomaticAdmin.usersDailyStats.csvURL}`
          : `http://localhost:4000/api/app/social-callback/googleDrive?admin=admin&report=userDailyStatsReports&url=${driveData.HexomaticAdmin.usersDailyStats.csvURL}`,
        "_blank"
      );
    }
  }, [driveData]);

  useEffect(() => {
    if (
      trafficAndCredicDataTable &&
      trafficAndCredicDataTable &&
      trafficAndCredicDataTable.HexomaticAdmin &&
      trafficAndCredicDataTable.HexomaticAdmin.usersDailyStats &&
      trafficAndCredicDataTable.HexomaticAdmin.usersDailyStats.daily_stats
    ) {
      setTbData(
        JSON.parse(
          trafficAndCredicDataTable &&
            trafficAndCredicDataTable &&
            trafficAndCredicDataTable.HexomaticAdmin &&
            trafficAndCredicDataTable.HexomaticAdmin.usersDailyStats &&
            trafficAndCredicDataTable.HexomaticAdmin.usersDailyStats.daily_stats
        )
      );

      setUserTotalCount(
        trafficAndCredicDataTable.HexomaticAdmin.usersDailyStats
          .hc_daily_stats_count
      );
    }
  }, [trafficAndCredicDataTable]);

  const tableData = {
    tData:
      tbData &&
      tbData.map((item) => ({
        id: item.user && item.user.id,
        "User Email": (item.user && item.user.email) || "N/A",
        date: moment(item.date).utc().format("DD-MM-YYYY hh:mm:ss a"),
        "data center":
          (item.stats &&
            item.stats.network &&
            `${(item.stats.network / 1024 / 1024).toFixed(2)} mb`) ||
          "N/A",
        "residential geo":
          (item.stats &&
            item.stats.proxy_premium &&
            `${(item.stats.proxy_premium / 1024 / 1024).toFixed(2)} mb`) ||
          "N/A",
        residential:
          (item.stats &&
            item.stats.proxy_unmetered &&
            `${(item.stats.proxy_unmetered / 1024 / 1024).toFixed(2)} mb`) ||
          "N/A",
        request: (item.stats && item.stats.request) || "N/A",
      })),
  };

  const handleFromDate = (date: Date) => {
    if (date) {
      return setFromDate(moment(date).format("YYYY-MM-DDT00:00:00.000"));
    }
    setFromDate(null);
  };

  const handleToDate = (date: Date) => {
    if (date) {
      return setToDate(moment(date).format("YYYY-MM-DDT23:59:59.999"));
    }
    setToDate(null);
  };

  const handleSubmit = (event: FormEvent, userEmail: string) => {
    event.preventDefault();
    setDateValues({
      fmDate: fromDate,
      tDate: toDate,
    });

    // if (userEmail) {
    setSearchEmail(userEmail);
    // }
  };

  const handleSortingClick = (column: string) => {
    if (column === "Created At") {
      setOrderBy("createdAt");
      setOrderByASC(!orderByASC);
    }

    if (column === "Updated At") {
      setOrderBy("updatedAt");
      setOrderByASC(!orderByASC);
    }
  };

  const createRegDataArray = (array: any[], objectFiled: string): any[] => {
    const result = [];

    array.forEach((obj: any) => {
      if (obj[objectFiled]) {
        result.push({
          count: (obj[objectFiled] / 1024 / 1024).toFixed(2),
          time: obj.date,
        });
      }
    });

    return result;
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Col md={2}>
          <Form.Checkbox
            label="Filter Hexact usage"
            checked={filterHexScans}
            onChange={() => setFilterHexScans(!filterHexScans)}
          />
        </Grid.Col>
     </Grid.Row>
      <Grid.Row>
        <div className="col-12 col-md-8">
          {!trafficAndCredicDataLoading &&
            trafficAndCredicData &&
            trafficAndCredicData.HexomaticAdmin && 
            trafficAndCredicData.HexomaticAdmin.timeSeriesTrafficAndCredits &&
            trafficAndCredicData.HexomaticAdmin.timeSeriesTrafficAndCredits
              .time_series && (
              <Chart
                yColName="Data Center mb"
                yColName2="Residential Geo mb"
                yColName3="Residential mb"
                regData={createRegDataArray(
                  trafficAndCredicData.HexomaticAdmin
                    .timeSeriesTrafficAndCredits.time_series,
                  "data_center"
                )}
                regData2={createRegDataArray(
                  trafficAndCredicData.HexomaticAdmin
                    .timeSeriesTrafficAndCredits.time_series,
                  "residential_geo"
                )}
                regData3={createRegDataArray(
                  trafficAndCredicData.HexomaticAdmin
                    .timeSeriesTrafficAndCredits.time_series,
                  "residential"
                )}
                interval={getCountArr(
                  trafficAndCredicData.HexomaticAdmin
                    .timeSeriesTrafficAndCredits.time_series.length
                )}
                chartName="Hexomatic traffic usage mb"
                propColors={["#0f6a7d", "#16ac59", "#a087b2"]}
              />
            )}
        </div>
        <div className="col-12 col-md-4">
          <form onSubmit={(e) => handleSubmit(e, inputValues.userEmail)}>
            <Form.Group>
              <Form.Input
                label="Input"
                className="margin-bottom"
                icon="search"
                position="append"
                name="userEmail"
                placeholder="Type user email for search"
                value={inputValues.userEmail}
                onChange={handleInputChange}
              />

              <Grid.Row>
                <Grid.Col>
                  <Form.Group label="From Date">
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      selected={fromDate ? new Date(fromDate) : null}
                      onChange={handleFromDate}
                      showMonthDropdown
                      placeholderText="Select a date"
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col>
                  <Form.Group label="To Date">
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      selected={toDate ? new Date(toDate) : null}
                      onChange={handleToDate}
                      showMonthDropdown
                      placeholderText="Select a date"
                      minDate={fromDate && new Date(fromDate)}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
              <Button
                className="margin-bottom"
                type="submit"
                icon="filter"
                color="primary"
                loading={trafficAndCredicDataTableLoading}
              >
                Filter
              </Button>
            </Form.Group>
          </form>
        </div>
      </Grid.Row>
      <Grid.Row className={showButtons === 'true' ? "justify-content-between" : "justify-content-end"}>
        {showButtons === 'true' && (
          <div className="col-12 col-md-3 col-xs-12">
            <Button.List>
              <Button
                onClick={() =>
                  getCSV({
                    variables: {
                      settings: {
                        email_search_value: searchEmail,
                        filter: {
                          limit: undefined,
                          page: undefined,
                          date_from: dateValues.fmDate,
                          date_to: dateValues.tDate,
                        },
                        hideHex: filterHexScans,
                        csvDownload: true,
                      },
                    },
                  })
                }
                loading={CSVLoading}
                color="primary"
              >
                Get CSV Link
              </Button>

              <Button
                onClick={() =>
                  uploadToDrive({
                    variables: {
                      settings: {
                        email_search_value: searchEmail,
                        filter: {
                          limit: undefined,
                          page: undefined,
                          date_from: dateValues.fmDate,
                          date_to: dateValues.tDate,
                        },
                        hideHex: filterHexScans,
                        csvDownload: true,
                      },
                    },
                  })
                }
                loading={driveDataLoading}
                color="primary"
              >
                Save to Drive
              </Button>
            </Button.List>
            {!CSVLoading &&
              CSVData &&
              CSVData.HexomaticAdmin &&
              CSVData.HexomaticAdmin.usersDailyStats &&
              CSVData.HexomaticAdmin.usersDailyStats.csvURL && (
                <Button
                  RootComponent="a"
                  href={CSVData.HexomaticAdmin.usersDailyStats.csvURL}
                  icon="download"
                >
                  Download CSV
                </Button>
              )}
            <br />
          </div>
        )}
        <Grid.Col md={3}>
          <Alert type="info">Result Count - {userTotalCount}</Alert>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row cards deck>
        <TableModal
          handleModal={handleModal}
          showModal={showModal}
          onSave={handleSave}
          property={editProperty}
          errorMessage={errorMessage}
        />
        <ServerSidePagination
          data={tbData && tbData}
          total={userTotalCount}
          onPageNum={handlePageNumberChange}
        >
          <TableCard
            loading={trafficAndCredicDataTableLoading}
            data={tbData && tbData}
            tableData={tableData}
            onHeaderClick={handleSortingClick}
          />
        </ServerSidePagination>
      </Grid.Row>
      <Grid.Row className="justify-content-end">
        {userTotalCount ? (
          <Grid.Col md={3}>
            <Alert type="info">Result Count - {userTotalCount}</Alert>
          </Grid.Col>
        ) : null}
      </Grid.Row>
    </Grid>
  );
};

export default Credit;
