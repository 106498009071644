import React, { useState, useEffect } from "react";
import { Grid, Button, Alert, Form } from "tabler-react";
import moment from "moment";
import DatePicker from "react-datepicker";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";

import {
  HEXOMATIC_NOTIFICATIONS_CHART,
  GET_HEXOMATIC_NOTIFICATIONS,
  GET_HEXOMATIC_NOTIFICATION_TYPE_OPTIONS,
} from "../../graphql/queries";

import ServerSidePagination from "../../components/pagination/serverSidePagination";
import TableCard from "../../components/tableCard";
import Chart from "../../components/chart";

import { getCountArr, isProduction } from "../../helpers";

interface HexomaticNotificationProps {
  constants: any;
  data: {
    HexomaticNotification: {
      adminGetMany: {
        total: number;
        notifications: any;
      };
    };
  };
  loading: boolean;
  orderByASC: boolean;
  variables: Object;
  refetchData: () => void;
  onPageNumberChange: (pageNumber: number) => void;
  onFilterDataChange: (data: string) => void;
  onFromDate: (date: string) => void;
  onToDate: (date: string) => void;
  onSelectChannel: (channel: string) => void;
  onSelectType: (channel: string) => void;
  setOrderBy: (column: string) => void | Promise<void>;
  setOrderByASC: (isASC: boolean) => void | Promise<void>;
  filterHexScans: boolean;
  setFilterHexScans: (val: boolean) => void;
}

const HexomaticNotifications = ({ refetchData, ...props }: HexomaticNotificationProps): JSX.Element => {
  const [baseNotifications, setBaseNotifications] = useState([]);
  const [constants, setConstants] = useState([]);
  const [notificationsPagination, setNotificationsPagination] = useState([]);
  const [typeOptions, setTypeOptions] = useState<{ type: string }[]>(null);

  const [total, setTotal] = useState(0);
  const [dataLoading, setDataLoading] = useState(true);
  const [showtypes, setShowtypes] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [filterInput, setFilterInput] = useState("");
  const [channel, setChannel] = useState("");
  const [type, setType] = useState("");
  const showButtons = localStorage.getItem("_rep");

  const channels = ["all", "email"];

  const [getCSV, { data, loading }] = useLazyQuery(GET_HEXOMATIC_NOTIFICATIONS);
  const [uploadToDrive, { data: driveData, loading: driveDataLoading }] =
    useLazyQuery(GET_HEXOMATIC_NOTIFICATIONS);

  const getTypes = useQuery(GET_HEXOMATIC_NOTIFICATION_TYPE_OPTIONS, {
    skip: !!typeOptions,
    variables: {
      hideHex: props.filterHexScans,
    },
  });

  const emailsChartDataQuery = useQuery(HEXOMATIC_NOTIFICATIONS_CHART, {
    variables: { channel: "email" },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const tableConst = {
    tData:
      constants &&
      constants.map((item) => ({
        Type: item.type,
        Description: item.description,
      })),
  };

  const tableData = {
    tData:
      baseNotifications &&
      baseNotifications.map((item) => ({
        ID: item.id,
        Channel: item.channel,
        Type: item.type,
        "Created At": moment(item.createdAt).utc().format("DD-MM-YYYY hh:mm:ss a"),
        User: item.user && item.user.email,
        workflowId: item.workflow ? item.workflow.id : "N/A",
      })),
  };

  useEffect(() => {
    if (
      props.data &&
      props.data.HexomaticNotification &&
      props.data.HexomaticNotification.adminGetMany &&
      props.data.HexomaticNotification.adminGetMany.notifications
    ) {
      setDataLoading(props.loading);
      setBaseNotifications(props.data.HexomaticNotification.adminGetMany.notifications);
      setNotificationsPagination(props.data.HexomaticNotification.adminGetMany.notifications);
      setTotal(props.data.HexomaticNotification.adminGetMany.total);
    }
  }, [props.data]);

  useEffect(() => {
    if (
      getTypes &&
      getTypes.data &&
      getTypes.data.HexomaticNotification &&
      getTypes.data.HexomaticNotification.hexomaticNotificationTypes &&
      getTypes.data.HexomaticNotification.hexomaticNotificationTypes.types
    ) {
      setTypeOptions([
        { type: "all" },
        ...getTypes.data.HexomaticNotification.hexomaticNotificationTypes.types,
      ]);
    }
  }, [getTypes]);

  useEffect(() => {
    if (
      driveData &&
      driveData.HexomaticNotification &&
      driveData.HexomaticNotification.adminGetMany &&
      driveData.HexomaticNotification.adminGetMany.csvURL
    ) {
      window.open(
        isProduction()
          ? `https://api.hexometer.com/v2/app/social-callback/googleDrive?admin=admin&report=wpNotifications&url=${driveData.HexomaticNotification.adminGetMany.csvURL}`
          : `http://localhost:4000/api/app/social-callback/googleDrive?admin=admin&report=wpNotifications&url=${driveData.HexomaticNotification.adminGetMany.csvURL}`,
        "_blank"
      );
    }
  }, [driveData]);

  const handleInputChange = (e: React.FormEvent) => setFilterInput((e.target as HTMLInputElement).value);

  const handleSelectChannel = (e: React.FormEvent) => setChannel((e.target as HTMLInputElement).value);

  const handleSelectType = (e: React.FormEvent) => setType((e.target as HTMLInputElement).value);

  const handleFromDate = (date: Date) => {
    if (date) {
      return setFromDate(moment(date).format("YYYY-MM-DDT00:00:00.000"));
    }
    return setFromDate(null);
  };

  const handleToDate = (date: Date) => {
    if (date) {
      return setToDate(moment(date).format("YYYY-MM-DDT23:59:59.999"));
    }
    return setToDate(null);
  };

  const handleSubmit = (
    event: React.FormEvent,
    input: string,
    channelName: string,
    type: string,
    startDate: string,
    endDate: string
  ) => {
    event.preventDefault();
    props.onFilterDataChange(input.trim());
    props.onFromDate(startDate);
    props.onToDate(endDate);
    if (channelName !== "all") {
      props.onSelectChannel(channelName);
    } else {
      props.onSelectChannel("");
    }
    if (type !== "all") {
      props.onSelectType(type);
    } else {
      props.onSelectType("");
    }
  };

  const handleSortingClick = (column: string) => {
    if (column === "Created At") {
      props.setOrderBy("createdAt");
      props.setOrderByASC(!props.orderByASC);
    }
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Col md={2}>
          <Form.Checkbox
            label="Filter Hexact notifications"
            checked={props.filterHexScans}
            onChange={() => props.setFilterHexScans(!props.filterHexScans)}
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <div className="col-12 col-md-8">
          {!emailsChartDataQuery.loading &&
            emailsChartDataQuery.data &&
            emailsChartDataQuery.data.HexomaticNotification &&
            emailsChartDataQuery.data.HexomaticNotification.hexomaticNotificationsChart && (
              <Chart
                yColName="Email"
                yColName2="Telegram"
                yColName3="Slack"
                yColName4="Trello"
                yColName5="Sms"
                regData={emailsChartDataQuery.data.HexomaticNotification.hexomaticNotificationsChart}
                interval={getCountArr(
                  emailsChartDataQuery.data.HexomaticNotification.hexomaticNotificationsChart.length
                )}
                chartName="Daily Sent Hexomatic Notifications"
              />
            )}
        </div>
        <div className="col-12 col-md-4">
          <form onSubmit={(e) => handleSubmit(e, filterInput, channel, type, fromDate, toDate)}>
            <Form.Group>
              <Form.Input
                label="Input"
                className="margin-bottom"
                icon="search"
                position="append"
                placeholder="Search User Email, User Name, Url"
                value={filterInput}
                onChange={handleInputChange}
              />
              <Form.Select onChange={handleSelectChannel} label="Channel">
                {channels.map((el) => (
                  <option key={el} value={el}>
                    {el}
                  </option>
                ))}
              </Form.Select>
              <Form.Select onChange={handleSelectType} label="Hexomatic Notification Type">
                {typeOptions &&
                  typeOptions.map((el: { type: string }) => (
                    <option key={el.type} value={el.type}>
                      {el.type}
                    </option>
                  ))}
              </Form.Select>
              <Form.Group label="From Date">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={fromDate ? new Date(fromDate) : null}
                  onChange={handleFromDate}
                  showMonthDropdown
                  placeholderText="Select a date"
                />
              </Form.Group>
              <Form.Group label="To Date">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={toDate ? new Date(toDate) : null}
                  onChange={handleToDate}
                  showMonthDropdown
                  placeholderText="Select a date"
                  minDate={fromDate && new Date(fromDate)}
                />
              </Form.Group>
              <Button className="margin-bottom" type="submit" icon="filter" color="primary">
                Filter
              </Button>
            </Form.Group>
          </form>
        </div>
      </Grid.Row>
      <Grid.Row className={showButtons === "true" ? "justify-content-between" : "justify-content-end"}>
        {showButtons === "true" && (
          <div className="col-12 col-md-3 col-xs-12">
            <Button.List>
              <Button
                onClick={() =>
                  getCSV({
                    variables: { ...props.variables, csvDownload: true },
                  })
                }
                loading={loading}
                color="primary"
                mr={1}
              >
                Get CSV Link
              </Button>
              <Button
                onClick={() =>
                  uploadToDrive({
                    variables: { ...props.variables, csvDownload: true },
                  })
                }
                loading={loading}
                color="primary"
              >
                Save to Drive
              </Button>
              {/* <Button
              onClick={() => setShowtypes(!showtypes)}
              // loading={loading}
              color="primary"
            >
              show Types
            </Button> */}
            </Button.List>
            {!loading &&
              data &&
              data.HexomaticNotification &&
              data.HexomaticNotification.adminGetMany &&
              data.HexomaticNotification.adminGetMany.csvURL && (
                <Button
                  RootComponent="a"
                  href={data.HexomaticNotification.adminGetMany.csvURL}
                  icon="download"
                >
                  Download CSV
                </Button>
              )}
            <br />
          </div>
        )}
        <Grid.Col md={3}>
          <Alert type="info">Result Count - {total}</Alert>
        </Grid.Col>
      </Grid.Row>
      {showtypes && (
        <TableCard
          loading={dataLoading}
          data={constants}
          tableData={tableConst}
          onHeaderClick={handleSortingClick}
        />
      )}
      <Grid.Row cards deck>
        <ServerSidePagination
          data={notificationsPagination}
          total={total}
          onPageNum={props.onPageNumberChange}
        >
          <TableCard
            loading={dataLoading}
            data={baseNotifications}
            tableData={tableData}
            onHeaderClick={handleSortingClick}
          />
        </ServerSidePagination>
      </Grid.Row>
      <Grid.Row className="justify-content-end">
        {total ? (
          <Grid.Col md={3}>
            <Alert type="info">Result Count - {total}</Alert>
          </Grid.Col>
        ) : null}
      </Grid.Row>
    </Grid>
  );
};

export default HexomaticNotifications;
