import React, { useState, useEffect } from "react";
import { SHORTLINK_CREATE_MUTATUION } from "../../graphql/mutations";
import { GET_SHORTLINK_QUERY } from "../../graphql/queries";
import Shortlink from "./index";
import { useQuery, useMutation } from "@apollo/react-hooks";

const ShortlinkQueries = () => {
  const [links, setLinks] = useState<any>([]);
  const [pageNumberState, setPageNumberState] = useState(1);
  const handlePageNumberChange = (pageNumber: number) => setPageNumberState(pageNumber);
  const [searchLink, setsearchLink] = useState<string>('')

  const { data, loading, refetch } = useQuery(GET_SHORTLINK_QUERY, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      settings: {
        page: pageNumberState,
        limit: 100,
        search: searchLink,
      }
    }
  });

  useEffect(() => {
    refetch()
  }, [pageNumberState])

  useEffect(() => {
    setLinks(data)
  }, [data])

  useEffect(() => {
    refetchData()
  }, [searchLink])

  const refetchData = async () => {
    await refetch()
  }

  const [createShortlinks] = useMutation(SHORTLINK_CREATE_MUTATUION, {
    onCompleted: () => {
      refetchData()
    }
  });

  return (
    <Shortlink
      data={links}
      loading={loading}
      searchLink={searchLink}
      setsearchLink={setsearchLink}
      createShortlinks={createShortlinks}
      onPageNumberChange={handlePageNumberChange}
    />
  )
};


export default ShortlinkQueries;
