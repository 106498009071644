import React, { FC, useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { Grid, Button, Alert, Form } from "tabler-react";
import moment from "moment";
import DatePicker from "react-datepicker";
import { GET_SURVEY_REPORT } from "../../graphql/queries";
import ServerSidePagination from "../../components/pagination/serverSidePagination";
import TableCard from "../../components/tableCard";

import { isProduction } from "../../helpers";

interface Props {
  data: {
    Admin: {
      getSurveyReport: {
        data: any;
        count: number;
        csvUrl: string;
      };
    };
  };
  loading: boolean;
  variables: Object;
  refetchData: () => void;
  onPageNumberChange: (pageNumber: number) => void;
  onFilterInputChange: (data: string) => void;
  onFromDate: (date: string) => void;
  onToDate: (date: string) => void;
  onSkippedSelect: (channel: string) => void;
  onCategorySelect: (channel: string) => void;
  filterHexScans: boolean;
  setFilterHexScans: (val: boolean) => void;
}

const skippedList = ["all", "yes", "no"];
const categoryList = ["All", "Marketing", "Sales", "Research", "Other"];

const HexomaticSurvey: FC<Props> = ({ refetchData, ...props }) => {
  const [surveyList, setSurveyList] = useState([]);
  const [pagination, setPagination] = useState([]);

  const [total, setTotal] = useState(0);
  const [dataLoading, setDataLoading] = useState(true);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [filterInput, setFilterInput] = useState("");
  const [skipped, setSkipped] = useState("all");
  const [category, setCategory] = useState("All");
  const showButtons = localStorage.getItem("_rep");
  const [csvUrl, setCsvUrl] = useState("");

  const [getCSVUrl, { data: csvData, loading }] = useLazyQuery(GET_SURVEY_REPORT);
  const [uploadToDrive, { data: driveData }] = useLazyQuery(GET_SURVEY_REPORT);

  const getAnswers = (arr: { question: string; answer: string }[]) => {
    const res = {};

    arr.forEach((item) => {
      res[item.question] = item.answer;
    });

    return res;
  };

  const tableData = {
    tData:
      surveyList &&
      surveyList.map((item) =>
        item.answers
          ? {
              ID: item.id,
              email: item.email,
              Firstname: item.firstname,
              Lastname: item.lastname,
              Skipped: item.skipped ? "YES" : "NO",
              ...getAnswers(item.answers),
            }
          : []
      ),
  };

  useEffect(() => {
    if (
      props.data &&
      props.data.Admin &&
      props.data.Admin.getSurveyReport &&
      props.data.Admin.getSurveyReport.data
    ) {
      setDataLoading(props.loading);
      setSurveyList(props.data.Admin.getSurveyReport.data);
      setPagination(props.data.Admin.getSurveyReport.data);
      setTotal(props.data.Admin.getSurveyReport.count);
    }
  }, [props.data]);

  useEffect(() => {
    if (csvData && csvData.Admin && csvData.Admin.getSurveyReport && csvData.Admin.getSurveyReport.csvUrl) {
      setCsvUrl(csvData.Admin.getSurveyReport.csvUrl);
    }
  }, [csvData]);

  useEffect(() => {
    if (
      driveData &&
      driveData.Admin &&
      driveData.Admin.getSurveyReport &&
      driveData.Admin.getSurveyReport.csvUrl
    ) {
      window.open(
        isProduction()
          ? `https://api.hexometer.com/v2/app/social-callback/googleDrive?admin=admin&report=referral&url=${driveData.Admin.getSurveyReport.csvUrl}`
          : `http://localhost:4000/api/app/social-callback/googleDrive?admin=admin&report=referral&url=${driveData.Admin.getSurveyReport.csvUrl}`,
        "_blank"
      );
    }
  }, [driveData]);

  const handleInputChange = (e: React.FormEvent) => setFilterInput((e.target as HTMLInputElement).value);

  const handleSelectSkipped = (e: React.FormEvent) => setSkipped((e.target as HTMLInputElement).value);

  const handleSelectCategory = (e: React.FormEvent) => setCategory((e.target as HTMLInputElement).value);

  const handleFromDate = (date: Date) => {
    if (date) {
      return setFromDate(moment(date).format("YYYY-MM-DDT00:00:00.000"));
    }
    return setFromDate(null);
  };

  const handleToDate = (date: Date) => {
    if (date) {
      return setToDate(moment(date).format("YYYY-MM-DDT23:59:59.999"));
    }
    return setToDate(null);
  };

  const handleSubmit = (
    event: React.FormEvent,
    input: string,
    skipped: string,
    category: string,
    startDate: string,
    endDate: string
  ) => {
    event.preventDefault();
    props.onFilterInputChange(input.trim());
    props.onSkippedSelect(skipped);
    props.onCategorySelect(category);
    props.onFromDate(startDate);
    props.onToDate(endDate);
  };

  return (
    <Grid>
      {/* <Grid.Row>
        <Grid.Col md={2}>
          <Form.Checkbox
            label="Filter Hexact survey"
            checked={props.filterHexScans}
            onChange={() => props.setFilterHexScans(!props.filterHexScans)}
          />
        </Grid.Col>
      </Grid.Row> */}
      <Grid.Row>
        <div className="col-12 col-md-8"></div>
        <div className="col-12 col-md-4">
          <form onSubmit={(e) => handleSubmit(e, filterInput, skipped, category, fromDate, toDate)}>
            <Form.Group>
              <Form.Input
                label="Input"
                className="margin-bottom"
                icon="search"
                position="append"
                placeholder="Search userId, email, firstName, lastName"
                value={filterInput}
                onChange={handleInputChange}
              />
              <Form.Select onChange={handleSelectSkipped} label="Skipped">
                {skippedList.map((el) => (
                  <option key={el} value={el}>
                    {el}
                  </option>
                ))}
              </Form.Select>

              <Form.Select onChange={handleSelectCategory} label="Category">
                {categoryList.map((el) => (
                  <option key={el} value={el}>
                    {el}
                  </option>
                ))}
              </Form.Select>

              <Form.Group label="From Date">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={fromDate ? new Date(fromDate) : null}
                  onChange={handleFromDate}
                  showMonthDropdown
                  placeholderText="Select a date"
                />
              </Form.Group>
              <Form.Group label="To Date">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={toDate ? new Date(toDate) : null}
                  onChange={handleToDate}
                  showMonthDropdown
                  placeholderText="Select a date"
                  minDate={fromDate && new Date(fromDate)}
                />
              </Form.Group>
              <Button className="margin-bottom" type="submit" icon="filter" color="primary">
                Filter
              </Button>
            </Form.Group>
          </form>
        </div>
      </Grid.Row>
      <Grid.Row className={showButtons === "true" ? "justify-content-between" : "justify-content-end"}>
        {showButtons === "true" && (
          <div className="col-12 col-md-3 col-xs-12">
            <Button.List>
              <Button
                onClick={() => {
                  getCSVUrl({
                    variables: {
                      settings: {
                        ...props.variables,
                        csvDownload: true,
                      },
                    },
                  });
                }}
                loading={loading}
                color="primary"
                mr={1}
              >
                Get CSV Link
              </Button>
              <Button
                onClick={() => {
                  uploadToDrive({
                    variables: {
                      settings: {
                        ...props.variables,
                        csvDownload: true,
                      },
                    },
                  });
                }}
                loading={false}
                color="primary"
              >
                Save to Drive
              </Button>
              {/* <Button
              onClick={() => setShowtypes(!showtypes)}
              // loading={loading}
              color="primary"
            >
              show Types
            </Button> */}
            </Button.List>
            {!loading && csvUrl && (
              <Button RootComponent="a" href={csvUrl} icon="download">
                Download CSV
              </Button>
            )}
            <br />
          </div>
        )}
        <Grid.Col md={3}>
          <Alert type="info">Result Count - {total}</Alert>
        </Grid.Col>
      </Grid.Row>

      <Grid.Row cards deck>
        <ServerSidePagination data={pagination} total={total} onPageNum={props.onPageNumberChange}>
          <TableCard loading={dataLoading} data={surveyList} tableData={tableData} onHeaderClick={() => {}} />
        </ServerSidePagination>
      </Grid.Row>
      <Grid.Row className="justify-content-end">
        {total ? (
          <Grid.Col md={3}>
            <Alert type="info">Result Count - {total}</Alert>
          </Grid.Col>
        ) : null}
      </Grid.Row>
    </Grid>
  );
};

export default HexomaticSurvey;
