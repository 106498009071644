import React, { useState, useEffect } from "react";
import { Alert, Button, Form } from "tabler-react";
import ReactModal from "react-modal";
import { TableModalProps } from "../../types";
import "react-mde/lib/styles/css/react-mde-all.css";
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { pricingPacks } from '../../constants'

const names = {
  STANDARD: 'STANDARD-hexometer-user',
  ADVANCED: 'ADVANCED-hexometer-user',
  GURU: 'GURU-hexometer-user',
  AGENCY_STANDARD: 'AGENCY_STANDARD-hexometer-user',
  AGENCY_ADVANCED: 'AGENCY_ADVANCED-hexometer-user',
  AGENCY_GURU: 'AGENCY_GURU-hexometer-user',
}

ReactModal.setAppElement('#root');

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    height: '80%'
  }
};

const Modal = (
  { handleModal, showModal, onSave, errorMessage }: TableModalProps
): JSX.Element => {

  const [loading, setLoading] = useState(false);
  const [targetPackage, setTargetPackage] = useState("");
  const [targetValue, setTargetValue] = useState(null);
  const [targetExtra, setTargetExtra] = useState("");
  const [prefix, setPrefix] = useState("");
  const [expireDate, setExpireDate] = useState(null);

  const handleOk = async () => {
    setLoading(true);

    let completed = true;
    if (targetPackage && targetValue && expireDate) {
      const formData = { targetPackage, targetValue, expireDate, targetExtra, prefix };
      completed = await onSave({ ...formData });
    }
    setLoading(false);
    if (completed) handleCancel();
  };

  const handleCancel = () => {
    setTargetPackage("");
    setTargetValue("");
    setExpireDate(null)
    setTargetExtra("");
    setPrefix("");
    handleModal();
  };

  useEffect(() => {
    if(window.innerWidth < 640) {
      customStyles.content.width = '90%'
    }
  }, [])

  const handleTargetPackageChange = (e: React.FormEvent) => {
    let value = names[(e.target as HTMLInputElement).value]
    if(value) {
      setTargetPackage(value);
      return
    }
    
    setTargetPackage((e.target as HTMLInputElement).value);
  }

  const handleTargetValueChange = (e: React.FormEvent) => {
    const value = (e.target as HTMLInputElement).value;
    if (value && !isNaN(parseInt(value))) setTargetValue(parseInt(value))
  };

  const handleExpireDate = (date: Date) => {
    if (date) {
      return setExpireDate(moment(date).format("YYYY-MM-DDT00:00:00.000"));
    }
    return setExpireDate(null);
  }
  const handleTargetExtraChange = (e: React.SyntheticEvent) => setTargetExtra((e.target as HTMLInputElement).value);
  const handlePrefixChange = (e: React.SyntheticEvent) => setPrefix((e.target as HTMLTextAreaElement).value);


  const getKey = () => {
    let v = Object.keys(names).find((name) => (names[name] === targetPackage))
    return (v === undefined) ? "" : v
  }

  getKey()

  return (
    <div style={{ height: 0 }}>
      <ReactModal
        isOpen={showModal}
        contentLabel="Create Translation"
        style={customStyles}
        onRequestClose={handleCancel}
      >
        <h4>Create PromoCode</h4>
        {errorMessage ?
          <Alert type="danger" isDismissible>
            {errorMessage}
          </Alert> : null}
        <Form onSubmit={(e: React.FormEvent) => e.preventDefault()}>
          {/* <Form.Input
            required
            type="text"
            placeholder='package'
            label="Target Package"
            value={targetPackage}
            onChange={handleTargetPackageChange}
          /> */}
          <Form.Select
            onChange={handleTargetPackageChange}
            label="Target Package"
            value={getKey()}
          >
            {pricingPacks.map(pack => <option key={pack} value={pack}>{pack}</option>)}
          </Form.Select>
          <Form.Input
            required
            type="number"
            placeholder='value'
            label="Target Value"
            value={targetValue ? targetValue : ''}
            onChange={handleTargetValueChange}
          />
          <Form.Group label="Expire Date">
            <DatePicker
              dateFormat="yyyy-MM-dd"
              selected={expireDate ? new Date(expireDate) : null}
              onChange={handleExpireDate}
              showMonthDropdown
              placeholderText="Select expire date"
            />
          </Form.Group>
          <Form.Input
            onChange={handleTargetExtraChange}
            placeholder="extra"
            type="text"
            label="Target Extra"
            value={targetExtra}
          />
          <Form.Input
            onChange={handlePrefixChange}
            placeholder="prefix"
            type="text"
            label="Prefix"
            value={prefix}
          />
          <Button.List style={{ marginTop: "5%" }}>
            <Button
              onClick={handleOk}
              color="primary"
              type="submit"
              loading={loading}
            >
              Save
            </Button>
            <Button
              onClick={handleCancel}
              color="secondary"
              type="button"
            >
              Cancel
            </Button>
          </Button.List>
        </Form>
      </ReactModal>
    </div>
  )
};

export default Modal;
