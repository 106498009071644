import React, { Fragment } from "react";
import { Card, Loader, Table, Form } from "tabler-react";
import { TableCardProps } from "../../types";
import "./index.css";

const TableCard = ({
  data,
  tableData,
  loading,
  multiSelect,
  onHeaderClick,
  orderByASC,
  orderBy,
}: TableCardProps): JSX.Element => {
  const handleHeaderClick = (name: string) => () => {
    onHeaderClick(name);
  };

  return (
    <Card className="table-responsive">
      <Table className="table card-table table-striped table-vcenter">
        <Table.Header>
          <Table.Row>
            {multiSelect && (
              <Table.ColHeader>
                <Form.Checkbox className="tableMargin" label=" " />
              </Table.ColHeader>
            )}
            {tableData.tData[0] &&
              Object.keys(tableData.tData[0]).map((row: string) => (
                <Table.ColHeader key={row}>
                  <div
                    onClick={handleHeaderClick(row)}
                    style={
                      row === "Action" || row === "Tech Stack"
                        ? { maxWidth: "250px" }
                        : row === "Actions"
                        ? { width: "180px" }
                        : {}
                    }
                  >
                    {row}{" "}
                    {orderBy === row.toLocaleLowerCase() ? (
                      orderByASC ? (
                        <span>&#8595;</span>
                      ) : (
                        <span>&#8593;</span>
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </Table.ColHeader>
              ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {loading && data.length === 0 && (
            <Table.Row>
              <Table.Col>
                <Loader className="position-static" />
              </Table.Col>
            </Table.Row>
          )}
          {!loading && data.length === 0 && (
            <Table.Row>
              <Table.Col className="text-center">
                <strong>Nothing found</strong>
              </Table.Col>
            </Table.Row>
          )}
          {tableData.tData &&
            tableData.tData.map((row: Object, index: number) => {
              return (
                <Table.Row key={index}>
                  {multiSelect && (
                    <Table.Col>
                      <Form.Checkbox className="tableMargin" label=" " />
                    </Table.Col>
                  )}
                  {Object.values(row).map((column: Object, ind: number) => (
                    <Table.Col key={ind}>
                      <div style={{ maxWidth: "20vw", wordWrap: "break-word" }}>{column}</div>
                    </Table.Col>
                  ))}
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
    </Card>
  );
};

export default TableCard;
