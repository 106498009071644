import React, { useEffect, useState } from 'react';
import { Grid, Button, Alert, Form } from "tabler-react";
import { ContentTextProps, blackListProps } from "../../types";
import ServerSidePagination from "../../components/pagination/serverSidePagination";
import TableCard from "../../components/tableCard";
import TableModal from "./tableModal";
import ReactModal from 'react-modal';
import { alertCustomStyles } from '../../helpers';
import moment from 'moment';

import './style.css'

const bldata = [
  {
    name: "name",
    id: '1'
  },
  {
    name: "name 3",
    id: '2'
  },
  {
    name: "name 3",
    id: '3'
  }
]

const JSONSchemas = (
  { data, loading, deleteSchema, updateSchema, createJsonSchema, onPageNumberChange }: any
): JSX.Element => {

  const [automationTypeList, setAutomationTypeList] = useState<Array<blackListProps>>([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [mutationCompleted, setMutationCompleted] = useState(false);
  const [EditType, setEditType] = useState<any>();
  const [total, setTotal] = useState(0)
  const [editField, setEditField] = useState<any>(null);

  const handleModal = () => {
    if (showModal) {
      setEditField(null);
      setErrorMessage("")
    }
    setShowModal(!showModal);
  }

  const handleSave = async ({ ...rest }) => {
    const { schema, comment } = rest
    if (EditType) {

    } else {
      const res = await createJsonSchema({
        variables: { settings: { schema: schema, comment: comment } }
      })
      if(res.data.HexomaticAdminOps && !res.data.HexomaticAdminOps.createJsonSchema) {
        
      }else {
        setErrorMessage(res.data.HexomaticAdminOps.createJsonSchema.err)
      }
    }
    return true;
  };

  const onDelete = async (id: number) => {
    let deleteConfirm = confirm(`Are you sure you want to delete automation schema with ID: ${id}`);
    if(!deleteConfirm) return  
    const res = await deleteSchema({
      variables: { settings: { id: id } }
    })
    if (res.data.HexomaticAdminOps && !res.data.HexomaticAdminOps.deleteJsonSchema) {
      setAutomationTypeList(automationTypeList.filter(item => item.id !== id))
      return
    }
    const { error_code } = res.data.HexomaticAdminOps.deleteJsonSchema
    if (error_code) {
      setErrorMessage(error_code)
      return
    }
  }

  const onUpdate = async ({ id, schema, comment }) => {
    const res = await updateSchema({
      variables: { settings: { id, schema, comment } }
    })
    handleModal()
    if (res.data.HexomaticAdminOps && !res.data.HexomaticAdminOps.updateJsonSchema) {
      const newAutomationTypeList = automationTypeList.map(item => {
        if (item.id === id) {
          return { ...item, schema, comment }
        }
        return item
      })
      setAutomationTypeList(newAutomationTypeList)
      return
    }
    const { error_code } = res.data.HexomaticAdminOps.updateJsonSchema
    if (error_code) {
      setErrorMessage(error_code)
      return
    }
  }

  const tableData = {
    tData: automationTypeList && automationTypeList.map((item: ContentTextProps) => (
      {
        "id": item.id,
        "Json schema":  item.schema,
        "comment" : item.comment,
        "created_at" : moment(parseInt(item.created_at)).utc().format('DD-MM-YYYY hh:mm:ss a'),
        "updated_at" : moment(parseInt(item.updated_at)).utc().format('DD-MM-YYYY hh:mm:ss a'),
        "Action":
          <Button.List>
            <Button color="primary" icon="edit" onClick={() => { setEditField(item); setShowModal(true) }} />
            <Button color="danger" icon="trash" onClick={() => onDelete(item.id)} />
          </Button.List>
      }
    ))
  };

  useEffect(() => {
    if (data && data.HexomaticAdmin && data.HexomaticAdmin.getJsonSchemas.json_schemas) {
      setDataLoading(loading);
      setAutomationTypeList(data.HexomaticAdmin.getJsonSchemas.json_schemas)
      // setTotal(data.EmailBlackList.adminGetMany.total)
    }
  }, [data]);

  return (
    <Grid.Row cards deck>
      <TableModal
        handleModal={handleModal}
        showModal={showModal}
        onSave={handleSave}
        onUpdate={onUpdate}
        editField={editField}
        errorMessage={errorMessage}
      />
      <ReactModal
        isOpen={!!errorMessage}
        style={alertCustomStyles}
      >
        {errorMessage ?
          <Alert type="danger">
            {errorMessage}
          </Alert> : null}
        <Button
          className=""
          onClick={() => setErrorMessage("")}
          color="primary"
        >OK</Button>
      </ReactModal>
      <div className="col-12 col-md-1 col-xs-6">
        <Button
          className=""
          onClick={() => setShowModal(true)}
          color="primary"
        >Create</Button>
        <br />
      </div>
      <Grid.Col md={9}>
      </Grid.Col>
      <div className="col-12 col-md-2 col-xs-6">
        <Alert type="info">
          Result Count - {total || automationTypeList.length}
        </Alert>
      </div>
      <div className="black-list-table-pagination">
        <ServerSidePagination
          data={automationTypeList}
          total={total}
          onPageNum={onPageNumberChange}
        >
          <TableCard
            loading={dataLoading}
            data={automationTypeList}
            tableData={tableData}
          />
        </ServerSidePagination>
      </div>

      {automationTypeList.length ? <div className="col-12 col-md-2 col-xs-12 offset-md-10">
        <Alert type="info">
          Result Count - {total || automationTypeList.length}
        </Alert>
      </div> : null}
    </Grid.Row>
  )
};

export default JSONSchemas;
