import React, { useCallback, useEffect, useRef, useState } from "react";
import { Loader } from "tabler-react";
import { useLazyQuery } from "@apollo/react-hooks";
import useUploadFile from "../../customHooks/useUploadFile";
import { GENERATE_HOT_JAR_CSV } from "../../graphql/queries";
import "./styles.css";

const Hotjar = () => {
  const csvInputRef = useRef<any>(null);
  const [uploadFile, deleteFile] = useUploadFile();
  const [loading, setLoading] = useState(false);
  const [link, setLink] = useState("");
  const [newLink, setNewLink] = useState("");
  const [gc_directory_path, setGcDirectoryPath] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [generate, { data, loading: generateLoading }] = useLazyQuery(GENERATE_HOT_JAR_CSV);

  useEffect(() => {
    if (data && data.Admin && data.Admin.generateHotjarCsv && data.Admin.generateHotjarCsv.newCsvUrl) {
      setNewLink(data.Admin.generateHotjarCsv.newCsvUrl);
      deleteFile([link], gc_directory_path);
    }
  }, [data]);

  const onSelect = useCallback(
    async (e: any) => {
      let file = e.target.files[0];
      if (!file) {
        return;
      }
      if (file && file.type !== "text/csv") {
        setErrorMessage("Please upload supported file type");
        return;
      }
      if (file && file.size >= 500000000) {
        setErrorMessage("Maximum file size is 500MB");
        return;
      }
      setLoading(true);
      setNewLink("");
      if (csvInputRef && csvInputRef.current && csvInputRef.current.value) csvInputRef.current.value = "";
      //@ts-ignore
      const data: any = await uploadFile([file]);
      if (data && data.gc_directory_path) {
        setGcDirectoryPath(data.gc_directory_path);
        setLink(data.files[0].link);
        generate({ variables: { settings: { csvUrl: data.files[0].link } } });
        setErrorMessage("");
      } else {
        setErrorMessage(data.error || data.file_error || "Something went wrong");
      }
      setLoading(false);
    },
    [csvInputRef]
  );

  return (
    <div className="hotjar-csv-page">
      <label className="cursor-pointer upload-file">
        <div className="select-file">{loading || generateLoading ? <Loader /> : "Upload CSV"}</div>
        <input
          ref={csvInputRef}
          accept=".csv"
          type="file"
          id="file-upload-csv"
          name="file"
          onChange={onSelect}
          className="d-none"
        />
      </label>
      {newLink && (
        <a href={newLink} className="btn">
          <i className="fe fe-download mr-2"></i>Download CSV
        </a>
      )}
    </div>
  );
};

export default Hotjar;
