import React, { FormEvent, useEffect, useState } from "react";
import { Grid, Button, Alert, Form, Icon } from "tabler-react";
import { ContentTextProps } from "../../types";
import ServerSidePagination from "../../components/pagination/serverSidePagination";
import moment from "moment";
import { GET_SUBSCRIBED_EMAIL_LIST } from "../../graphql/queries";
import { useLazyQuery } from "@apollo/react-hooks";
import TableCard from "../../components/tableCard";
import DatePicker from "react-datepicker";

import "./style.css";

const typesWithLabels = [
  { label: "Hexospark launch", value: "HEXOSPARK_SUBSCRIBE" },
  { label: "Hexact news", value: "HEXACT" },
  { label: "Hexomatic playbooks", value: "GROWTH_HACKING" },
  { label: "Hexomatic launch", value: "HEXOMATE_SUBSCRIBE" },
  { label: "Hexowatch playbooks", value: "MAGNET" },
  { label: "Hexowatch launch", value: "SUBSCRIBE" },
  { label: "StartEngine", value: "STARTENGINE" },
];

const SubscribedEmail = ({
  data,
  loading,
  filter,
  onPageNumberChange,
  refetchData,
  variables,
}: any): JSX.Element => {
  const [subscribed, setSubscribed] = useState<Array<any>>([]);
  // const [blackList, setBlackList] = useState<Array<blackListProps>>([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [types, setTipes] = useState<any>(null);
  const [searchEmail, setsearchEmail] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [emailStatus, setEmailStatus] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const showButtons = localStorage.getItem("_rep");

  const [getCSV, { data: CSVData, loading: CSVLoading }] = useLazyQuery(GET_SUBSCRIBED_EMAIL_LIST);
  const [uploadToDrive, { data: driveData, loading: driveDataLoading }] =
    useLazyQuery(GET_SUBSCRIBED_EMAIL_LIST);

  const tableData = {
    tData:
      subscribed &&
      subscribed.map((item: ContentTextProps) => ({
        email: item.email,
        createdAt: moment(+item.createdAt).format("DD-MM-YYYY"),
        type: typesWithLabels.find((sub: any) => sub.value === item.type) ? typesWithLabels.find((sub: any) => sub.value === item.type).label : '',
        comment: item.comment,
        verified: item.verified ? (
          <div className="verified-user">
            <Icon name="user-check" />
          </div>
        ) : (
          <div className="not-verified-user">
            <Icon name="user-x" />
          </div>
        ),
        "second email sent at": item.lead_magnet_email_send_at
          ? moment(+item.lead_magnet_email_send_at)
              .utc()
              .format("DD-MM-YYYY hh:mm:ss a")
          : "",
        // "Action":
        //   <Button.List>
        //     <Button color="primary" icon="edit" onClick={() => handleKeywordMutate(item)} />
        //     <Button color="danger" icon="trash" onClick={() => handleKeywordDelete(item)} />
        //   </Button.List>
      })),
  };

  useEffect(() => {
    if (
      driveData &&
      driveData.Admin &&
      driveData.Admin.getWatchSubscribeData &&
      driveData.Admin.getWatchSubscribeData.csv_url
    ) {
      const { error } = driveData.Admin.getWatchSubscribeData;
      if (!error) {
        window.open(
          `https://api.hexometer.com/v2/app/social-callback/google/drive/v1/uploadFile?url=${driveData.Admin.getWatchSubscribeData.csv_url}`,
          "_blank"
        );
      }
    }
  }, [driveData]);

  const onFilter = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const typeVal = typesWithLabels.find((sub: any) => sub.value === type);
    await filter({ type: typeVal ? typeVal.value : '', email: searchEmail, emailStatus, fromDate, toDate });
  };

  const handleSelectUsersStatus = (e: React.FormEvent) => {
    if ((e.target as HTMLInputElement).value === "All") {
      setEmailStatus(undefined);
      return;
    }
    setEmailStatus((e.target as HTMLInputElement).value);
  };

  const handleFromDate = (date: Date) => {
    if (date) {
      return setFromDate(moment(date).format("YYYY-MM-DDT00:00:00.000"));
    }
    setFromDate(null);
  };

  const handleToDate = (date: Date) => {
    if (date) {
      return setToDate(moment(date).format("YYYY-MM-DDT23:59:59.999"));
    }
    setToDate(null);
  };

  useEffect(() => {
    if (data && data.Admin && data.Admin.getWatchSubscribeData.subscribed_emails) {
      setDataLoading(loading);
      setSubscribed(data.Admin.getWatchSubscribeData.subscribed_emails);
      setTotal(data.Admin.getWatchSubscribeData.total_count);
    }
    if (data && data.Admin && data.Admin.getWatchSubscribeData.type_list) {
      setTipes([...data.Admin.getWatchSubscribeData.type_list]);
    }
  }, [data]);

  const toDrive = async () => {
    uploadToDrive({
      variables: { settings: { ...variables, csv_download_link: true } },
    });
  };

  return (
    <Grid.Row cards deck className="subscribed-emails">
      <div className="col-12 col-md-8"></div>
      <div className="col-12 col-md-4">
        <form>
          <Form.Group>
            <Form.Input
              label="Input"
              className="margin-bottom"
              icon="search"
              position="append"
              placeholder="Search for name, email"
              value={searchEmail}
              onChange={(e: React.FormEvent) => {
                const inputText = (e.target as HTMLInputElement).value;
                setsearchEmail(inputText);
              }}
            />
            <Form.Select
              value={type}
              onChange={(e: React.FormEvent) => {
                const inputText = (e.target as HTMLInputElement).value;
                setType(inputText);
              }}
              label="Select type"
            >
              <option value="">all</option>
              {types &&
                types.reverse().map((item) => {
                  const val = typesWithLabels.find((sub: any) => sub.value === item);
                  return (
                    <option key={item} value={item}>
                      {val ? val.label : ''}
                    </option>
                  );
                })}
            </Form.Select>
            <Form.Select onChange={handleSelectUsersStatus} label="Email status">
              <option value={undefined}>All</option>
              <option value={"VERIFIED"}>verified</option>
              <option value={"NOT_VERIFIED"}>not verified</option>
            </Form.Select>

            <Grid.Row>
              <Grid.Col>
                <Form.Group label="From Date">
                  <DatePicker
                    dateFormat="yyyy-MM-dd"
                    selected={fromDate ? new Date(fromDate) : null}
                    onChange={handleFromDate}
                    showMonthDropdown
                    placeholderText="Select a date"
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col>
                <Form.Group label="To Date">
                  <DatePicker
                    dateFormat="yyyy-MM-dd"
                    selected={toDate ? new Date(toDate) : null}
                    onChange={handleToDate}
                    showMonthDropdown
                    placeholderText="Select a date"
                    minDate={fromDate && new Date(fromDate)}
                  />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>

            <Button
              type="submit"
              icon="filter"
              color="primary"
              className=""
              loading={dataLoading}
              onClick={onFilter}
            >
              Filter
            </Button>
          </Form.Group>
        </form>
      </div>

      <div className={showButtons === "true" ? "justify-content-between" : "justify-content-end"}>
        {showButtons === "true" && (
          <div className="col-12 col-md-3 col-xs-12">
            <Button.List>
              <Button
                onClick={() =>
                  getCSV({
                    variables: {
                      settings: { ...variables, csv_download_link: true },
                    },
                  })
                }
                loading={CSVLoading}
                color="primary"
                mr={1}
              >
                Get CSV Link
              </Button>
              <Button onClick={toDrive} loading={driveDataLoading} color="primary">
                Save to Drive
              </Button>
            </Button.List>
            {!loading &&
              CSVData &&
              CSVData.Admin &&
              CSVData.Admin.getWatchSubscribeData &&
              CSVData.Admin.getWatchSubscribeData.csv_url && (
                <Button RootComponent="a" href={CSVData.Admin.getWatchSubscribeData.csv_url} icon="download">
                  Download CSV
                </Button>
              )}
            <br />
          </div>
        )}
        <div className="col-12 col-md-2 col-xs-12">
          <Alert type="info">Result Count - {total}</Alert>
        </div>
      </div>
      <div className="black-list-table-pagination">
        <ServerSidePagination data={subscribed} total={total} onPageNum={onPageNumberChange}>
          <TableCard loading={dataLoading} data={subscribed} tableData={tableData} />
        </ServerSidePagination>
      </div>

      {subscribed.length ? (
        <div className="col-12 col-md-2 col-xs-12 offset-md-10">
          <Alert type="info">Result Count - {total}</Alert>
        </div>
      ) : null}
    </Grid.Row>
  );
};

export default SubscribedEmail;
