import React, { useState, FormEvent, useEffect } from 'react';
import { Grid, Button, Form, Card } from "tabler-react";
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { SEND_PROPERTY_SUMMARY_EMAIL, GET_MONTHLY_SUMMARY_SCHEDULE_CONFIG, SET_MONTHLY_SUMMARY_SCHEDULE_CONFIG } from '../../graphql/mutations';

const UtilityToolsPage = () => {
  const [summaryFormData, setSummaryFormData] = useState({
    email: '',
    from: moment().startOf('week').subtract(6, 'days').toDate(),
    to: moment().startOf('week').toDate(),
    toAll: false,
  });

  const [ ongoing, setOngoing ] = useState(false)

  const [ sendSummary, { loading }] = useMutation(SEND_PROPERTY_SUMMARY_EMAIL);
  const [ sendMonthlySummaray, { loading: monthlyLoading } ] = useMutation(SET_MONTHLY_SUMMARY_SCHEDULE_CONFIG)
  const { data: monthlyScheduleBoolean, loading: loadingdata1, refetch: refetchmMnthlyScheduleBoolean } = useQuery(GET_MONTHLY_SUMMARY_SCHEDULE_CONFIG);
  // monthlyScheduleBoolean.Admin.getMonthlySummaryScheduleConfig

  useEffect(() => {
    if(monthlyScheduleBoolean) {
      setOngoing(monthlyScheduleBoolean.Admin.getMonthlySummaryScheduleConfig.ongoing)
    }
  }, [monthlyScheduleBoolean])

  const fieldChange = (e: FormEvent) => {
    const target = e.target as HTMLInputElement;
    setSummaryFormData({
      ...summaryFormData,
      [target.name]: target.value,
    });
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    await sendSummary({ variables: {...summaryFormData} });
  };

  const startAndStop = async (bool: boolean) => {
    await sendMonthlySummaray({variables: {ongoing: bool }})
    const { data } = await refetchmMnthlyScheduleBoolean()
    setOngoing(data.Admin.getMonthlySummaryScheduleConfig.ongoing)
  }
  
  return (
    <Grid>
      <Grid.Row>
        <Grid.Col md={8}>
          <Card className='p-3'>
            <h4>Weekly Summary Form</h4>
            <div>&nbsp;</div>
            <Form onSubmit={handleSubmit}>
              <Form.Group label="From Date">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={summaryFormData.from}
                  onChange={d => setSummaryFormData({...summaryFormData, from: d})}
                  showMonthDropdown
                />
              </Form.Group>
              <Form.Group label="To Date">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={summaryFormData.to}
                  onChange={d => setSummaryFormData({...summaryFormData, to: d})}
                  showMonthDropdown
                />
              </Form.Group>
              <Form.Group>
                <Form.Input
                  label="Test Email (optional)"
                  className="margin-bottom"
                  icon="mail"
                  position="append"
                  placeholder="Search for Name"
                  name='email'
                  value={summaryFormData.email}
                  onChange={fieldChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Checkbox
                  label="Send to all users and properties"
                  checked={summaryFormData.toAll}
                  onChange={() => setSummaryFormData({...summaryFormData, toAll: !summaryFormData.toAll})}
                />
              </Form.Group>
              <Form.Group>
                <Button
                  loading={loading}
                  color="primary"
                >
                  Send Summary
                </Button>
              </Form.Group>
            </Form>
          </Card>
        </Grid.Col>
        <Grid.Col md={4}>
          <Card className='p-3'>
            <h4>monthly Summary</h4>
                <Button
                  loading={monthlyLoading}
                  color="primary"
                  onClick={() => startAndStop(true)}
                  disabled={ongoing}
                >
                  start hexometer properties monthly summary sending
                </Button>
                <br/>
                <Button
                  loading={monthlyLoading}
                  color="primary"
                  onClick={() => startAndStop(false)}
                  disabled={!ongoing}
                >
                  stop
                </Button>

                <br/>
                <div>
                  <span style={{'fontWeight': 'bold'}}> state: {ongoing ? 'ongoing' : 'stoped'} </span>
                </div>
          </Card>
        </Grid.Col>
      </Grid.Row>
    </Grid>
  )
};


export default UtilityToolsPage;
