import React, { useState, useEffect } from "react";
import { Grid, Button, Alert, Form } from "tabler-react";
import moment from "moment";
import { ChannelProps, ContentTextProps } from "../../types";
import ServerSidePagination from "../../components/pagination/serverSidePagination";
import TableCard from "../../components/tableCard";
import DatePicker from "react-datepicker";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import { CHANNELS_CHART, GET_CHANNELS } from "../../graphql/queries";
import Chart from "../../components/chart";
import { getCountArr, isProduction } from "../../helpers";

const Channels = ({ refetchData, ...props }: ChannelProps): JSX.Element => {
  const [baseNotifications, setBaseNotifications] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [notificationsPagination, setNotificationsPagination] = useState([]);
  const [total, setTotal] = useState(0);
  const [filterInput, setFilterInput] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [channel, setChannel] = useState("");
  const [type, setType] = useState("");
  const showButtons = localStorage.getItem("_rep");

  const [getCSV, { data, loading }] = useLazyQuery(GET_CHANNELS);
  const [uploadToDrive, { data: driveData, loading: driveDataLoading }] =
    useLazyQuery(GET_CHANNELS);

  const emailsChartDataQuery = useQuery(CHANNELS_CHART, {
    variables: { name: "email" },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const smsChartDataQuery = useQuery(CHANNELS_CHART, {
    variables: { name: "sms" },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const tgChartDataQuery = useQuery(CHANNELS_CHART, {
    variables: { name: "telegram" },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const slackChartDataQuery = useQuery(CHANNELS_CHART, {
    variables: { name: "slack" },
    notifyOnNetworkStatusChange: true,
  });

  const trelloChartDataQuery = useQuery(CHANNELS_CHART, {
    variables: { name: "trello" },
    notifyOnNetworkStatusChange: true,
  });

  const channelNames = ["all", "email", "sms", "slack", "trello", "telegram"];

  const tableData = {
    tData:
      baseNotifications &&
      baseNotifications.map((item) => ({
        ID: item.id,
        Channel: item.name,
        "Created At": moment(item.createdAt)
          .utc()
          .format("DD-MM-YYYY hh:mm:ss a"),
        User: item.user && item.user.email,
        "Property Hostname": item.property && item.property.hostname,
      })),
  };

  useEffect(() => {
    if (
      props.data &&
      props.data.Channel &&
      props.data.Channel.adminGetMany &&
      props.data.Channel.adminGetMany.channels
    ) {
      setDataLoading(props.loading);
      setBaseNotifications(props.data.Channel.adminGetMany.channels);
      setNotificationsPagination(props.data.Channel.adminGetMany.channels);
      setTotal(props.data.Channel.adminGetMany.total);
    }
  }, [props.data]);

  useEffect(() => {
    if (
      driveData &&
      driveData.Channel &&
      driveData.Channel.adminGetMany &&
      driveData.Channel.adminGetMany.csvURL
    ) {
      window.open(
        isProduction()
          ? `https://api.hexometer.com/v2/app/social-callback/googleDrive?admin=admin&report=channel&url=${driveData.Channel.adminGetMany.csvURL}`
          : `http://localhost:4000/api/app/social-callback/googleDrive?admin=admin&report=channel&url=${driveData.Channel.adminGetMany.csvURL}`,
        "_blank"
      );
    }
  }, [driveData]);

  const handleInputChange = (e: React.FormEvent) =>
    setFilterInput((e.target as HTMLInputElement).value);

  const handleSelectChannel = (e: React.FormEvent) =>
    setChannel((e.target as HTMLInputElement).value);

  const handleFromDate = (date: Date) => {
    if (date) {
      return setFromDate(moment(date).format("YYYY-MM-DDT00:00:00.000"));
    }
    return setFromDate(null);
  };

  const handleToDate = (date: Date) => {
    if (date) {
      return setToDate(moment(date).format("YYYY-MM-DDT23:59:59.999"));
    }
    return setToDate(null);
  };

  const handleSubmit = (
    event: React.FormEvent,
    input: string,
    channelName: string,
    type: string,
    startDate: string,
    endDate: string
  ) => {
    event.preventDefault();
    props.onFilterDataChange(input.trim());
    props.onFromDate(startDate);
    props.onToDate(endDate);
    if (channelName !== "all") {
      props.onSelectChannel(channelName);
    } else {
      props.onSelectChannel("");
    }
  };

  const handleSortingClick = (column: string) => {
    if (column === "Created At") {
      props.setOrderBy("createdAt");
      props.setOrderByASC(!props.orderByASC);
    }
  };

  return (
    <Grid>
      <Grid.Row>
        <div className="col-12 col-md-8">
          {!emailsChartDataQuery.loading &&
            emailsChartDataQuery.data &&
            emailsChartDataQuery.data.Channel &&
            emailsChartDataQuery.data.Channel.channelsChart &&
            !smsChartDataQuery.loading &&
            smsChartDataQuery.data &&
            smsChartDataQuery.data.Channel &&
            smsChartDataQuery.data.Channel.channelsChart &&
            !tgChartDataQuery.loading &&
            tgChartDataQuery.data &&
            tgChartDataQuery.data.Channel &&
            tgChartDataQuery.data.Channel.channelsChart &&
            !slackChartDataQuery.loading &&
            slackChartDataQuery.data &&
            slackChartDataQuery.data.Channel &&
            slackChartDataQuery.data.Channel.channelsChart &&
            !trelloChartDataQuery.loading &&
            trelloChartDataQuery.data &&
            trelloChartDataQuery.data.Channel &&
            trelloChartDataQuery.data.Channel.channelsChart && (
              <Chart
                yColName="Email"
                yColName2="Slack"
                yColName3="Telegram"
                yColName4="SMS"
                yColName5="Trello"
                regData={emailsChartDataQuery.data.Channel.channelsChart}
                regData2={slackChartDataQuery.data.Channel.channelsChart}
                regData3={tgChartDataQuery.data.Channel.channelsChart}
                regData4={smsChartDataQuery.data.Channel.channelsChart}
                regData5={trelloChartDataQuery.data.Channel.channelsChart}
                interval={null}
                chartName="Daily activated Channels"
              />
            )}
        </div>
        <div className="col-12 col-md-4">
          <form
            onSubmit={(e) =>
              handleSubmit(e, filterInput, channel, type, fromDate, toDate)
            }
          >
            <Form.Group>
              <Form.Input
                label="Input"
                className="margin-bottom"
                icon="search"
                position="append"
                placeholder="Search for Code, User Email, User Name"
                value={filterInput}
                onChange={handleInputChange}
              />
              <Form.Select onChange={handleSelectChannel} label="Channel Name">
                {channelNames.map((el) => (
                  <option key={el} value={el}>
                    {el}
                  </option>
                ))}
              </Form.Select>
              <Form.Group label="From Date">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={fromDate ? new Date(fromDate) : null}
                  onChange={handleFromDate}
                  showMonthDropdown
                  placeholderText="Select a date"
                />
              </Form.Group>
              <Form.Group label="To Date">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={toDate ? new Date(toDate) : null}
                  onChange={handleToDate}
                  showMonthDropdown
                  placeholderText="Select a date"
                  minDate={fromDate && new Date(fromDate)}
                />
              </Form.Group>
              <Button
                className="margin-bottom"
                type="submit"
                icon="filter"
                color="primary"
              >
                Filter
              </Button>
            </Form.Group>
          </form>
        </div>
      </Grid.Row>
      <Grid.Row className={showButtons === 'true' ? "justify-content-between" : "justify-content-end"}>
        {showButtons === 'true' && (
          <div className="col-12 col-md-3 col-xs-12">
            <Button.List>
              <Button
                onClick={() =>
                  getCSV({
                    variables: { ...props.variables, csvDownload: true },
                  })
                }
                loading={loading}
                color="primary"
                mr={1}
              >
                Get CSV Link
              </Button>
              <Button
                onClick={() =>
                  uploadToDrive({
                    variables: { ...props.variables, csvDownload: true },
                  })
                }
                loading={driveDataLoading}
                color="primary"
              >
                Save to Drive
              </Button>
            </Button.List>
            {!loading &&
              data &&
              data.Channel &&
              data.Channel.adminGetMany &&
              data.Channel.adminGetMany.csvURL && (
                <Button
                  RootComponent="a"
                  href={data.Channel.adminGetMany.csvURL}
                  icon="download"
                >
                  Download CSV
                </Button>
              )}
            <br />
          </div>
        )}
        <Grid.Col md={3}>
          <Alert type="info">Result Count - {total}</Alert>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row cards deck>
        <ServerSidePagination
          data={notificationsPagination}
          total={total}
          onPageNum={props.onPageNumberChange}
        >
          <TableCard
            loading={dataLoading}
            data={baseNotifications}
            tableData={tableData}
            onHeaderClick={handleSortingClick}
          />
        </ServerSidePagination>
      </Grid.Row>
      <Grid.Row className="justify-content-end">
        {total ? (
          <Grid.Col md={3}>
            <Alert type="info">Result Count - {total}</Alert>
          </Grid.Col>
        ) : null}
      </Grid.Row>
    </Grid>
  );
};

export default Channels;
