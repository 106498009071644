import React from "react";
import { Alert, Button } from "tabler-react";
import ReactModal from "react-modal";
import { TableModalProps } from "../../types";
import "react-mde/lib/styles/css/react-mde-all.css";

ReactModal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    // height: "80%",
  },
};

const UsersModal = ({
  handleModal,
  showModal,
  errorMessage,
  data,
}: any): JSX.Element => {
  return (
    <div style={{ height: 0 }}>
      <ReactModal
        isOpen={showModal}
        contentLabel="impact"
        style={customStyles}
        onRequestClose={handleModal}
      >
        <h4>Imact actions</h4>
        {errorMessage ? <Alert type="danger">{errorMessage}</Alert> : null}
        <div>
          {data &&
            data.map((item) => (
              <div>
                <p>action id: {item.id}</p>
              </div>
            ))}
        </div>
        <div className="col-md-12 d-flex align-items-end justify-content-end">
          <div className="d-flex  " style={{ marginBottom: "16px" }}>
            <Button onClick={handleModal} color="secondary" type="button">
              Cancel
            </Button>
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

export default UsersModal;
