import React, { useEffect, useState, FormEvent } from "react";
import { Grid, Form, Button, Alert } from "tabler-react";
import { ContentTextProps, ScanLogProps } from "../../types";
import moment from "moment";
import TableCard from "../../components/tableCard";
import "./index.css";
import ServerSidePagination from "../../components/pagination/serverSidePagination";
import { SCANREQUESTLOG_CHART, SCAN_REQUEST_LOG } from "../../graphql/queries";
import Chart from "../../components/chart";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import DatePicker from "react-datepicker";
import { tools } from "../../constants";
import { sources } from "../../constants";
import { getCountArr, isProduction } from "../../helpers";

const ScanLogs = (props: ScanLogProps): JSX.Element => {
  const [requestLogs, setRequestLogs] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [scanLogsPagination, setScanLogsPagination] = useState([]);
  const [filterInput, setFilterInput] = useState("");
  const [total, setTotal] = useState(0);
  const [selectTool, setSelectTool] = useState("");
  const [source, setSource] = useState("all");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [countryCode, setCountryCode] = useState("");
  const showButtons = localStorage.getItem("_rep");

  const [getCSV, { data, loading }] = useLazyQuery(SCAN_REQUEST_LOG);
  const [uploadToDrive, { data: driveData, loading: driveDataLoading }] =
    useLazyQuery(SCAN_REQUEST_LOG);

  const chartDataQuery = useQuery(SCANREQUESTLOG_CHART, {
    variables: { hideHexScans: props.filterHexScans },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const tableData = {
    tData:
      requestLogs &&
      requestLogs.map((item: ContentTextProps) => {
        return {
          Input: item.input,
          "Scan Tool": item.scanTool,
          Time: moment(item.time).utc().format("DD-MM-YYYY hh:mm:ss a"),
          "Country / Ip":
            item.countryCode && item.countryCode !== "N/A"
              ? item.countryCode
              : item.ip,
          Source: item.source,
          "User Email":
            item.user && item.user.email ? item.user.email : "No email",
        };
      }),
  };

  useEffect(() => {
    if (
      props.data &&
      props.data.ScanRequestLog &&
      props.data.ScanRequestLog.getMany.requestLogs
    ) {
      setDataLoading(props.loading);
      setRequestLogs(props.data.ScanRequestLog.getMany.requestLogs);
      setScanLogsPagination(props.data.ScanRequestLog.getMany.requestLogs);
      setTotal(props.data.ScanRequestLog.getMany.total);
    }
  }, [props.data]);

  useEffect(() => {
    if (
      driveData &&
      driveData.ScanRequestLog &&
      driveData.ScanRequestLog.getMany &&
      driveData.ScanRequestLog.getMany.csvURL
    ) {
      window.open(
        isProduction()
          ? `https://api.hexometer.com/v2/app/social-callback/googleDrive?admin=admin&report=scan&url=${driveData.ScanRequestLog.getMany.csvURL}`
          : `http://localhost:4000/api/app/social-callback/googleDrive?admin=admin&report=scan&url=${driveData.ScanRequestLog.getMany.csvURL}`,
        "_blank"
      );
    }
  }, [driveData]);

  const handleInputChange = (e: FormEvent) =>
    setFilterInput((e.target as HTMLInputElement).value);

  const handleSelectedTool = (e: FormEvent) =>
    setSelectTool((e.target as HTMLInputElement).value);

  const handleSource = (e: FormEvent) =>
    setSource((e.target as HTMLInputElement).value);

  const handleCountryCode = (e: FormEvent) =>
    setCountryCode((e.target as HTMLInputElement).value);

  const handleFromDate = (date: Date) => {
    if (date) {
      return setFromDate(moment(date).format("YYYY-MM-DDT00:00:00.000"));
    }
    return setFromDate(null);
  };

  const handleToDate = (date: Date) => {
    if (date) {
      return setToDate(moment(date).format("YYYY-MM-DDT23:59:59.999"));
    }
    return setToDate(null);
  };

  const handleSubmit = (
    event: FormEvent,
    data: string,
    toolName: string,
    ext: string,
    ctrCode: string,
    startDate: string,
    endDate: string
  ) => {
    event.preventDefault();
    props.onFilterDataChange(data.trim());
    props.onFromDate(startDate);
    props.onToDate(endDate);
    props.onCountryCode(ctrCode);

    if (toolName !== "Select tool") {
      props.onToolSelect(toolName);
    } else {
      props.onToolSelect("");
    }

    if (ext === "all") {
      props.source(undefined);
    } else {
      props.source(ext);
    }
  };

  const handleSortingClick = (column: string) => {
    if (column === "Time") {
      props.setOrderBy("time");
      props.setOrderByASC(!props.orderByASC);
    }
  };

  return (
    <Grid>
      <Grid.Row className="mb-5">
        <div className="col-12 col-md-8">
          {!chartDataQuery.loading &&
            chartDataQuery.data &&
            chartDataQuery.data.ScanRequestLog &&
            chartDataQuery.data.ScanRequestLog.scanRequestLogChart && (
              <Chart
                yColName="Scaned Requests"
                regData={chartDataQuery.data.ScanRequestLog.scanRequestLogChart}
                interval={getCountArr(
                  chartDataQuery.data.ScanRequestLog.scanRequestLogChart.length
                )}
                chartName="Scan Request Activity"
              />
            )}
        </div>
        <div className="col-12 col-md-4">
          <form
            onSubmit={(e) =>
              handleSubmit(
                e,
                filterInput,
                selectTool,
                source,
                countryCode,
                fromDate,
                toDate
              )
            }
          >
            <Form.Group>
              <Form.Input
                label="Input"
                className="margin-bottom"
                icon="search"
                position="append"
                placeholder="Search for Input, User Email, User Name"
                value={filterInput}
                onChange={handleInputChange}
              />
              <Form.Select label="Tool Name" onChange={handleSelectedTool}>
                <option value="Select tool">Select tool</option>
                {tools.map((tool) => (
                  <option key={tool} value={tool}>
                    {tool}
                  </option>
                ))}
              </Form.Select>
              <Form.Select onChange={handleSource} label="Source">
                <option value="all">all</option>
                {sources.map((el) => (
                  <option key={el} value={el}>
                    {el}
                  </option>
                ))}
              </Form.Select>
              <Form.Input
                label="Country Code"
                className="margin-bottom"
                icon="search"
                position="append"
                placeholder="Search for country code"
                value={countryCode}
                onChange={handleCountryCode}
              />
              <Form.Group label="From Date">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={fromDate ? new Date(fromDate) : null}
                  onChange={handleFromDate}
                  showMonthDropdown
                  placeholderText="Select a date"
                />
              </Form.Group>
              <Form.Group label="To Date">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={toDate ? new Date(toDate) : null}
                  onChange={handleToDate}
                  showMonthDropdown
                  placeholderText="Select a date"
                  minDate={fromDate && new Date(fromDate)}
                />
              </Form.Group>
              <Button type="submit" icon="filter" color="primary">
                Filter
              </Button>
            </Form.Group>
          </form>
        </div>
      </Grid.Row>
      <Grid.Row className={showButtons === 'true' ? "justify-content-between" : "justify-content-end"}>
        {showButtons === 'true' && (
          <div className="col-12 col-md-3 col-xs-12">
            <Button.List>
              <Button
                onClick={() =>
                  getCSV({
                    variables: { ...props.variables, csvDownload: true },
                  })
                }
                loading={loading}
                color="primary"
                mr={1}
              >
                Get CSV Link
              </Button>
              <Button
                onClick={() =>
                  uploadToDrive({
                    variables: { ...props.variables, csvDownload: true },
                  })
                }
                loading={driveDataLoading}
                color="primary"
              >
                Save to Drive
              </Button>
            </Button.List>
            {!loading &&
              data &&
              data.ScanRequestLog &&
              data.ScanRequestLog.getMany &&
              data.ScanRequestLog.getMany.csvURL && (
                <Button
                  RootComponent="a"
                  href={data.ScanRequestLog.getMany.csvURL}
                  icon="download"
                >
                  Download CSV
                </Button>
              )}
            <br />
          </div>
        )}
        <Grid.Col md={3}>
          <Alert type="info">Result Count - {total}</Alert>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row cards deck>
        <ServerSidePagination
          data={scanLogsPagination}
          total={total}
          onPageNum={props.onPageNumberChange}
        >
          <TableCard
            loading={dataLoading}
            data={requestLogs}
            tableData={tableData}
            onHeaderClick={handleSortingClick}
          />
        </ServerSidePagination>
      </Grid.Row>
      <Grid.Row className="justify-content-end">
        {total ? (
          <Grid.Col md={3}>
            <Alert type="info">Result Count - {total}</Alert>
          </Grid.Col>
        ) : null}
      </Grid.Row>
    </Grid>
  );
};

export default ScanLogs;
