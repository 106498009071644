import React, { useEffect, useState } from "react";
import { Alert, Button, Form, Table } from "tabler-react";
import ReactModal from "react-modal";
import { TableModalProps } from "../../types";
import ReactMde from "react-mde";
import * as Showdown from "showdown";
import "react-mde/lib/styles/css/react-mde-all.css";
import { useQuery } from "@apollo/react-hooks";
import { GET_PUBLIC_WORKFLOW_CATEGORIES } from "../../graphql/queries";

ReactModal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    height: "50%",
  },
};

const TableModal = ({
  handleModal,
  showModal,
  onSave,
  editFiled,
  errorMessage,
}: TableModalProps): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [categoryIds, setCategoryIds] = useState([] as any);
  const [image, setImage] = useState("");
  const [tab, setTab] = useState<"write" | "preview">("preview");
  const [categoriesList, setCategoriesList] = useState([] as any);

  const { data: categories } = useQuery(GET_PUBLIC_WORKFLOW_CATEGORIES, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (
      categories &&
      categories.HexomaticWorkflowCategory &&
      categories.HexomaticWorkflowCategory.getWorkflowCategories &&
      categories.HexomaticWorkflowCategory.getWorkflowCategories.categories
    ) {
      setCategoriesList(categories.HexomaticWorkflowCategory.getWorkflowCategories.categories);
    }
  }, [categories]);

  const handleOk = async () => {
    setLoading(true);
    const formData = {
      id: editFiled.id,
      name,
      description,
      metaTitle,
      metaDescription,
      image,
      categoryIds,
    };
    const completed = await onSave({ ...formData });
    setLoading(false);
    if (completed) handleCancel();
  };

  const handleCancel = () => {
    setName("");
    handleModal();
  };

  useEffect(() => {
    if (editFiled) {
      setName(editFiled.name);
      setDescription(editFiled.description);
      setMetaTitle(editFiled.meta_title);
      setMetaDescription(editFiled.meta_description);
      setImage(editFiled.image);
      editFiled.categories && setCategoryIds(editFiled.categories.map((item: any) => item.id));
    }
  }, [editFiled]);

  useEffect(() => {
    if (window.innerWidth < 640) {
      customStyles.content.width = "90%";
    }
  }, []);

  const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true,
  });

  const handleNameChange = (e: React.FormEvent) => setName((e.target as HTMLInputElement).value);
  const handleDescriptionChange = (e: React.FormEvent) =>
    setDescription((e.target as HTMLInputElement).value);
  const handleMetaTitleChange = (e: React.FormEvent) => setMetaTitle((e.target as HTMLInputElement).value);
  const handleMetaDescriptionChange = (e: React.FormEvent) =>
    setMetaDescription((e.target as HTMLInputElement).value);
  const handleTabChange = (tab: "write" | "preview") => setTab(tab);

  return (
    <div style={{ height: 0 }}>
      <ReactModal
        isOpen={showModal}
        contentLabel="Create category name"
        style={customStyles}
        onRequestClose={handleCancel}
      >
        <h4>Edit public workflow</h4>
        {errorMessage ? <Alert type="danger">{errorMessage}</Alert> : null}
        <Form onSubmit={(e: React.FormEvent) => e.preventDefault()}>
          <Form.Input
            required
            type="text"
            placeholder="name"
            label="name"
            value={name}
            onChange={handleNameChange}
          />
          <Form.Input
            type="text"
            placeholder="meta title"
            label="meta title"
            value={metaTitle}
            onChange={handleMetaTitleChange}
          />
          <span style={{ marginBottom: "5px", fontWeight: 600 }}>short description</span>
          <ReactMde
            onChange={(value: string) => setDescription(value)}
            onTabChange={handleTabChange}
            value={description}
            generateMarkdownPreview={(markdown) => Promise.resolve(converter.makeHtml(markdown))}
            selectedTab={tab}
            className="mb-2"
          />
          <span style={{ marginBottom: "5px", fontWeight: 600 }}>public description</span>
          <ReactMde
            onChange={(value: string) => setMetaDescription(value)}
            onTabChange={handleTabChange}
            value={metaDescription}
            generateMarkdownPreview={(markdown) => Promise.resolve(converter.makeHtml(markdown))}
            selectedTab={tab}
            className="mb-2"
          />
          <Form.Input
            required
            type="text"
            placeholder="image"
            label="image"
            value={image}
            onChange={(e: any) => setImage(e.target.value)}
          />
          {categoriesList &&
            categoriesList.map((row: any) => {
              return (
                <div key={row.id} className="d-flex">
                  <Form.Checkbox
                    className=""
                    label={row.name}
                    checked={categoryIds.includes(row.id)}
                    onChange={() => {
                      if (categoryIds.includes(row.id)) {
                        const category = categoryIds.filter((item: any) => item !== row.id);
                        setCategoryIds(category);
                      } else {
                        const category = [...categoryIds];
                        category.push(row.id);
                        setCategoryIds(category);
                      }
                    }}
                  />
                </div>
              );
            })}
          <Button.List style={{ marginTop: "5%" }}>
            <Button onClick={handleOk} color="primary" type="submit" loading={loading}>
              Save
            </Button>
            <Button onClick={handleCancel} color="secondary" type="button">
              Cancel
            </Button>
          </Button.List>
        </Form>
      </ReactModal>
    </div>
  );
};

export default TableModal;
