import React, { FC, useEffect, useState } from "react";
import C3Chart from "react-c3js";
import moment from "moment";
import "c3/c3.css";
import DatePicker from "react-datepicker";
import { Card, Form, Grid, Button } from "tabler-react";
import { ChartProps } from "../../types";
import { filterButtons, filterButtonsMonth } from "../piechart";

// const intervals = [7, 30, 45, 90, 120, 150];
// const minInterval = intervals[0];

const ChartWithIntervals: FC<ChartProps> = ({
  regData,
  chartName,
  yColName,
  yColName3,
  yColName2,
  regData2,
  regData3,
  interval,
  propColors,
  onFrequency,
  onFromDate,
  onToDate,
  setIntervalName,
  intervalName,
  fromDate,
  toDate,
  frequency,
  ...props
}) => {
  // const [chartInterval, setChartInterval] = useState(minInterval);
  const [inputVal, setInputVal] = useState(7);
  // const [totalCount, setTotalCount] = useState(0)

  const [data, setData] = useState({
    x: "x",
    columns: [
      ["x"],
      [yColName],
      [yColName2],
      [yColName3],
      [props.yColName4],
      [props.yColName5],
      [props.yColName6],
      [props.yColName7],
      [props.yColName8],
      [props.yColName9],
      [props.yColName10],
      [props.yColName11],
      [props.yColName12],
    ],
    type: "area-spline",
  });

  const color = {
    pattern: propColors ? propColors : ["#1f77b4", "#ff7f0e", "#000000", "#98ca33", "#e74c3c"],
  };

  const [axis, setAxis] = useState({
    x: {
      type: "timeseries",
      tick: {
        count: data.columns[0].length - 1 <= 7 ? data.columns[0].length - 1 : 7,
        format: "%Y-%m-%d",
      },
    },
  });

  const [axisM, setAxisM] = useState({
    x: {
      type: "timeseries",
      tick: {
        count: data.columns[0].length - 1 <= 7 ? data.columns[0].length - 1 : 7,
        format: "%Y-%m",
      },
    },
  });

  const createDateArray = (fromDate: Date, toDate: Date, frequency: string) => {
    let currentDate = new Date(fromDate);
    let dateArray = [];
    if (frequency === "month") {
      // Ensure start date is at the beginning of the month
      fromDate.setUTCDate(1);
  
      // Loop through each month between fromDate and end dates
      let current = new Date(fromDate);
      while (current <= toDate) {
          const year = current.getUTCFullYear();
          const month = current.getUTCMonth() + 1; // Add 1 because getUTCMonth() returns 0-indexed month
  
          // Format year and month as YYYY-MM
          const formattedMonth = `${year}-${month.toString().padStart(2, '0')}`;
  
          // Add the formatted month to the result array
          dateArray.push(formattedMonth);
  
          // Move to the next month
          current.setUTCMonth(current.getUTCMonth() + 1);
      }
    } else {
      while (currentDate <= toDate) {
        dateArray.push(currentDate.toISOString().slice(0, 10));
        currentDate.setDate(currentDate.getDate() + 1);
      }
    }
    return dateArray;
  };

  useEffect(() => {
    const chartInterval = createDateArray(new Date(fromDate), new Date(toDate), frequency);
    let localData = {
      x: "x",
      columns: [
        ["x"],
        [yColName],
        [yColName2],
        [yColName3],
        [props.yColName4],
        [props.yColName5],
        [props.yColName6],
        [props.yColName7],
        [props.yColName8],
        [props.yColName9],
        [props.yColName10],
        [props.yColName11],
        [props.yColName12],
      ],
      type: "area-spline",
    };
    if (regData && !props.registerChart) {
      chartInterval.map((item, index) => {
        const timeF = moment(new Date(item)).utc().format(frequency === "month" ? "YYYY-MM" : "YYYY-MM-DD");
        const reg = regData.find((r) => {
          let time = r.time;
          if (+r.time) {
            time = +r.time;
          }
          return (
            moment(new Date(time))
              .utc()
              .format(frequency === "month" ? "YYYY-MM" : "YYYY-MM-DD") === timeF
          );
        });
        localData.columns[0].push(timeF);

        if (reg) {
          localData.columns[1].push(reg.count);
        } else {
          localData.columns[1].push("0");
        }

        if (regData2) {
          const chartData2 = regData2.find((r) => {
            let time = r.time;
            if (+r.time) {
              time = +r.time;
            }
            return (
              moment(new Date(time))
                .utc()
                .format(frequency === "month" ? "YYYY-MM" : "YYYY-MM-DD") === timeF
            );
          });

          if (chartData2) {
            localData.columns[2].push(chartData2.count);
          } else {
            localData.columns[2].push("0");
          }
        }

        if (regData3) {
          const chartData3 = regData3.find((r) => {
            let time = r.time;
            if (+r.time) {
              time = +r.time;
            }
            return (
              moment(new Date(time))
                .utc()
                .format(frequency === "month" ? "YYYY-MM" : "YYYY-MM-DD") === timeF
            );
          });

          if (chartData3) {
            localData.columns[3].push(chartData3.count);
          } else {
            localData.columns[3].push("0");
          }
        }

        if (props.regData4) {
          const chartData4 = props.regData4.find((r) => {
            let time = r.time;
            if (+r.time) {
              time = +r.time;
            }
            return (
              moment(new Date(time))
                .utc()
                .format(frequency === "month" ? "YYYY-MM" : "YYYY-MM-DD") === timeF
            );
          });

          if (chartData4) {
            localData.columns[4].push(chartData4.count);
          } else {
            localData.columns[4].push("0");
          }
        }

        if (props.regData5) {
          const chartData5 = props.regData5.find((r) => {
            let time = r.time;
            if (+r.time) {
              time = +r.time;
            }
            return (
              moment(new Date(time))
                .utc()
                .format(frequency === "month" ? "YYYY-MM" : "YYYY-MM-DD") === timeF
            );
          });

          if (chartData5) {
            localData.columns[5].push(chartData5.count);
          } else {
            localData.columns[5].push("0");
          }
        }
        if (props.regData6) {
          const chartData6 = props.regData6.find((r) => {
            let time = r.time;
            if (+r.time) {
              time = +r.time;
            }
            return (
              moment(new Date(time))
                .utc()
                .format(frequency === "month" ? "YYYY-MM" : "YYYY-MM-DD") === timeF
            );
          });

          if (chartData6) {
            localData.columns[6].push(chartData6.count);
          } else {
            localData.columns[6].push("0");
          }
        }
        if (props.regData7) {
          const chartData7 = props.regData7.find((r) => {
            let time = r.time;
            if (+r.time) {
              time = +r.time;
            }
            return (
              moment(new Date(time))
                .utc()
                .format(frequency === "month" ? "YYYY-MM" : "YYYY-MM-DD") === timeF
            );
          });

          if (chartData7) {
            localData.columns[7].push(chartData7.count);
          } else {
            localData.columns[7].push("0");
          }
        }
        if (props.regData8) {
          const chartData8 = props.regData8.find((r) => {
            let time = r.time;
            if (+r.time) {
              time = +r.time;
            }
            return (
              moment(new Date(time))
                .utc()
                .format(frequency === "month" ? "YYYY-MM" : "YYYY-MM-DD") === timeF
            );
          });

          if (chartData8) {
            localData.columns[8].push(chartData8.count);
          } else {
            localData.columns[8].push("0");
          }
        }
        if (props.regData9) {
          const chartData9 = props.regData9.find((r) => {
            let time = r.time;
            if (+r.time) {
              time = +r.time;
            }
            return (
              moment(new Date(time))
                .utc()
                .format(frequency === "month" ? "YYYY-MM" : "YYYY-MM-DD") === timeF
            );
          });

          if (chartData9) {
            localData.columns[9].push(chartData9.count);
          } else {
            localData.columns[9].push("0");
          }
        }
        if (props.regData10) {
          const chartData10 = props.regData10.find((r) => {
            let time = r.time;
            if (+r.time) {
              time = +r.time;
            }
            return (
              moment(new Date(time))
                .utc()
                .format(frequency === "month" ? "YYYY-MM" : "YYYY-MM-DD") === timeF
            );
          });

          if (chartData10) {
            localData.columns[10].push(chartData10.count);
          } else {
            localData.columns[10].push("0");
          }
        }

        if (props.regData11) {
          const chartData11 = props.regData11.find((r) => {
            let time = r.time;
            if (+r.time) {
              time = +r.time;
            }
            return (
              moment(new Date(time))
                .utc()
                .format(frequency === "month" ? "YYYY-MM" : "YYYY-MM-DD") === timeF
            );
          });

          if (chartData11) {
            localData.columns[11].push(chartData11.count);
          } else {
            localData.columns[11].push("0");
          }
        }
        if (props.regData12) {
          const chartData12 = props.regData12.find((r) => {
            let time = r.time;
            if (+r.time) {
              time = +r.time;
            }
            return (
              moment(new Date(time))
                .utc()
                .format(frequency === "month" ? "YYYY-MM" : "YYYY-MM-DD") === timeF
            );
          });

          if (chartData12) {
            localData.columns[12].push(chartData12.count);
          } else {
            localData.columns[12].push("0");
          }
        }
      });
    }

    if (regData && props.registerChart) {
      chartInterval.map((item, index) => {
        const timeF = moment(new Date(item)).utc().format(frequency === "month" ? "YYYY-MM" : "YYYY-MM-DD");
        const reg = regData.find((r) => {
          let time = r.time;
          if (+r.time) {
            time = +r.time;
          }

          return (
            moment(new Date(time))
              .utc()
              .format(frequency === "month" ? "YYYY-MM" : "YYYY-MM-DD") === timeF
          );
        });
        localData.columns[0].push(timeF);
        if (reg) {
          localData.columns[1].push(
            reg.hexospark + reg.hexometer + reg.hexomatic + reg.hexowatch + reg.hexofy
          );
          localData.columns[2].push(reg.hexometer);
          localData.columns[3].push(reg.hexowatch);
          localData.columns[4].push(reg.hexomatic);
          localData.columns[5].push(reg.hexospark);
          localData.columns[6].push(reg.hexofy);
        } else {
          localData.columns[1].push("0");
          localData.columns[2].push("0");
          localData.columns[3].push("0");
          localData.columns[4].push("0");
          localData.columns[5].push("0");
          localData.columns[6].push("0");
        }
      });
    }
    const finData =
      frequency === "month"
        ? {
            ...localData,
            columns: localData.columns.map((sub, index) =>
              index === 0 ? sub.map((subsub) => (subsub !== "x" ? subsub + "-01" : subsub)) : sub
            ),
          }
        : localData;
    setData(finData);
  }, [
    regData,
    regData2,
    regData3,
    props.registerChart,
    props.regData4,
    props.regData5,
    props.regData6,
    props.regData6,
    props.regData7,
    props.regData8,
    props.regData9,
    props.regData10,
    props.regData11,
    props.regData12,
    props.reload,
    props.reloadData,
    fromDate,
    toDate,
    frequency,
  ]);

  // const onChangeVal = (e) => {
  //   if (isNaN(e.target.value)) {
  //     return;
  //   }
  //   setInputVal(+e.target.value);
  // };

  // const handleKeyDown = (e: React.KeyboardEvent) => {
  //   if (e.keyCode === 13) {
  //     setChartInterval(inputVal);
  //   }
  // };

  return (
    <Card>
      <Card.Header>
        <div>{chartName}</div>
        <div className="frequency-tabs">
          <div
            className={`frequency-tab tab-left ${frequency === "month" ? "active" : ""}`}
            onClick={() => onFrequency("month")}
          >
            Monthly
          </div>
          <div
            className={`frequency-tab tab-right ${frequency !== "month" ? "active" : ""}`}
            onClick={() => onFrequency("daily")}
          >
            Daily
          </div>
        </div>
      </Card.Header>
      <Card.Body className={`chart-wrapper ${frequency === "month" ? "d-none" : ""}`}>
        {regData && <C3Chart data={data} axis={axis} color={color} padding={{ right: 20 }} />}
      </Card.Body>
      <Card.Body className={`chart-wrapper ${frequency !== "month" ? "d-none" : ""}`}>
        {regData && <C3Chart data={data} axis={axisM} color={color} padding={{ right: 20 }} />}
      </Card.Body>
      <Card.Footer>
        <Button.List>
          {(frequency === 'month' ? filterButtonsMonth : filterButtons).map((btnObj) => (
            <Button
              onClick={() => {
                onFromDate(new Date(btnObj.fromDate));
                onToDate(new Date(btnObj.toDate));
                setIntervalName(btnObj.name);
              }}
              color={intervalName === btnObj.name ? "active" : "secondary"}
              key={btnObj.name}
            >
              {btnObj.name}
            </Button>
          ))}
          <Grid.Row className="from-to-datepicker">
            <Grid.Col sm={6} md={4}>
              <Form.Group>
                <Grid.Row className="no-col-flex">
                  <Grid.Col md={3} sm={3} className="col-12 d-flex">
                    <Form.Label className="m-auto">From:</Form.Label>
                  </Grid.Col>
                  <Grid.Col>
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      selected={new Date(fromDate)}
                      onChange={(date) => {
                        onFromDate(date);
                        setIntervalName("");
                      }}
                      showMonthDropdown
                      placeholderText="Select a date"
                    />
                  </Grid.Col>
                </Grid.Row>
              </Form.Group>
            </Grid.Col>
            <Grid.Col sm={6} md={4}>
              <Form.Group>
                <Grid.Row>
                  <Grid.Col md={3} sm={3} className="col-12 d-flex">
                    <Form.Label className="m-auto">To:</Form.Label>
                  </Grid.Col>
                  <Grid.Col>
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      selected={new Date(toDate)}
                      onChange={(date) => {
                        setIntervalName("");
                        onToDate(date);
                      }}
                      showMonthDropdown
                      placeholderText="Select a date"
                      minDate={fromDate ? new Date(fromDate) : null}
                    />
                  </Grid.Col>
                </Grid.Row>
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
        </Button.List>
        {/* <span>Last </span>
        <input onKeyDown={handleKeyDown} type="text" value={inputVal} onChange={onChangeVal} />
        <span> Days </span>
        <button onClick={() => setChartInterval(inputVal)}>Get</button>
        {interval && <span> Max Days: {interval[interval.length - 1]}</span>} */}

        {/* <Button.List>
          {intervals.map(interval =>
            <Button
              onClick={() => setChartInterval(interval)}
              color={chartInterval === interval ? "active" : "secondary"}
              key={interval}
            >Last {interval} days</Button>
          )}
        </Button.List> */}
      </Card.Footer>
    </Card>
  );
};

export default ChartWithIntervals;
