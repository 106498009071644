import React, { useState, useEffect } from "react";
import { Grid, Button, Alert, Form } from "tabler-react";
import moment from "moment";
import { NotificationProps } from "../../types";
import ServerSidePagination from "../../components/pagination/serverSidePagination";
import TableCard from "../../components/tableCard";
import DatePicker from "react-datepicker";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import { NOTIFICATIONS_CHART, GET_NOTIFICATIONS } from "../../graphql/queries";
import Chart from "../../components/chart";
import { getCountArr, isProduction } from "../../helpers";

const Notifications = ({ refetchData, ...props }: NotificationProps): JSX.Element => {
  const [baseNotifications, setBaseNotifications] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [notificationsPagination, setNotificationsPagination] = useState([]);
  const [total, setTotal] = useState(0);
  const [filterInput, setFilterInput] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [channel, setChannel] = useState("");
  const [type, setType] = useState("");
  const showButtons = localStorage.getItem("_rep");

  const [getCSV, { data, loading }] = useLazyQuery(GET_NOTIFICATIONS);
  const [uploadToDrive, { data: driveData, loading: driveDataLoading }] = useLazyQuery(GET_NOTIFICATIONS);

  const emailsChartDataQuery = useQuery(NOTIFICATIONS_CHART, {
    variables: { channel: "email" },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const smsChartDataQuery = useQuery(NOTIFICATIONS_CHART, {
    variables: { channel: "sms" },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const tgChartDataQuery = useQuery(NOTIFICATIONS_CHART, {
    variables: { channel: "telegram" },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const slackChartDataQuery = useQuery(NOTIFICATIONS_CHART, {
    variables: { channel: "slack" },
    notifyOnNetworkStatusChange: true,
  });

  const trelloChartDataQuery = useQuery(NOTIFICATIONS_CHART, {
    variables: { channel: "trello" },
    notifyOnNetworkStatusChange: true,
  });

  const channels = {
    all: [
      "all",
      "pingResponseCodeChange",
      "broken_links",
      "general",
      "metaDescriptionDuplicate",
      "metaTitleDuplicate",
      "verifyEmail",
      "passwordReset",
      "calendarInvite",
      "verificationCode",
      "invitedRegistered",
      "invitedNotRegistered",
      "techStack",
      "welcomeEmail",
      "webMetrics",
    ],
    email: [
      "all",
      "pingResponseCodeChange",
      "broken_links",
      "general",
      "metaDescriptionDuplicate",
      "metaTitleDuplicate",
      "verifyEmail",
      "passwordReset",
      "calendarInvite",
      "invitedRegistered",
      "invitedNotRegistered",
      "techStack",
      "welcomeEmail",
      "monthlySummary",
      "webMetrics",
    ],
    sms: ["all", "pingResponseCodeChange", "verificationCode"],
    slack: [
      "all",
      "pingResponseCodeChange",
      "broken_links",
      "general",
      "metaDescriptionDuplicate",
      "metaTitleDuplicate",
      "techStack",
      "webMetrics",
    ],
    // trello: ['all', 'pingResponseCodeChange', 'broken_links'],
    telegram: [
      "all",
      "pingResponseCodeChange",
      "broken_links",
      "general",
      "metaDescriptionDuplicate",
      "metaTitleDuplicate",
      "techStack",
      "webMetrics",
    ],
  };

  const tableData = {
    tData:
      baseNotifications &&
      baseNotifications.map((item) => ({
        ID: item.id,
        Channel: item.channel,
        Type: item.type,
        "Created At": moment(item.createdAt).utc().format("DD-MM-YYYY hh:mm:ss a"),
        User: item.user && item.user.email,
        "Property Hostname": item.property && item.property.hostname,
      })),
  };

  useEffect(() => {
    if (
      props.data &&
      props.data.Notification &&
      props.data.Notification.adminGetMany &&
      props.data.Notification.adminGetMany.notifications
    ) {
      setDataLoading(props.loading);
      setBaseNotifications(props.data.Notification.adminGetMany.notifications);
      setNotificationsPagination(props.data.Notification.adminGetMany.notifications);
      setTotal(props.data.Notification.adminGetMany.total);
    }
  }, [props.data]);

  useEffect(() => {
    if (
      driveData &&
      driveData.Notification &&
      driveData.Notification.adminGetMany &&
      driveData.Notification.adminGetMany.csvURL
    ) {
      window.open(
        isProduction()
          ? `https://api.hexometer.com/v2/app/social-callback/googleDrive?admin=admin&report=notifications&url=${driveData.Notification.adminGetMany.csvURL}`
          : `http://localhost:4000/api/app/social-callback/googleDrive?admin=admin&report=notifications&url=${driveData.Notification.adminGetMany.csvURL}`,
        "_blank"
      );
    }
  }, [driveData]);

  const handleInputChange = (e: React.FormEvent) => setFilterInput((e.target as HTMLInputElement).value);

  const handleSelectChannel = (e: React.FormEvent) => setChannel((e.target as HTMLInputElement).value);

  const handleSelectType = (e: React.FormEvent) => setType((e.target as HTMLInputElement).value);

  const handleFromDate = (date: Date) => {
    if (date) {
      return setFromDate(moment(date).format("YYYY-MM-DDT00:00:00.000"));
    }
    return setFromDate(null);
  };

  const handleToDate = (date: Date) => {
    if (date) {
      return setToDate(moment(date).format("YYYY-MM-DDT23:59:59.999"));
    }
    return setToDate(null);
  };

  const handleSubmit = (
    event: React.FormEvent,
    input: string,
    channelName: string,
    type: string,
    startDate: string,
    endDate: string
  ) => {
    event.preventDefault();
    props.onFilterDataChange(input.trim());
    props.onFromDate(startDate);
    props.onToDate(endDate);
    if (channelName !== "all") {
      props.onSelectChannel(channelName);
    } else {
      props.onSelectChannel("");
    }
    if (type !== "all") {
      props.onSelectType(type);
    } else {
      props.onSelectType("");
    }
  };

  const handleSortingClick = (column: string) => {
    if (column === "Created At") {
      props.setOrderBy("createdAt");
      props.setOrderByASC(!props.orderByASC);
    }
  };

  return (
    <Grid>
      <Grid.Row>
        <div className="col-12 col-md-8">
          {!emailsChartDataQuery.loading &&
            emailsChartDataQuery.data &&
            emailsChartDataQuery.data.Notification &&
            emailsChartDataQuery.data.Notification.notificationsChart &&
            !smsChartDataQuery.loading &&
            smsChartDataQuery.data &&
            smsChartDataQuery.data.Notification &&
            smsChartDataQuery.data.Notification.notificationsChart &&
            !tgChartDataQuery.loading &&
            tgChartDataQuery.data &&
            tgChartDataQuery.data.Notification &&
            tgChartDataQuery.data.Notification.notificationsChart &&
            !slackChartDataQuery.loading &&
            slackChartDataQuery.data &&
            slackChartDataQuery.data.Notification &&
            slackChartDataQuery.data.Notification.notificationsChart &&
            !trelloChartDataQuery.loading &&
            trelloChartDataQuery.data &&
            trelloChartDataQuery.data.Notification &&
            trelloChartDataQuery.data.Notification.notificationsChart && (
              <Chart
                yColName="Email"
                yColName2="SMS"
                yColName3="Telegram"
                yColName4="Slack"
                yColName5="Trello"
                regData={emailsChartDataQuery.data.Notification.notificationsChart}
                regData2={smsChartDataQuery.data.Notification.notificationsChart}
                regData3={tgChartDataQuery.data.Notification.notificationsChart}
                regData4={slackChartDataQuery.data.Notification.notificationsChart}
                regData5={trelloChartDataQuery.data.Notification.notificationsChart}
                interval={getCountArr(emailsChartDataQuery.data.Notification.notificationsChart.length)}
                chartName="Daily Sent Notifications"
              />
            )}
        </div>
        <div className="col-12 col-md-4">
          <form onSubmit={(e) => handleSubmit(e, filterInput, channel, type, fromDate, toDate)}>
            <Form.Group>
              <Form.Input
                label="Input"
                className="margin-bottom"
                icon="search"
                position="append"
                placeholder="Search for Code, User Email, User Name"
                value={filterInput}
                onChange={handleInputChange}
              />
              <Form.Select onChange={handleSelectChannel} label="Channel">
                {Object.keys(channels).map((el) => (
                  <option key={el} value={el}>
                    {el}
                  </option>
                ))}
              </Form.Select>
              <Form.Select onChange={handleSelectType} label="Hexometer Notification Type">
                {channels[channel === "" ? "all" : channel].map((el: string) => (
                  <option key={el} value={el}>
                    {el}
                  </option>
                ))}
              </Form.Select>
              <Form.Group label="From Date">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={fromDate ? new Date(fromDate) : null}
                  onChange={handleFromDate}
                  showMonthDropdown
                  placeholderText="Select a date"
                />
              </Form.Group>
              <Form.Group label="To Date">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={toDate ? new Date(toDate) : null}
                  onChange={handleToDate}
                  showMonthDropdown
                  placeholderText="Select a date"
                  minDate={fromDate && new Date(fromDate)}
                />
              </Form.Group>
              <Button className="margin-bottom" type="submit" icon="filter" color="primary">
                Filter
              </Button>
            </Form.Group>
          </form>
        </div>
      </Grid.Row>
      <Grid.Row className={showButtons === "true" ? "justify-content-between" : "justify-content-end"}>
        {showButtons === "true" && (
          <div className="col-12 col-md-3 col-xs-12">
            <Button.List>
              <Button
                onClick={() =>
                  getCSV({
                    variables: { ...props.variables, csvDownload: true },
                  })
                }
                loading={loading}
                color="primary"
                mr={1}
              >
                Get CSV Link
              </Button>
              <Button
                onClick={() =>
                  uploadToDrive({
                    variables: { ...props.variables, csvDownload: true },
                  })
                }
                loading={loading}
                color="primary"
              >
                Save to Drive
              </Button>
            </Button.List>
            {!loading &&
              data &&
              data.Notification &&
              data.Notification.adminGetMany &&
              data.Notification.adminGetMany.csvURL && (
                <Button RootComponent="a" href={data.Notification.adminGetMany.csvURL} icon="download">
                  Download CSV
                </Button>
              )}
            <br />
          </div>
        )}
        <Grid.Col md={3}>
          <Alert type="info">Result Count - {total}</Alert>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row cards deck>
        <ServerSidePagination
          data={notificationsPagination}
          total={total}
          onPageNum={props.onPageNumberChange}
        >
          <TableCard
            loading={dataLoading}
            data={baseNotifications}
            tableData={tableData}
            onHeaderClick={handleSortingClick}
          />
        </ServerSidePagination>
      </Grid.Row>
      <Grid.Row className="justify-content-end">
        {total ? (
          <Grid.Col md={3}>
            <Alert type="info">Result Count - {total}</Alert>
          </Grid.Col>
        ) : null}
      </Grid.Row>
    </Grid>
  );
};

export default Notifications;
