import React, { useEffect, useState } from "react";
import { Grid, Button, Alert, Form } from "tabler-react";
import { ContentTextProps, blackListProps } from "../../types";
import ServerSidePagination from "../../components/pagination/serverSidePagination";
import TableCard from "../../components/tableCard";
import TableModal from "./tableModal";
import AttachModal from "./attachModal";
import ReactModal from "react-modal";
import { alertCustomStyles } from "../../helpers";
import "./style.css";

const Automations = ({
  data,
  loading,
  updateAutomation,
  deleteAutomation,
  createAutomation,
  automationTypeList,
  categoryList,
  onPageNumberChange,
  refetchData,
  syncAutomation,
  syncLoading,
  onFilterDataChange,
  automationSuggestionsList,
}: any): JSX.Element => {
  const [automationList, setAutomationList] = useState<Array<blackListProps>>([]);
  const [automationListPagination, setAutomationListPagination] = useState<Array<blackListProps>>([]);

  const [dataLoading, setDataLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showAttachModal, setShowAttachModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [total, setTotal] = useState(0);
  const [editField, setEditField] = useState<any>(null);

  const [filterInput, setFilterInput] = useState("");

  const [selected, setSelected] = useState<number[]>([]);

  const handleModal = () => {
    if (showModal) {
      setEditField(null);
      setErrorMessage("");
    }
    setShowModal(false);
    setShowAttachModal(false);
    setSelected([]);
  };

  const handleSave = async ({ ...rest }) => {
    const {
      category_id,
      type_id,
      name,
      url,
      meta_title,
      meta_description,
      short_description,
      long_description,
      long_description_details,
      icon_url,
      // credit_price,
      // premium_credit_price,
      is_new,
      active,
      has_source,
      source_csv,
      source_txt,
      order,
      inputs,
      outputs,
      multi,
    } = rest;
    if (editField) {
    } else {
      const res = await createAutomation({
        variables: {
          settings: {
            automation_data: {
              category_id,
              type_id,
              name,
              url,
              meta_title,
              meta_description,
              short_description,
              long_description,
              long_description_details,
              icon_url,
              // premium_credit_price,
              is_new,
              active,
              settings: {
                hasSource: has_source,
                source_csv,
                source_txt,
              },
              order: order,
              inputs: inputs,
              outputs: outputs,
              multi: multi,
              automationSuggestions: selected.map((item: any) => item.value),
            },
          },
        },
      });
      if (res.data.HexomaticAdminOps && !res.data.HexomaticAdminOps.createAutomation) {
      } else {
        const { error_code } = res.data.HexomaticAdminOps.createAutomation;
        if (error_code) {
          setErrorMessage(error_code);
          return;
        }
      }
    }
    return true;
  };

  const onDelete = async (id: number) => {
    let deleteConfirm = confirm(`Are you sure you want to delete automation with ID: ${id}`);
    if (!deleteConfirm) return;
    const res = await deleteAutomation({
      variables: { settings: { automation_id: id } },
    });
    if (res.data.HexomaticAdminOps && !res.data.HexomaticAdminOps.deleteAutomation) {
      setAutomationList(automationList.filter((item) => item.id !== id));
      return;
    }
    const { error_code } = res.data.HexomaticAdminOps.deleteAutomation;
    if (error_code) {
      setErrorMessage(error_code);
      return;
    }
  };

  const onUpdate = async ({
    id,
    category_id,
    type_id,
    name,
    url,
    meta_title,
    meta_description,
    short_description,
    long_description,
    long_description_details,
    icon_url,
    // credit_price,
    // premium_credit_price,
    is_new,
    active,
    has_source,
    source_csv,
    source_txt,
    order,
    inputs,
    outputs,
    multi,
  }) => {
    let fileds = {
      id,
      name,
      url,
      meta_title,
      meta_description,
      short_description,
      long_description,
      long_description_details,
      icon_url,
      // credit_price,
      // premium_credit_price,
      is_new,
      active,
      settings: {
        hasSource: has_source,
        source_csv,
        source_txt,
      },
      order,
      inputs,
      outputs,
      multi,
    };
    let sendData: any = Object.entries(fileds).reduce((acc, [key, value]) => {
      if (
        value !== null &&
        (value.hasSource !== null || value.source_csv !== null || value.source_txt !== null)
      )
        acc[key] = value;
      return acc;
    }, {});
    const obj: any = {};
    if (sendData.settings && sendData.settings.hasSource !== null) {
      obj.hasSource = sendData.settings.hasSource;
    }
    if (sendData.settings && sendData.settings.source_csv !== null) {
      obj.source_csv = sendData.settings.source_csv;
    }
    if (sendData.settings && sendData.settings.source_txt !== null) {
      obj.source_txt = sendData.settings.source_txt;
    }
    sendData.settings = obj;
    const res = await updateAutomation({
      variables: {
        settings: {
          automation_data: {
            category_id: category_id !== null ? category_id : undefined,
            type_id: type_id !== null ? type_id : undefined,
            automationSuggestions: selected.map((item: any) => item.value),
            ...sendData,
          },
        },
      },
    });
    if (res && res.data && res.data.HexomaticAdminOps && !res.data.HexomaticAdminOps.updateAutomation)
      refetchData();

    if (res.data.HexomaticAdminOps && !res.data.HexomaticAdminOps.deleteAutomation) {
      handleModal();
      //for select
      if (category_id !== null) sendData.category = categoryList.find((item) => item.id === category_id);
      if (type_id !== null) sendData.type = automationTypeList.find((item) => item.id === type_id);
      const newAutomationList = automationList.map((item) => {
        if (item.id === id) {
          if (sendData.settings) {
            return {
              ...item,
              ...sendData,
            };
          }
          return { ...item, ...sendData };
        }
        return item;
      });

      setAutomationList(newAutomationList);
      return;
    }
    const { error_code } = res.data.HexomaticAdminOps.updateAutomation;
    if (error_code) {
      setErrorMessage(error_code);
      return;
    }
  };

  const onSyncAutomation = async () => {
    const res = await syncAutomation();
    if (res.data.HexomaticAdminOps && res.data.HexomaticAdminOps.syncAutomations !== null) {
      const { error_code } = res.data.HexomaticAdminOps.syncAutomations;
      if (error_code) {
        setErrorMessage(error_code);
        return;
      }
    }
  };

  const textCheckbox = ({ item, name }) => {
    return (
      <div style={{ display: "flex" }}>
        <span style={{ marginRight: "5px" }}>
          {name === "active" ? "active" : name === "is_new" ? "new" : name}
        </span>
        <Form.Checkbox
          className="crs-point"
          label=" "
          checked={
            (name === "source_txt" || name === "source_csv") && item.source ? item.source[name] : item[name]
          }
          onChange={(e) => {
            e.stopPropagation();
            onUpdate({
              id: item.id,
              active: name === "active" ? !item.active : null,
              category_id: null,
              type_id: null,
              name: null,
              url: null,
              meta_title: null,
              meta_description: null,
              short_description: null,
              long_description: null,
              long_description_details: null,
              icon_url: null,
              // credit_price: null,
              // premium_credit_price: null,
              is_new: name === "is_new" ? !item.is_new : null,
              has_source: name === "has_source" ? !item.has_source : null,
              order: item.order,
              inputs: item.inputs,
              outputs: item.outputs,
              multi: name === "multi" ? !item.multi : null,
              source_csv: name === "source_csv" ? !item.source.source_csv : null,
              source_txt: name === "source_txt" ? !item.source.source_txt : null,
            });
          }}
        />
      </div>
    );
  };
  const tableData = {
    tData:
      automationList &&
      automationList.map((item: ContentTextProps) => ({
        id: item.id,
        name: item.name,
        type: item && item.type && item.type.name,
        category: item && item.category && item.category.name,
        url: item.url,
        "icon Url": item.icon_url,
        meta_title: item.meta_title,
        meta_description: item.meta_description,
        // credit_price: item.credit_price,
        // premium_credit_price: item.premium_credit_price,
        unit_Prices:
          item.prices &&
          item.prices[0] !== null &&
          item.prices.map((unit: any, ind: number) => {
            return (
              <div key={ind} className="prices">
                <p>
                  <b>unit_name</b>: {unit.unit_name}
                </p>
                <p>
                  <b>credit_price</b>: {unit.credit_price}
                </p>
                <p>
                  <b>premium_credit_price</b>: {unit.premium_credit_price}
                </p>
              </div>
            );
          }),
        short_description: item.short_description,
        long_description: item.long_description,
        long_description_details: item.long_description_details,
        has_source: textCheckbox({ item, name: "has_source" }),
        is_active: textCheckbox({ item, name: "active" }),
        is_new: textCheckbox({ item, name: "is_new" }),
        multi: textCheckbox({ item, name: "multi" }),
        // source_csv: textCheckbox({ item, name: "source_csv" }),
        // source_txt: textCheckbox({ item, name: "source_txt" }),
        Action: (
          <Button.List>
            <Button
              color="primary"
              icon="edit"
              onClick={() => {
                setEditField(item);
                setShowModal(true);
                item.suggestAutomations &&
                  setSelected(
                    item.suggestAutomations.map((item: { name: string; suggestAutomationId: number }) => {
                      return { label: item.name, value: item.suggestAutomationId };
                    })
                  );
              }}
            />
            <Button
              color="danger"
              icon="trash"
              onClick={() => {
                setEditField(item);
                onDelete(item.id);
              }}
            />
            <Button
              icon="list"
              onClick={() => {
                setShowAttachModal(true);
                setEditField(item);
              }}
            >
              {/* <img
                src="https://image.flaticon.com/icons/png/512/1975/1975681.png"
                width="15"
                alt=""
              /> */}
            </Button>
          </Button.List>
        ),
      })),
  };

  useEffect(() => {
    if (data && data.HexomaticAdmin && data.HexomaticAdmin.getAutomations.automations) {
      setDataLoading(loading);
      setAutomationList(data.HexomaticAdmin.getAutomations.automations);
      setAutomationListPagination(data.HexomaticAdmin.getAutomations.automations);
      setTotal(data.HexomaticAdmin.getAutomations.total);
    }
  }, [data]);

  // const handleCardLimit = (): void => {
  //   setIsClickedLoadMore(true);
  //   if (count > cardLimit) setCardLimit(cardLimit + 20);
  // };

  const handleInputChange = (e: React.FormEvent) => setFilterInput((e.target as HTMLInputElement).value);

  const handleSubmit = (event: React.FormEvent, input: string) => {
    event.preventDefault();
    onFilterDataChange(input.trim());
  };

  return (
    <>
      <Grid.Row>
        <div className="col-12 col-md-8"></div>
        <div className="col-12 col-md-4">
          <form onSubmit={(e) => handleSubmit(e, filterInput)}>
            <Form.Group>
              <Form.Input
                label="Input"
                className="margin-bottom"
                icon="search"
                position="append"
                placeholder="Automation name"
                value={filterInput}
                onChange={handleInputChange}
              />

              <Button className="margin-bottom" type="submit" icon="filter" color="primary">
                Filter
              </Button>
            </Form.Group>
          </form>
        </div>
      </Grid.Row>

      <Grid.Row cards deck>
        {showModal && (
          <TableModal
            handleModal={handleModal}
            showModal={showModal}
            onSave={handleSave}
            onUpdate={onUpdate}
            editField={editField}
            errorMessage={errorMessage}
            automationTypeList={automationTypeList}
            categoryList={categoryList}
            automationData={data.HexomaticAdmin.getAutomations.automations.find(
              (item: any) => (item && item.id) === (editField && editField.id)
            )}
            automationSuggestions={automationSuggestionsList.filter(
              (el: { value: number }) => editField && el.value !== editField.id
            )}
            selected={selected}
            setSelected={setSelected}
          />
        )}
        {showAttachModal && (
          <AttachModal
            handleModal={handleModal}
            showModal={showAttachModal}
            onSave={handleSave}
            onUpdate={onUpdate}
            editField={editField}
            errorMessage={errorMessage}
            refetchData={refetchData}
          />
        )}
        <ReactModal isOpen={!!errorMessage} style={alertCustomStyles}>
          {errorMessage ? <Alert type="danger">{errorMessage}</Alert> : null}
          <Button className="" onClick={() => setErrorMessage("")} color="primary">
            OK
          </Button>
        </ReactModal>
        <div className="col-12 col-md-1 col-xs-6">
          <Button className="" onClick={() => setShowModal(true)} color="primary">
            Create
          </Button>
          <br />
        </div>
        <div className="col-12 col-md-1 col-xs-6">
          <Button loading={syncLoading} className="" onClick={() => onSyncAutomation()} color="primary">
            Sync automation
          </Button>
          <br />
        </div>
        <Grid.Col md={8}></Grid.Col>
        <div className="col-12 col-md-2 col-xs-6">
          <Alert type="info">Result Count - {total}</Alert>
        </div>
        <div className="black-list-table-pagination">
          <ServerSidePagination data={automationListPagination} total={total} onPageNum={onPageNumberChange}>
            <TableCard loading={dataLoading} data={automationList} tableData={tableData} />
          </ServerSidePagination>
        </div>
      </Grid.Row>
    </>
  );
};

export default Automations;
