import React, { useState, useEffect } from 'react';

const DateAndTime = () => {
  const [dateAndTime, setDateAndTime] = useState(new Date().toUTCString());

  useEffect(() => {
    setInterval(() => setDateAndTime(new Date().toUTCString()), 1000)
  })

  return (
    <div className="d-md-flex p-1">
      <p>{dateAndTime}</p>
    </div>
  )
}

export default DateAndTime