import React, { useEffect, useState } from "react";
import { Grid, Button, Alert } from "tabler-react";
import ReactModal from "react-modal";
import { ContentTextProps, blackListProps } from "../../types";
import ServerSidePagination from "../../components/pagination/serverSidePagination";
import TableCard from "../../components/tableCard";
import { alertCustomStyles } from "../../helpers";
import TableModal from "./tableModal";

import "./style.css";

const PublicTemplateCategories = ({
  data,
  loading,
  deleteType,
  updateType,
  createAutomationType,
  onPageNumberChange,
}: any): JSX.Element => {
  const [automationTypeList, setAutomationTypeList] = useState<
    Array<blackListProps>
  >([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [mutationCompleted, setMutationCompleted] = useState(false);
  const [EditType, setEditType] = useState<any>();
  const [total, setTotal] = useState(0);
  const [editField, setEditField] = useState<any>(null);

  const handleModal = () => {
    if (showModal) {
      setEditField(null);
      setErrorMessage("");
    }
    setShowModal(!showModal);
  };

  const handleSave = async ({ ...rest }) => {
    const { name } = rest;
    if (EditType) {
    } else {
      const res = await createAutomationType({
        variables: { settings: { name: name } },
      });
      if (
        res.data.HexosparkAdminOps &&
        !res.data.HexosparkAdminOps.adminCreatePublicEmailTemplateCategory 
      ) {
        handleModal();
        return;
      }
      const { error_code } = res.data.HexosparkAdminOps.adminCreatePublicEmailTemplateCategory;
      if (error_code) {
        setErrorMessage(error_code);
        return;
      }
    }
    return true;
  };

  const onDelete = async (id: number) => {
    let deleteConfirm = confirm(
      `Are you sure you want to delete category with ID: ${id}`
    );
    if (!deleteConfirm) return;
    const res = await deleteType({
      variables: { settings: { ids: [id] } },
    });
    if (
      res.data.HexosparkAdminOps &&
      !res.data.HexosparkAdminOps.adminDeletePublicEmailTemplateCategory
    ) {
      setAutomationTypeList(
        automationTypeList.filter((item) => item.id !== id)
      );
      return;
    }
    const { error_code } = res.data.HexosparkAdminOps.adminDeletePublicEmailTemplateCategory;
    if (error_code) {
      setErrorMessage(error_code);
      return;
    }
  };

  const onUpdate = async ({ category_id, name }) => {
    const res = await updateType({
      variables: { settings: { id: category_id, name } },
    });
    handleModal();
    if (
      res.data.HexosparkAdminOps &&
      !res.data.HexosparkAdminOps.adminDeletePublicEmailTemplateCategory
    ) {
      const newAutomationTypeList = automationTypeList.map((item) => {
        if (item.id === category_id) {
          return { ...item, name: name };
        }
        return item;
      });
      setAutomationTypeList(newAutomationTypeList);
      return;
    }
    const { error_code } = res.data.HexosparkAdminOps.adminUpdatePublicEmailTemplateCategory;
    if (error_code) {
      setErrorMessage(error_code);
      return;
    }
  };

  const tableData = {
    tData:
      automationTypeList &&
      automationTypeList.map((item: ContentTextProps) => ({
        id: item.id,
        name: item.name,
        Action: (
          <Button.List>
            <Button
              color="primary"
              icon="edit"
              onClick={() => {
                setEditField(item);
                setShowModal(true);
              }}
            />
            <Button
              color="danger"
              icon="trash"
              onClick={() => onDelete(item.id)}
            />
          </Button.List>
        ),
      })),
  };

  useEffect(() => {
    if (
      data &&
      data.HexosparkAdmin &&
      data.HexosparkAdmin.adminGetPublicEmailTemplateCategories.categories
    ) {
      setDataLoading(loading);
      setAutomationTypeList(data.HexosparkAdmin.adminGetPublicEmailTemplateCategories.categories);
      // setTotal(data.EmailBlackList.adminGetMany.total)
    }
  }, [data]);

  return (
    <Grid.Row cards deck>
      <TableModal
        handleModal={handleModal}
        showModal={showModal}
        onSave={handleSave}
        onUpdate={onUpdate}
        editField={editField}
        errorMessage={errorMessage}
      />
      <ReactModal isOpen={!!errorMessage} style={alertCustomStyles}>
        {errorMessage ? <Alert type="danger">{errorMessage}</Alert> : null}
        <Button
          className=""
          onClick={() => setErrorMessage("")}
          color="primary"
        >
          OK
        </Button>
      </ReactModal>
      <div className="col-12 col-md-1 col-xs-6">
        <Button className="" onClick={() => setShowModal(true)} color="primary">
          Create
        </Button>
        <br />
      </div>
      <Grid.Col md={9}></Grid.Col>
      <div className="col-12 col-md-2 col-xs-6">
        <Alert type="info">
          Result Count - {total || automationTypeList.length}
        </Alert>
      </div>
      <div className="black-list-table-pagination">
        <ServerSidePagination
          data={automationTypeList}
          total={total}
          onPageNum={onPageNumberChange}
        >
          <TableCard
            loading={dataLoading}
            data={automationTypeList}
            tableData={tableData}
          />
        </ServerSidePagination>
      </div>

      {automationTypeList.length ? (
        <div className="col-12 col-md-2 col-xs-12 offset-md-10">
          <Alert type="info">
            Result Count - {total || automationTypeList.length}
          </Alert>
        </div>
      ) : null}
    </Grid.Row>
  );
};

export default PublicTemplateCategories;
