import React, { useEffect, useState } from 'react';
import AdditionalEmailComponent from './additionalEmail';
import { useQuery } from '@apollo/react-hooks';
import { GET_ADDITIONAL_EMAILS } from "../../graphql/queries";


const AdditionalEmail = () => {
  const [pageNumberState, setPageNumberState] = useState(1);
  const [typeState, setTypeState] = useState('hexometer')
  const [fetchedData, setFetchedData] = useState(null)

  const { data, loading, refetch } = useQuery(GET_ADDITIONAL_EMAILS, {
    variables: {
      settings: {
        type: typeState,
        page: pageNumberState,
        limit: 50,
        csv_download_link: false,
      }
    }
  });

  useEffect(() => {
    refetching()
  }, [pageNumberState, typeState])

  useEffect(() => {
    setFetchedData(data)
  }, [data])

  const refetching = async () => {
    const {data} = await refetch()
    setFetchedData(data)
  }

  const handlePageNumberChange = (pageNumber: number) => setPageNumberState(pageNumber);
  const handleTypeChange = (tp: string) => setTypeState(tp);

  return (
    <AdditionalEmailComponent
      data={fetchedData}
      loading={loading}
      onPageNumberChange={handlePageNumberChange}
      refetchData={refetch}
      onSelectType={handleTypeChange}
    />
  )
};

export default AdditionalEmail;