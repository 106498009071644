import React, { FormEvent, ChangeEvent, useEffect, useState } from "react";
import moment from "moment";
import { Grid, Button, Alert, Form } from "tabler-react";
import DatePicker from "react-datepicker";

import ServerSidePagination from "../../components/pagination/serverSidePagination";
import TableCard from "../../components/tableCard";

import { ContentTextProps, blackListProps } from "../../types";
import TableModal from "./tableModal";

import "./style.css";

const ScrapingRecipes = ({
  data,
  loading,
  searchEmail,
  createAutomationCategory,
  onPageNumberChange,
  setHideHex,
  hideHex,
  ...props
}: any): JSX.Element => {
  const [scrapingRecipesList, setScrapingRecipesList] = useState<Array<blackListProps>>([]);

  const [errorMessage, setErrorMessage] = useState("");
  const [filterInput, setFilterInput] = useState("");

  const [editValue, setEditValue] = useState<any>();

  const [dataLoading, setDataLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const [isPublic, setIsPublic] = useState<boolean | null>(null);

  const [total, setTotal] = useState(0);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const handleModal = () => {
    if (showModal) {
      setEditValue(null);
      setErrorMessage("");
    }
    setShowModal(!showModal);
  };

  const handleSubmit = (event: FormEvent, input: string, isPublic: boolean | null) => {
    event.preventDefault();
    props.setFilterInput(input.trim());
    props.setToDate(toDate);
    props.setFromDate(fromDate);
    props.setIsPublic(isPublic);
  };

  const handleFromDate = (date: Date) => {
    if (date) {
      return setFromDate(moment(date).format("YYYY-MM-DDT00:00:00.000"));
    }
    setFromDate(null);
  };

  const handleToDate = (date: Date) => {
    if (date) {
      return setToDate(moment(date).format("YYYY-MM-DDT23:59:59.999"));
    }
    setToDate(null);
  };

  const handleSave = async ({ ...rest }) => {
    return false;
  };

  const tableData = {
    tData:
      scrapingRecipesList &&
      scrapingRecipesList.map((item: ContentTextProps) => ({
        id: item.id,
        user_email: item.user_email,
        name: item.name,
        url: item.url,
        actions: item.actions,
        "is public": item.is_public ? "public" : "private",
        status: item.status,

        //   "Action":
        //     <Button.List>
        //       <Button color="primary" icon="edit" onClick={() => console.log('edit')} />
        //       <Button color="danger" icon="trash" onClick={() => console.log('delete')} />
        //     </Button.List>
      })),
  };

  useEffect(() => {
    if (data && data.HexomaticAdmin && data.HexomaticAdmin.getScrapingRecipes.scraping_recipes) {
      setDataLoading(loading);
      setScrapingRecipesList(data.HexomaticAdmin.getScrapingRecipes.scraping_recipes);
      setTotal(data.HexomaticAdmin.getScrapingRecipes.total);
    }
  }, [data]);

  const handleInputChange = (e: React.FormEvent) => setFilterInput((e.target as HTMLInputElement).value);
  const handleSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "public") {
      setIsPublic(true);
    } else if (e.target.value === "private") {
      setIsPublic(false);
    } else setIsPublic(null);
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Col md={2}>
          <Form.Checkbox
            label="Filter Hexact recipes"
            checked={hideHex}
            onChange={() => setHideHex(!hideHex)}
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row cards deck>
        <TableModal
          handleModal={handleModal}
          showModal={showModal}
          onSave={handleSave}
          editValue={editValue}
          errorMessage={errorMessage}
        />
        <div className="col-12 col-md-8"></div>
        <div className="col-12 col-md-4">
          <form onSubmit={(e) => handleSubmit(e, filterInput, isPublic)}>
            <Form.Group>
              <Grid.Row>
                <Grid.Col>
                  <Form.Group label="From Date">
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      selected={fromDate ? new Date(fromDate) : null}
                      onChange={handleFromDate}
                      showMonthDropdown
                      placeholderText="Select a date"
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col>
                  <Form.Group label="To Date">
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      selected={toDate ? new Date(toDate) : null}
                      onChange={handleToDate}
                      showMonthDropdown
                      placeholderText="Select a date"
                      minDate={fromDate && new Date(fromDate)}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>

              <Grid.Row>
                <Grid.Col>
                  <Form.Input
                    label="Input"
                    className="margin-bottom"
                    icon="search"
                    position="append"
                    placeholder="Search for User Email, User Name"
                    value={filterInput}
                    onChange={handleInputChange}
                  />
                </Grid.Col>
              </Grid.Row>

              <Grid.Row>
                <Grid.Col>
                  <Form.Select label="Select" onChange={handleSelect}>
                    <option value="All">All</option>
                    <option value="private">Private</option>
                    <option value="public">Public</option>
                  </Form.Select>
                </Grid.Col>
              </Grid.Row>

              <Button
                type="submit"
                icon="filter"
                color="primary"
                className=""
                loading={dataLoading}
                // onClick={() => {}}
              >
                Filter
              </Button>
            </Form.Group>
          </form>
        </div>

        {/* <div className="col-12 col-md-1 col-xs-6">
        <Button
          className=""
          onClick={() => handleKeywordMutate(null)}
          color="primary"
        >Create</Button>
        <br />
      </div> */}
        <Grid.Col md={10}></Grid.Col>
        <div className="col-12 col-md-2 col-xs-6">
          <Alert type="info">Result Count - {total}</Alert>
        </div>
        <div className="black-list-table-pagination">
          <ServerSidePagination data={scrapingRecipesList} total={total} onPageNum={onPageNumberChange}>
            <TableCard loading={dataLoading} data={scrapingRecipesList} tableData={tableData} />
          </ServerSidePagination>
        </div>

        {scrapingRecipesList.length ? (
          <div className="col-12 col-md-2 col-xs-12 offset-md-10">
            <Alert type="info">Result Count - {total}</Alert>
          </div>
        ) : null}
      </Grid.Row>
    </Grid>
  );
};

export default ScrapingRecipes;
