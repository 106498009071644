import React from "react";
import { PiechartProps, FilterButtonTypes } from "../../types";
import { Card, Button, Form, Grid } from "tabler-react";
import C3Chart from "react-c3js";
import DatePicker from "react-datepicker";
import moment from "moment";
//@ts-ignore
import { useHistory } from "react-router-dom";
import { refreshIcon } from "../../image/svgIcons";
import "./styles.css";

export const setDate = (days: number) => {
  let date = new Date();
  date.setDate(date.getDate() - days);
  date.setUTCHours(0, 0, 0, 0);
  return date;
};

const getFirstDayOfMonth = (prev: number) => {
  const today = new Date();
  today.setDate(1);
  today.setMonth(today.getMonth() - prev);
  today.setUTCHours(0, 0, 0, 0);
  return today;
};

const getFirstDayOfYear = (prev?: number) => {
  const today = new Date();
  today.setDate(1);
  today.setMonth(0);
  today.setFullYear(today.getFullYear() - prev);
  today.setUTCHours(0, 0, 0, 0);
  return today;
};

const getLastDayOfMonth = () => {
  const today = new Date();
  today.setDate(0);
  today.setUTCHours(23, 59, 59, 999);
  return today;
};

const getLastDayOfYear = () => {
  const today = new Date();
  today.setDate(0);
  today.setMonth(11);
  today.setFullYear(today.getFullYear() - 1);
  today.setUTCHours(23, 59, 59, 999);
  return today;
};

export const filterButtons: FilterButtonTypes[] = [
  {
    name: "Today",
    fromDate: setDate(0),
    toDate: new Date(setDate(0).setUTCHours(23, 59, 59, 999)),
  },
  {
    name: "Yesterday",
    fromDate: setDate(1),
    toDate: new Date(setDate(1).setUTCHours(23, 59, 59, 999)),
  },
  // {
  //   name: "Yesterday",
  //   fromDate: new Date(setDate(1).setUTCHours(0, 0, 0, 0)),
  //   toDate: setDate(0),
  // },
  {
    name: "Last 7 days",
    fromDate: setDate(6),
    toDate: new Date(setDate(0).setUTCHours(23, 59, 59, 999)),
  },
  {
    name: "Last 30 days",
    fromDate: setDate(29),
    toDate: new Date(setDate(0).setUTCHours(23, 59, 59, 999)),
  },
  {
    name: "Last 90 days",
    fromDate: setDate(89),
    toDate: new Date(setDate(0).setUTCHours(23, 59, 59, 999)),
  },
  {
    name: "All",
    fromDate: "2019-01-01",
    toDate: new Date(setDate(0).setUTCHours(23, 59, 59, 999)),
  },
];

export const filterButtonsMonth: FilterButtonTypes[] = [
  {
    name: "This month",
    fromDate: getFirstDayOfMonth(0),
    toDate: new Date(setDate(0).setUTCHours(23, 59, 59, 999)),
  },
  {
    name: "Last month",
    fromDate: getFirstDayOfMonth(1),
    toDate: getLastDayOfMonth(),
  },
  {
    name: "Last 3 months",
    fromDate: getFirstDayOfMonth(2),
    toDate: new Date(setDate(0).setUTCHours(23, 59, 59, 999)),
  },
  {
    name: "Last 6 months",
    fromDate: getFirstDayOfMonth(5),
    toDate: new Date(setDate(0).setUTCHours(23, 59, 59, 999)),
  },
  {
    name: "This year",
    fromDate: getFirstDayOfYear(0),
    toDate: new Date(setDate(0).setUTCHours(23, 59, 59, 999)),
  },
  {
    name: "Last year",
    fromDate: getFirstDayOfYear(1),
    toDate: getLastDayOfYear(),
  },
  {
    name: "All",
    fromDate: "2019-01-01",
    toDate: new Date(setDate(0).setUTCHours(23, 59, 59, 999)),
  },
];

const PiechartCard = (props: PiechartProps): JSX.Element => {
  const { push } = useHistory();

  const handleFromDateChange = (date: Date) => {
    if (date) {
      props.fromDateChange(moment(date).format("YYYY-MM-DDT00:00:00.000"));
    } else {
      props.fromDateChange(moment().format("YYYY-MM-DDT00:00:00.000"));
    }
  };

  const handleToDateChange = (date: Date) => {
    if (date) {
      props.toDateChange(moment(date).format("YYYY-MM-DDT23:59:59.999"));
    } else {
      props.fromDateChange(moment().format("YYYY-MM-DDT23:59:59.999"));
    }
  };

  const colors = props && props.colors ? { ...props.colors } : null;
  const newData =
    props.limit && props.data.length > props.limit
      ? props.data
          .slice(0, props.limit)
          .map((toolObj) => [`${toolObj.count} ${toolObj.field}`, toolObj.count])
      : props.data.map((toolObj) => {
          const newField = `${toolObj.count} ${toolObj.field}`;
          //color key must have same name what newField like this '13 Active'
          if (colors) colors[newField] = props.colors[toolObj.field];
          return [newField, toolObj.count];
        });

  const handleNavigation = () => {
    switch (props.title) {
      case "Hexomatic traffic usage":
        push("hexomatic-traffic-usage");
        break;

      case "Hexomatic credits usage":
        push("hexomatic-credits-usage");
        break;

      case "Hexowatch notifications by channels":
        push("hexowatch-notifications");
        break;

      case "Registered users":
      case "Registered users by type":
      case "Registered users by country":
      case "Hexometer users":
      case "Hexowatch users":
        push("users");
        break;
      case "Referral link stats":
        push("/referral-link-stats");
        break;
      case "Hexomatic human powered data collection":
        push("/hexomatic-tasks");
        break;
      case "Google Chrome shortcut clicks":
        push("/shortcut-clicks");
        break;
      case "Hexomatic extension":
        push("/free-tools");
        break;
      case "Hexomatic free tools":
        push("/free-tools");
        break;
      case "Hexomatic workflow analytics":
        push("/hexomatic-workflows");
        break;
      default:
        push("/");
        break;
    }
  };

  const onRefresh = (e) => {
    e.stopPropagation();
    props.reloadData();
  };

  return (
    <Card className="print-only">
      <div className="graph-link" onClick={handleNavigation}>
        <Card.Header className={props.reload ? "between" : ""}>
          <Card.Title className="print-only">
            {props.showDataLenght
              ? props.title
              : props.title === "Hexomatic credits usage"
              ? `${props.title}`
              : `${props.title} - ${
                  props.data.length === 0
                    ? 0
                    : props.title === "Hexomatic traffic usage" || props.title === "Hexowatch traffic usage"
                    ? `${Math.round(
                        +props.data
                          .map((toolObj: any) => +toolObj.count)
                          .reduce((acc: any, el: any) => acc + el, 0)
                      )}
                      mb
                      `
                    : +props.data
                        .map((toolObj: any) => +toolObj.count)
                        .reduce((acc: any, el: any) => acc + el, 0)
                        .toFixed(2)
                }`}
          </Card.Title>

          {props.reload && (
            <Card.Title className="pichart-title">
              {props.cardOptions}
              <span className="refresh-icon" onClick={onRefresh}>
                {refreshIcon}
              </span>
            </Card.Title>
          )}
        </Card.Header>
      </div>
      <Card.Body>
        <div
          style={{
            maxWidth: newData.length > 16 ? "100%" : "450px",
            height: newData.length > 40 ? "600px" : "",
          }}
        >
          <C3Chart
            data={{
              columns: newData,
              type: "pie",
              colors: colors ? colors : {},
            }}
            pie={{
              label: {
                format: (value) => (props.title === "Hexomatic traffic usage" ? `${value} mb` : value),
              },
            }}
            legend={{
              position: "right",
            }}
            size={{
              height: newData.length > 40 ? 500 : "",
            }}
          />
        </div>
      </Card.Body>
      <Card.Footer className="no-print">
        {props.showSelect && (
          <Grid.Row>
            <Grid.Col sm={6} md={4}>
              <Form.Group>
                <Form.Select
                  onChange={(e: React.FormEvent) =>
                    props.showSelect.handleSelect((e.target as HTMLSelectElement).value)
                  }
                  label={props.showSelect.label}
                  value={props.showSelect.value}
                >
                  {props.showSelect.options.map((pack) => (
                    <option key={pack} value={pack}>
                      {pack}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
        )}
        <Button.List>
          {filterButtons.map((btnObj) => (
            <Button
              onClick={() => props.filterBtnClick(btnObj)}
              color={props.filterObj === btnObj ? "active" : "secondary"}
              key={btnObj.name}
            >
              {btnObj.name}
            </Button>
          ))}
          <Grid.Row className="from-to-datepicker">
            <Grid.Col sm={6} md={4}>
              <Form.Group>
                <Grid.Row className="no-col-flex">
                  <Grid.Col md={3} sm={3} className="col-12 d-flex">
                    <Form.Label className="m-auto">From:</Form.Label>
                  </Grid.Col>
                  <Grid.Col>
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      selected={new Date(props.filterObj.fromDate)}
                      onChange={(date) => handleFromDateChange(date)}
                      showMonthDropdown
                      placeholderText="Select a date"
                    />
                  </Grid.Col>
                </Grid.Row>
              </Form.Group>
            </Grid.Col>
            <Grid.Col sm={6} md={4}>
              <Form.Group>
                <Grid.Row>
                  <Grid.Col md={3} sm={3} className="col-12 d-flex">
                    <Form.Label className="m-auto">To:</Form.Label>
                  </Grid.Col>
                  <Grid.Col>
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      selected={new Date(props.filterObj.toDate)}
                      onChange={(date) => handleToDateChange(date)}
                      showMonthDropdown
                      placeholderText="Select a date"
                      minDate={props.filterObj.fromDate ? new Date(props.filterObj.fromDate) : null}
                    />
                  </Grid.Col>
                </Grid.Row>
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
        </Button.List>
      </Card.Footer>
    </Card>
  );
};

export default PiechartCard;
