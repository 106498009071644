import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";

import {
  CREATE_PUBLIC_TAMPLATE,
  DELETE_PUBLIC_TAMPLATE,
  UPDATE_PUBLIC_TAMPLATE,
} from "../../graphql/mutations";
import { GET_HEXOSPARK_PUBLIC_TEMPLATES } from "../../graphql/queries";

import HexosparkPublicTamplate from "./index";

const HexosparkPublicTemplateQueries = () => {
  const [publicTemplateList, setPublicTemplateList] = useState<any>([]);
  const [pageNumberState, setPageNumberState] = useState(1);
  const [searchEmail, setSearchEmail] = useState<string>("");

  const handlePageNumberChange = (pageNumber: number) => setPageNumberState(pageNumber);

  const { data, loading, refetch } = useQuery(GET_HEXOSPARK_PUBLIC_TEMPLATES, {
    variables: {
      settings: {
        page: pageNumberState,
        limit: 100,
        // sortDir,
        // sortField,
        // name
        // from
        // to
      },
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    refetch();
  }, [pageNumberState]);

  useEffect(() => {
    setPublicTemplateList(data);
  }, [data]);

  useEffect(() => {
    refetchData();
  }, [searchEmail]);

  const refetchData = async () => {
    await refetch();
  };

  const [deletePublicTemplate] = useMutation(DELETE_PUBLIC_TAMPLATE);
  const [updatePublicTemplate] = useMutation(UPDATE_PUBLIC_TAMPLATE);

  const [createPublicTemplate] = useMutation(CREATE_PUBLIC_TAMPLATE, {
    onCompleted: () => {
      refetchData();
    },
  });

  return (
    <HexosparkPublicTamplate
      data={publicTemplateList}
      loading={loading}
      searchEmail={searchEmail}
      setSearchEmail={setSearchEmail}
      onPageNumberChange={handlePageNumberChange}
      createPublicTemplate={createPublicTemplate}
      deletePublicTemplate={deletePublicTemplate}
      updatePublicTemplate={updatePublicTemplate}
      refetch={refetch}
    />
  );
};

export default HexosparkPublicTemplateQueries;
