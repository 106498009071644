import React, { useState, useEffect } from "react";
import { Grid, Button, Alert, Form } from "tabler-react";
import moment from "moment";
import DatePicker from "react-datepicker";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";

import {
  GET_HEXOMATIC_SHARED_RECIPES,
  GET_HEXOMATIC_SHARED_RECIPES_CHART,
} from "../../graphql/queries";

import ServerSidePagination from "../../components/pagination/serverSidePagination";
import TableCard from "../../components/tableCard";
import Chart from "../../components/chart";

import { getCountArr, isProduction } from "../../helpers";

interface HexomaticScrapingRecipeProps {
  data: {
    HexomaticScrapingRecipe: {
      getSharedScrapingRecipesReport: {
        count: number;
        sharedRecipes: any;
      };
    };
  };
  loading: boolean;
  orderByASC: boolean;
  variables: {settings: Object};
  refetchData: () => void;
  onPageNumberChange: (pageNumber: number) => void;
  onFilterDataChangeBy: (data: string) => void;
  onFilterDataChangeWith: (data: string) => void;
  onFromDate: (date: string) => void;
  onToDate: (date: string) => void;
  // onSelectShareType: (channel: string) => void;
  setOrderBy: (column: string) => void | Promise<void>;
  setOrderByASC: (isASC: boolean) => void | Promise<void>;
  filterHexScans: boolean;
  setFilterHexScans: (val: boolean) => void;
}

const HexomaticSharedRecipes = ({ refetchData, ...props }: HexomaticScrapingRecipeProps): JSX.Element => {
  const [sharedScrapingRecipesData, setSharedRecipesData] = useState([]);
  const [notificationsPagination, setNotificationsPagination] = useState([]);

  const [total, setTotal] = useState(0);
  const [dataLoading, setDataLoading] = useState(true);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [filterInputSharedBy, setFilterInputSharedBy] = useState("");
  const [filterInputSharedWith, setFilterInputSharedWith] = useState("");
  // const [selectedSharedType, setSelectedShareType] = useState("");
  const showButtons = localStorage.getItem("_rep");

  // const shareType = ["all", "imports", "shares"];

  const [getCSV, { data, loading }] = useLazyQuery(GET_HEXOMATIC_SHARED_RECIPES);
  const [uploadToDrive, { data: driveData, loading: driveDataLoading }] =
    useLazyQuery(GET_HEXOMATIC_SHARED_RECIPES);

  const sharedRecipesChartData = useQuery(GET_HEXOMATIC_SHARED_RECIPES_CHART, {
    variables: { from: null, to: null, hideHex: props.filterHexScans },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const tableData = {
    tData:
      sharedScrapingRecipesData &&
      sharedScrapingRecipesData.map((item) => ({
        'Original recipe ID': item.original_recipe_id,
        'User email': item.user_email,
        "Number of shares": item.number_of_shares,
        'Number of imports': item.numberOfImports,
        "Users shared with": item.usersSharedWith.map((sub: any) => sub.user_email || 'with link').join(', '),
        "Imported by": item.usersImportedBy.map((sub: any) => sub.user_email).join(', '),
      })),
  };

  useEffect(() => {
    if (
      props.data &&
      props.data.HexomaticScrapingRecipe &&
      props.data.HexomaticScrapingRecipe.getSharedScrapingRecipesReport &&
      props.data.HexomaticScrapingRecipe.getSharedScrapingRecipesReport.sharedRecipes
    ) {
      setDataLoading(props.loading);
      setSharedRecipesData(props.data.HexomaticScrapingRecipe.getSharedScrapingRecipesReport.sharedRecipes);
      setNotificationsPagination(props.data.HexomaticScrapingRecipe.getSharedScrapingRecipesReport.sharedRecipes);
      setTotal(props.data.HexomaticScrapingRecipe.getSharedScrapingRecipesReport.count);
    }
  }, [props.data]);

  useEffect(() => {
    if (
      driveData &&
      driveData.HexomaticScrapingRecipe &&
      driveData.HexomaticScrapingRecipe.getSharedScrapingRecipesReport &&
      driveData.HexomaticScrapingRecipe.getSharedScrapingRecipesReport.csvURL
    ) {
      window.open(
        isProduction()
          ? `https://api.hexometer.com/v2/app/social-callback/googleDrive?admin=admin&report=sharedRecipes&url=${driveData.HexomaticScrapingRecipe.getSharedScrapingRecipesReport.csvURL}`
          : `http://localhost:4000/api/app/social-callback/googleDrive?admin=admin&report=sharedRecipes&url=${driveData.HexomaticScrapingRecipe.getSharedScrapingRecipesReport.csvURL}`,
        "_blank"
      );
    }
  }, [driveData]);

  const handleInputChangeBy = (e: React.FormEvent) => setFilterInputSharedBy((e.target as HTMLInputElement).value);
  const handleInputChangeWith = (e: React.FormEvent) => setFilterInputSharedWith((e.target as HTMLInputElement).value);

  // const handleSelectShareType = (e: React.FormEvent) => setSelectedShareType((e.target as HTMLInputElement).value);

  const handleFromDate = (date: Date) => {
    if (date) {
      return setFromDate(moment(date).format("YYYY-MM-DDT00:00:00.000"));
    }
    return setFromDate(null);
  };

  const handleToDate = (date: Date) => {
    if (date) {
      return setToDate(moment(date).format("YYYY-MM-DDT23:59:59.999"));
    }
    return setToDate(null);
  };

  const handleSubmit = (
    event: React.FormEvent,
    inputBy: string,
    inputWith: string,
    startDate: string,
    endDate: string
  ) => {
    event.preventDefault();
    props.onFilterDataChangeBy(inputBy.trim());
    props.onFilterDataChangeWith(inputWith.trim());
    props.onFromDate(startDate);
    props.onToDate(endDate);
    // if (selectedSharedType !== "all") {
    //   props.onSelectShareType(selectedSharedType);
    // } else {
    //   props.onSelectShareType("");
    // }
  };

  const handleSortingClick = (column: string) => {
    if (column === "Created At") {
      props.setOrderBy("createdAt");
      props.setOrderByASC(!props.orderByASC);
    }
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Col md={2}>
          <Form.Checkbox
            label="Filter Hexact shares"
            checked={props.filterHexScans}
            onChange={() => props.setFilterHexScans(!props.filterHexScans)}
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <div className="col-12 col-md-8">
          {!sharedRecipesChartData.loading &&
            sharedRecipesChartData.data &&
            sharedRecipesChartData.data.HexomaticScrapingRecipe &&
            sharedRecipesChartData.data.HexomaticScrapingRecipe.getSharedScrapingRecipesChart && (
              <Chart
                yColName="Shares"
                yColName2="Imports"
                regData={sharedRecipesChartData.data.HexomaticScrapingRecipe.getSharedScrapingRecipesChart.shares}
                regData2={sharedRecipesChartData.data.HexomaticScrapingRecipe.getSharedScrapingRecipesChart.imports}
                interval={getCountArr(
                  sharedRecipesChartData.data.HexomaticScrapingRecipe.getSharedScrapingRecipesChart.length
                )}
                chartName="Shared scraping recipes"
              />
            )}
        </div>
        <div className="col-12 col-md-4">
          <form onSubmit={(e) => handleSubmit(e, filterInputSharedBy, filterInputSharedWith, fromDate, toDate)}>
            <Form.Group>
              <Form.Input
                label="Shared by"
                className="margin-bottom"
                icon="search"
                position="append"
                placeholder="Shared by User Email"
                value={filterInputSharedBy}
                onChange={handleInputChangeBy}
              />
              <Form.Input
                label="Shared with"
                className="margin-bottom"
                icon="search"
                position="append"
                placeholder="Shared with User Email"
                value={filterInputSharedWith}
                onChange={handleInputChangeWith}
              />
              {/* <Form.Select onChange={handleSelectShareType} label="Type">
                {shareType.map((el) => (
                  <option key={el} value={el}>
                    {el}
                  </option>
                ))}
              </Form.Select> */}
              <Form.Group label="From Date">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={fromDate ? new Date(fromDate) : null}
                  onChange={handleFromDate}
                  showMonthDropdown
                  placeholderText="Select a date"
                />
              </Form.Group>
              <Form.Group label="To Date">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={toDate ? new Date(toDate) : null}
                  onChange={handleToDate}
                  showMonthDropdown
                  placeholderText="Select a date"
                  minDate={fromDate && new Date(fromDate)}
                />
              </Form.Group>
              <Button className="margin-bottom" type="submit" icon="filter" color="primary">
                Filter
              </Button>
            </Form.Group>
          </form>
        </div>
      </Grid.Row>
      <Grid.Row className={showButtons === "true" ? "justify-content-between" : "justify-content-end"}>
        {showButtons === "true" && (
          <div className="col-12 col-md-3 col-xs-12">
            <Button.List>
              <Button
                onClick={() => 
                  getCSV({
                    variables: { settings: {...props.variables.settings, csvDownload: true} },
                  })
                }
                loading={loading}
                color="primary"
                mr={1}
              >
                Get CSV Link
              </Button>
              <Button
                onClick={() =>
                  uploadToDrive({
                    variables: { settings: {...props.variables.settings, csvDownload: true} },
                  })
                }
                loading={driveDataLoading}
                color="primary"
              >
                Save to Drive
              </Button>
              {/* <Button
              onClick={() => setShowtypes(!showtypes)}
              // loading={loading}
              color="primary"
            >
              show Types
            </Button> */}
            </Button.List>
            {!loading &&
              data &&
              data.HexomaticScrapingRecipe &&
              data.HexomaticScrapingRecipe.getSharedScrapingRecipesReport &&
              data.HexomaticScrapingRecipe.getSharedScrapingRecipesReport.csvURL && (
                <Button
                  RootComponent="a"
                  href={data.HexomaticScrapingRecipe.getSharedScrapingRecipesReport.csvURL}
                  icon="download"
                >
                  Download CSV
                </Button>
              )}
            <br />
          </div>
        )}
        <Grid.Col md={3}>
          <Alert type="info">Result Count - {total}</Alert>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row cards deck>
        <ServerSidePagination
          data={notificationsPagination}
          total={total}
          onPageNum={props.onPageNumberChange}
        >
          <TableCard
            loading={dataLoading}
            data={sharedScrapingRecipesData}
            tableData={tableData}
            onHeaderClick={handleSortingClick}
          />
        </ServerSidePagination>
      </Grid.Row>
    </Grid>
  );
};

export default HexomaticSharedRecipes;
