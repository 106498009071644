import React, { useEffect, useState, FormEvent } from "react";
import { Grid, Form, Button, Alert } from "tabler-react";
import { CancellationFeedbacksProps } from "../../types";
import moment from "moment";
import TableCard from "../../components/tableCard";
import ServerSidePagination from "../../components/pagination/serverSidePagination";
import { CANCELLATION_FEEDBACKS } from "../../graphql/queries";
import { useLazyQuery } from "@apollo/react-hooks";
import DatePicker from "react-datepicker";
import { isProduction } from "../../helpers";
import "./index.css";

const CancellationFeedbacks = (props: CancellationFeedbacksProps): JSX.Element => {
  const [cancellationFeedbacks, setCancellationFeedbacks] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [scanLogsPagination, setScanLogsPagination] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [total, setTotal] = useState(0);
  const [selectProduct, setSelectProduct] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const showButtons = localStorage.getItem("_rep");

  const [getCSV, { data, loading }] = useLazyQuery(CANCELLATION_FEEDBACKS);
  const [uploadToDrive, { data: driveData, loading: driveDataLoading }] =
    useLazyQuery(CANCELLATION_FEEDBACKS);
  const tableData = {
    tData:
      cancellationFeedbacks &&
      cancellationFeedbacks.map((item: any) => {
        return {
          "first_name": item.first_name || "N/A",
          "last_name": item.last_name || 'N/A',
          "email": item.email || 'N/A',
          "description": item.description || 'N/A',
          
          // "User Email": item.email || "No email",
          // "First name": item.first_name || 'N/A',
          // "Last name": item.last_name || 'N/A',
          // "Plan cancelled": item.plan_cancelled,
          // "Cancellation request date": item.requested_to_cancel_at,
          // "Will be cancelled": item.date_cancelled,
          // "Plan age": item.plan_age,
          // "Lifetime revenue": item.lifetime_revenue && Number.isInteger(item.lifetime_revenue) ? item.lifetime_revenue : item.lifetime_revenue ? item.lifetime_revenue.toFixed(2) : 0,
          // "Product": item.product,
        };
      }),
  };

  useEffect(() => {
    if (props.data && props.data.Billing && props.data.Billing.getCancellations) {
      setDataLoading(props.loading);
      setCancellationFeedbacks(props.data.Billing.getCancellations.data);
      setScanLogsPagination(props.data.Billing.getCancellations.data);
      setTotal(props.data.Billing.getCancellations.count);
    }
  }, [props.data]);

  useEffect(() => {
    if (
      driveData &&
      driveData.Billing &&
      driveData.Billing.getCancellations &&
      driveData.Billing.getCancellations.csvUrl
    ) {
      window.open(
        isProduction()
          ? `https://api.hexometer.com/v2/app/social-callback/googleDrive?admin=admin&report=referral&url=${driveData.Billing.getCancellations.csvUrl}`
          : `http://localhost:4000/api/app/social-callback/googleDrive?admin=admin&report=referral&url=${driveData.Billing.getCancellations.csvUrl}`,
        "_blank"
      );
    }
  }, [driveData]);

 
  const handleSearchTextInputChange = (e: FormEvent) =>
    setSearchText((e.target as HTMLInputElement).value);
  const handleSelectedProduct = (e: FormEvent) =>
    setSelectProduct((e.target as HTMLInputElement).value);

  const handleFromDate = (date: Date) => {
    if (date) {
      return setFromDate(moment(date).format("YYYY-MM-DDT00:00:00.000"));
    }
    return setFromDate(null);
  };

  const handleToDate = (date: Date) => {
    if (date) {
      return setToDate(moment(date).format("YYYY-MM-DDT23:59:59.999"));
    }
    return setToDate(null);
  };

  const handleSubmit = (
    event: FormEvent,
    searchText: string,
    actionName: string,
    startDate: string,
    endDate: string
  ) => {
    event.preventDefault();
    props.onSearchText(searchText.trim());
    props.onFromDate(startDate);
    props.onToDate(endDate);

    if (actionName !== "Select action") {
      props.onProductSelect(actionName);
    } else {
      props.onProductSelect("");
    }
  };

  const handleSortingClick = (column: string) => {
    if (column === "Click date") props.setOrderBy("created_at");
    else if (column === "Action Name") props.setOrderBy("type");
    if (column === "Click date" || column === "Action Name")
      props.setOrderByASC(!props.orderByASC);
  };
  return (
    <Grid>
      <Grid.Row className="mb-5">
        <div className="col-12 col-md-8"></div>
        <div className="col-12 col-md-4">
          <form
            onSubmit={(e) =>
              handleSubmit(
                e,
                searchText,
                selectProduct,
                fromDate,
                toDate
              )
            }
          >
            <Form.Group>
              <Form.Input
                label="Input"
                className=""
                icon="search"
                position="append"
                placeholder="Search by user ID or email"
                value={searchText}
                onChange={handleSearchTextInputChange}
              />
              <Form.Select label="Product" onChange={handleSelectedProduct}>
                <option value="Select action">Select product</option>
                {['hexomatic', 'hexowatch', 'hexometer', 'hexospark', 'hexofy', 'premium_credits'].map((action) => (
                  <option key={action} value={action}>
                    {action}
                  </option>
                ))}
              </Form.Select>
              <Form.Group
                label="From Date (Will be cancelled on)"
                className="shortcutClicksDatePicker"
              >
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={fromDate ? new Date(fromDate) : null}
                  onChange={handleFromDate}
                  showMonthDropdown
                  placeholderText="Select a date"
                />
              </Form.Group>
              <Form.Group label="To Date (Will be cancelled on)" className="shortcutClicksDatePicker">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={toDate ? new Date(toDate) : null}
                  onChange={handleToDate}
                  showMonthDropdown
                  placeholderText="Select a date"
                  minDate={fromDate && new Date(fromDate)}
                />
              </Form.Group>
              <Button type="submit" icon="filter" color="primary">
                Filter
              </Button>
            </Form.Group>
          </form>
        </div>
      </Grid.Row>
      <Grid.Row className={showButtons === 'true' ? "justify-content-between" : "justify-content-end"}>
        {showButtons === 'true' && (
          <div className="col-12 col-md-3 col-xs-12">
            <Button.List>
              <Button
                onClick={() =>
                  getCSV({
                    variables: {
                      settings: {
                        ...props.variables,
                        csvDownload: true,
                      },
                    },
                  })
                }
                loading={loading}
                color="primary"
                mr={1}
              >
                Get CSV Link
              </Button>
              <Button
                onClick={() =>
                  uploadToDrive({
                    variables: {
                      settings: {
                        ...props.variables,
                        csvDownload: true,
                      },
                    },
                  })
                }
                loading={driveDataLoading}
                color="primary"
              >
                Save to Drive
              </Button>
            </Button.List>
            {!loading &&
              data &&
              data.Billing &&
              data.Billing.getCancellations &&
              data.Billing.getCancellations.csvUrl && (
                <Button
                  RootComponent="a"
                  href={data.Billing.getCancellations.csvUrl}
                  icon="download"
                  className="mt-2"
                >
                  Download CSV
                </Button>
              )}
            <br />
          </div>
        )}
        <Grid.Col md={3}>
          <Alert type="info">Result Count - {total}</Alert>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row cards deck>
        <ServerSidePagination
          data={scanLogsPagination}
          total={total}
          onPageNum={props.onPageNumberChange}
        >
          <TableCard
            loading={dataLoading}
            data={cancellationFeedbacks}
            tableData={tableData}
            onHeaderClick={handleSortingClick}
          />
        </ServerSidePagination>
      </Grid.Row>
      <Grid.Row className="justify-content-end">
        {total ? (
          <Grid.Col md={3}>
            <Alert type="info">Result Count - {total}</Alert>
          </Grid.Col>
        ) : null}
      </Grid.Row>
    </Grid>
  );
};

export default CancellationFeedbacks;
