import React, { FormEvent, useEffect, useState } from "react";
import { Grid, Button, Alert, Form } from "tabler-react";
import { ContentTextProps, blackListProps } from "../../types";
import ServerSidePagination from "../../components/pagination/serverSidePagination";
import TableCard from "../../components/tableCard";
import DatePicker from "react-datepicker";
import moment from "moment";

import "./style.css";

const WorkFlows = ({
  data,
  loading,
  setCreator,
  setPerformer,
  searchCreator,
  hideHex,
  setHideHex,
  searchPerformer,
  onPageNumberChange,
  ...props
}: any): JSX.Element => {
  const [list, setList] = useState<Array<blackListProps>>([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [creatorEmailText, setCreatorEmailText] = useState("");
  const [performerEmailText, setPerformerEmailText] = useState("");
  const [total, setTotal] = useState(0);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    props.setToDate(toDate);
    props.setFromDate(fromDate);
    setCreator(creatorEmailText);
    setPerformer(performerEmailText);
  };

  const handleFromDate = (date: Date) => {
    if (date) {
      return setFromDate(moment(date).format("YYYY-MM-DDT00:00:00.000"));
    }
    setFromDate(null);
  };

  const handleToDate = (date: Date) => {
    if (date) {
      return setToDate(moment(date).format("YYYY-MM-DDT23:59:59.999"));
    }
    setToDate(null);
  };

  const tableData = {
    tData:
      list &&
      list.map((item: ContentTextProps) => ({
        id: item.id,
        task_creator: item.creator.firstName + " " + item.creator.lastName,
        email_of_task_creator: item.creator.email,
        tasks: item.question,
        date_created: moment(new Date(parseInt(item.created_at)))
          .utc()
          .format("YYYY-MM-DD"),
        quantity: item.quantity,
        task_performer: item.performer ? item.performer.firstName + " " + item.performer.lastName : "",
        email_of_task_performer: item.performer ? item.performer.email : "",
        start_date:
          item.startDate &&
          moment(new Date(parseInt(item.startDate)))
            .utc()
            .format("YYYY-MM-DD hh:mm"),
        finished_date:
          item.endDate &&
          moment(new Date(parseInt(item.endDate)))
            .utc()
            .format("YYYY-MM-DD hh:mm"),
        duration: item.duration + " min",
        status: item.status,
      })),
  };

  useEffect(() => {
    if (
      data &&
      data.HexomaticHumanValidationsTasks &&
      data.HexomaticHumanValidationsTasks.adminGetHumanValidationsTasks
    ) {
      setDataLoading(loading);
      data.HexomaticHumanValidationsTasks.adminGetHumanValidationsTasks.tasks &&
        setList(data.HexomaticHumanValidationsTasks.adminGetHumanValidationsTasks.tasks);
      setTotal(data.HexomaticHumanValidationsTasks.adminGetHumanValidationsTasks.count || 0);
    }
  }, [data]);

  return (
    <Grid>
      <Grid.Row>
        <Grid.Col md={2}>
          <Form.Checkbox
            label="Filter Hexact tasks"
            checked={hideHex}
            onChange={() => setHideHex(!hideHex)}
          />
        </Grid.Col>
      </Grid.Row>

      <Grid.Row cards deck>
        <div className="col-12 col-md-8"></div>
        <div className="col-12 col-md-4">
          <form onSubmit={(e) => handleSubmit(e)}>
            <Form.Group>
              <Form.Input
                label=""
                className="margin-bottom"
                icon="search"
                position="append"
                name="userEmail"
                placeholder="Type task creator name or email for search"
                value={creatorEmailText}
                onChange={(e) => setCreatorEmailText(e.target.value)}
              />
              <Form.Input
                label=""
                className="margin-bottom"
                icon="search"
                position="append"
                name="userEmail"
                placeholder="Type performer email for search"
                value={performerEmailText}
                onChange={(e) => setPerformerEmailText(e.target.value)}
              />
              <Grid.Row>
                <Grid.Col>
                  <Form.Group label="From Date">
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      selected={fromDate ? new Date(fromDate) : null}
                      onChange={handleFromDate}
                      showMonthDropdown
                      placeholderText="Select a date"
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col>
                  <Form.Group label="To Date">
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      selected={toDate ? new Date(toDate) : null}
                      onChange={handleToDate}
                      showMonthDropdown
                      placeholderText="Select a date"
                      minDate={fromDate && new Date(fromDate)}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
              <Button type="submit" icon="filter" color="primary" className="" loading={dataLoading}>
                Filter
              </Button>
            </Form.Group>
          </form>
        </div>
        {/* </Grid.Row> */}

        <Grid.Col md={10}></Grid.Col>
        <div className="col-12 col-md-2 col-xs-6">
          <Alert type="info">Result Count - {total}</Alert>
        </div>
        <div className="black-list-table-pagination">
          <ServerSidePagination data={list} total={total} onPageNum={onPageNumberChange}>
            <TableCard loading={dataLoading} data={list} tableData={tableData} />
          </ServerSidePagination>
        </div>

        {list.length ? (
          <div className="col-12 col-md-2 col-xs-12 offset-md-10">
            <Alert type="info">Result Count - {total}</Alert>
          </div>
        ) : null}
      </Grid.Row>
    </Grid>
  );
};

export default WorkFlows;
