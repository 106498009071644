import React, { useState } from 'react';
import Questionnaires from './questionnaire';
import { useQuery } from '@apollo/react-hooks';
import { GET_QUESTIONNAIRES } from "../../graphql/queries";


const QuestionnairesQueries = () => {
  const [pageNumberState, setPageNumberState] = useState(1);
  const [filterDataState, setFilterDataState] = useState("");
  const [type, setType] = useState<string | undefined>();
  const [answer1, setAnswer1] = useState<string | undefined>();
  const [answer2, setAnswer2] = useState<string | undefined>()
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [orderByASC, setOrderByASC] = useState(false);

  const { data, loading, refetch } = useQuery(GET_QUESTIONNAIRES, {
    variables: {
      input: {
        regFrom: fromDate,
        regTo: toDate,
        pageNumber: pageNumberState,
        searchQuery: filterDataState,
      },
      type,
      answer1: answer1,
      answer2: answer2,
      orderBy, 
      orderByASC,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });

  const handlePageNumberChange = (pageNumber: number) => setPageNumberState(pageNumber);
  const handleFilterDataChange = (filterData: string) => setFilterDataState(filterData);
  const handleSelectType = (type: string | undefined) => setType(type);
  const handleSelectAnswer1 = (chnl: string | undefined) => setAnswer1(chnl);
  const handleSelectAnswer2 = (tp: string | undefined) => setAnswer2(tp);
  const handleFromDate = (date: string) => setFromDate(date);
  const handleToDate = (date: string) => setToDate(date);

  return (
    <Questionnaires
      data={data}
      loading={loading}
      onPageNumberChange={handlePageNumberChange}
      refetchData={refetch}
      onFilterDataChange={handleFilterDataChange}
      onSelectType={handleSelectType}
      onSelectAnswer1={handleSelectAnswer1}
      onSelectAnswer2={handleSelectAnswer2}
      onFromDate={handleFromDate}
      onToDate={handleToDate}
      setOrderByASC={setOrderByASC}
      orderByASC={orderByASC}
      setOrderBy={setOrderBy}
      variables={{
        input: {
          regFrom: fromDate,
          regTo: toDate,
          pageNumber: pageNumberState,
          searchQuery: filterDataState,
        },
        type,
        answer1: answer1,
        answer2: answer2,
      }}
    />
  )
};

export default QuestionnairesQueries;