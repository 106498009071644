import React, { useEffect, useState } from 'react';
import { Grid, Button, Alert, Form } from "tabler-react";
import { ContentTextProps, TranslationProps, KeywordsProps, blackListProps } from "../../types";
import ServerSidePagination from "../../components/pagination/serverSidePagination";
import moment from "moment";
import SearchInput from "../../components/searchInput";
import TableCard from "../../components/tableCard";
import TableModal from "./tableModal";

import './style.css'

const bldata = [
  {
    email: "test@gmail.com",
    Created_At: "27-05-2020",
    Updated_At: "27-05-2020",
  },
  {
    email: "test@mail.ru",
    Created_At: "27-05-2020",
    Updated_At: "27-05-2020",
  },
  {
    email: "test@yahoo.com",
    Created_At: "27-05-2020",
    Updated_At: "27-05-2020",
  }
]

const BlackListEmail = (
  { data, loading, setsearchEmail, searchEmail, createBlackList, updateBlackList, deleteBlackList, onPageNumberChange }: any
): JSX.Element => {

  const [blackList, setBlackList] = useState<Array<blackListProps>>([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [mutationCompleted, setMutationCompleted] = useState(false);
  const [editEmail, setEditEmail] = useState<any>();
  const [total, setTotal] = useState(0)

  const handleModal = () => {
    if (showModal) {
      setEditEmail(null);
      setErrorMessage("")
    }
    setShowModal(!showModal);
  }

  const handleSave = async ({ ...rest }) => {
    const { email } = rest
    if (email.length < 3) {
      setErrorMessage('email can\'t be empty!');
      return false;
    } else if (email.indexOf('@') === -1) {
      setErrorMessage(`email field must contain '@' symbol`);
      return false;
    }
    if (editEmail) {
      const updateResponse = await updateBlackList({
        variables: { email: email, id: editEmail.id },
      });

      const error = updateResponse.data.EmailBlackListOps.adminUpdateEmail.error;
      const message = updateResponse.data.EmailBlackListOps.adminUpdateEmail.message;
      if (error) {
        setErrorMessage(message);
        return false;
      } else {
        setMutationCompleted(true);
      }
    } else {
      const createResponse = await createBlackList({
        variables: { email: email },
      });

      const error = createResponse.data.EmailBlackListOps.adminAddEmail.error;
      const message = createResponse.data.EmailBlackListOps.adminAddEmail.message;
      if (error) {
        setErrorMessage(message);
        return false;
      } else {
        setMutationCompleted(true);
      }
    }
    return true;
  };

  const handleKeywordDelete = async (row: ContentTextProps) => {
    let deleteConfirm = confirm("Are you sure you want to delete translation?");
    if (deleteConfirm) {
      const deleteResponse = await deleteBlackList({
        variables: { email: row.email },
      });
      const error = deleteResponse.data.EmailBlackListOps.adminDeleteEmail.error;
      const message = deleteResponse.data.EmailBlackListOps.adminDeleteEmail.message;
      if (error) {
        setErrorMessage(message);
      } else {
        setMutationCompleted(true);
      }
    }
  };

  const handleKeywordMutate = (row: object | null) => {
    setShowModal(true);
    setEditEmail(row);
  };
  const tableData = {
    tData: blackList && blackList.map((item: ContentTextProps) => (
      {
        "email": item.email,
        "created at": moment(+item.createdAt).format('DD-MM-YYYY'),
        "updated at": moment(+item.updatedAt).format('DD-MM-YYYY'),
        "Action":
          <Button.List>
            <Button color="primary" icon="edit" onClick={() => handleKeywordMutate(item)} />
            <Button color="danger" icon="trash" onClick={() => handleKeywordDelete(item)} />
          </Button.List>
      }
    ))
  };

  useEffect(() => {
    if (data && data.EmailBlackList && data.EmailBlackList.adminGetMany.email_black_list) {
      setDataLoading(loading);
      setBlackList(data.EmailBlackList.adminGetMany.email_black_list)
      setTotal(data.EmailBlackList.adminGetMany.total)
    }
  }, [data]);

  return (
    <Grid.Row cards deck>
      <TableModal
        handleModal={handleModal}
        showModal={showModal}
        onSave={handleSave}
        editEmail={editEmail}
        errorMessage={errorMessage}
      />
      <div className="col-12 col-md-12 col-xs-12">
        <Form.Group className="offset-9">
          <Form.Input
            icon="search"
            position="append"
            placeholder={'Search for email'}
            value={searchEmail}
            onChange={(e: React.FormEvent) => {
              const inputText = (e.target as HTMLInputElement).value;
              setsearchEmail(inputText);
            }}
          />
        </Form.Group>
      </div>
      <div className="col-12 col-md-1 col-xs-6">
        <Button
          className=""
          onClick={() => handleKeywordMutate(null)}
          color="primary"
        >Create</Button>
        <br />
      </div>
      <Grid.Col md={9}>
      </Grid.Col>
      <div className="col-12 col-md-2 col-xs-6">
        <Alert type="info">
          Result Count - {total}
        </Alert>
      </div>
      <div className="black-list-table-pagination">
        <ServerSidePagination
          data={blackList}
          total={total}
          onPageNum={onPageNumberChange}
        >
          <TableCard
            loading={dataLoading}
            data={blackList}
            tableData={tableData}
          />
        </ServerSidePagination>
      </div>

      {blackList.length ? <div className="col-12 col-md-2 col-xs-12 offset-md-10">
        <Alert type="info">
          Result Count - {total}
        </Alert>
      </div> : null}
    </Grid.Row>
  )
};

export default BlackListEmail;
