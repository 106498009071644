import React, { useEffect, useState } from "react";
import { Alert, Button, Form } from "tabler-react";
import ReactModal from "react-modal";
import "react-mde/lib/styles/css/react-mde-all.css";
import ServerSidePagination from "../../components/pagination/serverSidePagination";
import TableCard from "../../components/tableCard";
import { ContentTextProps } from "../../types";

ReactModal.setAppElement('#root');

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    height: '50%'
  }
};

const TableModal = (
  { handleModal, showModal, editField, errorMessage, outputIds, inputIds, schemaList, schemaType, onAttachIds, onDeleteAttachedIs }: any
): JSX.Element => {

  const [errMsg, setErrMsg] = useState("")

  const handleCancel = () => {
    handleModal();
  };

  const onAttach = ({automationId, schemaId, type}) => {
    onAttachIds({automationId, schemaId, type})
  }

  const onDelete = ({automationId, schemaId, type}) => {
    onDeleteAttachedIs({automationId, schemaId, type})
  }


  useEffect(() => {
    if (window.innerWidth < 640) {
      customStyles.content.width = '90%'
    }
  }, [])

  // automation_id: Int
  // output_schema_ids: [ Int ]
  // input_schema_ids: [ Int ]

  const tableData = {
    tData: schemaList && schemaList.map((item: ContentTextProps) => (
      {
        "id": item.id,
        "Json schema": item.schema,
        "Action":
          <Button.List>
            {[...inputIds ? inputIds : [], ...outputIds ? outputIds : []].includes(item.id) ? 
              <Button color="danger" icon="trash" onClick={() => onDelete({ automationId: editField.id, schemaId: item.id, type: schemaType })} /> :
              <Button color="primary" onClick={() => {onAttach({ automationId: editField.id, schemaId: item.id, type: schemaType })}} >
                <img src="https://image.flaticon.com/icons/png/512/1975/1975681.png" width="15" alt="" />
              </Button>}
          </Button.List>
      }
    ))
  };


  return (
    <div style={{ height: 0 }}>
      <ReactModal
        isOpen={showModal}
        contentLabel="Create Automation"
        style={customStyles}
        onRequestClose={handleCancel}
      >
        <h4>{editField ? "Edit Automation" : "Create Automation"}</h4>
        {(errorMessage || errMsg) ?
          <Alert type="danger">
            {errorMessage || errMsg}
          </Alert> : null}
        <div className="black-list-table-pagination">
          <ServerSidePagination
            data={schemaList}
            total={0}
            onPageNum={() => {}}
          >
            <TableCard
              loading={false}
              data={schemaList}
              tableData={tableData}
            />
          </ServerSidePagination>
        </div>
        <Button.List style={{ marginTop: "5%" }}>
          {/* <Button onClick={handleOk} color="primary" type="submit" loading={loading}>Save</Button> */}
          <Button onClick={handleCancel} color="secondary" type="button">Cancel</Button>
        </Button.List>
        {(errorMessage || errMsg) ?
          <Alert type="danger">
            {errorMessage || errMsg}
          </Alert> : null}
      </ReactModal>
    </div>
  )
};

export default TableModal;
