import React, { useState, useEffect, FC, useReducer } from "react";
import CreditUsage from "./creditUsage";
import { useQuery } from "@apollo/react-hooks";
import {
  GET_HEXOMATIC_AUTOMATION_NAMES,
  GET_HEXOMATIC_AUTOAMTION_USAGE,
  GET_AUTOMATION_CREDIT_TIME_SERIES,
  GET_AUTOMATION_PREMIUM_CREDIT_TIME_SERIES,
  GET_AUTOMATIONS_CREDIT_USAGES_DATA,
} from "../../graphql/queries";
import moment from "moment";

const AutomationCreditUsage: FC = () => {
  const [fromDate, setFromDate] = useState(
    moment(new Date().setDate(new Date().getDate() - 7)).format("YYYY-MM-DDT00:00:00.000")
  );
  const [toDate, setToDate] = useState(moment(new Date().toUTCString()).format("YYYY-MM-DDT23:59:59.999"));
  const [orderBy, setOrderBy] = useState(localStorage.getItem("credits-usage-sort-by") || "credit");
  const [orderByASC, setOrderByASC] = useState<boolean>(
    !!localStorage.getItem("credits-usage-sort-asc") || true
  );

  const [finalCreditData, setFinalCreditData] = useState<
    {
      id: number;
      name: string;
      usedCredits: number;
      usedPremiumCredits: number;
    }[]
  >([]);

  const [automationUsageData, setAutomationUsageData] = useState([] as any);
  const [timeSeries, setTimeSeries] = useState<
    {
      _id: string;
      time: Date;
      credit: {
        [key: string]: number;
      };
    }[]
  >([]);

  const [timePremiumSeries, setTimePremiumSeries] = useState<
    {
      _id: string;
      time: Date;
      premium_credit: {
        [key: string]: number;
      };
    }[]
  >([]);

  const [automationNames, setAutomationNames] = useState<
    {
      id: number;
      name: string;
    }[]
  >([]);

  const [includedAutomationIds, setIncludedAutomationIds] = useState<string[]>([]);
  const [pageNumberState, setPageNumberState] = useState(1);
  const [filterHexUsers, setFilterHexUsers] = useReducer((prev) => !prev, true);

  const { loading: automationTmeSeriesLoading } = useQuery(GET_AUTOMATION_CREDIT_TIME_SERIES, {
    variables: {
      settings: {
        page: pageNumberState,
        hideHex: filterHexUsers,
        from: moment(new Date().setDate(new Date().getDate() - 100)).format("YYYY-MM-DDT00:00:00.000"),
        to: moment(new Date()).format('YYYY-MM-DDT00:00:00.000')
      },
    },
    onCompleted(result) {
      if (result.HexomaticAutomation.getAutomationCreditTimeSeries.time_series) {
        setTimeSeries(JSON.parse(result.HexomaticAutomation.getAutomationCreditTimeSeries.time_series));
      }
    },
    fetchPolicy: "network-only",
  });

  const { loading: automationPremiumTmeSeriesLoading } = useQuery(GET_AUTOMATION_PREMIUM_CREDIT_TIME_SERIES, {
    variables: {
      settings: {
        page: pageNumberState,
        hideHex: filterHexUsers,
        from: moment(new Date().setDate(new Date().getDate() - 100)).format("YYYY-MM-DDT00:00:00.000"),
        to: moment(new Date()).format('YYYY-MM-DDT00:00:00.000')
      },
    },
    onCompleted(result) {
      if (result.HexomaticAutomation.getAutomationPremiumCreditTimeSeries.time_series) {
        setTimePremiumSeries(
          JSON.parse(result.HexomaticAutomation.getAutomationPremiumCreditTimeSeries.time_series)
        );
      }
    },
    fetchPolicy: "network-only",
  });

  const { loading: automationNamesLoading } = useQuery(GET_HEXOMATIC_AUTOMATION_NAMES, {
    onCompleted(result) {
      if (result.HexomaticAutomation.getAutomationNames.names) {
        setAutomationNames(result.HexomaticAutomation.getAutomationNames.names);
      }
    },
    fetchPolicy: "network-only",
  });

  const { loading: automationUsageLoading } = useQuery(GET_AUTOMATIONS_CREDIT_USAGES_DATA, {
    variables: {
      settings: {
        filter: {
          from: fromDate,
          to: toDate,
          includedAutomationIds,
          hideHex: filterHexUsers,
        },
      },
    },
    onCompleted(result) {
      if (result.HexomaticAutomation.getAutomationCreditUsageInfo) {
        const resCredits = JSON.parse(result.HexomaticAutomation.getAutomationCreditUsageInfo.credit);
        const resPremiumCredits = JSON.parse(
          result.HexomaticAutomation.getAutomationCreditUsageInfo.premium_credit
        );
        const finData = resCredits;
        resPremiumCredits &&
          resPremiumCredits.map((item: any) => {
            finData.find((sub: any, index: any) => {
              if (sub.id === item.id) {
                finData[index] = {
                  ...item,
                  count: {
                    credit: sub.count[0] || 0,
                    premium: item.count[0] || 0,
                  },
                };
              }
            });
          });
        finData && setAutomationUsageData(finData);
      }
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    const arr: any[] = [];
    let data = null;

    if (automationNames.length && automationUsageData.length) {
      if (orderBy === "credit") {
        automationUsageData.sort((obj1: any, obj2: any) => {
          return orderByASC
            ? +obj2.count.credit - +obj1.count.credit
            : +obj1.count.credit - +obj2.count.credit;
        });
        setAutomationUsageData(automationUsageData);
        data = automationUsageData;
      } else if (orderBy === "premium credit") {
        automationUsageData.sort((obj1: any, obj2: any) => {
          return orderByASC
            ? +obj2.count.premium - +obj1.count.premium
            : +obj1.count.premium - +obj2.count.premium;
        });
        setAutomationUsageData(automationUsageData);
        data = automationUsageData;
      }

      data.forEach((currCredit: any) => {
        const currentAutomation = automationNames.find((name: any) => +name.id === +currCredit.id);
        currentAutomation &&
          arr.push({
            id: +currCredit.id,
            name: currentAutomation.name,
            usedCredits: currCredit.count.credit || 0,
            usedPremiumCredits: currCredit.count.premium || 0,
          });
      });
    }
    setFinalCreditData(arr);
  }, [automationUsageData, automationNames, orderBy, orderByASC]);

  const handlePageNumberChange = (pageNumber: number) => setPageNumberState(pageNumber);

  const handleFromDate = (date: string) => setFromDate(date);
  const handleToDate = (date: string) => setToDate(date);

  return (
    <CreditUsage
      automationNames={automationNames}
      finalCreditData={finalCreditData}
      timeSeries={timeSeries}
      timePremiumSeries={timePremiumSeries}
      loading={
        automationUsageLoading ||
        automationNamesLoading ||
        automationTmeSeriesLoading ||
        automationPremiumTmeSeriesLoading
      }
      filterHexUsers={filterHexUsers}
      setFilterHexUsers={setFilterHexUsers}
      setIncludedAutomationIds={setIncludedAutomationIds}
      onPageNumberChange={handlePageNumberChange} //!change
      refetchData={() => {}} //!change
      onFromDate={handleFromDate}
      onToDate={handleToDate}
      setOrderByASC={setOrderByASC}
      orderByASC={orderByASC}
      orderBy={orderBy}
      setOrderBy={setOrderBy}
    />
  );
};

export default AutomationCreditUsage;
