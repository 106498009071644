import React, { useEffect, useState } from "react";
import { Alert, Button, Form } from "tabler-react";
import ReactModal from "react-modal";
import "react-mde/lib/styles/css/react-mde-all.css";
import {
  otherTemplateNames,
  hexomaticTemplateNames,
  hexowatchTemplateNames,
  hexometerTemplateNames,
} from "../../constants";
import moment from "moment";

ReactModal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    height: "50%",
  },
};

const TableModal = ({
  handleModal,
  showModal,
  onSave,
  onUpdate,
  editField,
  errorMessage,
}: any): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [href, setHref] = useState("");
  const [image, setImage] = useState("");
  const [product, setProduct] = useState("");
  const [date, setDate] = useState("");
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [preview, setPreview] = useState(false);

  const handleOk = async () => {
    setLoading(true);
    if (editField) {
      const formatedDate =
        date && moment(date, "MM/DD/YYYY").isValid() ? moment(date, "MM/DD/YYYY").format("MM/DD/YYYY") : null;
      if (!formatedDate) {
        setErrorMsg("Please enter valid date");
        setLoading(false);
        return;
      }
      await onUpdate({
        news_id: editField.id,
        img: image,
        subject: subject,
        description: description,
        href: href,
        product: product,
        date: formatedDate,
        templates_id: selectedTemplates,
      });
      setLoading(false);
      setSubject("");
      setDescription("");
      setHref("");
      setImage("");
      setProduct("");
      setSelectedTemplates([]);
      handleModal();
      setDate("");
      return;
    }
    const formData = {
      subject,
      description,
      href,
      product,
      img: image,
      templateNames: selectedTemplates,
      date,
    };
    const completed = await onSave({ ...formData });
    if (completed) {
      setSubject("");
      setDescription("");
      setHref("");
      setImage("");
      setProduct("");
      setSelectedTemplates([]);
      handleModal();
      setDate("");
    }
    setLoading(false);
    // if (completed) handleCancel();
  };

  const handleCancel = () => {
    setSubject("");
    setDescription("");
    setHref("");
    setImage("");
    setProduct("");
    setSelectedTemplates([]);
    handleModal();
    setDate("");
  };

  useEffect(() => {
    if (window.innerWidth < 640) {
      customStyles.content.width = "90%";
    }
  }, []);

  useEffect(() => {
    if (editField) {
      setSubject(editField.variables.subject);
      setImage(editField.variables.img_url);
      setDescription(editField.variables.description);
      setHref(editField.variables.href);
      setProduct(editField.variables.product);
      setDate(editField.variables.date);
      const data =
        editField &&
        editField.templates_id &&
        typeof editField.templates_id === "string" &&
        editField.templates_id.split(", ");
      data && data.length
        ? setSelectedTemplates(data.map((sub: any) => +sub))
        : editField &&
          editField.templates_id &&
          Array.isArray(editField.templates_id) &&
          setSelectedTemplates(editField.templates_id);
    }
  }, [editField]);

  const handleSubjectChange = (e: React.FormEvent) => setSubject((e.target as HTMLInputElement).value);

  return (
    <div style={{ height: 0 }}>
      <ReactModal
        isOpen={showModal}
        contentLabel="Create custom message"
        style={customStyles}
        onRequestClose={handleCancel}
      >
        <h4>{editField ? "Edit custom message" : "Create custom message"}</h4>
        {errorMessage || errorMsg ? <Alert type="danger">{errorMessage || errorMsg}</Alert> : null}
        <Form onSubmit={(e: React.FormEvent) => e.preventDefault()}>
          <Form.Input
            required
            type="text"
            placeholder="title"
            label="custom message title (recommended 70 chars)"
            value={subject}
            onChange={handleSubjectChange}
          />
          <Form.Textarea
            onChange={(e: React.FocusEvent) => setDescription((e.target as HTMLInputElement).value)}
            placeholder="text"
            type="text"
            label="custom message text (recommended 150 chars)"
            value={description}
          ></Form.Textarea>

          <Form.Input
            required
            type="text"
            placeholder="image URL"
            label={
              <>
                <div>custom message image URL</div>
                <small>
                  recommended: size <strong>270x150 in px</strong>, format{" "}
                  <strong>png/jpg (compressed)</strong>
                </small>
              </>
            }
            value={image}
            onChange={(e: any) => setImage(e.target.value)}
          />

          <Form.Input
            required
            type="text"
            placeholder="message URL"
            label="custom message URL"
            value={href}
            onChange={(e: any) => setHref(e.target.value)}
          />

          <Form.Input
            required
            type="text"
            placeholder="MM/DD/YYYY"
            label="custom message date"
            value={date}
            onChange={(e: any) => setDate(e.target.value)}
          />

          {/* <Form.Input
            required
            type="text"
            placeholder="product type"
            label="product type"
            value={product}
            onChange={(e: any) => setProduct(e.target.value)}
          /> */}
          <div>
            <strong>Hexomatic emails</strong>
            {hexomaticTemplateNames &&
              hexomaticTemplateNames.map((row: any) => {
                return (
                  <div key={row.label} className="d-flex">
                    <Form.Checkbox
                      className=""
                      label={row.label}
                      checked={
                        typeof row.value === "number"
                          ? selectedTemplates.includes(row.value)
                          : selectedTemplates.includes(row.value[0])
                      }
                      onChange={() => {
                        if (
                          typeof row.value === "number"
                            ? selectedTemplates.includes(row.value)
                            : selectedTemplates.includes(row.value[0])
                        ) {
                          const category = selectedTemplates.filter((item: any) =>
                            Array.isArray(row.value) ? !row.value.includes(item) : item !== row.value
                          );
                          setSelectedTemplates(category);
                        } else {
                          const category = [...selectedTemplates];
                          typeof row.value === "number"
                            ? category.push(row.value)
                            : row.value.map((sub: any) => category.push(sub));
                          setSelectedTemplates(category);
                        }
                      }}
                    />
                  </div>
                );
              })}
          </div>
          <div>
            <strong>Hexowatch emails</strong>
            {hexowatchTemplateNames &&
              hexowatchTemplateNames.map((row: any) => {
                return (
                  <div key={row.label} className="d-flex">
                    <Form.Checkbox
                      className=""
                      label={row.label}
                      checked={
                        typeof row.value === "number"
                          ? selectedTemplates.includes(row.value)
                          : selectedTemplates.includes(row.value[0])
                      }
                      onChange={() => {
                        if (
                          typeof row.value === "number"
                            ? selectedTemplates.includes(row.value)
                            : selectedTemplates.includes(row.value[0])
                        ) {
                          const category = selectedTemplates.filter((item: any) =>
                            Array.isArray(row.value) ? !row.value.includes(item) : item !== row.value
                          );
                          setSelectedTemplates(category);
                        } else {
                          const category = [...selectedTemplates];
                          typeof row.value === "number"
                            ? category.push(row.value)
                            : row.value.map((sub: any) => category.push(sub));
                          setSelectedTemplates(category);
                        }
                      }}
                    />
                  </div>
                );
              })}
          </div>
          <div>
            <strong>Hexometer emails</strong>
            {hexometerTemplateNames &&
              hexometerTemplateNames.map((row: any) => {
                return (
                  <div key={row.label} className="d-flex">
                    <Form.Checkbox
                      className=""
                      label={row.label}
                      checked={
                        typeof row.value === "number"
                          ? selectedTemplates.includes(row.value)
                          : selectedTemplates.includes(row.value[0])
                      }
                      onChange={() => {
                        if (
                          typeof row.value === "number"
                            ? selectedTemplates.includes(row.value)
                            : selectedTemplates.includes(row.value[0])
                        ) {
                          const category = selectedTemplates.filter((item: any) =>
                            Array.isArray(row.value) ? !row.value.includes(item) : item !== row.value
                          );
                          setSelectedTemplates(category);
                        } else {
                          const category = [...selectedTemplates];
                          typeof row.value === "number"
                            ? category.push(row.value)
                            : row.value.map((sub: any) => category.push(sub));
                          setSelectedTemplates(category);
                        }
                      }}
                    />
                  </div>
                );
              })}
          </div>
          <div>
            <strong>Other</strong>
            {otherTemplateNames &&
              otherTemplateNames.map((row: any) => {
                return (
                  <div key={row.label} className="d-flex">
                    <Form.Checkbox
                      className=""
                      label={row.label}
                      checked={
                        typeof row.value === "number"
                          ? selectedTemplates.includes(row.value)
                          : selectedTemplates.includes(row.value[0])
                      }
                      onChange={() => {
                        if (
                          typeof row.value === "number"
                            ? selectedTemplates.includes(row.value)
                            : selectedTemplates.includes(row.value[0])
                        ) {
                          const category = selectedTemplates.filter((item: any) =>
                            Array.isArray(row.value) ? !row.value.includes(item) : item !== row.value
                          );
                          setSelectedTemplates(category);
                        } else {
                          const category = [...selectedTemplates];
                          typeof row.value === "number"
                            ? category.push(row.value)
                            : category.map((sub: any) => category.push(sub));
                          setSelectedTemplates(category);
                        }
                      }}
                    />
                  </div>
                );
              })}
          </div>
          {errorMessage || errorMsg ? <Alert type="danger">{errorMessage || errorMsg}</Alert> : null}
          <Button.List style={{ marginTop: "5%" }}>
            <Button onClick={handleOk} color="primary" type="submit" loading={loading}>
              Save
            </Button>
            <Button onClick={handleCancel} color="secondary" type="button">
              Cancel
            </Button>
            <Button onClick={() => setPreview(true)} color="secondary" type="button">
              Preview
            </Button>
          </Button.List>
        </Form>
      </ReactModal>
      {preview && (
        <ReactModal
          isOpen={preview}
          contentLabel="Custom message preview"
          onRequestClose={() => setPreview(null)}
          className="preview-modal"
        >
          <div style={{ background: "#fff", maxWidth: "270px", margin: "auto" }}>
            <a href={href} target="_blank" style={{ textDecoration: "none" }}>
              <div style={{ border: "1px solid #E5E5E5", borderRadius: "8px" }}>
                <div
                  style={{
                    background: "#F5F7F6",
                    height: "150px",
                    overflow: "hidden",
                    display: "flex",
                    borderRadius: "8px 8px 0 0",
                  }}
                >
                  <img
                    style={{
                      height: "auto",
                      width: "100%",
                      margin: "auto",
                      position: "relative",
                      top: " 50%",
                      transform: " translateY(-50%)",
                    }}
                    src={image}
                    alt={subject}
                  />
                </div>
                <div style={{ padding: "20px 10px" }}>
                  <div
                    style={{
                      height: "30px",
                      overflow: "hidden",
                      color: "#000",
                      lineHeight: "14px",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                  >
                    {subject}
                  </div>
                </div>
                <div style={{ padding: "0px 10px" }}>
                  <div
                    style={{
                      height: "50px",
                      overflow: "hidden",
                      color: "#6F6D6D",
                      lineHeight: "16px",
                      fontSize: "10px",
                    }}
                  >
                    {description}
                  </div>
                </div>

                <div style={{ padding: "10px", display: "flex", borderRadius: "0 0 8px 8px" }}>
                  <div
                    style={{ padding: "0px 10px 0 0", fontSize: "10px", color: "#757575", margin: "auto 0" }}
                  >
                    {date}
                  </div>
                  <div
                    style={{ padding: "0px", fontSize: "12px", fontWeight: 600, margin: "auto 0 auto auto" }}
                  >
                    <a href={href} style={{ textDecoration: "none", color: "#AF71FB" }}>
                      Read more →
                    </a>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </ReactModal>
      )}
    </div>
  );
};

export default TableModal;
