import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { GET_HEXOMATIC_SHARED_WORKFLOWS } from "../../graphql/queries";
import HexomaticSharedWorkflows from "./hexomaticSharedWorkflows";

const HexomaticSharedWorkflowsQueries = () => {
  const [pageNumberState, setPageNumberState] = useState(1);
  const [filterDataStateBy, setFilterDataStateBy] = useState("");
  const [filterDataStateWith, setFilterDataStateWith] = useState("");
  // const [selectShareTypeState, setSelectShareTypeState] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [orderByASC, setOrderByASC] = useState(false);
  const [filterHexScans, setFilterHexScans] = useState(true);

  const { data, loading, refetch } = useQuery(GET_HEXOMATIC_SHARED_WORKFLOWS, {
    variables: {
      settings : {
        from: fromDate,
        to: toDate,
        userSharedBySearchInput: filterDataStateBy,
        userSharedWithSearchInput: filterDataStateWith,
        csvDownload : false,
        driveUpload: false,
        page: pageNumberState,
        pageSize: 100,
        sortField: orderBy,
        sortDir: orderByASC ? 'ASC' : 'DESC',
        hideHex: filterHexScans,
      }
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const handlePageNumberChange = (pageNumber: number) => setPageNumberState(pageNumber);
  const handleFilterDataChangeBy = (filterData: string) => setFilterDataStateBy(filterData);
  const handleFilterDataChangeWith = (filterData: string) => setFilterDataStateWith(filterData);
  // const handleSelectShareType = (type: string) => setSelectShareTypeState(type);
  const handleFromDate = (date: string) => setFromDate(date);
  const handleToDate = (date: string) => setToDate(date);

  return (
    <HexomaticSharedWorkflows
      data={data}
      loading={loading}
      onPageNumberChange={handlePageNumberChange}
      refetchData={refetch}
      onFilterDataChangeBy={handleFilterDataChangeBy}
      onFilterDataChangeWith={handleFilterDataChangeWith}
      // onSelectShareType={handleSelectShareType}
      onFromDate={handleFromDate}
      onToDate={handleToDate}
      setOrderByASC={setOrderByASC}
      orderByASC={orderByASC}
      setOrderBy={setOrderBy}
      setFilterHexScans={setFilterHexScans}
      filterHexScans={filterHexScans}
      variables={{
        settings : {
          from: fromDate,
          to: toDate,
          userSharedBySearchInput: filterDataStateBy,
          userSharedWithSearchInput: filterDataStateWith,
          csvDownload : false,
          driveUpload: false,
          page: pageNumberState,
          pageSize: 100,
          sortField: orderBy,
          sortDir: orderByASC ? 'ASC' : 'DESC',
          hideHex: filterHexScans,
        }
      }}
    />
  );
};

export default HexomaticSharedWorkflowsQueries;
