import React, { useEffect, useState, FormEvent } from "react";
import { Grid, Button, Form, Alert } from "tabler-react";
import { ContentTextProps, WatchPropertiesProps } from "../../types";
import moment from "moment";
import ServerSidePagination from "../../components/pagination/serverSidePagination";
import TableCard from "../../components/tableCard";
import TableModal from "./modal";
import "./index.css";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import Chart from "../../components/chart";
import {
  WATCH_PROPERTY_SUBSCRIPTIONS_CHART,
  PROPERTIES_DELETION_CHART,
  GET_WATCH_PROPERTIES,
} from "../../graphql/queries";
import DatePicker from "react-datepicker";
import { pricingPacks, watchPricingPacks } from "../../constants";
import { getCountArr, isProduction } from "../../helpers";

const hexowatchTools: { [key: string]: string } = {
  sectionElement: "HTML element monitoring",
  keywordSection: "Keyword monitoring",
  techStackTool: "Technology monitoring",
  sectionScreenTool: "Visual monitoring",
  pingTool: "Availability monitoring",
  htmlSectionTool: "Source code monitoring",
  contentSectionTool: "Content monitoring",
  domainWhoisTool: "Domain WHOIS Monitoring",
  automaticAITool: "Automatic AI monitoring",
  sitemapTool: "Sitemap monitoring",
  httpRequestTool: "Api monitoring",
  backlinkTool: "Backlink monitoring",
};

const WatchProperties = ({
  deleteProperty,
  updateProperty,
  refetchData,
  ...props
}: WatchPropertiesProps): JSX.Element => {
  const [baseProperties, setBaseProperties] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [propertyPagination, setPropertyPagination] = useState([]);
  const [filterInput, setFilterInput] = useState("");
  const [total, setTotal] = useState(0);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [mutationCompleted, setMutationCompleted] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [editProperty, setEditProperty] = useState<any>();
  const [selectPackage, setSelectPackage] = useState("");
  const [propertyStatus, setPropertyStatus] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [shared, setShared] = useState("");
  const [toolName, setToolName] = useState("");
  const showButtons = localStorage.getItem("_rep");

  const [getCSV, { data, loading }] = useLazyQuery(GET_WATCH_PROPERTIES);
  const [uploadToDrive, { data: driveData, loading: driveDataLoading }] =
    useLazyQuery(GET_WATCH_PROPERTIES);

  const chartDataQuery = useQuery(WATCH_PROPERTY_SUBSCRIPTIONS_CHART, {
    variables: { hidePropertiesFromHex: props.filterHexProperties },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  // const deletedPropertiesChartQuery = useQuery(PROPERTIES_DELETION_CHART, {
  //   fetchPolicy: "network-only",
  //   notifyOnNetworkStatusChange: true
  // })

  const handleModal = () => {
    if (showModal) {
      setEditProperty(null);
      setErrorMessage("");
    }
    setShowModal(!showModal);
  };

  const handleSave = async ({ ...rest }) => {
    if (editProperty) {
      const updateResponse = await updateProperty({
        variables: {
          id: rest.id,
          address: rest.address,
          paused: rest.paused,
          pricingPackage: rest.pricingPackage,
        },
      });

      const error = updateResponse.data.WatchOps.adminUpdate.error;
      const message = updateResponse.data.WatchOps.adminUpdate.message;
      if (error) {
        setErrorMessage(message);
        return false;
      } else {
        setMutationCompleted(true);
      }
    }
    return true;
  };

  const handlePropertyMutate = (row: object | null) => {
    setShowModal(true);
    setEditProperty(row);
  };

  const tableData = {
    tData:
      baseProperties &&
      baseProperties.map((item) => ({
        ID: item.id,
        URL: item.address,
        "Is Active": item.active ? "Yes" : "No",
        "Created At": moment(item.createdAt)
          .utc()
          .format("DD-MM-YYYY hh:mm:ss a"),
        "Updated At": moment(item.updatedAt)
          .utc()
          .format("DD-MM-YYYY hh:mm:ss a"),
        "User Email": item.userEmail ? item.userEmail : "N/A",
        "Country/Ip":
          item.countryCode && item.countryCode !== "N/A"
            ? item.countryCode
            : item.ip
            ? item.ip.split(", ")[0]
            : "N/A",
        PACKAGE:
          item.pricingPackage === "free-annual" ||
          item.pricingPackage === "free-month"
            ? "free"
            : item.pricingPackage,
        Tool: hexowatchTools[item.tool],
        "Should Be Deleted At": item.scheduledDeleteAt
          ? moment(parseInt(item.scheduledDeleteAt))
              .utc()
              .format("DD-MM-YYYY hh:mm:ss a")
          : "N/A",
        Action: (
          <Button.List>
            //{" "}
            <Button
              color="primary"
              icon="edit"
              onClick={() => {} /*  handlePropertyMutate(item) */}
            />
            <Button
              color="danger"
              icon="trash"
              onClick={() => handlePropertyDelete(item)}
            />
          </Button.List>
        ),
      })),
  };

  useEffect(() => {
    if (
      props.data &&
      props.data.Watch &&
      props.data.Watch.adminGetMany &&
      props.data.Watch.adminGetMany.wp
    ) {
      setDataLoading(props.loading);
      setBaseProperties(props.data.Watch.adminGetMany.wp);
      setPropertyPagination(props.data.Watch.adminGetMany.wp);
      setTotal(props.data.Watch.adminGetMany.total);
    }
  }, [props.data]);

  useEffect(() => {
    if (
      driveData &&
      driveData.Watch &&
      driveData.Watch.adminGetMany &&
      driveData.Watch.adminGetMany.csvURL
    ) {
      window.open(
        isProduction()
          ? `https://api.hexometer.com/v2/app/social-callback/googleDrive?admin=admin&report=watchProperty&url=${driveData.Watch.adminGetMany.csvURL}`
          : `http://localhost:4000/api/app/social-callback/googleDrive?admin=admin&report=watchProperty&url=${driveData.Watch.adminGetMany.csvURL}`,
        "_blank"
      );
    }
  }, [driveData]);

  const handleInputChange = (e: FormEvent) =>
    setFilterInput((e.target as HTMLInputElement).value);

  const handleSelectPricingPack = (e: FormEvent) =>
    setSelectPackage((e.target as HTMLInputElement).value);

  const handleSelectTool = (e: FormEvent) => {
    const value = (e.target as HTMLInputElement).value;
    if (value === "Select Tool") {
      setToolName("");
      return;
    }
    const toolName = Object.keys(hexowatchTools).find(
      (el) => hexowatchTools[el] === value
    );
    if (toolName) {
      setToolName(toolName);
      return;
    }

    setToolName("");
  };

  const handleCountryCode = (e: FormEvent) =>
    setCountryCode((e.target as HTMLInputElement).value);

  const handleSelectShared = (e: React.FormEvent) =>
    setShared((e.target as HTMLInputElement).value);

  const handlePropertyStatusChange = (e: FormEvent) =>
    setPropertyStatus((e.target as HTMLInputElement).value);

  const handleFromDate = (date: Date) => {
    if (date) {
      return setFromDate(moment(date).format("YYYY-MM-DDT00:00:00.000"));
    }
    return setFromDate(null);
  };

  const handleToDate = (date: Date) => {
    if (date) {
      return setToDate(moment(date).format("YYYY-MM-DDT23:59:59.999"));
    }
    return setToDate(null);
  };

  const handleSubmit = (
    event: FormEvent,
    data: string,
    packageName: string,
    startDate: string,
    endDate: string,
    status: string,
    ctrCode: string,
    shared: string,
    tool: string
  ) => {
    event.preventDefault();
    props.onFilterDataChange(data.trim());
    props.onFromDate(startDate);
    props.onToDate(endDate);
    props.onCountryCode(ctrCode);

    switch (status) {
      case "paused":
        props.onPropertyIsActive(false);
        props.onShceduledToDelete(false);
        break;
      case "active":
        props.onPropertyIsActive(true);
        props.onShceduledToDelete(false);
        break;
      case "scheduled to delete":
        props.onShceduledToDelete(true);
        props.onPropertyIsActive(undefined);
        break;
      case "without promocode":
        props.onShceduledToDelete(false);
        props.onPropertyIsActive(undefined);
        break;
      default:
        props.onPropertyIsActive(undefined);
        props.onShceduledToDelete(false);
    }

    if (packageName === "Select package") {
      props.onPackageSelect("");
    } else {
      props.onPackageSelect(packageName);
    }

    props.onSelectTool(tool);

    if (shared === "all") {
      props.onSelectShared(undefined);
    }
    if (shared === "shared") {
      props.onSelectShared(true);
    }
    if (shared === "not shared") {
      props.onSelectShared(false);
    }
  };

  const handlePropertyDelete = async (row: ContentTextProps) => {
    let deleteConfirm = confirm(
      "Are you sure you want to delete Hexowatch Property?"
    );
    if (deleteConfirm) {
      const deleteResponse = await deleteProperty({
        variables: { id: row.id },
      });
      const error = deleteResponse.data.WatchOps.adminDelete.error;
      const message = deleteResponse.data.WatchOps.adminDelete.message;
      if (error) {
        setErrorMessage(message);
      } else {
        setMutationCompleted(true);
        refetchData();
      }
    }
  };

  const handleSortingClick = (column: string) => {
    if (column === "Created At") {
      props.setOrderBy("createdAt");
      props.setOrderByASC(!props.orderByASC);
    }

    if (column === "Updated At") {
      props.setOrderBy("updatedAt");
      props.setOrderByASC(!props.orderByASC);
    }
  };

  return (
    <Grid>
      <Grid.Row>
        <div className="col-12 col-md-8">
          {!chartDataQuery.loading &&
            chartDataQuery.data &&
            chartDataQuery.data.Watch &&
            chartDataQuery.data.Watch.subscriptionsChart && (
              // !deletedPropertiesChartQuery.loading &&
              <Chart
                yColName="Subscribed Hexowatch Properties"
                // yColName2="Deleted Properties"
                regData={chartDataQuery.data.Watch.subscriptionsChart}
                // regData2={deletedPropertiesChartQuery.data.Watch.deletionChart}
                interval={getCountArr(
                  chartDataQuery.data.Watch.subscriptionsChart.length
                )}
                chartName="Hexowatch Subscription Activity"
              />
            )}
        </div>
        <div className="col-12 col-md-4">
          <form
            onSubmit={(e) =>
              handleSubmit(
                e,
                filterInput,
                selectPackage,
                fromDate,
                toDate,
                propertyStatus,
                countryCode,
                shared,
                toolName
              )
            }
          >
            <Form.Group>
              <Form.Input
                label="Input"
                className="margin-bottom"
                icon="search"
                position="append"
                placeholder="Search for address, user name, email, promocode"
                value={filterInput}
                onChange={handleInputChange}
              />
              <Form.Input
                label="Country Code"
                className="margin-bottom"
                icon="search"
                position="append"
                placeholder="Search for country code"
                value={countryCode}
                onChange={handleCountryCode}
              />
              <Form.Select
                label="Pricing Package"
                onChange={handleSelectPricingPack}
              >
                <option value="Select package">Select package</option>
                {watchPricingPacks.map((tool) => (
                  <option key={tool} value={tool}>
                    {tool}
                  </option>
                ))}
              </Form.Select>
              <Form.Select label="Select Tool" onChange={handleSelectTool}>
                <option value="Select Tool">Select Tool</option>
                {Object.keys(hexowatchTools).map((tool) => (
                  <option
                    key={hexowatchTools[tool]}
                    value={hexowatchTools[tool]}
                  >
                    {hexowatchTools[tool]}
                  </option>
                ))}
              </Form.Select>
              <Form.Select label="Status" onChange={handlePropertyStatusChange}>
                <option value="all">all</option>
                <option value="paused">paused</option>
                <option value="active">active</option>
                <option value="scheduled to delete">deleted</option>
              </Form.Select>
              <Form.Select onChange={handleSelectShared} label="Select Shared">
                {["all", "shared", "not shared"].map((el) => (
                  <option key={el} value={el}>
                    {el}
                  </option>
                ))}
              </Form.Select>
              <Grid.Row>
                <Grid.Col>
                  <Form.Group label="From Date">
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      selected={fromDate ? new Date(fromDate) : null}
                      onChange={handleFromDate}
                      showMonthDropdown
                      placeholderText="Select a date"
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col>
                  <Form.Group label="To Date">
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      selected={toDate ? new Date(toDate) : null}
                      onChange={handleToDate}
                      showMonthDropdown
                      placeholderText="Select a date"
                      minDate={fromDate && new Date(fromDate)}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
              <Button
                className="margin-bottom"
                type="submit"
                icon="filter"
                color="primary"
                loading={dataLoading}
              >
                Filter
              </Button>
            </Form.Group>
          </form>
        </div>
      </Grid.Row>
      <Grid.Row className={showButtons === 'true' ? "justify-content-between" : "justify-content-end"}>
        {showButtons === 'true' && (
          <div className="col-12 col-md-3 col-xs-12">
            <Button.List>
              <Button
                onClick={() =>
                  getCSV({
                    variables: { ...props.variables, csvDownload: true },
                  })
                }
                loading={loading}
                color="primary"
              >
                Get CSV Link
              </Button>
              <Button
                onClick={() =>
                  uploadToDrive({
                    variables: { ...props.variables, csvDownload: true },
                  })
                }
                loading={driveDataLoading}
                color="primary"
              >
                Save to Drive
              </Button>
            </Button.List>
            {!loading &&
              data &&
              data.Watch &&
              data.Watch.adminGetMany &&
              data.Watch.adminGetMany.csvURL && (
                <Button
                  RootComponent="a"
                  href={data.Watch.adminGetMany.csvURL}
                  icon="download"
                >
                  Download CSV
                </Button>
              )}
            <br />
          </div>
        )}
        <Grid.Col md={3}>
          <Alert type="info">Result Count - {total}</Alert>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row cards deck>
        <TableModal
          handleModal={handleModal}
          showModal={showModal}
          onSave={handleSave}
          property={editProperty}
          errorMessage={errorMessage}
        />
        <ServerSidePagination
          data={propertyPagination}
          total={total}
          onPageNum={props.onPageNumberChange}
        >
          <TableCard
            loading={dataLoading}
            data={baseProperties}
            tableData={tableData}
            onHeaderClick={handleSortingClick}
          />
        </ServerSidePagination>
      </Grid.Row>
      <Grid.Row className="justify-content-end">
        {total ? (
          <Grid.Col md={3}>
            <Alert type="info">Result Count - {total}</Alert>
          </Grid.Col>
        ) : null}
      </Grid.Row>
    </Grid>
  );
};

export default WatchProperties;
