import React, { useState, Fragment } from "react";
import { FREE_TOOLS } from "../../graphql/queries";
import FreeTools from ".";
import { useQuery } from "@apollo/react-hooks";

const FreeToolsQueries = () => {
  const [filterDataState, setFilterDataState] = useState("");
  const [filterBySocketId, setFilterBySocketId] = useState("");
  const [filterBySource, setFilterBySource] = useState("");
  const [filterByStatus, setFilterByStatus] = useState("");
  const [pageNumberState, setPageNumberState] = useState(1);
  const [toolName, setToolName] = useState("");
  const [sourceName, setSourceName] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [filterHexUsers, setFilterHexUsers] = useState(true);
  const [orderBy, setOrderBy] = useState("");
  const [orderByASC, setOrderByASC] = useState(false);
  const [hideHexData, setHideHexData] = useState(true);

  const { data, loading } = useQuery(FREE_TOOLS, {
    fetchPolicy: "no-cache",
    variables: {
      settings: {},
      filter: {
        from: fromDate,
        to: toDate,
        toolName: toolName,
        requestSource: sourceName,
        status: filterByStatus,
        source: filterBySource,
        ip: filterDataState,
        socketId: filterBySocketId,
        hideHex: hideHexData,
      },
      sortSettings: {
        sortField: orderBy,
        sortDir: orderByASC ? "ASC" : "DESC",
      },
      pageSettings: {
        page: pageNumberState,
        pageSize: 100,
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const handleFilterDataChange = (filterData: string) =>
    setFilterDataState(filterData);

  const handleFilterBySocketId = (filterData: string) =>
    setFilterBySocketId(filterData);
  const handleFilterBySource = (filterData: string) =>
    setFilterBySource(filterData);
  const handleFilterByStatus = (filterData: string) =>
    setFilterByStatus(filterData);
  const handlePageNumberChange = (pageNumber: number) =>
    setPageNumberState(pageNumber);
  const handleProductSelect = (toolName: string) => setToolName(toolName);
  const handleSourceSelect = (sourceName: string) => setSourceName(sourceName);
  const handleFromDate = (date: string) => setFromDate(date);
  const handleToDate = (date: string) => setToDate(date);

  return (
    <Fragment>
      <FreeTools
        data={data}
        loading={loading}
        onFilterDataChange={handleFilterDataChange}
        onFilterBySocketId={handleFilterBySocketId}
        onFilterBySource={handleFilterBySource}
        onFilterByStatus={handleFilterByStatus}
        onPageNumberChange={handlePageNumberChange}
        onToolSelect={handleProductSelect}
        onSourceSelect={handleSourceSelect}
        onFromDate={handleFromDate}
        onToDate={handleToDate}
        filterHexScans={filterHexUsers}
        setOrderBy={setOrderBy}
        setOrderByASC={setOrderByASC}
        setHideHex={setHideHexData}
        hideHex={hideHexData}
        orderByASC={orderByASC}
        variables={{
          filter: {
            from: fromDate,
            to: toDate,
            toolName: toolName,
            status: filterByStatus,
            source: filterBySource,
            ip: filterDataState,
            socketId: filterBySocketId,
            hideHex: hideHexData,
          },
          sortSettings: {
            sortField: orderBy,
            sortDir: orderByASC ? "ASC" : "DESC",
          },
          pageSettings: {
            page: pageNumberState,
            pageSize: 100,
          },
        }}
      />
    </Fragment>
  );
};

export default FreeToolsQueries;
