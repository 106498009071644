import React, { useState, useEffect } from "react";
import {
  CREATE_AUTOMATION_CATEGORIES,
  DELETE_AUTOMATION_CATEGORY,
  UPDATE_AUTOMATION_CATEGORY,
} from "../../graphql/mutations";
import { GET_AUTOMATION_CATEGORIES, } from "../../graphql/queries";
import AutomationCategories from "./index";
import { useQuery, useMutation } from "@apollo/react-hooks";

const AutomationCategoriesQueries = () => {
  const [categoryList, setCategoryList] = useState<any>([]);
  const [pageNumberState, setPageNumberState] = useState(1);
  const handlePageNumberChange = (pageNumber: number) => setPageNumberState(pageNumber);
  const [searchEmail, setsearchEmail] = useState<string>('')


  const { data, loading, refetch } = useQuery(GET_AUTOMATION_CATEGORIES, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      settings: {
        filter: {
          limit: 100
        }
      }
    }
  })

  useEffect(() => {
    refetch()
  }, [pageNumberState])

  useEffect(() => {
    setCategoryList(data)
  }, [data])

  useEffect(() => {
    refetchData()
  }, [searchEmail])

  const refetchData = async () => {
    await refetch()
  }

  const [deleteCategory] = useMutation(DELETE_AUTOMATION_CATEGORY)

  const [updateCategory] = useMutation(UPDATE_AUTOMATION_CATEGORY)



  const [createAutomationCategory] = useMutation(CREATE_AUTOMATION_CATEGORIES, {
    onCompleted: () => {
      refetchData();
    }
  })

  return (
    <AutomationCategories
      data={categoryList}
      loading={loading}
      searchEmail={searchEmail}
      setsearchEmail={setsearchEmail}
      onPageNumberChange={handlePageNumberChange}
      createAutomationCategory={createAutomationCategory}
      deleteCategory={deleteCategory}
      updateCategory={updateCategory}
    />
  )
};


export default AutomationCategoriesQueries;
