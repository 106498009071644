import React, { useState, useEffect } from 'react';
import { Grid, Button, Alert, Icon, Form } from 'tabler-react';
import moment from 'moment';
import { PromocodeProps, ContentTextProps } from "../../types";
import ServerSidePagination from "../../components/pagination/serverSidePagination";
import TableCard from "../../components/tableCard";
import DatePicker from 'react-datepicker';
import TableModal from './modal';
import { useQuery } from '@apollo/react-hooks';
import { PROMOCODE_ACTIVATION_CHART } from '../../graphql/queries';
import Chart from '../../components/chart';
import { getCountArr } from '../../helpers';


const PromoCodes = ({ refetchData, createPromoCode, cancelPromoCode, ...props }: PromocodeProps): JSX.Element => {

  const [basePromoCodes, setBasePromoCodes] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [promoCodePagination, setPromoCodesPagination] = useState([]);
  const [total, setTotal] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [mutationCompleted, setMutationCompleted] = useState(false);
  const [filterInput, setFilterInput] = useState('');
  const [createdFromDate, setCreatedFromDate] = useState(null);
  const [createdToDate, setCreatedToDate] = useState(null);
  const [activatedFrom, setActivatedFrom] = useState(null);
  const [activatedTo, setActivatedTo] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [status, setStatus] = useState('');

  const chartDataQuery = useQuery(PROMOCODE_ACTIVATION_CHART, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  })

  const promoCodeStatus = ['all', 'activated', "not activated"];

  const tableData = {
    tData: basePromoCodes && basePromoCodes.map((item) => (
      {
        "ID": item.id,
        "Code": item.code,
        "Target Package": item.targetPackage,
        "Target Extra": item.targetExtra,
        "Target Value": item.targetValue,
        "Created At": moment(item.createdAt).utc().format('DD-MM-YYYY hh:mm:ss a'),
        "Activated": item.activated ? 'Yes' : 'No',
        "Activated At": item.activated && moment(item.activatedAt).utc().format('DD-MM-YYYY hh:mm:ss a'),
        "Expire At": item.expireAt && moment(item.expireAt).utc().format('DD-MM-YYYY hh:mm:ss a'),
        "Used Times": item.usedTimes,
        "Canceled": item.canceled ? "Yes" : "No",
        "User": item.user && item.user.email,
        "Action": !item.canceled &&
          <Button color="danger" onClick={() => handlePromoCodeCancel(item.code)}>Cancel</Button>

      }
    ))
  };

  useEffect(() => {
    if (props.data && props.data.PromoCode && props.data.PromoCode.adminGetMany && props.data.PromoCode.adminGetMany.promocodes) {
      setDataLoading(props.loading);
      setBasePromoCodes(props.data.PromoCode.adminGetMany.promocodes);
      setPromoCodesPagination(props.data.PromoCode.adminGetMany.promocodes);
      setTotal(props.data.PromoCode.adminGetMany.total);
    }
  }, [props.data]);

  const handleInputChange = (e: React.FormEvent) => setFilterInput((e.target as HTMLInputElement).value);

  const handleSelectStatus = (e: React.FormEvent) => setStatus((e.target as HTMLInputElement).value)

  const handleCreatedFromDate = (date: Date) => {
    if (date) {
      return setCreatedFromDate(moment(date).format("YYYY-MM-DDT00:00:00.000"));
    }
    return setCreatedFromDate(null);
  }

  const handleCreatedToDate = (date: Date) => {
    if (date) {
      return setCreatedToDate(moment(date).format("YYYY-MM-DDT23:59:59.999"));
    }
    return setCreatedToDate(null)
  }

  const handleActivatedFromDate = (date: Date) => {
    if (date) {
      return setActivatedFrom(moment(date).format("YYYY-MM-DDT00:00:00.000"));
    }
    return setActivatedFrom(null);
  }

  const handleActivatedToDate = (date: Date) => {
    if (date) {
      return setActivatedTo(moment(date).format("YYYY-MM-DDT23:59:59.999"));
    }
    return setActivatedTo(null);
  }

  const handleSubmit = (event: React.FormEvent, input: string, promoCodeStatus: string, startDate: string, endDate: string, activatedFromDate: string, activatedToDate: string) => {
    event.preventDefault();
    props.onFilterDataChange(input.trim());
    props.onFromDate(startDate);
    props.onToDate(endDate);
    props.onActivatedFrom(activatedFromDate);
    props.onActivatedTo(activatedToDate)
    if (promoCodeStatus === 'all') {
      props.onSelectPromoCodeStatus(undefined);
    }
    if (promoCodeStatus === "activated") {
      props.onSelectPromoCodeStatus(true)
    }
    if (promoCodeStatus === "not activated") {
      props.onSelectPromoCodeStatus(false)
    }
  };

  const handlePromoCodeMutate = (row: object | null) => {
    setShowModal(!showModal);
  };

  const handlePromoCodeCancel = async (code: string) => {
    const cancelConfirm = confirm("Are you sure you want to cancel promoCode?");
    if (cancelConfirm) {
      const cancelResponse = await cancelPromoCode({
        variables: { code: code },
      });
      const error = cancelResponse.data.PromoCodeOps.invalidate.error;
      const message = cancelResponse.data.PromoCodeOps.invalidate.message;
      if (error) {
        setErrorMessage(message);
      } else {
        setMutationCompleted(true);
        refetchData();
      }
    }
  }

  const handleModal = () => {
    if (showModal) {
      setErrorMessage("");
    }
    setShowModal(!showModal);
  };

  const handleSave = async ({ ...rest }) => {

    const createResponse = await createPromoCode({
      variables: {
        targetPackage: rest.targetPackage,
        targetValue: rest.targetValue,
        expireAt: rest.expireDate,
        targetExtra: rest.tergetExtra,
        prefix: rest.prefix
      },
    });

    const error = createResponse.data.PromoCodeOps.create.error;
    const message = createResponse.data.PromoCodeOps.create.message;
    if (error) {
      setErrorMessage(message);
      return false;
    } else {
      setMutationCompleted(true);
      refetchData();
    }
    return true;
  };

  const handleSortingClick = (column: string) => {
    if (column === 'Created At') {
      props.setOrderBy('createdAt');
      props.setOrderByASC(!props.orderByASC);
    }

    if (column === "Activated At") {
      props.setOrderBy('activatedAt');
      props.setOrderByASC(!props.orderByASC);
    }

    if (column === "Expire At") {
      props.setOrderBy('expireAt');
      props.setOrderByASC(!props.orderByASC);
    }
  };

  return (
    <Grid>
      <Grid.Row>
      <div className='col-12 col-md-8'>
          {!chartDataQuery.loading && chartDataQuery.data && chartDataQuery.data.PromoCode && chartDataQuery.data.PromoCode.activationsChart &&
            <Chart
              yColName="PromoCode Activations"
              regData={chartDataQuery.data.PromoCode.activationsChart}
              interval={getCountArr(chartDataQuery.data.PromoCode.activationsChart.length)}
              chartName="Daily Activated PromoCodes"
            />
          }
        </div>
        <div className='col-12 col-md-4'>
          <form onSubmit={(e) =>
            handleSubmit(e, filterInput, status, createdFromDate, createdToDate, activatedFrom, activatedTo)
          }>
            <Form.Group>
              <Form.Input
                label="Input"
                className="margin-bottom"
                icon="search"
                position="append"
                placeholder="Search for Code, User Email, User Name"
                value={filterInput}
                onChange={handleInputChange}
              />
              <Form.Select onChange={handleSelectStatus} label="Status">
                {promoCodeStatus.map(el => (
                  <option
                    key={el}
                    value={el}
                  >
                    {el}
                  </option>
                ))}
              </Form.Select>
              <Grid.Row>
                <Grid.Col>
                  <Form.Group label="Created From Date">
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      selected={createdFromDate ? new Date(createdFromDate) : null}
                      onChange={handleCreatedFromDate}
                      showMonthDropdown
                      placeholderText="Select a date"
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col>
                  <Form.Group label="Created To Date">
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      selected={createdToDate ? new Date(createdToDate) : null}
                      onChange={handleCreatedToDate}
                      showMonthDropdown
                      placeholderText="Select a date"
                      minDate={createdFromDate && new Date(createdFromDate)}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col>
                  <Form.Group label="Activated From Date">
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      selected={activatedFrom ? new Date(activatedFrom) : null}
                      onChange={handleActivatedFromDate}
                      showMonthDropdown
                      placeholderText="Select a date"
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col>
                  <Form.Group label="Activated To Date">
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      selected={activatedTo ? new Date(activatedTo) : null}
                      onChange={handleActivatedToDate}
                      showMonthDropdown
                      placeholderText="Select a date"
                      minDate={activatedFrom && new Date(activatedFrom)}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
              <Button
                className="margin-bottom"
                type="submit"
                icon="filter"
                color="primary"
              >
                Filter
            </Button>
            </Form.Group>
          </form>
        </div>
      </Grid.Row>
      <Grid.Row className='justify-content-between'>
        <Grid.Col md={3}>
          <Button className="float-left" onClick={() => handlePromoCodeMutate(null)} color="primary">Create</Button>
        </Grid.Col>
        <Grid.Col md={3}>
          <Alert type="info">
            Result Count - {total}
          </Alert>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row cards deck>
        <TableModal
          handleModal={handleModal}
          showModal={showModal}
          onSave={handleSave}
          errorMessage={errorMessage}
        />
        <ServerSidePagination
          data={promoCodePagination}
          total={total}
          onPageNum={props.onPageNumberChange}
        >
          <TableCard
            loading={dataLoading}
            data={basePromoCodes}
            tableData={tableData}
            onHeaderClick={handleSortingClick}
          />
        </ServerSidePagination>
      </Grid.Row>
      <Grid.Row className="justify-content-end">
        {total ? <Grid.Col md={3}>
          <Alert type="info">
            Result Count - {total}
          </Alert>
        </Grid.Col> : null}
      </Grid.Row>
    </Grid>
  )
};

export default PromoCodes;