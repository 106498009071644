import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import App from './App';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks'
import GraphQLClient from './graphql';
import { createBrowserHistory } from "history";

import { MainComponentProps } from "./types";

const history = createBrowserHistory();
class Main extends Component<MainComponentProps> {
  render() {
    return (
      <ApolloProvider client={GraphQLClient}>
        <ApolloHooksProvider client={GraphQLClient}>
          <Router history={history}>
            <App />
          </Router>
        </ApolloHooksProvider>
      </ApolloProvider>
    );
  }
}

export default Main;
