import React, { useEffect, useState } from "react";
import { Alert, Button, Form } from "tabler-react";
import ReactModal from "react-modal";
import { TableModalProps } from "../../types";
import ReactMde from "react-mde";
import * as Showdown from "showdown";
import "react-mde/lib/styles/css/react-mde-all.css";

ReactModal.setAppElement('#root');

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    height: '50%'
  }
};

const TableModal = (
  { handleModal, showModal, onSave, editEmail, errorMessage }: TableModalProps
): JSX.Element => {

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [text, setText] = useState("");

  const handleOk = async () => {
    setLoading(true);
    const formData = { email };
    const completed = await onSave({ ...formData });
    setLoading(false);
    if (completed) handleCancel();
  };

  const handleCancel = () => {
    setEmail("");
    handleModal();
  };

  useEffect(() => {
    if (editEmail) {
      setEmail(editEmail.email);
      setText(editEmail.value);
    }
  }, [editEmail]);

  useEffect(() => {
    if(window.innerWidth < 640) {
      customStyles.content.width = '90%'
    }
  }, [])

  const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true
  });

  const handleEmailChange = (e: React.FormEvent) => setEmail((e.target as HTMLInputElement).value.trim());

  return (
    <div style={{ height: 0 }}>
      <ReactModal
        isOpen={showModal}
        contentLabel="Create Email"
        style={customStyles}
        onRequestClose={handleCancel}
      >
        <h4>{editEmail ? "Edit Email" : "Create Email"}</h4>
        {errorMessage ?
          <Alert type="danger">
            {errorMessage}
          </Alert> : null}
        <Form onSubmit={(e: React.FormEvent) => e.preventDefault()}>
          <Form.Input
            required
            type="text"
            placeholder='email'
            label="email"
            value={email}
            onChange={handleEmailChange}
          />
          <Button.List style={{ marginTop: "5%" }}>
            <Button onClick={handleOk} color="primary" type="submit" loading={loading}>Save</Button>
            <Button onClick={handleCancel} color="secondary" type="button">Cancel</Button>
          </Button.List>
        </Form>
      </ReactModal>
    </div>
  )
};

export default TableModal;
