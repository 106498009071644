import React, { useState, FC } from "react";
import HexosparkMonthlyReport from "./monthlyReport.queries";

const HexosparkMonthlyReportQueries: FC = () => {
  const [orderByASC, setOrderByASC] = useState(false);
  const [filterHexScans, setFilterHexScans] = useState(true);

  const [searchVal, setSearchVal] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [toDate, setToDate] = useState("");
  const handleToDate = (date: string) => setToDate(date);

  return (
    <HexosparkMonthlyReport
      // data={data}
      // loading={loading}
      // refetchData={refetch}
      // onFilterSearch={handleSearchValue}
      setOrderByASC={setOrderByASC}
      orderByASC={orderByASC}
      onToDate={handleToDate}
      setOrderBy={setOrderBy}
      setFilterHexScans={setFilterHexScans}
      filterHexScans={filterHexScans}
      variables={{
        settings: {
          settings: {
            accountSearchInput: searchVal,
            sortField: orderBy,
            sortDir: orderByASC ? "ASC" : "DESC",
            to: toDate,
          },
        },
      }}
    />
  );
};

export default HexosparkMonthlyReportQueries;
