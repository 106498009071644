import React, { useEffect, useState } from "react";
import { Alert, Button, Form } from "tabler-react";
import ReactModal from "react-modal";
import { TableModalProps } from "../../types";
import ReactMde from "react-mde";
import * as Showdown from "showdown";
import "react-mde/lib/styles/css/react-mde-all.css";

ReactModal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    height: "50%",
  },
};

const TableModal = ({
  handleModal,
  showModal,
  onSave,
  onUpdate,
  editField,
  errorMessage,
}: any): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [text, setText] = useState("");

  const handleOk = async () => {
    setLoading(true);
    if (editField) {
      await onUpdate({ id: editField.id, name: name });
      setLoading(false);
      return;
    }
    const formData = { name };
    const completed = await onSave({ ...formData });
    setLoading(false);
    // if (completed) handleCancel();
  };

  const handleCancel = () => {
    setName("");
    handleModal();
  };

  useEffect(() => {
    if (window.innerWidth < 640) {
      customStyles.content.width = "90%";
    }
  }, []);

  useEffect(() => {
    if (editField) {
      setName(editField.name);
    }
  }, [editField]);

  const handleEmailChange = (e: React.FormEvent) =>
    setName((e.target as HTMLInputElement).value);

  return (
    <div style={{ height: 0 }}>
      <ReactModal
        isOpen={showModal}
        contentLabel="Create input/output type"
        style={customStyles}
        onRequestClose={handleCancel}
      >
        <h4>{editField ? "Edit category name" : "Create input/output type"}</h4>
        {errorMessage ? <Alert type="danger">{errorMessage}</Alert> : null}
        <Form onSubmit={(e: React.FormEvent) => e.preventDefault()}>
          <Form.Input
            required
            type="text"
            placeholder="type"
            label="Input/output type"
            value={name}
            onChange={handleEmailChange}
          />
          <Button.List style={{ marginTop: "5%" }}>
            <Button
              onClick={handleOk}
              color="primary"
              type="submit"
              loading={loading}
            >
              Save
            </Button>
            <Button onClick={handleCancel} color="secondary" type="button">
              Cancel
            </Button>
          </Button.List>
        </Form>
      </ReactModal>
      {errorMessage ? <Alert type="danger">{errorMessage}</Alert> : null}
    </div>
  );
};

export default TableModal;
