import React, { useState, useEffect } from "react";
import { Grid, Button, Alert, Form } from "tabler-react";
import { AdditionalEmailProps } from "../../types";
import ServerSidePagination from "../../components/pagination/serverSidePagination";
import TableCard from "../../components/tableCard";
import { useLazyQuery } from "@apollo/react-hooks";
import { GET_ADDITIONAL_EMAILS } from "../../graphql/queries";
import "./style.css";

const types = ["hexometer", "hexowatch"];

const Additional = ({
  refetchData,
  ...props
}: AdditionalEmailProps): JSX.Element => {
  const [emailDatadata, setData] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [pagination, setpagination] = useState([]);
  const [total, setTotal] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [type, setType] = useState("hexometer");
  const showButtons = localStorage.getItem("_rep");

  const [getCSV, { data: CSVData, loading: CSVLoading }] = useLazyQuery(
    GET_ADDITIONAL_EMAILS
  );

  const tableData = {
    tData:
      emailDatadata && type === "hexometer"
        ? emailDatadata.map((item) => ({
            "User ID": item.user_id,
            "Prorperty ID": item.property_id,
            Email: item.email,
          }))
        : emailDatadata.map((item) => ({
            "User ID": item.user_id,
            Email: item.email,
          })),
  };

  useEffect(() => {
    if (
      props.data &&
      props.data.Admin &&
      props.data.Admin &&
      props.data.Admin.getNotificationEmails.hexometer_emails
    ) {
      setDataLoading(props.loading);
      setData(props.data.Admin.getNotificationEmails.hexometer_emails);
      setpagination(props.data.Admin.getNotificationEmails.hexometer_emails);
      setTotal(props.data.Admin.getNotificationEmails.count);
    }
    if (
      props.data &&
      props.data.Admin &&
      props.data.Admin &&
      props.data.Admin.getNotificationEmails.hexowatch_emails
    ) {
      setDataLoading(props.loading);
      setData(props.data.Admin.getNotificationEmails.hexowatch_emails);
      setpagination(props.data.Admin.getNotificationEmails.hexowatch_emails);
      setTotal(props.data.Admin.getNotificationEmails.count);
    }
  }, [props.data]);

  const handleSelectStatus = (e: React.FormEvent) => {
    setType((e.target as HTMLInputElement).value);
    props.onSelectType((e.target as HTMLInputElement).value);
  };

  const handleSubmit = (event, status) => {
    event.preventDefault();
  };

  // const onFilter = () => {
  //   refetchData()
  // }

  return (
    <Grid>
      <Grid.Row>
        <div className="col-12 col-md-8"></div>
        <div className="col-12 col-md-4">
          <form onSubmit={(e) => handleSubmit(e, status)}>
            <Form.Group>
              <Form.Select onChange={handleSelectStatus} label="select project">
                {types.map((el) => (
                  <option key={el} value={el}>
                    {el}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </form>
        </div>
      </Grid.Row>

      <div className={showButtons === 'true' ? "justify-content-between" : "justify-content-end"}>
        {showButtons === 'true' && (
          <div className="col-12 col-md-3 col-xs-12">
            <Button.List>
              <Button
                onClick={() =>
                  getCSV({
                    variables: {
                      settings: { csv_download_link: true, type: type },
                    },
                  })
                }
                loading={CSVLoading}
                color="primary"
                mr={1}
              >
                Get CSV Link
              </Button>
              {!CSVLoading &&
                CSVData &&
                CSVData.Admin &&
                CSVData.Admin.getNotificationEmails &&
                CSVData.Admin.getNotificationEmails.csv_url && (
                  <Button
                    RootComponent="a"
                    href={CSVData.Admin.getNotificationEmails.csv_url}
                    icon="download"
                  >
                    Download CSV
                  </Button>
                )}
            </Button.List>
            <br />
          </div>
        )}
        <div className="col-12 col-md-2 col-xs-12">
          <Alert type="info">Result Count - {total}</Alert>
        </div>
      </div>
      <Grid.Row cards deck>
        <ServerSidePagination
          data={pagination}
          total={total}
          onPageNum={props.onPageNumberChange}
        >
          <TableCard
            loading={dataLoading}
            data={emailDatadata}
            tableData={tableData}
            onHeaderClick={() => {}}
          />
        </ServerSidePagination>
      </Grid.Row>
      <Grid.Row className="justify-content-end">
        {total ? (
          <Grid.Col md={3}>
            <Alert type="info">Result Count - {total}</Alert>
          </Grid.Col>
        ) : null}
      </Grid.Row>
    </Grid>
  );
};

export default Additional;
