import React, { FC, useState, useEffect, useMemo, useReducer } from "react";
import { Grid, Button, Form, Alert, Loader } from "tabler-react";
import moment from "moment";
import ServerSidePagination from "../../components/pagination/serverSidePagination";
import DatePicker from "react-datepicker";

import TableCard from "../../components/tableCard";
import DataList from "../../components/dataList";
import CreditUsageLineCharts from "./creditUsageLineCharts";

// import { isProduction } from "../../helpers";

import "./index.css";
import { useLazyQuery } from "@apollo/react-hooks";
import { GET_AUTOMATIONS_CREDIT_USAGES_DATA } from "../../graphql/queries";
import { isProduction } from "../../helpers";

interface CreditUsageProps {
  automationNames: { id: number; name: string }[];
  finalCreditData: {
    id: number;
    name: string;
    usedCredits: number;
    usedPremiumCredits: number;
  }[];
  timeSeries: { _id: string; time: Date; credit: { [key: string]: number } }[];
  timePremiumSeries: {
    _id: string;
    time: Date;
    premium_credit: { [key: string]: number };
  }[];
  loading: boolean;
  setIncludedAutomationIds: (arg: string[]) => void;
  onPageNumberChange: (pageNumber: number) => void;
  refetchData: () => void;
  onFromDate: (date: string) => void;
  onToDate: (date: string) => void;
  setOrderByASC: (isASC: boolean) => void;
  orderByASC: boolean;
  setOrderBy: (column: string) => void | Promise<void>;
  orderBy: string;
  setFilterHexUsers: (arg: boolean) => void;
  filterHexUsers: boolean;
}

const CreditUsage: FC<CreditUsageProps> = ({
  refetchData,
  onPageNumberChange,
  automationNames,
  finalCreditData,
  timeSeries,
  timePremiumSeries,
  loading,
  setIncludedAutomationIds,
  onFromDate,
  onToDate,
  setOrderByASC,
  orderByASC,
  setOrderBy,
  orderBy,
  setFilterHexUsers,
  filterHexUsers,
}) => {
  const [dataLoading, setDataLoading] = useState(false);
  const showButtons = localStorage.getItem("_rep");
  // const [notificationsPagination, setNotificationsPagination] = useState([]);
  // const [total, setTotal] = useState(0);
  const [fromDate, setFromDate] = useState(
    moment(new Date().setDate(new Date().getDate() - 7)).format("YYYY-MM-DDT00:00:00.000")
  );
  const [toDate, setToDate] = useState(moment(new Date().toUTCString()).format("YYYY-MM-DDT23:59:59.999"));
  const [automationName, setAutomationName] = useState("");

  const [automationId, setAutomationId] = useState<string[]>([]);

  const [getCSV, { data, loading: csvLoading }] = useLazyQuery(GET_AUTOMATIONS_CREDIT_USAGES_DATA);
  const [uploadToDrive, { data: driveData, loading: driveDataLoading }] = useLazyQuery(
    GET_AUTOMATIONS_CREDIT_USAGES_DATA
  );

  const tableData = {
    tData:
      finalCreditData &&
      finalCreditData.map((item) => ({
        // Date: moment(item.date).utc().format("DD-MM-YYYY"),
        "Automation ID": item.id,
        "Automation Name": item.name,
        Credit: +item.usedCredits.toFixed(2),
        "Premium Credit": +item.usedPremiumCredits.toFixed(2),
      })),
  };

  useEffect(() => {
    setDataLoading(loading);

    // setNotificationsPagination(
    // data.HexomaticAutomation.getAutomationCreditUsageInfo.notifications
    // );
    // setTotal(data.HexomaticAutomation.getAutomationCreditUsageInfo.total);
  }, [loading]);

  const automationNameList = useMemo(() => {
    if (automationNames.length) {
      return automationNames.map((elem: { id: number; name: string }) => {
        return {
          label: elem.name,
          value: String(elem.id),
        };
      });
    }
  }, [automationNames]);

  useEffect(() => {
    if (automationNameList && automationNameList.length) {
      const nameObj = automationNameList.find(
        (el: { label: string; value: string }) => el.label === automationName
      );
      if (nameObj) {
        setAutomationId([nameObj.value]);
        return;
      }
      setAutomationId([]);
    }
  }, [automationName, automationNameList]);

  useEffect(() => {
    if (
      driveData &&
      driveData.HexomaticAutomation &&
      driveData.HexomaticAutomation.getAutomationCreditUsageInfo &&
      driveData.HexomaticAutomation.getAutomationCreditUsageInfo.csvURL
    ) {
      window.open(
        isProduction()
          ? `https://api.hexometer.com/v2/app/social-callback/googleDrive?admin=admin&report=automationCreditsUsage&url=${driveData.HexomaticAutomation.getAutomationCreditUsageInfo.csvURL}`
          : `http://localhost:4000/api/app/social-callback/googleDrive?admin=admin&report=automationCreditsUsage&url=${driveData.HexomaticAutomation.getAutomationCreditUsageInfo.csvURL}`,
        "_blank"
      );
    }
  }, [driveData]);

  const handleFromDate = (date: Date) => {
    if (date) {
      return setFromDate(moment(date).format("YYYY-MM-DDT00:00:00.000"));
    }
    return setFromDate(null);
  };

  const handleToDate = (date: Date) => {
    if (date) {
      return setToDate(moment(date).format("YYYY-MM-DDT23:59:59.999"));
    }
    return setToDate(null);
  };

  const handleSubmit = (event: React.FormEvent, startDate: string, endDate: string) => {
    event.preventDefault();
    onFromDate(startDate);
    onToDate(endDate);
    setIncludedAutomationIds(automationId);
  };

  const handleSortingClick = (column: string) => {
    if (column.toLocaleLowerCase() === "credit") {
      setOrderBy("credit");
      setOrderByASC(!orderByASC);
      localStorage.setItem("credits-usage-sort-by", "credit");
      localStorage.setItem("credits-usage-sort-asc", orderByASC ? "asc" : "");
    } else if (column.toLocaleLowerCase() === "premium credit") {
      setOrderBy("premium credit");
      setOrderByASC(!orderByASC);
      localStorage.setItem("credits-usage-sort-by", "premium credit");
      localStorage.setItem("credits-usage-sort-asc", orderByASC ? "asc" : "");
    }
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Col md={12} className="d-flex">
          <Form.Checkbox
            label="Filter Hexact usage"
            checked={filterHexUsers}
            onChange={setFilterHexUsers}
            disabled={loading}
          />
          {loading && <Loader className="loader-small" />}
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <div className="col-12">
          <CreditUsageLineCharts timeSeries={timeSeries} timePremiumSeries={timePremiumSeries} />
        </div>
      </Grid.Row>
      <Grid.Row>
        <div className="col-12 d-flex">
          <form onSubmit={(e) => handleSubmit(e, fromDate, toDate)}>
            <Form.Group className="d-flex">
              <Form.Group label="Automation" className="mr-2">
                <DataList
                  list={automationNameList}
                  value={automationName}
                  placeholder="Select automation name"
                  onChange={(e: { target: { value: string } }) => {
                    setAutomationName(typeof e === "string" ? e : e.target.value);
                  }}
                />
              </Form.Group>

              <Form.Group label="From Date" className="mr-2">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={fromDate ? new Date(fromDate) : null}
                  onChange={handleFromDate}
                  showMonthDropdown
                  placeholderText="Select a date"
                />
              </Form.Group>
              <Form.Group label="To Date" className="mr-2">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={toDate ? new Date(toDate) : null}
                  onChange={handleToDate}
                  showMonthDropdown
                  placeholderText="Select a date"
                  minDate={fromDate && new Date(fromDate)}
                />
              </Form.Group>
              <Button
                className="usage-filter-btn"
                type="submit"
                icon="filter"
                color="primary"
                loading={loading}
              >
                Filter
              </Button>
            </Form.Group>
          </form>
        </div>
      </Grid.Row>
      <Grid.Row className={showButtons === "true" ? "justify-content-between" : "justify-content-end"}>
        {showButtons === "true" && (
          <div className="col-12 col-md-3 col-xs-12">
            <Button.List>
              <Button
                onClick={() =>
                  getCSV({
                    variables: {
                      settings: {
                        filter: {
                          from: fromDate,
                          to: toDate,
                          includedAutomationIds: automationId,
                          hideHex: filterHexUsers,
                          csvDownload: true,
                        },
                      },
                    },
                  })
                }
                loading={csvLoading}
                color="primary"
                mr={1}
              >
                Get CSV Link
              </Button>
              <Button
                onClick={() =>
                  uploadToDrive({
                    variables: {
                      settings: {
                        filter: {
                          from: fromDate,
                          to: toDate,
                          includedAutomationIds: automationId,
                          hideHex: filterHexUsers,
                          csvDownload: true,
                        },
                      },
                    },
                  })
                }
                loading={driveDataLoading}
                color="primary"
              >
                Save to Drive
              </Button>
            </Button.List>
            {!loading &&
              data &&
              data.HexomaticAutomation &&
              data.HexomaticAutomation.getAutomationCreditUsageInfo &&
              data.HexomaticAutomation.getAutomationCreditUsageInfo.csvURL && (
                <Button
                  RootComponent="a"
                  href={data.HexomaticAutomation.getAutomationCreditUsageInfo.csvURL}
                  icon="download"
                >
                  Download CSV
                </Button>
              )}
            <br />
          </div>
        )}
        <Grid.Col md={3}>
          <Alert type="info">Result Count - {finalCreditData && finalCreditData.length}</Alert>
        </Grid.Col>
      </Grid.Row>

      <Grid.Row cards deck>
        {/* //! here is pagination  */}
        {/* <ServerSidePagination
          data={notificationsPagination}
          total={total}
          onPageNum={onPageNumberChange}
        > */}
        <TableCard
          loading={dataLoading}
          data={tableData}
          tableData={tableData}
          onHeaderClick={handleSortingClick}
          orderByASC={orderByASC}
          orderBy={orderBy}
        />
        {/* </ServerSidePagination> */}
      </Grid.Row>
      {/* <Grid.Row className="justify-content-end">
        {total ? (
          <Grid.Col md={3}>
            <Alert type="info">Result Count - {total}</Alert>
          </Grid.Col>
        ) : null}
      </Grid.Row> */}
    </Grid>
  );
};

export default CreditUsage;
