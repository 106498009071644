import React, { useState, useEffect } from "react";
import { GET_SUBSCRIBED_EMAIL_LIST } from "../../graphql/queries";
import SubscribedEmail from "./index";
import { useQuery } from "@apollo/react-hooks";

const BlackListEmailQueries = () => {
  const [emailList, setEmailList] = useState<any>([]);
  const [pageNumberState, setPageNumberState] = useState(1);
  const handlePageNumberChange = (pageNumber: number) =>
    setPageNumberState(pageNumber);
  const [searchEmail, setsearchEmail] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [emailStatus, setEmailStatus] = useState("");

  const { data, loading, refetch } = useQuery(GET_SUBSCRIBED_EMAIL_LIST, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      settings: {
        page: pageNumberState,
        limit: 100,
        type: type,
        order_by_field: "",
        search_by: searchEmail,
        order_by: "DESC",
        csv_download_link: false,
        send_type_list: true,
        emailStatus: emailStatus,
        subscribedFrom: fromDate,
        subscribedTo: toDate,
      },
    },
  });

  useEffect(() => {
    refetchData();
  }, [pageNumberState]);

  useEffect(() => {
    setEmailList(data);
  }, [data]);

  const refetchData = async () => {
    await refetch();
  };

  const filter = ({ type, email, fromDate, toDate, emailStatus }) => {
    setType(type);
    setsearchEmail(email);
    setFromDate(fromDate);
    setToDate(toDate);
    setEmailStatus(emailStatus);
  };

  return (
    <SubscribedEmail
      data={emailList}
      loading={loading}
      searchEmail={searchEmail}
      onPageNumberChange={handlePageNumberChange}
      type={type}
      filter={filter}
      refetchData={refetchData}
      emailStatus={emailStatus}
      fromDate={fromDate}
      toDate={toDate}
      variables={{
        page: pageNumberState,
        limit: 100,
        type: type,
        order_by_field: "",
        search_by: searchEmail,
        order_by: "DESC",
        csv_download_link: false,
        send_type_list: true,
        emailStatus: emailStatus,
        subscribedFrom: fromDate,
        subscribedTo: toDate,
      }}
    />
  );
};

export default BlackListEmailQueries;
