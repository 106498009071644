import React, { useState, useEffect, FC } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";

import {
  CREATE_SCRAPING_CATEGORY,
  UPDATE_SCRAPING_CATEGORY,
  DELETE_SCRAPING_CATEGORY,
} from "../../graphql/mutations";
import { GET_SCRAPING_CATEGORIES } from "../../graphql/queries";
import ScrapingCategories from "./index";

const ScrapingCategoriesQueries: FC = () => {
  const [categoryList, setCategoryList] = useState<any>([]);
  const [pageNumberState, setPageNumberState] = useState(1);
  const handlePageNumberChange = (pageNumber: number) => setPageNumberState(pageNumber);

  const { data, loading, refetch } = useQuery(GET_SCRAPING_CATEGORIES, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    refetch();
  }, [pageNumberState]);

  useEffect(() => {
    setCategoryList(data);
  }, [data]);

  const [deleteCategory] = useMutation(DELETE_SCRAPING_CATEGORY, {
    onCompleted: () => {
      refetch();
    },
  });

  const [updateCategory] = useMutation(UPDATE_SCRAPING_CATEGORY, {
    onCompleted: () => {
      refetch();
    },
  });

  const [createCategory] = useMutation(CREATE_SCRAPING_CATEGORY, {
    onCompleted: () => {
      refetch();
    },
  });

  return (
    <ScrapingCategories
      data={categoryList}
      loading={loading}
      onPageNumberChange={handlePageNumberChange}
      createCategory={createCategory}
      deleteCategory={deleteCategory}
      updateCategory={updateCategory}
    />
  );
};

export default ScrapingCategoriesQueries;
