import React, { useState, Fragment } from "react";
import { SCAN_REQUEST_LOG } from "../../graphql/queries";
import ScanLogs from "./";
import { Grid, Form } from 'tabler-react';
import { useQuery } from "@apollo/react-hooks";

const ScanLogsQueries = () => {
  const [filterDataState, setFilterDataState] = useState("");
  const [pageNumberState, setPageNumberState] = useState(1);
  const [toolName, setToolName] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [sourceState, setSourceState] = useState<string | undefined>();
  const [filterHexUsers, setFilterHexUsers] = useState(true);
  const [countryCodeState, setCountryCodeState] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [orderByASC, setOrderByASC] = useState(false);
  const { data, loading } = useQuery(SCAN_REQUEST_LOG, {
    variables: {
      input: { regFrom: fromDate, regTo: toDate, pageNumber: pageNumberState, searchQuery: filterDataState, hideHex: filterHexUsers },
      tool: toolName,
      source: sourceState,
      countryCode: countryCodeState,
      orderBy, orderByASC,
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  })

  const handleFilterDataChange = (filterData: string) => setFilterDataState(filterData);
  const handlePageNumberChange = (pageNumber: number) => setPageNumberState(pageNumber);
  const handleToolSelect = (toolName: string) => setToolName(toolName);
  const handleFromDate = (date: string) => setFromDate(date);
  const handleToDate = (date: string) => setToDate(date);
  const handleSource = (src: string | undefined) => setSourceState(src);
  const handleCountryCode = (code: string) => setCountryCodeState(code);

  return (
    <Fragment>
      <Grid.Row>
        <Grid.Col md={2}>
          <Form.Checkbox
            label="Filter Hexact scans"
            checked={filterHexUsers}
            onChange={() => setFilterHexUsers(!filterHexUsers)}
          />
        </Grid.Col>
      </Grid.Row>
      <ScanLogs
        data={data}
        loading={loading}
        onFilterDataChange={handleFilterDataChange}
        onPageNumberChange={handlePageNumberChange}
        onToolSelect={handleToolSelect}
        onFromDate={handleFromDate}
        onToDate={handleToDate}
        source={handleSource}
        filterHexScans={filterHexUsers}
        onCountryCode={handleCountryCode}
        setOrderBy={setOrderBy}
        setOrderByASC={setOrderByASC}
        orderByASC={orderByASC}
        variables={{
          input: {
            regFrom: fromDate,
            regTo: toDate,
            pageNumber: pageNumberState,
            searchQuery: filterDataState,
            hideHex: filterHexUsers
          },
          tool: toolName,
          source: sourceState,
          countryCode: countryCodeState
        }}
      />
    </Fragment>
  )
};

export default ScanLogsQueries;
