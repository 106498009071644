import React, { useState } from 'react';
import PromoCodes from './promoCodes';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_PROMOCODES } from "../../graphql/queries";
import { PROMOCODE_CREATE_MUTATION, PROMOCODE_CANCEL_MUTATION } from '../../graphql/mutations';


const PromoCodesQueries = () => {
  const [pageNumberState, setPageNumberState] = useState(1);
  const [filterDataState, setFilterDataState] = useState("");
  const [statusState, setStatusState] = useState<boolean | undefined>()
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [activatedFrom, setActivatedFrom] = useState('');
  const [activatedTo, setActivatedTo] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [orderByASC, setOrderByASC] = useState(false);

  const { data, loading, refetch } = useQuery(GET_PROMOCODES, {
    variables: {
      input: {
        regFrom: fromDate,
        regTo: toDate,
        pageNumber: pageNumberState,
        searchQuery: filterDataState,
      },
      activated: statusState,
      orderBy, 
      orderByASC,
      activatedFrom,
      activatedTo
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });

  const [createPromoCode] = useMutation(PROMOCODE_CREATE_MUTATION);
  const [cancelPromoCode] = useMutation(PROMOCODE_CANCEL_MUTATION);


  const handlePageNumberChange = (pageNumber: number) => setPageNumberState(pageNumber);
  const handleFilterDataChange = (filterData: string) => setFilterDataState(filterData);
  const handleSelectStatus = (promoCodeStatus: boolean | undefined) => setStatusState(promoCodeStatus);
  const handleFromDate = (date: string) => setFromDate(date);
  const handleToDate = (date: string) => setToDate(date);
  const handleActivatedFrom = (date: string) => setActivatedFrom(date);
  const handleActivatedTo = (date: string) => setActivatedTo(date);

  return (
    <PromoCodes
      data={data}
      loading={loading}
      onPageNumberChange={handlePageNumberChange}
      refetchData={refetch}
      createPromoCode={createPromoCode}
      onFilterDataChange={handleFilterDataChange}
      onSelectPromoCodeStatus={handleSelectStatus}
      onFromDate={handleFromDate}
      onToDate={handleToDate}
      onActivatedFrom={handleActivatedFrom}
      onActivatedTo={handleActivatedTo}
      setOrderByASC={setOrderByASC}
      orderByASC={orderByASC}
      setOrderBy={setOrderBy}
      cancelPromoCode={cancelPromoCode}
    />
  )
};

export default PromoCodesQueries;