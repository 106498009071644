import React, { useState, Fragment } from "react";
import { GET_USER_LOGS, } from "../../graphql/queries";
import { useQuery } from "@apollo/react-hooks";
import UserLogs from ".";
import { Grid, Form } from 'tabler-react';

const UserLogsQueries = () => {
  const [filterDataState, setFilterDataState] = useState("");
  const [pageNumberState, setPageNumberState] = useState(1);
  const [regFrom, setRegFrom] = useState('');
  const [regTo, setRegTo] = useState('');
  const [logFrom, setLogFrom] = useState('');
  const [logTo, setLogTo] = useState('');
  const [hideHexLogins, sethideHexLogins] = useState(true);
  const [action, setAction] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [orderByASC, setOrderByASC] = useState(false);

  const { data, loading } = useQuery(GET_USER_LOGS, {
    variables: {
      input: {
        regFrom: regFrom,
        regTo: regTo,
        pageNumber: pageNumberState,
        searchQuery: filterDataState,
        hideHex: hideHexLogins
      },
      logFrom: logFrom,
      logTo: logTo,
      action,
      orderBy, 
      orderByASC,
      csvDownload: false,
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  })


  const handleFilterDataChange = (filterData: string) => setFilterDataState(filterData);
  const handlePageNumberChange = (pageNumber: number) => setPageNumberState(pageNumber);
  const handleRegistrationsFrom = (date: string) => setRegFrom(date);
  const handleRegistrationsTo = (date: string) => setRegTo(date);
  const handleLoginsFrom = (date: string) => setLogFrom(date);
  const handleLoginsTo = (date: string) => setLogTo(date);
  const handelActionType = (type: string) => setAction(type);

  return (
    <Fragment>
      <Grid.Row>
        <Grid.Col md={2}>
          <Form.Checkbox
            label="Filter Hexact logins"
            checked={hideHexLogins}
            onChange={() => sethideHexLogins(!hideHexLogins)}
          />
        </Grid.Col>
      </Grid.Row>
      <UserLogs
        setActionType={handelActionType}
        data={data}
        loading={loading}
        onFilterDataChange={handleFilterDataChange}
        onPageNumberChange={handlePageNumberChange}
        onRegistrationsFrom={handleRegistrationsFrom}
        onRegistrationsTo={handleRegistrationsTo}
        onLoginsFrom={handleLoginsFrom}
        onLoginsTo={handleLoginsTo}
        hideHexLogins={hideHexLogins}
        orderByASC={orderByASC}
        setOrderBy={setOrderBy}
        setOrderByASC={setOrderByASC}
        variables={{
          input: {
            regFrom: regFrom,
            regTo: regTo,
            pageNumber: pageNumberState,
            searchQuery: filterDataState,
            hideHex: hideHexLogins
          },
          logFrom: logFrom,
          logTo: logTo,
          action,
        }}
      />
    </Fragment>
  )
};

export default UserLogsQueries;