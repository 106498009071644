import React, { useState, FC } from "react";
import { useQuery } from "@apollo/react-hooks";
import { GET_SURVEY_REPORT } from "../../graphql/queries";
import HexomaticSurvey from "./hexomaticSurvey";

const HexomaticSurveyQueries: FC = () => {
  const [pageNumberState, setPageNumberState] = useState(1);
  const [skippedState, setSkippedState] = useState<boolean | null>(null);
  const [categoryState, setCategoryState] = useState<string | null>(null);
  const [filterInputState, setFilterInputState] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [filterHexScans, setFilterHexScans] = useState(true);

  const { data, loading, refetch } = useQuery(GET_SURVEY_REPORT, {
    variables: {
      settings: {
        filter: {
          page: pageNumberState,
          limit: 100,
          searchValue: filterInputState,
          product: "hexomatic",
          fromDate: fromDate,
          toDate: toDate,
          searchField: null,
          skipped: skippedState,
          category: categoryState,
        },
        csvDownload: false,
      },
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const handlePageNumberChange = (pageNumber: number) => setPageNumberState(pageNumber);
  const handleFilterInputChange = (filterInput: string) => setFilterInputState(filterInput);
  const handleSkippedChange = (val: string) => setSkippedState(val === "all" ? null : val === "yes");
  const handleCategoryChange = (val: string) => setCategoryState(val === "All" ? null : val);
  const handleFromDate = (date: string) => setFromDate(date);
  const handleToDate = (date: string) => setToDate(date);

  return (
    <HexomaticSurvey
      data={data}
      loading={loading}
      onPageNumberChange={handlePageNumberChange}
      refetchData={refetch}
      onFilterInputChange={handleFilterInputChange}
      onSkippedSelect={handleSkippedChange}
      onCategorySelect={handleCategoryChange}
      onFromDate={handleFromDate}
      onToDate={handleToDate}
      setFilterHexScans={setFilterHexScans}
      filterHexScans={filterHexScans}
      variables={{
        filter: {
          page: pageNumberState,
          limit: 100,
          searchValue: filterInputState,
          product: "hexomatic",
          fromDate: fromDate,
          toDate: toDate,
          searchField: null,
          skipped: skippedState,
          category: categoryState,
        },
      }}
    />
  );
};

export default HexomaticSurveyQueries;
