import React, { useState, useEffect, FC } from "react";
import { Loader } from "tabler-react";

import Chart from "../../components/chart";
import { getCountArr } from "../../helpers";

interface CreditUsageLineChartsProps {
  timeSeries: any;
  timePremiumSeries: any;
}

const creditIds = ["61", "43", "6", "7", "97", "81", "17", "74", "98", "9"];

const premiumCreditIds = ["74", "38", "43", "95", "96", "45", "30", "31", "33", "105"];

enum AutomationNames {
  EMAIL_ADDRESS_VALIDATION = "61",
  SCRAPING_RECIPE = "43",
  EMAILS_SCRAPER = "6",
  SOCIAL_LINKS_SCRAPER = "7",
  PULL_CONTACTS = "97",
  CRAWLER = "81",
  GET_PAGE_CONTENT = "17",
  EMAIL_DISCOVERY = "74",
  DISCOVER_PROFILE = "98",
  SCREENSHOT_CAPTURE = "9",
}

enum PremiumAutomationNames {
  EMAIL_DISCOVERY = "74",
  TRAFFIC_INSIGHTS = "38",
  SCRAPING_RECIPE = "43",
  SEO_REFFERRING_DOMAINS = "96",
  SEO_BACKLINK_EXPLOER = "95",
  EMAIL_VERIFICATION = "45",
  GOOGLE_SEARCH = "30",
  GOOGLE_MAPS = "31",
  GOOGLE_TRANSLATE = "33",
  GOOGLE_IMAGE_SEARCH = "105",
}

const getCreditTotal = (totalArr: { time: number; count: number }[]) => {
  return totalArr.reduce((acc, val) => {
    const { time, count } = val;

    const ind = acc.findIndex((el) => el.time === time);
    if (ind !== -1) {
      acc[ind].count += count;
      acc[ind].count = +acc[ind].count.toFixed(2);
    } else {
      acc.push({
        time,
        count: +count.toFixed(2),
      });
    }

    return acc;
  }, []);
};

const CreditUsageLineCharts: FC<CreditUsageLineChartsProps> = (props) => {
  //! credit

  const [scrapingRecipe, setScrapingRecipe] = useState<{ time: number; count: number }[]>([]);

  const [emailScraper, setEmailScraper] = useState<{ time: number; count: number }[]>([]);

  const [socilaLinksScraper, setSocialLinksScraper] = useState<{ time: number; count: number }[]>([]);

  const [pullContacts, setPullContacts] = useState<{ time: number; count: number }[]>([]);

  const [emailAddressValidation, setEmailAddressValidation] = useState<{ time: number; count: number }[]>([]);

  const [crawler, setCrawler] = useState<{ time: number; count: number }[]>([]);
  const [getPageContent, setGetPageContent] = useState<{ time: number; count: number }[]>([]);
  const [emailDiscovery, setEmailDiscovery] = useState<{ time: number; count: number }[]>([]);
  const [discoverProfile, setDiscoverProfile] = useState<{ time: number; count: number }[]>([]);
  const [screenshotCapture, setScreenshotCapture] = useState<{ time: number; count: number }[]>([]);

  //!premium credit
  const [trafficInsights, setTrafficInsightsArr] = useState<{ time: number; count: number }[]>([]);

  const [seoBacklinkDomains, setSEOBacklinkDomains] = useState<{ time: number; count: number }[]>([]);
  const [emailDiscoveryPrem, setEmailDiscoveryArr] = useState<{ time: number; count: number }[]>([]);

  const [scrapingRecipePrem, setScrapingRecipePremium] = useState<{ time: number; count: number }[]>([]);
  const [seoRefDomains, setSEORefDomains] = useState<{ time: number; count: number }[]>([]);

  const [emailVerification, setEmailVarification] = useState<{ time: number; count: number }[]>([]);

  const [googleSearch, setGoogleSearch] = useState<{ time: number; count: number }[]>([]);
  const [googleMaps, setGoogleMaps] = useState<{ time: number; count: number }[]>([]);
  const [googleTRanslate, setGoogleTranslate] = useState<{ time: number; count: number }[]>([]);
  const [googleImageSearch, setGoogleImageSearch] = useState<{ time: number; count: number }[]>([]);

  //!total

  const [totalArr, setTotalArr] = useState<{ time: number; count: number }[]>([]);
  const [premiumTotalArr, setPremiumTotalArr] = useState<{ time: number; count: number }[]>([]);

  const [otherArr, setOtherArr] = useState<{ time: number; count: number }[]>([]);

  const [premiumOtherArr, setPremiumOtherArr] = useState<{ time: number; count: number }[]>([]);

  useEffect(() => {
    //!credit
    const Email_Address_Validation = [];
    const Scraping_recipe = [];
    const Emails_scraper = [];
    const Social_links_scraper = [];
    const Pull_contacts = [];
    const Crawler = [];
    const Get_page_content = [];
    const Email_discovery = [];
    const Discover_profile = [];
    const Screenshot_capture = [];

    //! premium credit
    const Email_discovery_premium = [];
    const Traffic_Insights = [];
    const Scraping_recipe_premium = [];
    const SEO_referring_domains = [];
    const SEO_backlink_explorer = [];
    const Email_Verification = [];
    const Google_Search = [];
    const Google_Maps = [];
    const Google_Translate = [];
    const Google_image_search = [];

    const other = [];
    const premiumOther = [];

    const total = [];
    const premiumTotal = [];

    // 34_deepl-translate //prem
    // 71_amazon-scraper // prem
    //  31_google-maps // prem
    //  30_google-search // prem
    // 74_email-discovery
    //  119 yelp
    //  81_crawler
    // 125_google_bigquery
    // 105_google-image-search
    // 33_google-translate-api

    // 15_get-word-count
    // 17_get-page-content
    // 92_find-and-replace
    // 104_article-curator
    // 98_discover-profile

    //47 data_input
    // 44_text-transformation
    // 65_keyword-finder
    // 12_discover-whois
    //76_phone-number

    props.timeSeries &&
      props.timeSeries.forEach((element) => {
        Object.keys(element.credit).forEach((el) => {
          total.push({
            time: new Date(element.time).getTime(),
            count: element.credit[el],
          });

          if (!creditIds.includes(el)) {
            other.push({
              time: new Date(element.time).getTime(),
              count: element.credit[el],
            });
          }

          if (el === "61") {
            Email_Address_Validation.push({
              time: new Date(element.time).getTime(),
              count: element.credit[el],
            });
          }
          if (el === "43") {
            Scraping_recipe.push({
              time: new Date(element.time).getTime(),
              count: element.credit[el],
            });
          }
          if (el === "6") {
            Emails_scraper.push({
              time: new Date(element.time).getTime(),
              count: element.credit[el],
            });
          }
          if (el === "7") {
            Social_links_scraper.push({
              time: new Date(element.time).getTime(),
              count: element.credit[el],
            });
          }
          if (el === "97") {
            Pull_contacts.push({
              time: new Date(element.time).getTime(),
              count: element.credit[el],
            });
          }

          if (el === "81") {
            Crawler.push({
              time: new Date(element.time).getTime(),
              count: element.credit[el],
            });
          }

          if (el === "17") {
            Get_page_content.push({
              time: new Date(element.time).getTime(),
              count: element.credit[el],
            });
          }

          if (el === "74") {
            Email_discovery.push({
              time: new Date(element.time).getTime(),
              count: element.credit[el],
            });
          }

          if (el === "98") {
            Discover_profile.push({
              time: new Date(element.time).getTime(),
              count: element.credit[el],
            });
          }

          if (el === "9") {
            Screenshot_capture.push({
              time: new Date(element.time).getTime(),
              count: element.credit[el],
            });
          }
        });
      });

    props.timePremiumSeries &&
      props.timePremiumSeries.forEach((element) => {
        Object.keys(element.premium_credit).forEach((el) => {
          premiumTotal.push({
            time: new Date(element.time).getTime(),
            count: +element.premium_credit[el].toFixed(2),
          });

          if (!premiumCreditIds.includes(el)) {
            premiumOther.push({
              time: new Date(element.time).getTime(),
              count: +element.premium_credit[el].toFixed(2),
            });
          }

          if (el === "74") {
            Email_discovery_premium.push({
              time: new Date(element.time).getTime(),
              count: +element.premium_credit[el].toFixed(2),
            });
          }

          if (el === "38") {
            Traffic_Insights.push({
              time: new Date(element.time).getTime(),
              count: +element.premium_credit[el].toFixed(2),
            });
          }
          if (el === "43") {
            Scraping_recipe_premium.push({
              time: new Date(element.time).getTime(),
              count: +element.premium_credit[el].toFixed(2),
            });
          }
          if (el === "96") {
            SEO_referring_domains.push({
              time: new Date(element.time).getTime(),
              count: +element.premium_credit[el].toFixed(2),
            });
          }
          if (el === "95") {
            SEO_backlink_explorer.push({
              time: new Date(element.time).getTime(),
              count: +element.premium_credit[el].toFixed(2),
            });
          }

          if (el === "45") {
            Email_Verification.push({
              time: new Date(element.time).getTime(),
              count: +element.premium_credit[el].toFixed(2),
            });
          }

          if (el === "30") {
            Google_Search.push({
              time: new Date(element.time).getTime(),
              count: +element.premium_credit[el].toFixed(2),
            });
          }
          if (el === "31") {
            Google_Maps.push({
              time: new Date(element.time).getTime(),
              count: +element.premium_credit[el].toFixed(2),
            });
          }

          if (el === "33") {
            Google_Translate.push({
              time: new Date(element.time).getTime(),
              count: +element.premium_credit[el].toFixed(2),
            });
          }
          if (el === "105") {
            Google_image_search.push({
              time: new Date(element.time).getTime(),
              count: +element.premium_credit[el].toFixed(2),
            });
          }
        });
      });

    total.length && setTotalArr(total);
    premiumTotal.length && setPremiumTotalArr(premiumTotal);

    other.length && setOtherArr(other);
    premiumOther.length && setPremiumOtherArr(premiumOther);

    //!credit
    Email_Address_Validation.length && setEmailAddressValidation(Email_Address_Validation);
    Scraping_recipe.length && setScrapingRecipe(Scraping_recipe);
    Emails_scraper.length && setEmailScraper(Emails_scraper);
    Social_links_scraper.length && setSocialLinksScraper(Social_links_scraper);
    Pull_contacts.length && setPullContacts(Pull_contacts);
    Crawler && setCrawler(Crawler);
    Get_page_content && setGetPageContent(Get_page_content);
    Email_discovery && setEmailDiscovery(Email_discovery);
    Discover_profile && setDiscoverProfile(Discover_profile);
    Screenshot_capture && setScreenshotCapture(Screenshot_capture);

    //! premium credit
    Email_discovery_premium.length && setEmailDiscoveryArr(Email_discovery_premium);
    Traffic_Insights.length && setTrafficInsightsArr(Traffic_Insights);
    Scraping_recipe_premium.length && setScrapingRecipePremium(Scraping_recipe_premium);
    SEO_referring_domains.length && setSEORefDomains(SEO_referring_domains);
    SEO_backlink_explorer.length && setSEOBacklinkDomains(SEO_backlink_explorer);
    Email_Verification && setEmailVarification(Email_Verification);
    Google_Search && setGoogleSearch(Google_Search);
    Google_Maps && setGoogleMaps(Google_Maps);
    Google_Translate && setGoogleTranslate(Google_Translate);
    Google_image_search && setGoogleImageSearch(Google_image_search);
  }, [props.timeSeries, props.timePremiumSeries]);

  return (
    <>
      {totalArr.length ? (
        <Chart
          yColName="Email Address Validation"
          yColName2="Scraping recipe"
          yColName3="Emails scraper"
          yColName4="Social links scraper"
          yColName5="Pull contacts"
          yColName6="Crawler"
          yColName7="Get page content"
          yColName8="Email discovery"
          yColName9="Discover profile"
          yColName10="Screenshot capture"
          yColName11="Total"
          yColName12="Other"
          regData={emailAddressValidation}
          regData2={scrapingRecipe}
          regData3={emailScraper}
          regData4={socilaLinksScraper}
          regData5={pullContacts}
          regData6={crawler}
          regData7={getPageContent}
          regData8={emailDiscovery}
          regData9={discoverProfile}
          regData10={screenshotCapture}
          regData11={totalArr.length && getCreditTotal(totalArr)}
          regData12={otherArr.length && getCreditTotal(otherArr)}
          interval={getCountArr(emailAddressValidation.length)}
          chartName="Automation credits usage"
          propColors={[
            "#99cc33",
            "#31b7fb",
            "#b174fc",
            "#000000",
            "#12ac33",
            "#11151d",
            "#6106d1",
            "#e61111",
            "#557c06",
            "#d38181",
          ]}
        />
      ) : (
        <div style={{ height: 300 }} className="d-flex justify-content-center align-items-center">
          <Loader />
        </div>
      )}

      {premiumTotalArr.length ? (
        <Chart
          yColName="Email discovery"
          yColName2="Traffic Insights"
          yColName3="Scraping recipe"
          yColName4="SEO referring domains"
          yColName5="SEO backlink explorer"
          yColName6="Email Verification (EasyDMARC)"
          yColName7="Google Search"
          yColName8="Google Maps"
          yColName9="Google Translate"
          yColName10="Google image search"
          yColName11="Premium Total"
          yColName12="Premium Other"
          regData={emailDiscoveryPrem}
          regData2={trafficInsights}
          regData3={scrapingRecipePrem}
          regData4={seoRefDomains}
          regData5={seoBacklinkDomains}
          regData6={emailVerification}
          regData7={googleSearch}
          regData8={googleMaps}
          regData9={googleTRanslate}
          regData10={googleImageSearch}
          regData11={premiumTotalArr.length && getCreditTotal(premiumTotalArr)}
          regData12={premiumOtherArr.length && getCreditTotal(premiumOtherArr)}
          interval={getCountArr(emailDiscovery.length)}
          chartName="Premium credits usage"
          propColors={[
            "#99cc33",
            "#31b7fb",
            "#b174fc",
            "#000000",
            "#12ac33",
            "#11151d",
            "#6106d1",
            "#e61111",
            "#557c06",
            "#d38181",
          ]}
        />
      ) : (
        <div style={{ height: 300 }} className="d-flex justify-content-center align-items-center">
          <Loader />
        </div>
      )}
    </>
  );
};

export default CreditUsageLineCharts;
