import React, { FormEvent, useEffect, useState } from "react";
import { Grid, Button, Alert, Form } from "tabler-react";
import { ContentTextProps, blackListProps } from "../../types";
import ServerSidePagination from "../../components/pagination/serverSidePagination";
import TableCard from "../../components/tableCard";
import TableModal from "./tableModal";
import DatePicker from "react-datepicker";
import moment from "moment";

import "./style.css";

const WorkFlows = ({
  data,
  loading,
  setsearchEmail,
  searchEmail,
  createAutomationCategory,
  onPageNumberChange,
  setHideHex,
  hideHex,
  ...props
}: any): JSX.Element => {
  const [workflowsList, setWorkflowsList] = useState<Array<blackListProps>>([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [mutationCompleted, setMutationCompleted] = useState(false);
  const [editEmail, setEditEmail] = useState<any>();
  const [editCategory, setEditCategory] = useState<any>(false);
  const [emailText, setEmailText] = useState("");
  const [total, setTotal] = useState(0);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const handleModal = () => {
    if (showModal) {
      setEditEmail(null);
      setErrorMessage("");
    }
    setShowModal(!showModal);
  };

  const handleSave = async ({ ...rest }) => {
    const { name } = rest;
    if (editCategory) {
    } else {
      const res = await createAutomationCategory({
        variables: { settings: { name: name } },
      });
    }
    return true;
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    props.setToDate(toDate);
    props.setFromDate(fromDate);
    setsearchEmail(emailText);
  };

  const handleFromDate = (date: Date) => {
    if (date) {
      return setFromDate(moment(date).format("YYYY-MM-DDT00:00:00.000"));
    }
    setFromDate(null);
  };

  const handleToDate = (date: Date) => {
    if (date) {
      return setToDate(moment(date).format("YYYY-MM-DDT23:59:59.999"));
    }
    setToDate(null);
  };

  const tableData = {
    tData:
      workflowsList &&
      workflowsList.map((item: ContentTextProps) => ({
        id: item.id,
        user_email: item.user_email,
        name: item.name,
        "is public": item.is_public ? "public" : "private",
        status: item.status,
        steps:
          item.steps &&
          item.steps.map((step) => <img src={step.automation_icon_url} style={{ width: "20px" }} alt="" />),

        //   "Action":
        //     <Button.List>
        //       <Button color="primary" icon="edit" onClick={() => console.log('edit')} />
        //       <Button color="danger" icon="trash" onClick={() => console.log('delete')} />
        //     </Button.List>
      })),
  };

  useEffect(() => {
    if (
      data &&
      data.HexomaticAdmin &&
      data.HexomaticAdmin.getWorkflows &&
      data.HexomaticAdmin.getWorkflows.workflows
    ) {
      setDataLoading(loading);
      setWorkflowsList(data.HexomaticAdmin.getWorkflows.workflows);
      setTotal(data.HexomaticAdmin.getWorkflows.total);
    }
  }, [data]);

  return (
    <Grid>
      <Grid.Row>
        <Grid.Col md={2}>
          <Form.Checkbox
            label="Filter Hexact workflows"
            checked={hideHex}
            onChange={() => setHideHex(!hideHex)}
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row cards deck>
        <TableModal
          handleModal={handleModal}
          showModal={showModal}
          onSave={handleSave}
          editEmail={editEmail}
          errorMessage={errorMessage}
        />
        <div className="col-12 col-md-8"></div>
        <div className="col-12 col-md-4">
          <form onSubmit={(e) => handleSubmit(e)}>
            <Form.Group>
              <Form.Input
                label="Input"
                className="margin-bottom"
                icon="search"
                position="append"
                name="userEmail"
                placeholder="Type user email for search"
                value={emailText}
                onChange={(e) => setEmailText(e.target.value)}
              />
              <Grid.Row>
                <Grid.Col>
                  <Form.Group label="From Date">
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      selected={fromDate ? new Date(fromDate) : null}
                      onChange={handleFromDate}
                      showMonthDropdown
                      placeholderText="Select a date"
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col>
                  <Form.Group label="To Date">
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      selected={toDate ? new Date(toDate) : null}
                      onChange={handleToDate}
                      showMonthDropdown
                      placeholderText="Select a date"
                      minDate={fromDate && new Date(fromDate)}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
              <Button
                type="submit"
                icon="filter"
                color="primary"
                className=""
                loading={dataLoading}
                // onClick={() => setsearchEmail(emailText)}
              >
                Filter
              </Button>
            </Form.Group>
          </form>
        </div>
        {/* </Grid.Row> */}

        <Grid.Col md={10}></Grid.Col>
        <div className="col-12 col-md-2 col-xs-6">
          <Alert type="info">Result Count - {total}</Alert>
        </div>
        <div className="black-list-table-pagination">
          <ServerSidePagination data={workflowsList} total={total} onPageNum={onPageNumberChange}>
            <TableCard loading={dataLoading} data={workflowsList} tableData={tableData} />
          </ServerSidePagination>
        </div>

        {workflowsList.length ? (
          <div className="col-12 col-md-2 col-xs-12 offset-md-10">
            <Alert type="info">Result Count - {total}</Alert>
          </div>
        ) : null}
      </Grid.Row>
    </Grid>
  );
};

export default WorkFlows;
