import React, { useState, useEffect } from 'react';
import { Grid, Button, Alert, Icon, Form } from 'tabler-react';
import moment from 'moment';
import { TasksProps, ContentTextProps } from "../../types";
import ServerSidePagination from "../../components/pagination/serverSidePagination";
import TableCard from "../../components/tableCard";
import DatePicker from 'react-datepicker';


const AITasks = ({ resolveTask, refetchData, ...props }: TasksProps): JSX.Element => {

  const [baseTasks, setBaseTasks] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [taskPagination, setTaskPagination] = useState([]);
  const [total, setTotal] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [mutationCompleted, setMutationCompleted] = useState(false);
  const [filterInput, setFilterInput] = useState('');
  const [selectPriority, setSelectPriority] = useState('');
  const [selectResolved, setSelectResolved] = useState('');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);


  const handleResolveTask = async (row: ContentTextProps) => {
    let resolveConfirm = confirm("Are you sure you want to resolve Task?");

    if (resolveConfirm) {
      const resolveResponse = await resolveTask({
        variables: { id: row.id }
      })

      const error = resolveResponse.data.TaskOps.adminResolve.error;
      const message = resolveResponse.data.TaskOps.adminResolve.message;
      if (error) {
        setErrorMessage(message);
      } else {
        setMutationCompleted(true);
        refetchData();
      }
    }
  }

  const tableData = {
    tData: baseTasks && baseTasks.map((item) => (
      {
        "ID": item.id,
        "Title": item.title,
        "Content": item.content,
        "Priority": item.priority,
        "Task Type": item.taskType,
        "Created At": moment(item.createdAt).utc().format('DD-MM-YYYY hh:mm:ss a'),
        "User": item.user && item.user.email,
        "Property": item.property.address,
        "Resolved": (
          item.resolved
            ?
            <div className="resolved-task">
              <Icon name="check-circle" />
            </div>
            :
            <Button className="p-0" color="link" onClick={() => handleResolveTask(item)} >Resolve</Button>
        )
      }
    ))
  };

  useEffect(() => {
    if (props.data && props.data.Task && props.data.Task.adminGetMany && props.data.Task.adminGetMany.tasks) {
      setDataLoading(props.loading);
      setBaseTasks(props.data.Task.adminGetMany.tasks);
      setTaskPagination(props.data.Task.adminGetMany.tasks);
      setTotal(props.data.Task.adminGetMany.total);
    }
  }, [props.data]);

  const handleInputChange = (e: React.FormEvent) => setFilterInput((e.target as HTMLInputElement).value);

  const handleSelectPriority = (e: React.FormEvent) => {
    const value = (e.target as HTMLInputElement).value;
    if (value === 'all') {
      return setSelectPriority('');
    }
    setSelectPriority((e.target as HTMLInputElement).value);
  }

  const handleSelectResolved = (e: React.FormEvent) => {
    const value = (e.target as HTMLInputElement).value;
    if (value === 'all') {
      return setSelectResolved('')
    } else {

      setSelectResolved((e.target as HTMLInputElement).value);
    }
  }

  const handleFromDate = (date: Date) => {
    if (date) {
      return setFromDate(moment(date).format("YYYY-MM-DDT00:00:00.000"));
    }
    return setFromDate(null);
  }

  const handleToDate = (date: Date) => {
    if (date) {
      return setToDate(moment(date).format("YYYY-MM-DDT23:59:59.999"));
    }
    return setToDate(null)
  }

  const handleSubmit = (event: React.FormEvent, input: string, priority: string, resolved: string, startDate: string, endDate: string) => {
    event.preventDefault();
    props.onFilterDataChange(input);
    props.onFromDate(startDate);
    props.onResolvedSelect(resolved);
    props.onToDate(endDate);
    if (priority === "All") {
      props.onPrioritySelect('');
    } else {
      props.onPrioritySelect(priority)
    }
  };

  return (
    <Grid>
      <Grid.Row className='justify-content-end'>
        <Grid.Col md={4} mb={1}>
          <form onSubmit={(e) =>
            handleSubmit(e, filterInput, selectPriority, selectResolved, fromDate, toDate)
          }>
            <Form.Group>
              <Form.Input
                label="Input"
                className="margin-bottom"
                icon="search"
                position="append"
                placeholder="Search for Title, Content, User Email, User Name, Property"
                value={filterInput}
                onChange={handleInputChange}
              />
              <Form.Select label="Priority" onChange={handleSelectPriority}>
                <option value='all'>all</option>
                <option>error</option>
                <option>warning</option>
              </Form.Select>
              <Form.Select label="Resolved" onChange={handleSelectResolved}>
                <option value='all'>all</option>
                <option>yes</option>
                <option>no</option>
              </Form.Select>
              <Form.Group label="From Date">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={fromDate ? new Date(fromDate) : null}
                  onChange={handleFromDate}
                  showMonthDropdown
                  placeholderText="Select a date"
                />
              </Form.Group>
              <Form.Group label="To Date">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={toDate ? new Date(toDate) : null}
                  onChange={handleToDate}
                  showMonthDropdown
                  placeholderText="Select a date"
                  minDate={fromDate && new Date(fromDate)}
                />
              </Form.Group>
              <Button
                className="margin-bottom"
                type="submit"
                icon="filter"
                color="primary"
              >
                Filter
            </Button>
            </Form.Group>
          </form>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row className='justify-content-between'>
        <Grid.Col md={3}>
          <Button
            color="primary"
          >
            Get CSV Link
          </Button>
        </Grid.Col>
        <Grid.Col md={3}>
          <Alert type="info">
            Result Count - {total}
          </Alert>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row cards deck>
        <ServerSidePagination
          data={taskPagination}
          total={total}
          onPageNum={props.onPageNumberChange}
        >
          <TableCard
            loading={dataLoading}
            data={baseTasks}
            tableData={tableData}
          />
        </ServerSidePagination>
      </Grid.Row>
      <Grid.Row className="justify-content-end">
        {total ? <Grid.Col md={3}>
          <Alert type="info">
            Result Count - {total}
          </Alert>
        </Grid.Col> : null}
      </Grid.Row>
    </Grid>
  )
};

export default AITasks;