import React, { FC, useState, useEffect } from "react";
import { Grid, Button, Form, Loader, Card } from "tabler-react";
import { useMutation, useQuery, useLazyQuery } from "@apollo/react-hooks";

import { CREATE_RUNDOM_TIME_AND_COUNT } from "../../graphql/mutations";
import { GET_RANDOM_TIME_AND_COUNT, RUN_RUNDOM_TIME_AND_COUNTS } from "../../graphql/queries";

interface RandomProps {}

const Random: FC<RandomProps> = (props) => {
  const [time, setTime] = useState(null);
  const [count, setCount] = useState(null);
  const [isStart, setIsStart] = useState(true);
  const [error, setError] = useState("");

  const { data, loading: dataLoading, refetch } = useQuery(GET_RANDOM_TIME_AND_COUNT);
  const [
    runRundomTimeAndCounts,
    { data: runRundomTimeAndCountsData, loading: runRundomTimeAndCountsLoading },
  ] = useLazyQuery(RUN_RUNDOM_TIME_AND_COUNTS);

  const [createRundomTimeAndCount, { loading }] = useMutation(CREATE_RUNDOM_TIME_AND_COUNT, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      if (data && data.AdminOps && !data.AdminOps.createRandomTimeAndCountConfig) {
        refetch();
        return;
      }
    },
  });

  useEffect(() => {
    if (
      data &&
      data.Admin &&
      data.Admin.getRandomTimeAndCountConfig &&
      !data.Admin.getRandomTimeAndCountConfig.error
    ) {
      const { counts, times } = data.Admin.getRandomTimeAndCountConfig;
      if (counts && counts.length && times && times.length) {
        setCount(counts.slice(","));
        setTime(times.slice(","));
        setIsStart(false);
        return;
      }

      if (!(counts && counts.length) && !(times && times.length)) {
        setCount("");
        setTime("");
        setIsStart(true);
      }
    }
  }, [data]);

  useEffect(() => {
    if (
      runRundomTimeAndCountsData &&
      runRundomTimeAndCountsData.Admin &&
      runRundomTimeAndCountsData.Admin.runRandomTimeAndCounts &&
      runRundomTimeAndCountsData.Admin.runRandomTimeAndCounts.error
    ) {
      setError(runRundomTimeAndCountsData.Admin.runRandomTimeAndCounts.error);
    }
  }, [runRundomTimeAndCountsData]);

  const handleSubmit = async () => {
    if (time && count && isStart) {
      const { data } = await createRundomTimeAndCount({
        variables: {
          settings: {
            counts: count.split(",").map((el) => +el),
            times: time.split(",").map((el) => +el),
          },
        },
      });
      const errorMsg =
        data &&
        data.AdminOps &&
        data.AdminOps.createRandomTimeAndCountConfig &&
        data.AdminOps.createRandomTimeAndCountConfig.error_code;
      if (!errorMsg) {
        runRundomTimeAndCounts();
      }
    }

    if (!isStart) {
      createRundomTimeAndCount({
        variables: {
          settings: {
            counts: [],
            times: [],
          },
        },
      });
    }
  };

  return (
    <Grid>
      {dataLoading ? (
        <Card>
          <Loader className="position-static" />
        </Card>
      ) : (
        <Grid.Row>
          <div className="col-4 mx-auto">
            <Form.Group>
              <Form.Input
                placeholder="count"
                label="Count"
                value={count}
                onChange={(e: { target: { value: React.SetStateAction<number> } }) =>
                  setCount(e.target.value)
                }
              />
              <Form.Input
                placeholder="time"
                label="Time"
                value={time}
                onChange={(e: { target: { value: React.SetStateAction<number> } }) => setTime(e.target.value)}
              />

              <Button className="margin-bottom" onClick={handleSubmit} color="primary" loading={loading}>
                {isStart ? "Start" : "Stop"}
              </Button>
            </Form.Group>
          </div>
        </Grid.Row>
      )}
    </Grid>
  );
};

export default Random;
