import React, { useState, useEffect } from "react";
import { CREATE_AUTOMATION, ATTACH_SCHEMAS_TO_AUTOMATION, DELETE_SCHEMAS_FROM_AUTOMATION} from "../../graphql/mutations";
import { GET_AUTOMATION_WITH_IO, GET_SCHEMAS, } from "../../graphql/queries";
import BlackListEmail from "./index";
import { useQuery, useMutation } from "@apollo/react-hooks";

const BlackListEmailQueries = () => {
  const [atuomationList, setAtuomationList] = useState<any>([]);
  const [pageNumberState, setPageNumberState] = useState(1);
  const handlePageNumberChange = (pageNumber: number) => setPageNumberState(pageNumber);
  const [searchEmail, setsearchEmail] = useState<string>('')
  const [schemaList, setSchemaList] = useState<Array<any>>([]);

  const { data, loading, refetch } = useQuery(GET_AUTOMATION_WITH_IO, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      settings: {
        filter: {
          limit: 100
        }
      }
    }
  })

  const { data: schemaData, } = useQuery(GET_SCHEMAS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      settings: {
        filter: {
          limit: 100
        }
      }
    }
  })

  const [deleteSchemaFromAutomation] = useMutation(DELETE_SCHEMAS_FROM_AUTOMATION)
  const [attachSchemaToAutomation] = useMutation(ATTACH_SCHEMAS_TO_AUTOMATION)

  const [createAutomation] = useMutation(CREATE_AUTOMATION, {
    onCompleted: () => {
      refetchData();
    }
  })

  useEffect(() => {
    refetch()
  }, [pageNumberState])

  useEffect(() => {
    setAtuomationList(data)
  }, [data])

  useEffect(() => {
    if (schemaData && schemaData.HexomaticAdmin && schemaData.HexomaticAdmin.getJsonSchemas.json_schemas) {
      setSchemaList(schemaData.HexomaticAdmin.getJsonSchemas.json_schemas)
    }
  }, [schemaData])

  useEffect(() => {
    refetchData()
  }, [searchEmail])

  const refetchData = async () => {
    await refetch()
  }

  return (
    <BlackListEmail
      data={atuomationList}
      loading={loading}
      searchEmail={searchEmail}
      setsearchEmail={setsearchEmail}
      onPageNumberChange={handlePageNumberChange}
      createAutomation={createAutomation}
      schemaList={schemaList}
      deleteSchemaFromAutomation={deleteSchemaFromAutomation}
      attachSchemaToAutomation={attachSchemaToAutomation}
      refetchData={refetchData}
    />
  )
};


export default BlackListEmailQueries;
