import React, { useState, Fragment, useEffect } from "react";
import { GET_DISCOVERPROFILE_DATA } from "../../graphql/queries";
import DiscoverProfile from "./index";
import { Grid, Form } from "tabler-react";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import {isProduction} from '../../helpers';

const DiscoverProfileQueries = () => {
  const [ipSearch, setIpSearch] = useState("");
  const [source, setSource] = useState("");
  const [pageNumberState, setPageNumberState] = useState(1);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [filterHexUsers, setFilterHexUsers] = useState(true);
  const [orderBy, setOrderBy] = useState("");
  const [orderByASC, setOrderByASC] = useState(false);
  const [status, setStatus] = useState("all");
  const [googleDrive, setGoogleDrive] = useState(false);
  const { data, loading, refetch } = useQuery(GET_DISCOVERPROFILE_DATA, {
    fetchPolicy: "no-cache",
    variables: {
      filter: {
        ip: ipSearch,
        source: source,
        status: status === "all" ? null : status,
        from: fromDate,
        to: toDate,
      },
      sortSettings: {
        sortField: "",
        sortDir: orderBy,
      },
      pageSettings: {
        page: pageNumberState,
        pageSize: 100,
      },
    },
  });

  const [getCSVLink, { data: CSVLink, loading: csvLoading }] = useLazyQuery(GET_DISCOVERPROFILE_DATA);

  const handleIpSearch = (value: string) => setIpSearch(value);

  const handlePageNumberChange = (pageNumber: number) => {
    setPageNumberState(pageNumber);
  }
   

  const handleFromDate = (date: string) => setFromDate(date);
  const handleToDate = (date: string) => setToDate(date);

  const handleSource = (value: string) => setSource(value);

  useEffect(() => {
    if (
      CSVLink &&
      CSVLink.DiscoverProfile &&
      CSVLink.DiscoverProfile.getDiscoverProfileData &&
      CSVLink.DiscoverProfile.getDiscoverProfileData.csvURL
    ) {
      if (googleDrive) {
        window.open(
          isProduction()
            ? `https://api.hexometer.com/v2/app/social-callback/googleDrive?admin=admin&report=freeTools&url=${CSVLink.DiscoverProfile.getDiscoverProfileData.csvURL}`
            : `http://localhost:4000/api/app/social-callback/googleDrive?admin=admin&report=freeTools&url=${CSVLink.DiscoverProfile.getDiscoverProfileData.csvURL}`,
          "_blank"
        );
      } else {
        window.open(CSVLink.DiscoverProfile.getDiscoverProfileData.csvURL, "_blank");
      }
    }
  }, [CSVLink]);

  const handleGettingCSVLink = (googleDrive: boolean) => {
    setGoogleDrive(googleDrive); 
    getCSVLink({
      variables: {
        googleDrive: googleDrive,
        filter: {
          ip: ipSearch,
          source: source,
          status: status === "all" ? null : status,
          from: fromDate,
          to: toDate,
          csvDownload: true,
        },
        sortSettings: {
          sortField: "",
          sortDir: orderBy,
        },
        pageSettings: {
          page: undefined,
          pageSize: undefined,
        },
      },
    })
  }

  return (
    <Fragment>
      {/* <Grid.Row>
        <Grid.Col md={12}>
          <Form.Checkbox
            label="Filter data from Hexact"
            name="example-radios"
            value="option1"
            checked={filterHexUsers}
            onChange={() => setFilterHexUsers(!filterHexUsers)}
          />
        </Grid.Col>
      </Grid.Row> */}
      <DiscoverProfile
        data={data}
        loading={loading}
        onHandleIpSearch={handleIpSearch}
        onPageNumberChange={handlePageNumberChange}
        onFromDate={handleFromDate}
        onToDate={handleToDate}
        onStatusChange={setStatus}
        onHandleSource={handleSource}
        refetchData={() => refetch()}
        filterHexProperties={filterHexUsers}
        setOrderByASC={setOrderByASC}
        orderByASC={orderByASC}
        setOrderBy={setOrderBy}
        page={pageNumberState}
        variables={{
          filter: {
            ip: ipSearch,
            source: source,
            from: fromDate,
            to: toDate,
          },
          sortSettings: {
            sortField: "",
            sortDir: orderBy,
          },
          pageSettings: {
            page: pageNumberState,
            pageSize: 100,
          },
        }}
        csvLoading={csvLoading && !googleDrive}
        googleDriveLoading={csvLoading && googleDrive}
        getCSV={() => handleGettingCSVLink(false)}
        uploadToDrive={() => handleGettingCSVLink(true)}
      />
    </Fragment>
  );
};

export default DiscoverProfileQueries;
