import React, { useEffect, useState } from "react";
import { Grid, Button, Alert, Form } from "tabler-react";
import ReactTooltip from "react-tooltip";
import { useToasts } from "react-toast-notifications";
import { useMutation } from "@apollo/react-hooks";
import { SEND_TEMPLATE_TEST_EMAIL } from "../../graphql/mutations";
import { ContentTextProps, blackListProps } from "../../types";
import ServerSidePagination from "../../components/pagination/serverSidePagination";
import TableCard from "../../components/tableCard";
import TableModal from "./tableModal";
import { fullTemplatesList } from "../../constants";
import ReactModal from "react-modal";

import "./style.css";
import { alertCustomStyles } from "../../helpers";
import moment from "moment";

const EmailCustomMessages = ({
  data,
  loading,
  createAutomationCategory,
  deleteCategory,
  updateCategory,
  onPageNumberChange,
}: any): JSX.Element => {
  const [categoryList, setCategoryList] = useState<Array<blackListProps>>([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [editCategory, setEditCategory] = useState<any>(false);
  const [total, setTotal] = useState(0);
  const [editField, setEditField] = useState<any>(null);
  const [preview, setPreview] = useState(null as any);

  const { addToast } = useToasts();

  const [sendTestEmail, { loading: sending }] = useMutation(SEND_TEMPLATE_TEST_EMAIL);

  const handleModal = () => {
    if (showModal) {
      setEditField(null);
      setErrorMessage("");
    }
    setShowModal(!showModal);
  };

  const handleSave = async ({ ...rest }) => {
    const { img, subject, description, href, date, templateNames } = rest;
    const formatedDate =
      date && moment(date, "MM/DD/YYYY").isValid() ? moment(date, "MM/DD/YYYY").format("DD/MM/YYYY") : null;
  
    if (!img || !subject || !description || !href || !templateNames) {
      setErrorMessage("Please complete all fieds");
      return false;
    }
    if (!formatedDate) {
      setErrorMessage("Please enter valid date");
      return false;
    }
    if (editCategory) {
    } else {
      const res = await createAutomationCategory({
        variables: {
          settings: {
            variables: {
              img_url: img,
              subject: subject,
              description: description,
              href: href,
              date: formatedDate,
            },
            templates_id: templateNames,
          },
        },
      });

      if (
        res.data.CustomMessageOps &&
        res.data.CustomMessageOps.createCustomMessage &&
        !res.data.CustomMessageOps.createCustomMessage.error
      ) {
        handleModal();
        return;
      }
      const { error } = res.data.CustomMessageOps.createCustomMessage;
      if (error) {
        setErrorMessage(error);
        return;
      }
    }
    return true;
  };

  const onDelete = async (id: number) => {
    let deleteConfirm = confirm(`Are you sure you want to delete the custom message`);
    if (!deleteConfirm) return;
    const res = await deleteCategory({
      variables: { id: id },
    });
    if (
      res.data.CustomMessageOps &&
      res.data.CustomMessageOps.deleteCustomMessage &&
      !res.data.CustomMessageOps.deleteCustomMessage.error
    ) {
      setCategoryList(categoryList.filter((item) => item.id !== id));
      return;
    }
    const { message } = res.data.CustomMessageOps.deleteCustomMessage;
    if (message) {
      setErrorMessage(message);
      return;
    }
  };

  const onUpdata = async ({ news_id, img, subject, description, href, date, templates_id }) => {
    const formatedDate =
      date && moment(date, "DD/MM/YYYY").isValid() ? moment(date, "DD/MM/YYYY").format("DD/MM/YYYY") : null;
    if (!formatedDate) {
      setErrorMessage("Please enter valid date");
      return;
    }
    const res = await updateCategory({
      variables: {
        settings: {
          news_id: news_id,
          variables: {
            img_url: img,
            subject,
            description,
            href,
            date: formatedDate,
          },
          templates_id,
        },
      },
    });
    handleModal();
    if (
      res.data.CustomMessageOps &&
      res.data.CustomMessageOps.updateCustomMessage &&
      !res.data.CustomMessageOps.updateCustomMessage.error
    ) {
      const newCategories = categoryList.map((item) => {
        if (item.id === news_id) {
          return {
            ...item,
            variables: {
              img_url: img,
              subject,
              description,
              href,
              date,
            },
            templates_id,
          };
        }
        return item;
      });
      setCategoryList(newCategories);
      return;
    }
    const { error_code } = res.data.CustomMessageOps.updateCustomMessage;
    if (error_code) {
      setErrorMessage(error_code);
      return;
    }
  };

  const getTemplateNames = (templates: string) => {
    const data = (typeof templates === "string" ? templates.split(", ") : templates).map((temp: any) => {
      const val = fullTemplatesList.find((sub: any) =>
        typeof sub.value === "number" ? sub.value === +temp : sub.value.includes(+temp)
      );

      return val && val.label;
    });
    const withoitDuplicates = [...new Set(data)];
    return withoitDuplicates && withoitDuplicates.join(", ");
  };

  const handleSendingTestEmail = async (item: any) => {
    const data = await sendTestEmail({
      variables: {
        variables: {
          updatesURL: item.variables.href,
          updatesImage: item.variables.img_url,
          updatesSubject: item.variables.subject,
          updatesText: item.variables.description,
          updateDate: item.variables.date,
        },
      },
    });
    const { error, message } =
      data && data.data && data.data.CustomMessageOps && data.data.CustomMessageOps.sendAdminTestEmail;
    if (error) {
      addToast(message, { appearance: "error" });
    } else {
      addToast("Email is successfully sent", { appearance: "success" });
    }
  };

  const tableData = {
    tData:
      categoryList &&
      categoryList.map((item: ContentTextProps) => ({
        id: item.id,
        title: item.variables.subject,
        text: item.variables.description,
        image: item.variables.img_url,
        URL: item.variables.href,
        templates: getTemplateNames(item.templates_id),
        Actions: (
          <Button.List>
            <Button
              color="success"
              icon="eye"
              onClick={() => {
                setPreview(item);
              }}
            />
            <ReactTooltip
              effect={"solid"}
              place={"top"}
              backgroundColor={"#daeefc"}
              isCapture={true}
              textColor="#24587e"
            />
            <span data-tip={"Click to send test message to your email"} className="test-email-btn">
              <Button
                color="warning"
                icon="mail"
                disabled={sending}
                onClick={() => {
                  handleSendingTestEmail(item);
                }}
              />
            </span>
            <Button
              color="primary"
              icon="edit"
              onClick={() => {
                setEditField(item);
                setShowModal(true);
              }}
            />
            <Button color="danger" icon="trash" onClick={() => onDelete(item.id)} />
          </Button.List>
        ),
      })),
  };

  useEffect(() => {
    if (
      data &&
      data.CustomMessage &&
      data.CustomMessage.getCustomMessages &&
      data.CustomMessage.getCustomMessages.customMessages
    ) {
      setDataLoading(loading);
      const values = data.CustomMessage.getCustomMessages.customMessages.map((item: any) => {
        return { ...item, templates_id: item.templates_id.join(", ") };
      });
      setCategoryList(values);
      // setTotal(data.EmailBlackList.adminGetMany.total)
    }
  }, [data]);

  return (
    <Grid.Row cards deck>
      <TableModal
        handleModal={handleModal}
        showModal={showModal}
        onUpdate={onUpdata}
        onSave={handleSave}
        editField={editField}
        errorMessage={errorMessage}
      />

      <ReactModal isOpen={!!errorMessage} style={alertCustomStyles}>
        {errorMessage ? <Alert type="danger">{errorMessage}</Alert> : null}
        <Button className="" onClick={() => setErrorMessage("")} color="primary">
          OK
        </Button>
      </ReactModal>
      <div className="col-12 col-md-1 col-xs-6">
        <Button className="" onClick={() => setShowModal(true)} color="primary">
          Create
        </Button>
        <br />
      </div>
      <Grid.Col md={9}></Grid.Col>
      <div className="col-12 col-md-2 col-xs-6">
        <Alert type="info">Result Count - {total || categoryList.length}</Alert>
      </div>
      <div className="black-list-table-pagination">
        <ServerSidePagination data={categoryList} total={total} onPageNum={onPageNumberChange}>
          <TableCard loading={dataLoading} data={categoryList} tableData={tableData} />
        </ServerSidePagination>
      </div>

      {categoryList.length ? (
        <div className="col-12 col-md-2 col-xs-12 offset-md-10">
          <Alert type="info">Result Count - {total || categoryList.length}</Alert>
        </div>
      ) : null}
      {preview && preview.variables && (
        <ReactModal
          isOpen={preview}
          contentLabel="Custom message preview"
          onRequestClose={() => setPreview(null)}
          className="preview-modal"
        >
          <div style={{ background: "#fff", maxWidth: "270px", margin: "auto" }}>
            <a href={preview.variables.href} target="_blank" style={{ textDecoration: "none" }}>
              <div style={{ border: "1px solid #E5E5E5", borderRadius: "8px" }}>
                <div
                  style={{
                    background: "#F5F7F6",
                    height: "150px",
                    overflow: "hidden",
                    display: "flex",
                    borderRadius: "8px 8px 0 0",
                  }}
                >
                  <img
                    style={{
                      height: "auto",
                      width: "100%",
                      margin: "auto",
                      position: "relative",
                      top: " 50%",
                      transform: " translateY(-50%)",
                    }}
                    src={preview.variables.img_url}
                    alt={preview.variables.subject}
                  />
                </div>
                <div style={{ padding: "20px 10px" }}>
                  <div
                    style={{
                      height: "30px",
                      overflow: "hidden",
                      color: "#000",
                      lineHeight: "14px",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                  >
                    {preview.variables.subject}
                  </div>
                </div>
                <div style={{ padding: "0px 10px" }}>
                  <div
                    style={{
                      height: "50px",
                      overflow: "hidden",
                      color: "#6F6D6D",
                      lineHeight: "16px",
                      fontSize: "10px",
                    }}
                  >
                    {preview.variables.description}
                  </div>
                </div>

                <div style={{ padding: "10px", display: "flex", borderRadius: "0 0 8px 8px" }}>
                  <div
                    style={{ padding: "0px 10px 0 0", fontSize: "10px", color: "#757575", margin: "auto 0" }}
                  >
                    {preview.variables.date}
                  </div>
                  <div
                    style={{ padding: "0px", fontSize: "12px", fontWeight: 600, margin: "auto 0 auto auto" }}
                  >
                    <a href={preview.variables.href} style={{ textDecoration: "none", color: "#AF71FB" }}>
                      Read more →
                    </a>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </ReactModal>
      )}
    </Grid.Row>
  );
};

export default EmailCustomMessages;
