import React, { useState, Fragment } from "react";
import { DEMO_REQUESTS_REPORT } from "../../graphql/queries";
import CancellationFeedbacks from ".";
//import { Grid, Form } from "tabler-react";
import { useQuery } from "@apollo/react-hooks";

const DemoRequestsQueries = () => {
  const [searchText, setSearchText] = useState("");
  const [pageNumberState, setPageNumberState] = useState(1);
  const [productName, setProductName] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [filterHexUsers, setFilterHexUsers] = useState(true);
  const [orderBy, setOrderBy] = useState("");
  const [orderByASC, setOrderByASC] = useState(false);

  const { data, loading } = useQuery(DEMO_REQUESTS_REPORT, {
    fetchPolicy: "no-cache",
    variables: {
      settings: {
        filter: {
          date_from: fromDate,
          date_to: toDate,
          search: {
            search_field: undefined,
            search_value: searchText,
          },
          page: pageNumberState,
          limit: 100,
          product: productName === 'all' ? '' : productName,
        },
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const handleSearchText = (searchText: string) => setSearchText(searchText);
  const handlePageNumberChange = (pageNumber: number) =>
    setPageNumberState(pageNumber);
  const handleProductSelect = (product: string) => setProductName(product);
  const handleFromDate = (date: string) => setFromDate(date);
  const handleToDate = (date: string) => setToDate(date);

  return (
    <Fragment>
      {/* <Grid.Row>
        <Grid.Col md={2}>
          <Form.Checkbox
            label="Filter Hexact Scans"
            checked={filterHexUsers}
            onChange={() => setFilterHexUsers(!filterHexUsers)}
          />
        </Grid.Col>
     </Grid.Row>*/}
      <CancellationFeedbacks
        data={data}
        loading={loading}
        onSearchText={handleSearchText}
        onPageNumberChange={handlePageNumberChange}
        onProductSelect={handleProductSelect}
        onFromDate={handleFromDate}
        onToDate={handleToDate}
        filterHexScans={filterHexUsers}
        setOrderBy={setOrderBy}
        setOrderByASC={setOrderByASC}
        orderByASC={orderByASC}
        productName={productName === 'all' ? '' : productName}
        variables={{
          filter: {
            date_from: fromDate,
            date_to: toDate,
            search: {
              search_field: undefined,
              search_value: searchText,
            },
            // page: pageNumberState,
            // limit: 100,
            product: productName === 'all' ? '' : productName
          },
        }}
      />
    </Fragment>
  );
};

export default DemoRequestsQueries;
